import { defineStore } from 'pinia';
import { shallowRef } from 'vue';

import { getBedarfsinfoFor, getBeratungsdaten } from "@/api/mein-bedarf/meinBedarfApi";
import type { Bedarfsinfo } from "@/bedarf/types/bedarfsinfo";
import type { VerlaufLink } from "@/bedarf/types/verlauf";

import type { ThemenMenuItem } from "@/api/mein-bedarf/types";

export const useBedarfStore = defineStore('bedarf', () => {

  const bearbeitbar = shallowRef(false);
  const verlauf = shallowRef<VerlaufLink[]>([]);
  const themen = shallowRef<ThemenMenuItem[]>([]);
  let bedarfsinfo: null | Bedarfsinfo = null;

  function getBedarfsinfo(): Bedarfsinfo {
    if (!bedarfsinfo) throw new Error('Bedarfsinfo nicht gefunden.');
    return bedarfsinfo;
  }

  async function fetchBeratungsdaten(beratungstoken: string | null) {
    const resp = beratungstoken
      ? await getBeratungsdaten({ bedarfHash: beratungstoken })
      : await getBeratungsdaten();

    if (resp instanceof Error) return;

    themen.value = resp.themen;
    bearbeitbar.value = resp.bearbeitbar;
    verlauf.value = resp.verlauf.map(item => {
      const url = new URL(item.link);
      item.link = url.pathname + url.search
      return item
    });
  }

  async function fetchBedarfsinfo(bedarfHash: string, sparte: string) {
    const resp = await getBedarfsinfoFor({ bedarfHash, sparte });
    if (resp instanceof Error) return resp;
    bedarfsinfo = resp;

    return resp;
  }

  function $reset(): void {
    bearbeitbar.value = false;
    verlauf.value = [];
    themen.value = [];
    bedarfsinfo= null;
  }

  return { bearbeitbar, verlauf, themen, getBedarfsinfo, fetchBeratungsdaten, fetchBedarfsinfo, $reset };
});
