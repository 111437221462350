<template>
  <div
    class="flex flex-col w-full md:pr-2 md:border-r-2"
    :class="hasLongFirmenname ? 'md:max-w-[260px] lg:max-w-xs' : 'md:w-fit'"
  >
    <template v-if="isMobile">
      <span class="font-semibold min-w-0 truncate text-gray-900">
        {{ menuTitle }}
      </span>
      <span
        v-if="firmenname"
        :class="hasLongFirmenname ? 'text-xs tracking-tight' : 'text-sm'"
        :title="firmenname"
        class="truncate leading-5"
      >
        {{ firmenname }}
      </span>
    </template>
    <template v-else>
      <span
        v-if="maklername"
        :title="maklername"
        class="font-semibold min-w-0 truncate text-gray-900"
      >
        {{ maklername }}
      </span>
      <span
        v-if="firmenname && maklername !== firmenname"
        class="truncate leading-5"
        :class="hasLongFirmenname ? 'text-xs tracking-tight' : 'text-sm'"
        :title="firmenname"
      >
        {{ firmenname }}
      </span>
    </template>
  </div>

  <template v-if="!isMobile">
    <DesktopMenu />
    <DesktopMenuDropdown />
  </template>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useDetectMobile } from '@/application/composables/detectMobile';
import { useBaseDataStore } from '@/user/store/baseDataStore';
import DesktopMenu from "@/application/menu/components/DesktopMenu.vue";
import DesktopMenuDropdown from "@/application/menu/components/DesktopMenuDropdown.vue";
import { useRoute } from "vue-router";

const { firmenname, maklername } = storeToRefs(useBaseDataStore());

const route = useRoute();
const menuTitle = computed(() => route.meta.menuTitle ?? 'meine-finanzen.digital');
const { isMobile } = useDetectMobile();

const hasLongFirmenname = computed(() => firmenname.value && firmenname.value.length > 39);
</script>
