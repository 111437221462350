<template>
  <DsInput
    v-model="proxy"
    type="date"
  />
</template>

<script setup lang="ts">
import { DsInput } from '@demvsystems/design-components';
import { format as dateFormat, parse } from 'date-fns';
import { computed } from 'vue';

interface Props {
  modelValue: string | null;
  format?: string;
}

const props = withDefaults(defineProps<Props>(), {
  format: 'dd.MM.yyyy',
});

const emits = defineEmits(['update:modelValue']);

// DsInput from type date needs a date object (parse the string in get) or null,
// while our backend needs a string (format the Date in set)
const proxy = computed<Date | null>({
  get: () => {
    return props.modelValue === null ? null : parse(props.modelValue, props.format, new Date());
  },
  set: (date: Date | null | undefined) => {
    emits('update:modelValue', !date ? null : dateFormat(date, props.format));
  },
});
</script>
