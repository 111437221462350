import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import type { RouteLocationNormalized } from 'vue-router';

import * as base from '@/checkup/state/base';
import { clearErrorHandler } from '@/checkup/state/clearErrorHandler';
import { Step } from '@/checkup/utils/steps';
import type {
  AbschlussForm,
  BerufsdatenForm,
  CheckupErrors,
  CheckupState,
  DEMVWerteResponse,
  FahrzeugeForm,
  FinanzenForm,
  FreizeitForm,
  GesundheitForm,
  GrunddatenForm,
  KinderForm,
  LaravelError,
  PartnerForm,
  UseStep,
  WohnenForm
} from '@/checkup/types/types';
import { unflatten } from '@/checkup/utils/unflatten';
import { ValidationError } from "@/api/lib/errors";
import { throwableCall } from "@/api/lib/integration";
import { getSessionData } from "@/api/checkup/checkupApi";

/**
 * Data, Error and Meta states
 * */

// Reactive State on Step level and first object level,
// we can easily set first level when loading session
export const checkupState: CheckupState = {
  [Step.GRUNDDATEN]: ref<GrunddatenForm>(JSON.parse(JSON.stringify(base.grunddatenForm))),
  [Step.BERUF]: ref<BerufsdatenForm>(JSON.parse(JSON.stringify(base.berufsdatenForm))),
  [Step.FINANZEN]: ref<FinanzenForm>(JSON.parse(JSON.stringify(base.finanzenForm))),
  [Step.WOHNEN]: ref<WohnenForm>(JSON.parse(JSON.stringify(base.wohnenForm))),
  [Step.GESUNDHEIT]: ref<GesundheitForm>(JSON.parse(JSON.stringify(base.gesundheitForm))),
  [Step.FAHRZEUGE]: ref<FahrzeugeForm>(JSON.parse(JSON.stringify(base.fahrzeugeForm))),
  [Step.FREIZEIT]: ref<FreizeitForm>(JSON.parse(JSON.stringify(base.freizeitForm))),
  [Step.PARTNER]: ref<PartnerForm>(JSON.parse(JSON.stringify(base.parterForm))),
  [Step.KINDER]: ref<KinderForm>(JSON.parse(JSON.stringify(base.kinderForm))),
  [Step.ABSCHLUSS]: ref<AbschlussForm>(JSON.parse(JSON.stringify(base.abschlussForm))),
};

export const resetState = () => {
  checkupState[Step.GRUNDDATEN].value =JSON.parse(JSON.stringify(base.grunddatenForm));
  checkupState[Step.BERUF].value =JSON.parse(JSON.stringify(base.berufsdatenForm));
  checkupState[Step.FINANZEN].value =JSON.parse(JSON.stringify(base.finanzenForm));
  checkupState[Step.WOHNEN].value =JSON.parse(JSON.stringify(base.wohnenForm));
  checkupState[Step.GESUNDHEIT].value =JSON.parse(JSON.stringify(base.gesundheitForm));
  checkupState[Step.FAHRZEUGE].value =JSON.parse(JSON.stringify(base.fahrzeugeForm));
  checkupState[Step.FREIZEIT].value =JSON.parse(JSON.stringify(base.freizeitForm));
  checkupState[Step.PARTNER].value =JSON.parse(JSON.stringify(base.parterForm));
  checkupState[Step.KINDER].value =JSON.parse(JSON.stringify(base.kinderForm));
  checkupState[Step.ABSCHLUSS].value =JSON.parse(JSON.stringify(base.abschlussForm));
}

export const flattenedCheckupDataBefore: Record<string, unknown> = {};

export const checkupStartDate: Ref<null | Date> = ref(null);

export const checkupErrors = Object.fromEntries(Object
  .keys(Step)
  .map((step) => [Step[step as keyof typeof Step], ref<LaravelError | null>(null)])) as CheckupErrors;

// Meta data
export const currentStep = ref<number>(0);
export const isRouting = ref<boolean>(false);
export const currentRoute = ref<RouteLocationNormalized | null>(null);

export const demvWerte = {} as DEMVWerteResponse;
/**
 * accessor functions
 * */

export const useStep = <S extends Step>(step: S): UseStep<S> => {
  const form = checkupState[step];
  const errors = checkupErrors[step];
  const formErrors = computed<Record<string, string[] | never>>(() => (errors.value ? errors.value.errors : {}));
  const errorMessage = computed<string>(() => (errors.value ? errors.value.message : ''));

  const clear = clearErrorHandler(errors);

  return {
    form,
    formErrors,
    errorMessage,
    clear,
    set: (field, value) => {
      form.value[field as keyof typeof field] = value;
      clear(field);
    },
  };
};

export const handleValidationError = (step, error) => {
  if (error instanceof ValidationError) {
    const { message, errors } = error;
    checkupErrors[step as keyof CheckupErrors].value = { message, errors: unflatten(errors) };

    const target = document.getElementById(`formError.${Object.keys(errors)[0]}`)

    if (target) {
      target.scrollIntoView({ behavior: "smooth", block: 'center' });
    }

    return true;
  }
  return false;
};

export async function loadSessionData(): Promise<void> {
  const data = await throwableCall(getSessionData())
  const steps = Object.keys(data.data);

  steps.forEach((step) => {
    Object.assign(checkupState[step].value, data.data[step]);
  });
  currentStep.value = data.currentStep;

  for (const key in data.data) {
    Object.assign(flattenedCheckupDataBefore, data.data[key]);
  }

  // data from getSessionData includes all checkup fields except 'anmerkungen'. To compare checkupData before and after
  // completing the checkup, we need to add 'anmerkungen = null' to flattenedCheckupDataBefore
  flattenedCheckupDataBefore.anmerkungen = null;
}

export const resetStepErrors = (step: string) => {
  checkupErrors[step].value = null;
}

export function resetErrors() {
  Object.keys(checkupErrors).map(step => {
    checkupErrors[step].value = null;
  });
}
