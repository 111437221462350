import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 62 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m59.9 12.14-2.76-8.1v-.06a5.75 5.75 0 0 0-5.27-3.5H35.35A20.56 20.56 0 0 0 20.7 6.53l-5.4 4.66h-3.37A11.87 11.87 0 0 0 .14 23v2.57c0 .54.47 1 1 1h5.8A6.77 6.77 0 0 0 20.3 25.1a6.77 6.77 0 0 0-13.49-.54H2.16V23c0-5.4 4.38-9.78 9.78-9.78h45a3.02 3.02 0 0 1 3.03 3.04v8.3h-4.45c-.27-3.51-3.17-6.21-6.75-6.21s-6.48 2.76-6.75 6.2H20.3v2.03h21.86c.67 3.03 3.37 5.26 6.61 5.26s5.94-2.3 6.61-5.26h4.92c.95 0 1.7-.74 1.7-1.69v-8.63a5.48 5.48 0 0 0-2.1-4.12m-46.4 8.23c2.62 0 4.71 2.1 4.71 4.72s-2.09 4.73-4.72 4.73-4.72-2.1-4.72-4.73 2.09-4.72 4.72-4.72zm35.27 9.45c-2.63 0-4.72-2.1-4.72-4.73s2.1-4.72 4.72-4.72c2.63 0 4.72 2.1 4.72 4.72s-2.09 4.73-4.72 4.73M36.3 2.56h8.97l1.22 8.64H33.8zM22.06 8.1c0-.07.07-.07 0 0a18.5 18.5 0 0 1 12.07-5.47l-2.43 8.43.27.07H18.42l3.64-3.04zm26.5 3.1-1.2-8.64h4.45c1.48 0 2.83.88 3.37 2.23l2.23 6.48h-8.84v-.07z" }, null, -1)
  ])))
}
export default { render: render }