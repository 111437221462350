export enum PasswordHealth {
  VeryWeak,
  Weak,
  Moderate,
  Strong,
  VeryStrong,
}
export interface PasswordHealthService {
  setPassword(password: string): void
  estimate(): void | Promise<void>
  getClassification(): PasswordHealth | null
}
