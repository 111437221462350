<template>
  <div class="flex flex-col grow bg-light">
    <div class="container py-3 md:py-4">
      <DsButton
        variant="clear"
        icon="chevron-left"
        :handler="goBack"
      >
        Zurück
      </DsButton>
    </div>
    <div class="flex flex-col sm:container mb-10">
      <ShallowCard class="px-5 " no-hover>
        <div class="mt-2.5">
          <h2 class="font-medium" v-text="news.title" />
        </div>
        <div class="flex">
          <DsBadge>
            {{ news.createdAt }}
          </DsBadge>
        </div>
        <div
          class="markdown-base-styling flex flex-col gap-2 mt-10 mb-3"
          v-html="markdown"
        />
      </ShallowCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import { marked } from 'marked';
import { computed, onMounted } from 'vue';

import type { News } from '@/api/news/types';
import { useUnreadHintStore } from "@/application/store/unreadHintStore";
import { UnreadHintType } from "@/api/unreadHint/types";
import ShallowCard from '@/contracts/components/ShallowCard.vue';
import { DsBadge, DsButton } from '@demvsystems/design-components';
import { useGoBack } from '@/application/router/utils/goBack';

interface Props {
  news: News,
  content: string,
}

const props = defineProps<Props>();

const markdown = computed(() => marked.parse(props.content));

onMounted(() => {
  useUnreadHintStore().removeUnreadHints(
    [{ type: UnreadHintType.News, object_id: props.news.id }],
    true
  );
});

const { goBack } = useGoBack();
</script>

<style scoped>
::v-deep(.markdown-base-styling) ul {
  padding-left: 20px;
  list-style-type: disc;
}

::v-deep(.markdown-base-styling) ul li {
  margin-bottom: 0.25rem;
}
</style>
