import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 30 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m25.14 12.49 1.08-3.23c-1.05-.44-2.16-.7-3.3-.88l-1.07 3.23c1.14.18 2.24.44 3.3.88zm-6.75-1.23 1.07-3.23c-1.17-.09-2.33-.17-3.47-.35l-1.07 3.23c1.13.17 2.3.26 3.47.35m-4.54 2.87 1.07-3.22a14 14 0 0 1-3.3-.88c-.35 1.08-.7 2.15-1.07 3.23 1.05.44 2.16.7 3.3.88z" }, null, -1),
    _createElementVNode("path", { d: "m18.39 11.26-1.08 3.23c1.17.08 2.33.17 3.47.35l1.07-3.23a49 49 0 0 0-3.47-.35zM23 18.94l1.07-3.22c-1.05-.44-2.15-.7-3.3-.88l-1.06 3.22a14 14 0 0 1 3.29.88m-9.15-4.81-1.08 3.23c1.14.18 2.3.27 3.47.35l1.07-3.22c-1.16-.1-2.33-.18-3.46-.36" }, null, -1),
    _createElementVNode("path", { d: "M30.32 7.88a9.64 9.64 0 0 0-3.78-2.25 25 25 0 0 0-6-.94c-2-.16-4.07-.31-5.94-.93a9.4 9.4 0 0 1-3.56-2.09L11.39.6l-.9-.3L.12 31.45l.91.3 4.55-13.69a9.7 9.7 0 0 0 3.57 2.06c1.9.63 3.98.79 6 .94 2 .15 4.07.3 5.94.93a9.4 9.4 0 0 1 3.68 2.2l.14.13 5.45-16.39-.06-.05zm-11.65 13.3 1.04-3.11a45 45 0 0 0-3.47-.36l-1.03 3.11h-.03a45 45 0 0 1-3.44-.35l1.03-3.1a14 14 0 0 1-3.29-.89L8.45 19.6a9.2 9.2 0 0 1-2.78-1.79l1.04-3.1a9.1 9.1 0 0 0 2.77 1.77l1.07-3.22a9.3 9.3 0 0 1-2.77-1.78l1.07-3.23a9.3 9.3 0 0 0 2.78 1.78L12.7 6.8a9.3 9.3 0 0 1-2.78-1.78l1.03-3.1a9.4 9.4 0 0 0 2.78 1.77L12.7 6.8c1.05.44 2.16.7 3.3.88l1.03-3.11c1.14.18 2.31.27 3.46.35l-1.03 3.11c1.16.1 2.33.18 3.47.36l1.03-3.11a15.3 15.3 0 0 1 3.3.87l-1.04 3.12a9.3 9.3 0 0 1 2.84 1.84L28 14.33a9.3 9.3 0 0 0-2.85-1.84l-1.07 3.23a9.3 9.3 0 0 1 2.85 1.84l-1.08 3.23A9.3 9.3 0 0 0 23 18.95l-1.04 3.11-.79-.3a15.5 15.5 0 0 0-2.5-.58" }, null, -1)
  ])))
}
export default { render: render }