import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 30 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M28.67 19.06a6.8 6.8 0 0 0-1.07-1.64c-.35-.39-.78-.67-1.17-1-1.88-1.56-2.88-4.01-4.73-5.61a7.35 7.35 0 0 0-3.45-1.7c-.6-.12-1.35-.33-1.42-.93 0-.18.04-.36.1-.54.47-1.31.9-2.6 1.36-3.9.1-.33.21-.61.43-.86.24-.32.64-.53.92-.85s.4-.86.04-1.14c-.6-.1-1.25-.04-1.82.21.18-.32-.03-.74-.35-.92S16.8 0 16.44 0c-.85 0-1.74.04-2.6.04-.39 0-.85.03-1.1.32-.14.14-.18.32-.32.46-.39.43-1.07.28-1.63.18s-1.32-.04-1.5.53c-.03.21 0 .39.04.6.46 1.85 1.28 3.6 2.42 5.12.25.36.53.71.57 1.14a27.55 27.55 0 0 0-9.78 8.07 13.7 13.7 0 0 0-2.14 3.7 6.64 6.64 0 0 0-.1 4.23c.67 1.89 2.34 3.24 3.98 4.37a15.5 15.5 0 0 0 4.76 2.56c2 .57 4.1.43 6.15.29 2.2-.18 4.45-.32 6.51-1.14 1.7-.64 3.2-1.7 4.7-2.74 1.52-1.06 3.16-2.27 3.51-4.09.29-1.56-.5-3.16-1.24-4.58m-13.12 3.98.04 1.53h-2.1l.04-1.5c-1-.14-1.82-.6-2.6-1.74l1.35-1.7c.75.89 1.6 1.06 2.28 1.06.74 0 1.24-.32 1.2-.81 0-.47-.46-.75-1.31-.82-1.67-.18-3.06-.68-2.92-2.7.11-1.28 1.03-1.92 2.14-2.14l.03-.89h1.57l.03.86c.68.1 1.25.35 1.81.78l-.88 1.35a2.6 2.6 0 0 0-1.68-.53c-.5 0-.96.17-.96.57 0 .53.47.6 1.18.7 1.46.18 3.02.82 3.2 2.85.18 1.81-.96 2.81-2.42 3.13" }, null, -1)
  ])))
}
export default { render: render }