<template>
  <SpartenWrapper
    :image="`images/bedarfsinfo/pkv/${image}`"
    :text="subtitle"
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    :versorgungen="data.bedarfsinfo.versorgungen"
    versorgungen-headline="Wie bin ich aktuell bei Krankheit geschützt?"
    versorgungen-description="Private Krankenversicherung"
  >
    <Beamte
      v-if="data.bedarfsinfo.isBeamter"
      :erstattungssatz="data.bedarfsinfo.erstattungssatz"
    />
    <Default
      v-else
      :einkommen="data.bedarfsinfo.einkommen"
      :erstattungssatz="data.bedarfsinfo.erstattungssatz"
    />
  </SpartenWrapper>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import Beamte from '@/bedarf/components/gesundheitsvorsorge/pkv/Beamte.vue';
import Default from '@/bedarf/components/gesundheitsvorsorge/pkv/Default.vue';
import type { PKVInfo } from '@/bedarf/types/bedarfsinfo';

interface Props {
  data: PKVInfo
}

const props = defineProps<Props>();

const image = computed(() => (
  props.data.bedarfsinfo.isBeamter
    ? 'medical-doctor-talking-to-patient-female-patient'
    : 'arzt'
));

const subtitle = props.data.bedarfsinfo.isBeamter
  ? `Während ihrer Ausbildung als Beamter, sowie als Beamter auf Lebenszeit, sind Sie beihilfeberechtigt.
    Ihr Dienstherr übernimmt somit einen Teil der entstandenen Krankheits- und Pflegekosten.`
  : `Aufgrund Ihrer beruflichen Situation entscheiden Sie, ob Sie sich gesetzlich oder privat krankenversichern.
     Beide Versicherungssysteme bieten Vor- und Nachteile, die im Folgenden beleuchtet werden.`;

</script>
