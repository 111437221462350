import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 98.89 98.89"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"49.44\" cy=\"49.44\" r=\"49.44\" fill=\"#fff\"></circle><path fill=\"none\" stroke=\"#343a40\" stroke-miterlimit=\"10\" stroke-width=\"4\" d=\"m8.54 25.7 39.2-14.06 24.39 68-39.2 14.06z\"></path><path fill=\"#343a40\" d=\"M41 43.32a5.3 5.3 0 0 1 1 1.31l2.16 3.51L41 49.7 38.08 45a3.05 3.05 0 0 0-2.85-1.39 10.46 10.46 0 0 1-5.36-1.31 8.8 8.8 0 0 1-3.49-3.78 12.7 12.7 0 0 1-1.23-5.76A12.66 12.66 0 0 1 26.38 27a8.8 8.8 0 0 1 3.49-3.76 10.47 10.47 0 0 1 5.36-1.32 10.37 10.37 0 0 1 5.32 1.32 8.85 8.85 0 0 1 3.5 3.76 12.66 12.66 0 0 1 1.23 5.75 12.5 12.5 0 0 1-1.43 6.16 8.38 8.38 0 0 1-4.09 3.73 3.5 3.5 0 0 1 1.24.68m-1.25-4.81a8.92 8.92 0 0 0 1.64-5.76A8.87 8.87 0 0 0 39.77 27a5.54 5.54 0 0 0-4.54-2 5.59 5.59 0 0 0-4.58 2A8.87 8.87 0 0 0 29 32.75a8.92 8.92 0 0 0 1.63 5.76 5.58 5.58 0 0 0 4.58 2 5.52 5.52 0 0 0 4.56-2z\"></path><path fill=\"#fff\" stroke=\"#343a40\" stroke-miterlimit=\"10\" stroke-width=\"4\" d=\"M47.4 12.2 87 25.11 64.6 93.8 25 80.9z\"></path><path fill=\"#343a40\" d=\"M64.39 47.24a5.4 5.4 0 0 1 .61 1.57l1 4-3.56.46-1.36-5.37a3.05 3.05 0 0 0-2.28-2.2 10.6 10.6 0 0 1-4.67-2.93A8.86 8.86 0 0 1 52 38.08a12.8 12.8 0 0 1 .64-5.85 12.6 12.6 0 0 1 3-5.07 8.87 8.87 0 0 1 4.49-2.49A11.51 11.51 0 0 1 70.24 28a8.84 8.84 0 0 1 2.14 4.67 12.6 12.6 0 0 1-.63 5.84 12.6 12.6 0 0 1-3.28 5.41 8.48 8.48 0 0 1-5.06 2.26 3.3 3.3 0 0 1 .98 1.06m.33-5a9 9 0 0 0 3.35-5 8.82 8.82 0 0 0 .25-6 6.21 6.21 0 0 0-8.66-2.86 8.88 8.88 0 0 0-3.35 4.95 8.84 8.84 0 0 0-.25 6 6.18 6.18 0 0 0 8.66 2.86zm-22.1 9.22 25.5 8.45-1 2.99-25.5-8.45zm-2.5 6.25 11.69 3.87-1 3-11.68-3.88zm19.43 6.26 6.38 2.12-1 2.99-6.38-2.12zm-21.68.24 11.69 3.87-1 3-11.68-3.88zm19.43 6.26 6.38 2.12-1 2.99-6.37-2.12z\"></path>", 5)
  ])))
}
export default { render: render }