import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 98.89 98.89"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"49.44\" cy=\"49.44\" r=\"49.44\" fill=\"#4181D2\"></circle><g fill=\"#FFF\"><circle cx=\"46.32\" cy=\"24.66\" r=\"6.4\"></circle><path d=\"m72.9 72.04-5.53-17.9a3.14 3.14 0 0 0-.55-1.33 3.13 3.13 0 0 0-3.27-1.3H49.32V35.94c0-2.1-1.72-3.82-3.82-3.82s-3.82 1.72-3.82 3.82V53.8c0 .54.11 1.05.31 1.51a3.14 3.14 0 0 0 3.03 2.43h16.94l4.98 16.14a3.13 3.13 0 0 0 3.89 2.06 3.13 3.13 0 0 0 2.07-3.9\"></path><path d=\"M49.64 47.08c.02.81.7 1.45 1.51 1.43l9.64-.26a1.47 1.47 0 0 0-.08-2.94l-9.64.26c-.81.02-1.45.7-1.43 1.51M60.4 58.27s0-.01 0 0c-1.74 0-2.23-.01-4.89.04-.03 0-.05.02-.05.05l.01.14.05.8a12.28 12.28 0 1 1-14.5-11.75l.07-.01c.03-.01.04-.03.04-.04s-.03-3.26-.03-4.95h-.01a17.23 17.23 0 0 0-14.88 19.61c.99 6.97 7.58 13.54 14.55 14.51a17.23 17.23 0 0 0 19.66-18.24z\"></path></g>", 2)
  ])))
}
export default { render: render }