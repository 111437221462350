import { demvWerte } from '@/checkup/state';
import type { DEMVWerte } from '@/checkup/types/types';
import { throwableCall } from "@/api/lib/integration";
import { getDemvWerte } from "@/api/common/commonApi";
export * from '../types/enums';


export async function fetchDEMVWerte(): Promise<void> {
  Object.assign(demvWerte, await throwableCall(getDemvWerte()))
}

export function toLabelAndValue(werte: DEMVWerte): { label: string, value: number }[] {
  return Object.entries(werte).map(([value, description]) => ({ label: description, value: Number.parseInt(value, 10) }
  ));
}
