import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 33 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M25.26 20.75H7.6c.58 1.22 4.31 2.16 8.83 2.16s8.24-.94 8.82-2.16zM1.9 17.25c-.06 1.1 1.24 2.27 2.9 2.6s3.04-.29 3.1-1.39c.05-1.1-1.25-2.26-2.9-2.6s-3.05.3-3.1 1.4zM16.43 1.7c-3.73.03-8.09.16-11.66 1.02-.32.06-.36.4-.36.4l-.3 8.13c-.03 1.4 1.58 2.05 12.32 2.08 10.75-.03 12.36-.67 12.33-2.08l-.3-8.14s-.04-.33-.36-.39c-3.57-.86-7.93-1-11.67-1.02m14.54 15.57c-.06-1.1-1.44-1.73-3.1-1.4s-2.95 1.5-2.9 2.6c.06 1.1 1.45 1.73 3.1 1.4s2.95-1.5 2.9-2.6M16.49 28.8h-.98c-3.65-.01-7.23-.08-10.2-.3v1.66c0 .91-.74 1.65-1.65 1.65H2.3c-.91 0-1.65-.74-1.65-1.65V26.7l-.1-1.06a27 27 0 0 1-.45-5.01c0-2.34.26-4.48.7-6.13a7 7 0 0 1 .41-1.31L2.85 1.96s.07-.47.49-.57C7.1.36 16.18.26 16.5.26c.31 0 9.39.1 13.15 1.13.42.1.49.57.49.57l1.64 11.23a11 11 0 0 1 .42 1.31c.43 1.65.69 3.8.69 6.13 0 1.84-.16 3.56-.44 5.01l-.11 1.06v3.47c0 .91-.74 1.65-1.65 1.65h-1.36c-.9 0-1.64-.74-1.64-1.65v-1.66c-2.98.22-6.56.29-10.21.3h-.98z" }, null, -1)
  ])))
}
export default { render: render }