export async function sha256(string: string): Promise<string> {
  // from https://remarkablemark.medium.com/how-to-generate-a-sha-256-hash-with-javascript-d3b2696382fd
  const utf8 = new TextEncoder().encode(string);
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray
    .map((bytes) => bytes.toString(16).padStart(2, '0'))
    .join('');
}

export const getUtf8Bytes = (str: string): Uint8Array =>  new TextEncoder().encode(str);

export async function hmac(key: string, message: string, binary: boolean = false): Promise<string|ArrayBuffer> {
  // from https://github.com/danharper/hmac-examples
  const cryptoKey = await crypto.subtle.importKey(
    'raw',
    getUtf8Bytes(key),
    { name: 'HMAC', hash: 'SHA-256' },
    true,
    ['sign'],
  );
  const sig = await crypto.subtle.sign(
    'HMAC',
    cryptoKey,
    getUtf8Bytes(message),
  );

  if (binary) return sig;

  return [...new Uint8Array(sig)].map(b => b.toString(16).padStart(2, '0')).join('');
}

export const binaryToBase64 = (binary: ArrayBuffer): string => btoa(
  String.fromCharCode(...new Uint8Array(binary)),
);
