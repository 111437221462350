<template>
  <section class="section">
    <div class="container">
      <div class="row text-center mb-5">
        <div class="col-md-8 offset-md-2">
          <h2 class="pb-5">
            Benötige ich eine Auslandskranken&shy;versicherung?
          </h2>
          <p class="pb-5">
            Sie haben eine private Krankenversicherung und daher werden die
            Behandlungskosten innerhalb der Europäischen Union, Island, Liechtenstein
            und Norwegen übernommen.
          </p>
          <p class="pb-5">
            Je nach gewähltem Tarif, haben Sie meist auch für einen begrenzten Zeitraum
            (1-12 Monate) in außereuropäischen Gebieten Versicherungsschutz.
          </p>
          <p>
            Häufig werden jedoch die Kosten eines aus medizinischen Gründen veranlassten
            Rücktransportes nicht übernommen.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <NiceToKnow
            classes="mb-0"
            :bullets="niceToKnow"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';

const niceToKnow = [
  {
    title: 'Hinweis',
    text: `Häufig besteht bereits (eingeschränkter) Versicherungsschutz über eine Kreditkarte oder einen Automobilclub.
            Im Zweifel kann eine weitere Versicherung abgeschlossen werden, um die nicht versicherten Leistungen
            abzudecken.`,
  },
];
</script>
