import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 33 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M27.35 1.82h-5.47v3.65L16.41 0 0 16.41h2.74v15.5h27.35V16.4h2.73l-5.47-5.47zM23.7 3.65h1.83v5.47L23.7 7.29zm-7.29.22 10.72 10.72H5.69zm10.94 25.3H5.47V16.41h21.88z" }, null, -1),
    _createElementVNode("path", { d: "M20.97 18.23h-9.12v9.12h9.12zm-1.82 7.3h-5.47v-5.47h5.47z" }, null, -1)
  ])))
}
export default { render: render }