<template>
  <div
    class="row"
  >
    <div class="w-full text-center my-7 px-14">
      <!--
        for vue slider v3

        tooltip="always"
        marks
        hide-label
       -->
      <Slider
        v-model="selectedValue"
        :format="formatDecimal"
        :no-ui-slider-options="options"
        hide-min-max-label
        class="mb-7"
      />
    </div>

    <div class="col-md-6">
      <table class="table">
        <thead>
          <tr class="bg-light">
            <th
              colspan="2"
              class="border-t-0"
            >
              1. Instanz
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Eigener Anwalt</td>
            <td
              class="text-right whitespace-nowrap"
              v-text="formatDecimal(selected.first.own)"
            />
          </tr>
          <tr>
            <td>Eigener & gegnerischer Anwalt</td>
            <td
              class="text-right whitespace-nowrap"
              v-text="formatDecimal(selected.first.total)"
            />
          </tr>
          <tr>
            <td>Gerichtskosten</td>
            <td
              class="text-right whitespace-nowrap"
              v-text="formatDecimal(selected.first.court)"
            />
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Gesamt</th>
            <th
              class="text-right whitespace-nowrap"
              v-text="formatDecimal(selected.first.court + selected.first.total)"
            />
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="col-md-6">
      <table class="table">
        <thead>
          <tr class="bg-light">
            <th
              colspan="2"
              class="border-t-0"
            >
              2. Instanz
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Eigener Anwalt</td>
            <td
              class="text-right whitespace-nowrap"
              v-text="formatDecimal(selected.second.own)"
            />
          </tr>
          <tr>
            <td>Eigener & gegnerischer Anwalt</td>
            <td
              class="text-right whitespace-nowrap"
              v-text="formatDecimal(selected.second.total)"
            />
          </tr>
          <tr>
            <td>Gerichtskosten</td>
            <td
              class="text-right whitespace-nowrap"
              v-text="formatDecimal(selected.second.court)"
            />
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Gesamt</th>
            <th
              class="text-right whitespace-nowrap"
              v-text="formatDecimal(selected.second.court + selected.second.total)"
            />
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="col-12">
      <table class="table text-center">
        <thead>
          <tr class="bg-light text-danger">
            <th class="border-t-0">
              1. und 2. Instanz
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              class="text-lg text-danger font-bold whitespace-nowrap"
              v-text="formatDecimal(
                selected.first.court + selected.first.total +
                  selected.second.court + selected.second.total
              )"
            />
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

import Slider from '@/application/components/Slider.vue';
import { formatDecimal } from '@/application/utils/money';

import rows from './KostenRechtsstreitData.json';

const amounts = rows.map(({ amount }) => amount);
const amountStep = (1 / (amounts.length - 1)) * 100;

const range = amounts.reduce((accumulator, amount, i) => {
  if (i === 0) {
    accumulator.min = amount;
    return accumulator;
  }
  if (amounts.length - 1 === i) {
    accumulator.max = amount;
    return accumulator;
  }
  accumulator[`${i * amountStep}%`] = amount;
  return accumulator;
}, { min: 0, max: 1 });

const options = {
  snap: true,
  range,
  pips: {
    mode: 'range',
    density: 100,
    filter: (value) => {
      if (value === 5000 || value === 1_000_000) {
        return 1;
      }
      return 0;
    },
    format: {
      to: (value) => formatDecimal(value),
    },
  },
};

const selectedValue = ref(rows[0].amount);

const selected = computed(() => {
  const index = amounts.indexOf(selectedValue.value);
  return rows[index];
});
</script>

<style lang="scss">
.slider-marker {
  top: 0;
  position: absolute;
  background: #d2d5db;
  height: 14px;
  width: 1px;
  transform: translate(-0.5px, 1px);
}

.slider-value {
  top: 0;
  font-size: 0.8rem;
  position: absolute;
  transform: translate(-50%, 20px);
  font-weight: bold;
}
</style>
