import type { Filter } from './../type/Filter'
import type { SearchablePrimitive } from './../type/SearchablePrimitive'
import type { Ref } from "vue";
import { ref } from "vue";

function compareWithSearchString(searchable: SearchablePrimitive, query: string): boolean {
    if (!searchable) {
        return false
    }
    return (searchable + '').toLowerCase().includes(query.toLowerCase())
}

export function useSearchFilter<T>(
    context: Array<(item: T) => SearchablePrimitive>,
    minimumSearchLength = 2
): { query: Ref<string> , filter: Filter<T> } {

    const query = ref('')

    function filter (item: T): boolean {

        if (query.value.length < minimumSearchLength) {
            return true
        }

        return context.some((compare) => {

            const comparable = compare(item);

            if (!Array.isArray(comparable)) {
                return compareWithSearchString(comparable, query.value)
            }

            return comparable.some((comparableItem: SearchablePrimitive) => {
                return compareWithSearchString(comparableItem, query.value)
            });
        });
    }

    return {
        query,
        filter
    }
}

