<template>
  <div class="container flex flex-col text-center">
    <section class="flex flex-col section items-center">
      <SvgVue
        icon="icomoon/party"
        class="icon Icon Icon--party mb-7 inline-block"
      />
      <h1 class="mb-7">
        Geschafft!
      </h1>
      <p class="mb-7 max-w-lg">
        Vielen Dank, dass Sie sich die Zeit genommen haben. Eine individuelle Auswertung erhalten Sie in Ihrem
        persönlichen Gespräch.
      </p>
      <div class="flex flex-row items-center justify-center gap-4">
        <DsButton
          :handler="userStore.logUserOutByUser"
          variant="secondary"
          size="lg"
          icon="sign-out-alt"
          icon-variant="solid"
        >
          Ausloggen
        </DsButton>
        <RouterLink
          v-slot="{ navigate }"
          :to="{ name: Finanzmanager.VERTRAEGE }"
          custom
        >
          <DsButton
            :handler="navigate"
            icon="handshake"
            icon-variant="regular"
            size="lg"
          >
            Meine Verträge
          </DsButton>
        </RouterLink>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { DsButton } from '@demvsystems/design-components';

import SvgVue from '@/application/components/SvgVue';
import { Finanzmanager } from '@/application/router/types/types';
import { resetState } from "@/checkup/state";
import { onMounted } from "vue";
import { useUserStore } from "@/user/store/userStore";

onMounted(() => {
  resetState();
})

const userStore = useUserStore();
</script>
