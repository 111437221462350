export enum UnreadHintType {
  Contract = 'contract',
  Document = 'document',
  News = 'news',
}

export type UnreadHint = {
  type: UnreadHintType,
  object_id?: number,
};

export type UnreadHintResponse = {
  [key in UnreadHintType]?: number[]
};
