import type { ComputedRef, Ref } from 'vue';
import { computed } from 'vue';

export const useRatios = (
  income: Ref<number>,
  monetaryNeed: Ref<number>,
): {
  total: ComputedRef<number>,
  incomeToTotal: ComputedRef<number>,
  monetaryNeedToTotal: ComputedRef<number>
} => {
  const total = computed(() => Math.max(income.value, monetaryNeed.value));

  return {
    total,
    incomeToTotal: computed(() => (income.value / total.value) || 0),
    monetaryNeedToTotal: computed(() => (monetaryNeed.value / total.value) || 0),
  };
};
