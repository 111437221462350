<template>
  <section class="flex flex-col gap-3 mb-7 w-full sm:w-1/2 md:w-2/5 lg:w-1/3 self-center">
    <h2 class="md:mb-4 checkup-h2">
      Mein Vermögen
    </h2>
    <div class="flex flex-col gap-5">
      <!-- TODO: remove handleMoneyValue, when backend accepts value as float -->
      <DsFormGroup label="Zum Sparen frei verfügbares Einkommen pro Monat">
        <MoneyInput
          :model-value="form.monatlichFreiesEinkommen"
          size="lg"
          @update:model-value="set('monatlichFreiesEinkommen', handleMoneyValue($event))"
        />
        <DsFieldError
          v-for="error in formErrors.monatlichFreiesEinkommen"
          :key="error"
        >
          {{ error }}
        </DsFieldError>
      </DsFormGroup>
      <DsFormGroup label="Gesamtsumme des Vermögens">
        <MoneyInput
          :model-value="form.vermoegen"
          size="lg"
          @update:model-value="set('vermoegen', handleMoneyValue($event))"
        />
        <DsFieldError
          v-for="error in formErrors.vermoegen"
          :key="error"
        >
          {{ error }}
        </DsFieldError>
      </DsFormGroup>
    </div>

    <h2 class="mt-7 mb-2 checkup-h2">
      Meine Verbindlichkeiten
    </h2>

    <div class="flex flex-col gap-5 mb-5">
      <!-- TODO: remove handleMoneyValue, when backend accepts value as float -->
      <DsFormGroup label="Höhe der monatlichen Verbindlichkeiten">
        <MoneyInput
          :model-value="form.monatlicheVerbindlichkeiten"
          size="lg"
          @update:model-value="set('monatlicheVerbindlichkeiten', handleMoneyValue($event))"
        />
        <DsFieldError
          v-for="error in formErrors.monatlicheVerbindlichkeiten"
          :key="error"
        >
          {{ error }}
        </DsFieldError>
      </DsFormGroup>
      <DsFormGroup label="Gesamtsumme der Verbindlichkeiten">
        <MoneyInput
          :model-value="form.verbindlichkeiten"
          size="lg"
          @update:model-value="set('verbindlichkeiten', handleMoneyValue($event))"
        />
        <DsFieldError
          v-for="error in formErrors.verbindlichkeiten"
          :key="error"
        >
          {{ error }}
        </DsFieldError>
      </DsFormGroup>
    </div>

    <DsFormGroup label="Bemerkung">
      <DsTextarea
        v-model="form.bemerkung"
        placeholder="Bitte geben Sie hier Ihre Bemerkung ein."
        :rows="5"
      />
      <DsFieldError
        v-for="error in formErrors.bemerkung"
        :key="error"
      >
        {{ error }}
      </DsFieldError>
    </DsFormGroup>
  </section>
</template>

<script setup lang="ts">
import { DsFieldError, DsFormGroup, DsTextarea } from '@demvsystems/design-components';

import MoneyInput from '@/application/components/form-elements/MoneyInput/MoneyInput.vue';
import { useStep } from '@/checkup/state';
import { Step } from '@/checkup/utils/steps';

const { form, formErrors, set } = useStep(Step.FINANZEN);

// TODO: remove when backend accepts value as float
const handleMoneyValue = (value: number | string | null): number | string | null => {
  if (value === null || value === '') return null;
  if (typeof value === 'number') return value.toString();
  return value;
};
</script>
