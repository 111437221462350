import type { DocumentType } from '@/api/common/types';
import type { DocumentsResponse, S3Info, SignedUrlResponseData, UploadMetaData } from '@/api/documents/types';
import { useDelete, useGet, usePost, usePut } from "@/api/lib/integration";

export const notifyAboutUploadedFiles = usePost<UploadMetaData, void>('/api/dokumente');

const getSignedUrl = useGet<{ fileType: string }, SignedUrlResponseData>('/api/signed-url');

export const uploadToS3 = async (file: File): Promise<S3Info> => {
  const resp = await getSignedUrl({ fileType: file.type });
  if (resp instanceof Error) throw resp;
  if (resp === null) throw new Error('no signed url');

  const { downloadUrl, postData, uuid } = resp;
  const { formInput, formAttributes: { action } } = postData;

  const formData = new FormData();
  Object.entries(formInput).forEach(([key, value]) => formData.append(key, value));
  formData.append('file', file);
  await fetch(action, { method: 'post', body: formData });

  return { name: file.name, content_type: file.type, downloadUrl, uuid };
};

export const getUploadableDocumentTypes = useGet<void, DocumentType[]>('/api/dokumente/uploadable-types');

export const getDocuments = useGet<void, DocumentType[]>('/api/dokumente/');

export const updateDocument = usePut<Record<string, unknown>, DocumentsResponse>('/api/dokumente/');
export const deleteDocument = useDelete<{ userDocumentId: number }, void>('/api/dokumente/{userDocumentId}');
