<script setup lang="ts">
import { DsButton } from "@demvsystems/design-components";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

interface Props {
  zoomIn: (() => void)|null,
  zoomOut: (() => void)|null,
  download: () => Promise<void>,
  filename: string,
}

defineProps<Props>();
const emit = defineEmits(['close']);
</script>

<template>
  <div class="sticky z-[2005] left-0 top-0 w-full px-4 py-2 shadow-md bg-white overflow-hidden">
    <div class="grid grid-cols-3 align-items-center h-8">
      <div class="text-left flex flex-row items-center">
        <div class="truncate text-blue-500">{{ filename }}</div>
        <DsButton variant="clear" icon="download" class="ml-2 shadow-none" :handler="download" />
      </div>
      <div class="text-center">
        <DsButton v-if="zoomIn" variant="clear" icon="plus" class="shadow-none" @click="zoomIn" />
        <DsButton v-if="zoomOut" variant="clear" icon="minus" class="shadow-none" @click="zoomOut" />
      </div>
      <div class="text-right">
        <DsButton variant="clear" class="shadow-none close-button" @click="emit('close')">
          <FontAwesomeIcon transform="grow-10" fixed-width icon="times" />
        </DsButton>
      </div>
    </div>
  </div>
</template>

<style scoped>
.close-button :deep(button) {
  @apply px-2;
}
</style>
