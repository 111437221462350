import type { InjectionKey } from 'vue';
import { inject as vueInject } from 'vue';

export const inject = <T>(key: InjectionKey<T>): T => {
  const injected = vueInject(key);

  if (injected === undefined) {
    throw new Error(`Could not resolve '${key.description}'`);
  }

  return injected;
};
