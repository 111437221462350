import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 80 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M18.28 1.55c-1.85 0-6.7.64-6.7.64S5.4-.79 1.47 1.13s1.56 2.66 3.4 2.66c1.86 0 6.71-.64 6.71-.64s6.18 2.96 10.11 1.06-1.56-2.66-3.4-2.66zm43.44.15c1.85 0 6.7.64 6.7.64S74.6-.63 78.53 1.28c3.93 1.9-1.56 2.66-3.4 2.66s-6.71-.64-6.71-.64-6.18 2.96-10.11 1.06 1.56-2.66 3.4-2.66z" }, null, -1),
    _createElementVNode("path", { d: "M53.33 31.54h-3.3v-.4c0-5.1-2.22-9.79-5.67-12.01-2.85.43-5.87.43-8.72 0-3.45 2.23-5.67 6.92-5.67 12v.41h-3.3v-.4c0-5.03 1.85-9.82 4.98-12.97a13.6 13.6 0 0 1-4.73-2.58H10.07V5.42a1.51 1.51 0 0 1 3.02 0v4.73c5.12-.3 10.6-1.34 13.54-1.96C29.2 5.64 34.3 4.06 40 4.06c5.83 0 11.15 1.71 13.6 4.38 2.9.6 8.29 1.62 13.3 1.9V5.43a1.51 1.51 0 0 1 3.03 0V15.8H52.85a14.1 14.1 0 0 1-4.5 2.38c3.12 3.15 4.97 7.94 4.97 12.96v.41zm-2.48-.82h1.65c-.1-4.91-2.04-9.53-5.21-12.42l-.54-.5.7-.2a13.9 13.9 0 0 0 4.98-2.53l.11-.1h16.57V5.42a.69.69 0 0 0-1.38 0v5.8l-.43-.02a96 96 0 0 1-14-1.98l-.13-.03-.09-.1C50.81 6.53 45.68 4.9 40 4.9c-5.46 0-10.5 1.55-12.87 3.97l-.1.09-.11.02a97 97 0 0 1-14.22 2.03l-.44.02v-5.6a.69.69 0 0 0-1.37 0v9.35h16.34l.12.1c1.2 1.12 3 2.06 5.2 2.73l.7.2-.54.5c-3.18 2.9-5.11 7.51-5.21 12.42h1.65c.12-5.27 2.51-10.1 6.18-12.38l.13-.08.15.03c2.86.45 5.91.45 8.77 0l.15-.03.13.08c3.66 2.29 6.06 7.11 6.18 12.38zm-2.83-20.44H31.98V8.36l.12-.13c1.43-1.46 4.5-2.4 7.84-2.4 3.42 0 6.62 1.02 7.97 2.54l.1.12v1.79zM32.8 9.46h14.38V8.8c-1.26-1.3-4.14-2.15-7.25-2.15-3.03 0-5.8.8-7.13 2.04v.76z" }, null, -1),
    _createElementVNode("path", { d: "M45.5 31.54h-11a1.7 1.7 0 0 1-1.71-1.7v-5.86c0-.94.76-1.7 1.7-1.7h11.02c.94 0 1.7.76 1.7 1.7v5.86c0 .94-.76 1.7-1.7 1.7zm-11-8.43c-.5 0-.89.39-.89.87v5.86c0 .48.4.88.88.88h11.02c.48 0 .87-.4.87-.88v-5.86a.87.87 0 0 0-.87-.87H34.49zm5.5 7.55a3.75 3.75 0 1 1 0-7.5 3.75 3.75 0 0 1 0 7.5m0-6.67a2.92 2.92 0 1 0 0 5.85 2.92 2.92 0 0 0 0-5.85" }, null, -1),
    _createElementVNode("path", { d: "M40 29.22a2.3 2.3 0 1 1 0-4.62 2.3 2.3 0 0 1 0 4.62m0-3.79a1.48 1.48 0 1 0 0 2.96 1.48 1.48 0 0 0 0-2.96" }, null, -1)
  ])))
}
export default { render: render }