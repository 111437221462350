import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 30 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M28.4 1.82h-4.01c-.77 0-1.34.6-1.58 1.2h-2.23C20.4 1.56 19.63.25 18.14.25L12 .26c-1.4 0-2.23 1.34-2.43 2.76H7.06c-.24-.6-.82-1.2-1.58-1.2h-4c-.66 0-1.36.47-1.36 1.8S.82 5.4 1.47 5.4h4c.67 0 1.19-.45 1.47-.96h2.68c.11.49.34.96.66 1.38a3.5 3.5 0 0 0-2.63 2.71L5.99 20.18c0 1.31 1.97 2.24 3.27 2.72l-.17.9a.71.71 0 0 0 .7.85h2.11v5.2c0 1.05.82 1.9 1.83 1.9h2.7a1.9 1.9 0 0 0 1.87-1.9v-5.2h2.11c.21 0 .41-.1.55-.26s.19-.38.15-.59l-.18-.92c1.2-.43 3.22-1.35 3.22-2.7L22.5 8.5a3.53 3.53 0 0 0-2.63-2.67c.33-.42.55-.9.67-1.38h2.4c.28.5.8.96 1.46.96h4c.66 0 1.36-.47 1.36-1.8s-.7-1.79-1.35-1.79zM5.47 4h-3.9a4.4 4.4 0 0 1 0-.75h3.9c.09.02.09.73 0 .74zm6.51-2.3 6.15-.01c1.04 0 1.05 1.99 1.05 2 0 1.83-2.91 2.65-4 2.65-1.72 0-4.24-.82-4.24-2.64 0-.84.36-2 1.04-2m4.89 28.14c0 .28-.24.5-.45.5h-2.7c-.22 0-.4-.23-.4-.5v-5.2h3.55zm-6.13-6.62L11 21.7c.47-2.74 1.47-3.61 4.09-3.61 2.66 0 3.66.87 4.09 3.6 0 0 .25 1.44.28 1.53zM21.1 8.77l1.64 11.42c-.1.34-1.34 1.07-2.82 1.51l-.02-.13c-.49-3.1-1.74-4.2-4.8-4.2-2.99 0-4.24 1.1-4.78 4.2l-.03.16c-1.37-.43-2.74-1.18-2.87-1.54L9.05 8.78c.32-1.94 3.08-2.1 3.63-2.18.68.26 1.5.44 2.5.44.95 0 1.7-.14 2.33-.35v.01c.4.03 3.11.2 3.58 2.08zm7.19-4.79H24.4c-.1-.01-.1-.72 0-.74h3.89c.02.08.02.67 0 .74" }, null, -1)
  ])))
}
export default { render: render }