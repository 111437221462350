<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useMediaPreviewStore } from "@/application/store/mediaPreviewStore";
import MediaPreview from "@/application/components/MediaPreview/MediaPreview.vue";

const { mediaRef } = storeToRefs(useMediaPreviewStore());
</script>

<template>
  <MediaPreview v-if="mediaRef" :media-ref="mediaRef" />
</template>

<style>

</style>
