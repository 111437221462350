<template>
  <section class="section">
    <div class="container">
      <div class="text-center mb-7">
        <h2>Beihilfe erstattet nur einen Teil der Krankheitskosten</h2>
      </div>
      <div class="row">
        <div class="col-12 pb-14 text-center">
          In welchem Umfang die Behandlungskosten durch den Dienstherrn erstattet werden, ergibt sich
          aus den Beihilfesätzen. Je nach familiärer Situation und dem jeweiligen Dienstherrn variiert
          die Höhe der Erstattung. Die folgende Grafik gibt einen allgemeinen Überblick:
        </div>
      </div>
    </div>
  </section>

  <section>
    <div class="container mx-auto">
      <div class="table-responsive">
        <table class="table Table">
          <colgroup>
            <col width="34%">
            <col width="23%">
            <col width="23%">
            <col width="23%">
          </colgroup>
          <thead>
            <tr>
              <th />
              <th class="text-center">
                Für sich selbst
              </th>
              <th class="text-center">
                Für Ehegatten
              </th>
              <th class="text-center">
                Für Kinder
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Aktive Beamte...</td>
              <td class="text-center">
                50 %
              </td>
              <td class="text-center">
                70 %
              </td>
              <td class="text-center">
                80 %
              </td>
            </tr>
            <tr>
              <td>...ab zwei Kindern</td>
              <td class="text-center">
                70 %
              </td>
              <td class="text-center">
                70 %
              </td>
              <td class="text-center">
                80 %
              </td>
            </tr>
            <tr>
              <td>Beamte im Ruhestand und deren Witwen/Witwer</td>
              <td class="text-center">
                70 %
              </td>
              <td class="text-center">
                70 %
              </td>
              <td class="text-center">
                70 %
              </td>
            </tr>
            <tr class="border-t">
              <td>Waisen oder Halbwaisen</td>
              <td class="text-center">
                80 %
              </td>
              <td class="text-center" />
              <td class="text-center" />
            </tr>
          </tbody>
        </table>

        <div class="text-center my-14">
          <strong>Achtung:</strong> Baden-Württemberg hat für Beamte, die nach dem 1. Januar 2013 eingestellt
          wurden, die
          Beihilfesätze drastisch gekürzt. Diese Landesbeamten und ihre Ehepartner erhalten nur noch 50 % der
          Aufwendungen ersetzt, egal wie viele Kinder diese haben. Nur für Kinder selbst gilt weiter ein
          Beihilfesatz von 80 %. Ihren tatsächlichen Beihilfesatz erfahren Sie bei ihrem Dienstherren.
        </div>
      </div>
    </div>
  </section>

  <section class="section">
    <div class="container">
      <div class="text-center mb-7">
        <h2>Wer trägt die verbleibenden Krankheitskosten?</h2>
      </div>
      <div class="row">
        <div class="col-12 pb-14 text-center">
          Je nach Erkrankung und der daraus folgenden notwendigen Behandlung, verbleibt nach Zahlung der
          Beihilfe ein hoher Betrag offen. Um diese verbleibenden Krankheitskosten nicht selber tragen zu
          können, kann eine Krankenversicherung abgeschlossen werden. Als Beamtenanwärter oder Beamter haben
          Sie dabei die Wahlfreiheit, ob Sie sich in der gesetzlichen oder privaten Krankenversicherung
          versichern wollen.
        </div>
      </div>
    </div>
  </section>

  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 flex flex-col justify-around">
          <BulletPointList
            title="Gesetzliche Krankenversicherung"
            :items="gesetzlich"
          />

          <p>
            *Ist der Dienstherr aus einem der folgenden Bundesländer, so wird ein Zuschuss zur GKV gewährt:
            Berlin, Brandenburg, Bremen, Hamburg, Hessen und Thüringen. Unter bestimmten Voraussetzungen
            zahlen zudem die folgenden Bundesländer einen Zuschuss: Mecklenburg-Vorpommern, Sachsen und
            Schleswig-Holstein.
          </p>
        </div>
        <div class="col-12 col-md-6">
          <BulletPointList
            title="Private Krankenversicherung"
            :items="privat"
          />
        </div>
      </div>
    </div>
  </section>

  <section class="section">
    <div class="container">
      <div class="text-center mb-7">
        <h2>Leistungen in der Krankenversicherung</h2>
      </div>
      <div class="custom-tabs">
        <Tabs
          v-cloak
          has-separated-tabs
          initial-tab="Ambulant"
        >
          <Tab
            name="Ambulant"
            active
          >
            <Ambulant />
          </Tab>
          <Tab name="Stationär">
            <Behandlung />
            <div class="row">
              <div class="col-xl-10 offset-xl-1">
                <Table />
              </div>
            </div>
          </Tab>
          <Tab name="Zahn">
            <Zahn :value="erstattungssatz" />
          </Tab>
        </Tabs>
      </div>
    </div>
  </section>

  <JumboImage
    image="images/bedarfsinfo/pkv/medical-doctor-talking-to-patient-to-comforting"
  />

  <Beitraege />

  <Massnahmen />

  <section class="section">
    <div class="container">
      <div class="text-center mb-7">
        <h2>Worauf muss ich bei der Beihilfe achten?</h2>
      </div>
      <div class="mb-7">
        <p class="text-center">
          Die Leistungen der Beihilfe sind je Bundesland unterschiedlich. Diese werden
          in den Beihilfevorschriften geregelt und können jederzeit geändert werden. Für
          Sie gelten die Beihilfevorschriften Ihres Dienstherrn.
        </p>
      </div>

      <BulletPointList :items="beihilfe" />
    </div>
  </section>
</template>

<script setup lang="ts">
import Behandlung from '@/bedarf/components/Behandlung.vue';
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import JumboImage from '@/bedarf/components/JumboImage.vue';
import Ambulant from '@/bedarf/components/gesundheitsvorsorge/ambulant/Ambulant.vue';
import Table from '@/bedarf/components/gesundheitsvorsorge/krankenhauszusatz/Table.vue';
import Beitraege from '@/bedarf/components/gesundheitsvorsorge/pkv/Beitraege.vue';
import Massnahmen from '@/bedarf/components/gesundheitsvorsorge/pkv/Massnahmen.vue';
import Zahn from '@/bedarf/components/gesundheitsvorsorge/pkv/Zahn.vue';
import Tab from '@/application/components/Tab.vue';
import Tabs from '@/application/components/Tabs.vue';
import { h, withModifiers } from 'vue';
import { scrollToId } from "@/application/utils/scrollToId";
import type { VNode } from "vue";

interface Props {
  erstattungssatz: number,
}

defineProps<Props>();

const gesetzlich: Array<{ icon: string, text: string } | { icon: string, component: VNode }> = [
  {
    icon: 'minus',
    text: 'Kein Arbeitgeberzuschuss wie bei Angestellten: Zahlung des vollen Beitragssatzes plus Zusatzbeitrag.*',
  },
  {
    icon: 'minus',
    text: `De facto kein Beihilfeanspruch: Die Behandlungskosten werden direkt zwischen Arzt und Krankenkasse
            abgerechnet. Beamte erhalten keine Rechnungen, um diese bei der Beihilfe einzureichen.`,
  },
  {
    icon: 'minus',
    text: 'Lediglich Regelleistungen der GKV und wer bessere Leistungen wünscht, benötigt eine Zusatzversicherung.',
  },
  {
    icon: 'plus',
    component: h('span', [
      'Kinder können in der GKV häufig beitragsfrei mitversichert werden. ',
      h('a', {
        onClick: withModifiers(() => scrollToId('kind-gesetzlich-oder-privat'), ['prevent']),
        href: '#',
      }, ['Hier können Sie prüfen, wie Ihr(e) Kind(er) zu versichern sind.']),
    ]),
  },
  {
    icon: 'plus',
    text: `Ehepartner mit Einkommen unterhalb von monatlich 450 EUR können in der GKV beitragsfrei mitversichert
            werden.`,
  },
  {
    icon: 'plus',
    text: `Ein Wechsel der Krankenkasse ist innerhalb der GKV einfacher, da beispielsweise keine Gesundheitsprüfung
            anfällt.`,
  },
];

const privat = [
  {
    icon: 'plus',
    text: `Nur versichern was notwendig ist: Es können lediglich die verbleibenden Krankheitskosten versichert werden,
            die der Dienstherr im Rahmen der Beihilfe nicht übernimmt.`,
  },
  {
    icon: 'plus',
    text: `Beitrag: Häufig ist die private Krankenversicherung günstiger, da nur die verbleibenden Krankheitskosten
            versichert werden müssen.`,
  },
  {
    icon: 'plus',
    text: `Höhere Leistungen: Beispielsweise kurze Wartezeiten bei der Terminvergabe, freie Wahl des behandelnden Arztes
            im Krankenhaus und bessere Zahnleistungen.`,
  },
  {
    icon: 'minus',
    text: `Familienmitglieder müssen einzeln versichert werden (nur verbleibende Krankheitskosten, da Dienstherr auch
            diesen eine Beihilfe gewährt).`,
  },
  {
    icon: 'minus',
    text: `Vorleistung: Sie erhalten die Rechnung vom Arzt und reichen diese bei der Beihilfe ein. Häufig benötigt die
            Beihilfestelle länger als die Rechnungsfrist ist, so dass Sie in Vorleistung gehen müssen. Zusätzlich
            reichen Sie die Rechnung bei der privaten Krankenversicherung ein. Diese erstattet die verbleibenden
            Krankenkosten im Regelfall innerhalb weniger Tage.`,
  },
];

const beihilfe = [
  {
    text: `Achten Sie darauf, dass ihr Tarif auch Beihilfeergänzungsleistungen beinhaltet. So vermeiden Sie teilweise
            Eigenbeteiligungen.`,
  },
  {
    text: `Bei einem Wechsel des Dienstherrn (beispielsweise nach einem Umzug in ein anderes Bundesland), muss der Tarif
            angepasst werden.`,
  },
];
</script>
