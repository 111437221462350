<script setup lang="ts">

import DetailView from "@/contracts/components/DetailView.vue";
import { useContractStore } from "@/contracts/stores/contractStore";
import type { Contract } from "@/api/contracts/types";
import type { Ref } from "vue";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute()
const contractId = route.params.id
const data: Ref<Contract | undefined> = ref(undefined)
const contractsStore = useContractStore();

onMounted(async () => {
  if (contractsStore.contracts.length === 0 && contractsStore.depots.length === 0) {
    await contractsStore.fetchContractsAndDepots();
  }
  data.value = contractsStore.contracts.find((contract) => contract.id === Number(contractId));
})

</script>

<template>
  <DetailView
    v-if="data"
    :data="data"
  />
</template>
