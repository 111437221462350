<template>
  <section class="section bg-light text-center">
    <div class="container">
      <h2>Woran liegt und wie wahrscheinlich ist es, dass ich berufsunfähig werde?</h2>
      <div class="row mt-7 print:block overflow-hidden">
        <div class="col-12 col-lg-6 mb-14 lg:mb-0">
          <h4>Wahrscheinlichkeit, bis zur Rente mit 65 berufsunfähig zu werden nach Altersgruppen</h4>
          <div class="row mt-7 mr-1.5 md:mr-0 text-left">
            <div class="col-12 col-md-10">
              <div
                v-for="{name, gender: { women, men }} in ageGroupStatistics"
                :key="name"
                class="row"
              >
                <div
                  class="col-3 text-right self-center"
                  v-text="name"
                />
                <div class="col-9 border-left border-dark py-2.5 pl-0">
                  <div
                    class="bg-secondary text-white mb-1.5 py-1.5 px-2.5 lead"
                    :style="{width: `${women * 2}%` }"
                    v-text="`${women}%`"
                  />
                  <div
                    class="bg-dark text-white py-1.5 px-2.5 lead"
                    :style="{width: `${men * 2}%` }"
                    v-text="`${men}%`"
                  />
                </div>
              </div>
              <!-- legend and axis -->
              <div class="row">
                <div class="col-9 offset-3 p-0">
                  <hr class="border-dark m-0">
                </div>
              </div>
              <div class="row ">
                <div class="col-9 offset-3 p-0 flex justify-between mt-2.5">
                  <div>0%</div>
                  <div>10%</div>
                  <div>20%</div>
                  <div>30%</div>
                  <div>40%</div>
                  <div>50%</div>
                </div>
              </div>
              <div class="row mt-2.5">
                <div class="offset-3 col-9 pl-0">
                  <!-- eslint-disable vue/no-v-html -->
                  <div class="flex">
                    <div class="media mr-14">
                      <div class="media-left">
                        <div
                          class="px-1.5 lead bg-secondary"
                          v-html="'&emsp;'"
                        />
                      </div>
                      <div class="media-body ml-2.5 self-center">
                        Frauen
                      </div>
                    </div>
                    <div class="media">
                      <div class="media-left">
                        <div
                          class="px-1.5 lead bg-dark"
                          v-html="'&emsp;'"
                        />
                      </div>
                      <div class="media-body ml-2.5  self-center">
                        Männer
                      </div>
                    </div>
                    <!-- eslint-enable vue/no-v-html -->
                  </div>
                </div>
              </div>
              <div class="text-center mt-5">
                <a href="https://de.statista.com/statistik/daten/studie/28002/umfrage/wahrscheinlichkeit-der-berufsunfaehigkeit-bis-zur-rente-mit-65/">Quelle: Statista (2009)</a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <h4>Ursachen für Berufsunfähigkeit</h4>
          <SvgVue
            icon="bedarfsinfo/berufsunfaehigkeit/ursachen"
            class="w-1/2 my-7 inline-block"
          />
          <div class="row text-left pt-2.5">
            <div class="col-md-8 offset-md-2 col-lg offset-lg-0">
              <div class="row">
                <template
                  v-for="{name, percentage} in causes"
                  :key="name"
                >
                  <div class="col-2 col-sm-1 col-md-1 text-secondary">
                    <strong>{{ percentage }}%</strong>
                  </div>
                  <div class="col-10 col-sm-11 col-md-5 mb-2.5">
                    {{ name }}
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="text-center mt-2.5">
            <a href="https://de.statista.com/statistik/daten/studie/536354/umfrage/verteilung-der-ursachen-von-berufsunfaehigkeit-in-deutschland/">Quelle: Statista (2022)</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import SvgVue from "@/application/components/SvgVue";

type Group = {
  name: string,
  gender: {
    men: number,
    women: number,
  }
};

type Cause = {
  name: string,
  percentage: number
};

const ageGroupStatistics: Group[] = [
  { name: '20-jährige', gender: { women: 43, men: 38 } },
  { name: '30-jährige', gender: { women: 41, men: 37 } },
  { name: '40-jährige', gender: { women: 39, men: 35 } },
  { name: '50-jährige', gender: { women: 34, men: 29 } },
];

const causes: Cause[] = [
  { name: 'Nervenkrankheiten', percentage: 32 },
  { name: 'Erkrankung des Skelett- und Bewegungsapparates', percentage: 20 },
  { name: 'Sonstige Erkrankungen', percentage: 18 },
  { name: 'Tumore', percentage: 15 },
  { name: 'Unfälle', percentage: 8 },
  { name: 'Erkrankung des Herzens und des Gefäßsystems', percentage: 7 },
];
</script>
