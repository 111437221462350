import { getLiveUpdateChannel, getVersionString } from "@/application/utils/envInfo";
import { ref } from "vue";

const envInfo = ref('-');
const liveUpdateChannel = ref('-');
getVersionString().then((version) => envInfo.value = version);
getLiveUpdateChannel().then((channel) => liveUpdateChannel.value = channel);

export function useEnvInfo() {
  return { versionString: envInfo, liveUpdateChannel };
}
