<template>
  <div>
    <div class="row pflegegrad-slider">
      <div class="col-12 col-xl-8">
        <div class="flex flex-row mt-5 slider-container">
          <div class="w-1/2" />
          <div class="w-1/2 relative">
            <Slider
              v-model="pflegegrad"
              :min="1"
              :max="5"
              :step="1"
              orientation="vertical"
              :tooltips="false"
              hide-min-max-label
              class="relative"
              style="left: -10px;"
            />
            <div
              v-for="(label, i) in labels"
              :key="i"
              :class="[i % 2 === 1 ? '' : 'vue-slider-piecewise-label--left', { active: pflegegrad-1 >= i }]"
              class="vue-slider-piecewise-label absolute cursor-pointer"
              :style="{
                top: `${i*25}%`,
              }"
              @click="pflegegrad = i+1"
            >
              <div
                :class="[i % 2 === 1 ? 'pl-2.5' : 'pr-2.5']"
                class="md:flex flex-col md:flex-row"
              >
                <div
                  :class="[i % 2 === 1 ? 'order-md-2 md:ml-2.5' : 'md:mr-2.5 icon-to-right-side']"
                  class="text-center hidden md:block"
                >
                  <div
                    :class="label.classes"
                    class="square-icon square-icon--6 m-1.5"
                  >
                    <SvgVue
                      :icon="`bedarfsinfo/pflegevorsorge/grad${i+1}`"
                    />
                  </div>
                </div>
                <div class="md:mt-0">
                  <h5 class="mb-2.5 md:mb-1">
                    Pflegegrad {{ i+1 }}
                  </h5>
                  <div class="pflegegrad-description">
                    {{ label.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-4 flex">
        <desktop
          v-bind="childProps"
          class="hidden xl:flex"
        />
      </div>
    </div>

    <mobile
      v-bind="childProps"
      class="block xl:hidden"
    />

    <div class="row">
      <div class="col-12 col-xl-4 offset-xl-8 text-left text-muted mt-5 xl:mt-0">
        <table class="mx-auto">
          <tr>
            <td class="text-right align-baseline">
              <small><sup>1</sup></small>
            </td>
            <td>
              <small>Bundesdurchschnittliche Kosten gemäß Angaben der
                Süddeutsche Krankenversicherung a.G.</small>
            </td>
          </tr>
          <tr>
            <td class="text-right align-baseline">
              <small><sup>2</sup></small>
            </td>
            <td>
              <small>Max. Erstattungssatz bei vollstationärer Pflege
                inkl. Entlastungsleistungen</small>
            </td>
          </tr>
          <tr>
            <td class="text-right align-baseline">
              <small><sup>3</sup></small>
            </td>
            <td>
              <small>Max. Erstattungssatz bei häuslicher Pflege für
                Pflegesachleistungen inkl. Entlastungsleistungen</small>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, unref } from 'vue';

import Slider from '@/application/components/Slider.vue';

import Desktop from './desktop.vue';
import Mobile from './mobile.vue';
import SvgVue from "@/application/components/SvgVue";

const labels = [
  {
    text: 'Geringe Beeinträchtigung der Selbständigkeit oder der Fähigkeiten',
    classes: 'square-icon--scale-on-height',
  },
  {
    text: 'Erhebliche Beeinträchtigungen der Selbständigkeit oder der Fähigkeiten',
    classes: 'square-icon--scale-on-height',
  },
  {
    text: 'Schwere Beeinträchtigungen der Selbständigkeit oder der Fähigkeiten',
    classes: 'square-icon--scale-on-height',
  },
  {
    text: 'Schwerste Beeinträchtigungen der Selbständigkeit oder der Fähigkeiten',
    classes: '',
  },
  {
    text: `Schwerste Beeinträchtigungen der Selbständigkeit oder der
                 Fähigkeiten mit besonderen Anforderungen an die pflegerische Versorgung`,
    classes: '',
  },
];

const averageCosts = {
  stationaer: {
    // [kosten, spv]
    1: [800, 125],
    2: [2445, 770],
    3: [2937, 1262],
    4: [3450, 1775],
    5: [3680, 2005],
  },
  ambulant: {
    1: [800, 125],
    2: [1511, 689],
    3: [2142, 1298],
    4: [2524, 1612],
    5: [3488, 1995],
  },
};

const pflegegrad = ref(2);

const childProps = computed(() => {
  const { stationaer, ambulant } = averageCosts;
  const grad = unref(pflegegrad);
  return {
    stationaer: {
      spv: stationaer[grad][1],
      total: stationaer[grad][0],
      luecke: stationaer[grad][0] - stationaer[grad][1],
    },
    ambulant: {
      spv: ambulant[grad][1],
      total: ambulant[grad][0],
      luecke: ambulant[grad][0] - ambulant[grad][1],
    },
  };
});
</script>

<style lang="scss">

.slider-marker {
  top: 0;
  position: absolute;
  background: #d2d5db;
  height: 14px;
  width: 1px;
  transform: translate(-0.5px, 1px);
}

.slider-value {
  top: 0;
  font-size: 0.8rem;
  position: absolute;
  transform: translate(-50%, 20px);
  font-weight: bold;
}

.slider-vertical .slider-origin {
  top: 0;
}
</style>

<style lang="scss">
  .pflegegrad-slider {
    .vue-slider {
      margin: 0 auto !important;
    }
  }
</style>

<style lang="scss" scoped>
  .slider-container {
    margin: 20px 0 175px;

    @media (min-width: 576px) {
      margin-bottom: 100px;
    }
  }

  .vue-slider-piecewise-label {
    opacity: 0.5;
    top: 0;
    transform: translate(0, -12px) !important;
    white-space: normal !important;
    width: 140px;

    &.active {
      opacity: 1;
    }

    &--left {
      left: auto !important;
      right: 100% !important;
      transform: translate(0, -12px) !important;
    }

    @media (min-width: 576px) {
      width:250px
    }

    @media (min-width: 768px) {
      transform: translate(8px, -12px) !important;
      width: 300px;
    }
  }

  .pflegegrad-description {
    font-size: 0.8rem;
  }
</style>
