<script setup lang="ts">
import { DsAlert, DsCheckbox, DsFormGroup, DsIcon, DsVirtualList } from '@demvsystems/design-components';
import { computed, ref, watch } from 'vue';
import { storeToRefs } from "pinia";
import { useUserDataStore } from "@/user/store/userDataStore";
import type { BankAccount } from "@/api/bankAccount/types";
import { useContractStore } from "@/contracts/stores/contractStore";
import type { ContractOption } from "@/contracts/stores/types";

interface Props {
  modelValue: number[],
  bankAccountId?: number,
}

const props = defineProps<Props>();
const emit = defineEmits(['close', 'update:modelValue'])

const { contractOptionsOwnContracts } = storeToRefs(useContractStore());
const { contractToBankAccountMap } = storeToRefs(useUserDataStore());

const checkedContractIds = ref<number[]>(props.modelValue);
const foreignAssignedContractIsChecked = ref(false);

function getAssignedBankAccount(contractId: number): BankAccount|null {
  return contractToBankAccountMap.value.get(contractId) ?? null;
}

function isAssignedToThis(contractId: number): boolean {
  return !!props.bankAccountId && getAssignedBankAccount(contractId)?.id === props.bankAccountId;
}

function isAssignedToOther(contractId: number): boolean {
  const account = getAssignedBankAccount(contractId);

  return !!account && account.id !== props.bankAccountId;
}

function handleSelectAll(value: boolean) {
  return checkedContractIds.value = value ? contractOptionsOwnContracts.value.map(contract => contract.value) : [];
}

watch(checkedContractIds, (ids) => {
  emit('update:modelValue', ids);
  foreignAssignedContractIsChecked.value = ids.some(isAssignedToOther);
});

function bankAccountInfo(contractId: number): string {
  const account = getAssignedBankAccount(contractId);
  if (!account) return '-';

  return `${account.bankName} ${account.iban}`
}

const sortedContractOptions = computed(() => [...contractOptionsOwnContracts.value].sort(
  (a: ContractOption, b: ContractOption) => {
    const aAssignedToThis = isAssignedToThis(a.value);
    const aAssignedToOther = isAssignedToOther(a.value);
    const bAssignedToThis = isAssignedToThis(b.value);
    const bAssignedToOther = isAssignedToOther(b.value);

    if (aAssignedToThis && !bAssignedToThis) return -1;
    if (bAssignedToThis && !aAssignedToThis) return 1;
    if (aAssignedToOther && !bAssignedToOther) return -1;
    if (bAssignedToOther && !aAssignedToOther) return 1;

    // Wenn beide gleich zugewiesen sind, alphabetische Reihenfolge
    return a.label.localeCompare(b.label, 'de');
  }
));
</script>

<template>
  <div class="flex flex-col gap-4 justify-between">
    <div
      class="rounded-full px-2 py-1 hover:bg-gray-100 self-start -ml-1 mt-1 cursor-pointer"
      @click="emit('close')"
    >
      <DsIcon
        name="arrow-left-long"
        size="lg"
      />
      <span class="ml-2">zurück</span>
    </div>
    <Transition name="fade" mode="out-in">
      <DsAlert
        v-if="foreignAssignedContractIsChecked"
        type="info"
        label="Zugeordnete Verträge"
        class="mb-2 text-start"
      >
        Bestehende Zuordnungen zu anderen Bankverbindungen werden überschrieben
        und die Abbuchung der Beiträge wird umgestellt.
      </DsAlert>
    </Transition>
    <div class="flex flex-col gap-5 items-start p-1 border rounded-lg w-full">
      <DsFormGroup
        :label="`Alle auswählen (${checkedContractIds.length} von ${contractOptionsOwnContracts.length} ausgewählt)`"
        label-for="select-all"
        inline
      >
        <DsCheckbox
          id="select-all"
          :model-value="checkedContractIds.length > 0"
          :indeterminate="checkedContractIds.length > 0 && checkedContractIds.length !== contractOptionsOwnContracts.length"
          class="p-1"
          @update:model-value="handleSelectAll"
        />
      </DsFormGroup>
      <div class="w-full space-y-1 max-h-80 md:max-h-96 overflow-y-auto">
        <DsVirtualList
          v-slot="{ item: contract }"
          :items="sortedContractOptions"
          :item-height="70"
        >
          <div class="flex flex-row gap-2 items-start text-start text-xs">
            <input
              :id="`bac-${contract.value}`"
              v-model="checkedContractIds"
              :value="contract.value"
              type="checkbox"
              class="form-checkbox h-5 w-5 rounded border-gray-300 text-blue-500 focus:ring-blue-500 m-1"
            >
            <label
              :for="`bac-${contract.value}`"
              class="flex flex-col cursor-pointer text-gray-900 w-full"
            >
              <span class="text-sm w-11/12 truncate">{{ contract.label }} {{ contract.inactive ? '(inaktiv)' : '' }}</span>
              <span>{{ contract.number ? `Nr.: ${contract.number}` : 'Keine Vertragsnummer' }}</span>
              <span
                v-if="isAssignedToOther(contract.value)"
                class="text-xs text-orange-800 bg-orange-100 px-1.5 py-0.5 rounded-md mr-auto"
              >
                zugeordnet zu: {{ bankAccountInfo(contract.value) }}
              </span>
              <span
                v-else-if="isAssignedToThis(contract.value)"
                class="text-xs text-green-800 bg-green-100 px-1.5 py-0.5 rounded-md mr-auto"
              >
                dieser Bankverbindung zugeordnet
              </span>
              <span
                v-else
                class="text-xs text-gray-700 bg-gray-100 px-1.5 py-0.5 rounded-md mr-auto"
              >
                Noch nicht zugeordnet
              </span>
            </label>
          </div>
        </DsVirtualList>
      </div>
    </div>
  </div>
</template>
