<template>
  <div>
    <div class="row">
      <div class="col-md-9 offset-md-1 col-lg-8 offset-lg-2 mt-5 md:mb-14 mb-14">
        <!-- Mobile -->
        <Slider
          v-model="sliderIndex"
          orientation="vertical"
          :options="sliderConfig"
          class="flex flex-row lg:hidden"
        />

        <!-- Desktop -->
        <Slider
          v-model="sliderIndex"
          :options="sliderConfig"
          class="hidden lg:block mb-14"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-xl-10 offset-xl-1 mt-7 mb-2.5 md:mb-7">
        <div class="row">
          <div
            v-for="type in types"
            :key="type.name"
            class="col-sm"
          >
            <button
              :class="{
                'btn-dark-lg': type.name === current.name,
                'btn-outline-dark-lg': type.name !== current.name,
              }"
              class="w-full mb-2.5 sm:mb-0"
              type="button"
              @click="current = type"
              v-text="type.name"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table Table mt-7 lg:mt-0">
        <colgroup>
          <col width="30%">
          <col width="23%">
          <col width="23%">
          <col width="24%">
        </colgroup>
        <thead>
          <tr class="text-right">
            <th />
            <th>Regelversorgung</th>
            <th>Regelhöchstsatz</th>
            <th>Über Gebührenordnung</th>
          </tr>
        </thead>
        <tbody class="text-right">
          <tr>
            <td class="text-left">
              Rechnungsbetrag
            </td>
            <td v-text="format(current.regel)" />
            <td v-text="format(current.hoechst)" />
            <td v-text="format(current.ordnung)" />
          </tr>
          <tr>
            <td class="text-left">
              GKV zahlt
            </td>
            <td v-text="format(current.gkv)" />
            <td v-text="format(current.gkv)" />
            <td v-text="format(current.gkv)" />
          </tr>
          <tr>
            <td class="text-left">
              Eigenanteil ohne Zusatzversichung
            </td>
            <td v-text="format(current.regel - current.gkv)" />
            <td v-text="format(current.hoechst - current.gkv)" />
            <td v-text="format(current.ordnung - current.gkv)" />
          </tr>
          <tr class="border-t font-bold">
            <td class="text-left">
              Eigenanteil mit Zusatzversichung
            </td>
            <td
              v-for="(func, key) in sliderConfig[sliderIndex].calculations"
              :key="key"
              v-text="format(func === null ? 0 : func())"
            />
          </tr>
        </tbody>
      </table>
    </div>

    <div class="text-muted text-center text-sm">
      Anmerkungen: Bei den Rechnungsbeträgen handelt es sich um Durchschnittswerte gemäß GOÄ.<br>
      Bei den Berechnungen „über Gebührenordnung“ wurde der 5,5-fache Satz angenommen.
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

import { format } from '@/application/utils/money';

import Slider from './Slider.vue';

const types = [
  {
    name: 'Blinddarm',
    regel: 2300,
    hoechst: 5367,
    ordnung: 8435,
    gkv: 2300,
  },
  {
    name: 'Hüftgelenk',
    regel: 6700,
    hoechst: 15_630,
    ordnung: 24_565,
    gkv: 6700,
  },
  {
    name: 'Herzinfarkt',
    regel: 32_000,
    hoechst: 74_670,
    ordnung: 117_330,
    gkv: 32_000,
  },
];

const current = ref(types[0]);
const sliderIndex = ref(1);

const sliderConfig = [
  {
    label: 'Regelversorgung',
    text: 'Kein Einfluss, welcher Arzt die Behandlung durchführt',
    icon: 'minus',
    calculations: [
      null,
      () => current.value.hoechst - current.value.gkv,
      () => current.value.ordnung - current.value.gkv,
    ],
  },
  {
    label: 'Regelhöchstsatz',
    text: 'Greift bei freier Arztwahl und bei Routine-Eingriffen',
    icon: 'circle',
    iconVariant: 'regular',
    calculations: [
      null,
      null,
      () => current.value.ordnung - current.value.gkv - (current.value.hoechst - current.value.gkv),
    ],
  },
  {
    label: 'Über Gebührenordnung',
    text: 'Greift bei freier Arztwahl und komplizierter Behandlung',
    icon: 'plus',
    calculations: [
      null,
      null,
      null,
    ],
  },
];
</script>
