<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <section class="flex flex-col gap-5 border-x border-b rounded-b mb-7 w-full p-4 z-[1] bg-white">
    <section class="relative">
      <h2>
        {{ name }}
      </h2>
      <DsButton
        v-show="!form.wurdeGeladen"
        icon="xmark-large"
        icon-variant="regular"
        variant="clear"
        :disabled="form.wurdeGeladen"
        title="entfernen"
        class="absolute top-0 right-0"
        @click="$emit('delete', index)"
      />
    </section>

    <section class="flex flex-col gap-5 w-full lg:w-2/3 mx-auto mb-0">
      <h5 class="mb-2">
        Mein Kind ist
      </h5>
      <DsFieldError
        v-for="error in formErrors.anrede"
        :key="error"
      >
        {{ error }}
      </DsFieldError>

      <OptionGroup
        :id="`formError.kinder.${[index]}.anrede`"
        has-icons
        :options="[
          {value: 2, label: 'weiblich', icon: 'icomoon/female',
           labelClasses: 'flex flex-col', iconClass: 'gender'},
          {value: 1, label: 'männlich', icon: 'icomoon/male',
           labelClasses: 'flex flex-col', iconClass: 'gender'},
        ]"
        :value="form.anrede"
        option-width-classes="w-1/3 md:w-1/2"
        @change="set('anrede', $event)"
      />

      <div class="flex flex-col gap-5">
        <DsFormGroup
          :id="`formError.kinder.${[index]}.vorname`"
          label="Vorname"
        >
          <DsInput
            :model-value="form.vorname"
            size="lg"
            immediate
            @update:model-value="set('vorname', $event)"
          />
          <DsFieldError
            v-for="error in formErrors.vorname"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </DsFormGroup>
        <DsFormGroup
          :id="`formError.kinder.${[index]}.nachname`"
          label="Nachname"
        >
          <DsInput
            :model-value="form.nachname"
            size="lg"
            immediate
            @update:model-value="set('nachname', $event)"
          />
          <DsFieldError
            v-for="error in formErrors.nachname"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </DsFormGroup>
        <DsFormGroup
          :id="`formError.kinder.${[index]}.geburtsdatum`"
          label="Geburtsdatum"
        >
          <DateInput
            :model-value="form.geburtsdatum"
            size="lg"
            @update:model-value="set('geburtsdatum', $event)"
          />
          <DsFieldError
            v-for="error in formErrors.geburtsdatum"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </DsFormGroup>
      </div>
    </section>

    <section
      v-if="!isSixteen"
      class="flex flex-col gap-5 w-full lg:w-2/3 mx-auto mt-7"
    >
      <h5 class="-mb-3">
        Berufsstatus und Einkommen meines Kindes
      </h5>
      <DsFormGroup label="Berufsstatus">
        <DsSelect
          :model-value="form.taetigkeitsstatus"
          :data="taetigkeitsstatus"
          placeholder=""
          size="lg"
          @update:model-value="set('taetigkeitsstatus', $event)"
        />
        <DsFieldError
          v-for="error in formErrors.taetigkeitsstatus"
          :key="error"
        >
          {{ error }}
        </DsFieldError>
      </DsFormGroup>
      <DsFormGroup label="Bruttoeinkommen">
        <MoneyInput
          :model-value="form.brutto"
          size="lg"
          as-string
          @update:model-value="set('brutto', handleMoneyValue($event))"
        />
        <DsFieldError
          v-for="error in formErrors.brutto"
          :key="error"
        >
          {{ error }}
        </DsFieldError>
      </DsFormGroup>

      <DsRadioGroup
        :model-value="form.isBruttoJahresGehalt === null ? '' : form.isBruttoJahresGehalt ? '1' : '0'"
        :variant="isMobile ? 'button-col' : 'button'"
        class="w-full bg-white"
        @update:model-value="form.isBruttoJahresGehalt = $event === '1'"
      >
        <DsRadioButton
          value="1"
          style="height: 48px"
        >
          im Jahr
        </DsRadioButton>

        <DsRadioButton
          value="0"
          style="height: 48px"
        >
          im Monat
        </DsRadioButton>
      </DsRadioGroup>
      <DsFormGroup label="Nettoeinkommen">
        <MoneyInput
          :model-value="form.netto"
          size="lg"
          as-string
          @update:model-value="set('netto', handleMoneyValue($event))"
        />
        <DsFieldError
          v-for="error in formErrors.netto"
          :key="error"
        >
          {{ error }}
        </DsFieldError>
      </DsFormGroup>

      <DsRadioGroup
        :model-value="form.isNettoJahresGehalt === null ? '' : form.isNettoJahresGehalt ? '1' : '0'"
        :variant="isMobile ? 'button-col' : 'button'"
        class="w-full bg-white"
        @update:model-value="form.isNettoJahresGehalt = $event === '1'"
      >
        <DsRadioButton
          value="1"
          style="height: 48px"
        >
          im Jahr
        </DsRadioButton>

        <DsRadioButton
          value="0"
          style="height: 48px"
        >
          im Monat
        </DsRadioButton>
      </DsRadioGroup>
    </section>

    <section class="flex flex-col gap-5 w-full lg:w-2/3 mx-auto mt-7">
      <h5 class="mb-5">
        Der Krankenversicherungsstatus meines Kindes lautet
      </h5>
      <DsFieldError
        v-for="error in formErrors.krankenversicherungsstatus"
        :key="error"
      >
        {{ error }}
      </DsFieldError>
      <DsRadioGroup
        :model-value="form.krankenversicherungsstatus === null ? '' : `${form.krankenversicherungsstatus}`"
        variant="button-col"
        class="w-full bg-white"
        @update:model-value="form.krankenversicherungsstatus = $event === '' ? null : Number($event)"
      >
        <DsRadioButton
          v-for="{label, value} in krankenversicherungsstatus"
          :key="value"
          :value="`${value}`"
          style="height: 48px"
        >
          {{ label }}
        </DsRadioButton>
      </DsRadioGroup>
    </section>

    <section class="flex flex-col gap-5 w-full lg:w-2/3 mx-auto mt-7">
      <h5 class="mb-5">
        Mein Kind leidet an einer schweren Krankheit
        <DsButton
          class="ml-2"
          variant="outline"
          icon="info"
          size="sm"
          title="Weitere Infos anzeigen"
          @click="openModal"
        />
      </h5>
      <DsFieldError
        v-for="error in formErrors.hatSchwereKrankheit"
        :key="error"
      >
        {{ error }}
      </DsFieldError>

      <OptionGroup
        :id="`formError.kinder.${[index]}.hatSchwereKrankheit`"
        has-icons
        :options="[
          {label: 'Ja', labelClasses: `${form.hatSchwereKrankheit === 1 ? 'text-green-700' : 'flex flex-col'}`, value: 1, icon: 'icomoon/check', iconClass: `check ${form.hatSchwereKrankheit === 1 ? 'fill-green-700' : ''}`},
          {label: 'Nein', labelClasses: `${form.hatSchwereKrankheit === 0 ? 'text-red-700' : 'flex flex-col'}`, value: 0, icon: 'icomoon/close', iconClass: `close ${form.hatSchwereKrankheit === 0 ? 'fill-red-700' : ''}`}
        ]"
        :value="form.hatSchwereKrankheit"
        option-width-classes="w-1/3"
        @change="set('hatSchwereKrankheit', $event)"
      />

      <div v-if="form.hatSchwereKrankheit">
        <DsFormGroup label="Diagnose">
          <DsTextarea
            :model-value="form.diagnose === null ? undefined : form.diagnose"
            :input-id="`kind_${index}_diagnose`"
            :name="`kind_${index}_diagnose`"
            placeholder="Bitte geben Sie hier Ihre Diagnose ein."
            size="lg"
            :rows="8"
            @update:model-value="form.diagnose = $event === undefined ? null : $event"
          />
          <DsFieldError
            v-for="error in formErrors.diagnose"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </DsFormGroup>
      </div>
    </section>

    <SchwereKrankheitenModal
      :show="showModal"
      @close-modal="closeModal"
      @open-modal="openModal"
    />
  </section>
</template>

<script lang="ts">
import { DsButton, DsFieldError,
  DsFormGroup,
  DsInput,
  DsRadioButton,
  DsRadioGroup,
  DsSelect, DsTextarea } from '@demvsystems/design-components';
import { differenceInYears, parse } from 'date-fns';
import { sortBy } from 'lodash-es';
import type { PropType } from 'vue';
import { computed, defineComponent, ref } from 'vue';

import DateInput from '@/application/components/form-elements/DateInput/DateInput.vue';
import MoneyInput from '@/application/components/form-elements/MoneyInput/MoneyInput.vue';
import { useDetectMobile } from '@/application/composables/detectMobile';
import OptionGroup from '@/checkup/components/OptionGroup.vue';
import SchwereKrankheitenModal from '@/checkup/components/steps/SchwereKrankheitenModal.vue';
import { toLabelAndValue } from '@/checkup/utils/demvWerte';
import { demvWerte } from '@/checkup/state';
import type { Kind } from '@/checkup/types/types';

export default defineComponent({
  components: {
    DsTextarea,
    DsButton,
    DsRadioButton,
    DsRadioGroup,
    MoneyInput,
    DsSelect,
    DateInput,
    DsInput,
    DsFormGroup,
    DsFieldError,
    OptionGroup,
    SchwereKrankheitenModal,
  },

  props: {
    index: {
      type: Number,
      required: true,
    },
    form: {
      type: Object as PropType<Kind>,
      required: true,
    },
    formErrors: {
      type: Object,
      default: () => {},
    },
  },

  emits: ['delete', 'clear'],

  setup(props, { emit }) {
    const { isMobile } = useDetectMobile();

    const name = computed(() => (props.form.vorname
      ? `${props.form.vorname} ${props.form.nachname || ''}`.trim()
      : `Kind ${Number(props.index) + 1}`));

    const set = (field, value) => {
      // eslint-disable-next-line vue/no-mutating-props
      props.form[field] = value;
      emit('clear', `${field}`);
    };

    const showModal = ref(false);

    const openModal = () => {
      showModal.value = true;
    };
    const closeModal = () => {
      showModal.value = false;
    };

    return {
      name,
      set,
      showModal,
      openModal,
      closeModal,
      isMobile,

      // TODO: remove when backend accepts value as float
      handleMoneyValue(value: number | string | null): number | string | null {
        if (value === null || value === '0' || value === 0) return null;
        if (typeof value === 'number') return value.toString();
        return value;
      },
      taetigkeitsstatus: sortBy(toLabelAndValue(demvWerte.taetigkeitstatus), 'label'),
      krankenversicherungsstatus: toLabelAndValue(demvWerte.krankenversicherungsstatus),
      isSixteen: computed(() => {
        if (!props.form.geburtsdatum) return false;

        const now = new Date();
        const input = parse(props.form.geburtsdatum, 'dd.MM.yyyy', now);
        const age = differenceInYears(now, input);

        return !Number.isNaN(age) && age >= 16;
      }),
    };
  },
});
</script>
