<template>
  <section
    ref="element"
    class="section p-pkv-full-bg-section"
  >
    <div class="container">
      <div class="flex flex-col justify-center md:flex-row mb-7 divide-x divide-solid">
        <button
          v-for="(tab, index) in tabs"
          :key="tab.name"
          :class="[tab === current ? 'text-white text-lg bg-secondary hover:bg-[#2360B0] ' +
            'py-5 ease-in-out duration-300 md:last:rounded-r md:first:rounded-l' :
            'text-dark text-lg bg-white py-5 md:last:rounded-r md:first:rounded-l'
          ]"
          class="flex flex-grow justify-center items-center"
          @click="tabIndex = index"
        >
          {{ tab.name }}
        </button>
      </div>
      <div class="row justify-center print:block overflow-hidden">
        <div class="col bg-white shadow rounded">
          <div class="py-5 md:p-7">
            <component :is="current.slot" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { dom } from '@fortawesome/fontawesome-svg-core';
import { computed, onUpdated, ref, useSlots } from 'vue';

const slots = useSlots();
const element = ref(null);
const tabIndex = ref(0);

const tabs = computed(() => [
  {
    name: 'Arbeitslosigkeit in der PKV',
    slot: slots.arbeitslosigkeit,
  },
  {
    name: 'Wechsel zurück in die GKV',
    slot: slots.wechsel,
  },
  {
    name: 'Beitrag PKV zu Rentenbeginn',
    slot: slots.beitrag,
  },
]);

const current = computed(() => tabs.value[tabIndex.value]);

onUpdated(() => {
  dom.i2svg({ node: element.value });
});
</script>
