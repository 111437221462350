import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "fill-rule": "evenodd",
  "stroke-linejoin": "round",
  "stroke-miterlimit": "2",
  class: "col-12 col-md-8 p-0",
  "clip-rule": "evenodd",
  viewBox: "0 0 382 133"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<text font-family=\"&#39;Nunito Sans&#39;,sans-serif\" font-size=\"8\" font-weight=\"500\" transform=\"translate(.72 120.81)\"> Quelle: Robert-Koch.Institut, Stand 11/2016; Deutsche Herzstiftung, </text><text font-size=\"8\" font-weight=\"500\" transform=\"translate(.72 130.41)\"> Jahresbericht 2017; www.Schlaganfall-Hilfe.de, Schätzung Februar 2012 </text><path fill=\"#4181D2\" d=\"M96.76 0h284.9v32.4H96.76z\"></path><text fill=\"#fff\" font-size=\"12\" font-weight=\"500\" transform=\"translate(111.7 21.26)\"> ca. 490.000 </text><path fill=\"#333\" d=\"M96.76 35h111.8v32.4H96.76z\"></path><text fill=\"#fff\" font-size=\"12\" font-weight=\"500\" transform=\"translate(111.7 56.26)\"> ca. 220.000 </text><path fill=\"#333\" d=\"M96.76 70.5h132.9v32.4H96.76z\"></path><text fill=\"#fff\" font-size=\"12\" font-weight=\"500\" transform=\"translate(111.7 91.76)\"> ca. 270.000 </text><text font-size=\"12\" font-weight=\"500\" transform=\"translate(1.93 90.75)\"> Schlaganfall </text><g font-size=\"12\" font-weight=\"500\"><text transform=\"translate(37.56 48.11)\"> Akuter </text><text transform=\"translate(9.76 64.91)\"> Herzinfarkt </text></g><text font-size=\"12\" font-weight=\"500\" transform=\"translate(43.69 21.51)\"> Krebs </text>", 11)
  ])))
}
export default { render: render }