<template>
  <DsModal
    :show="show"
    hide-buttons
    @cancel="$emit('closeModal')"
  >
    <div
      role="document"
      class="flex flex-col gap-2 max-h-[calc(100vh-7rem)] overflow-y-auto
      -mx-4 sm:-mx-6 px-4 sm:px-6 -my-5 sm:-my-6 py-5 sm:py-6"
    >
      <div class="flex flex-row justify-between items-center mb-3">
        <h3 class="text-xl text-left font-bold text-gray-900 m-0">
          Schwere Krankheiten
        </h3>

        <DsButton
          icon="xmark-large"
          icon-variant="regular"
          variant="clear"
          title="Schließen"
          @click="$emit('closeModal')"
        />
      </div>

      <div
        v-for="{headline, content} in diseases"
        :key="headline"
      >
        <h4>
          {{ headline }}
        </h4>
        <p>
          {{ content }}
        </p>
      </div>
    </div>
  </DsModal>
</template>

<script setup lang="ts">
import { DsButton, DsModal } from '@demvsystems/design-components';

interface Props {
  show: boolean;
  buttonSize?: 'sm' | 'default' | 'lg';
}

defineProps<Props>();

defineEmits(['closeModal', 'openModal']);

const diseases = [
  { headline: 'Herz-/Kreislaufsystem',
    content: 'Arteriosklerose, arterielle Verschlusskrankheit (AVK), Herzinfarkt/-fehler/-stillstand/-erkrankungen, '
        + 'Embolie, Durchblutungsstörungen, Kardiomyopathie, Thrombose, Bluthochdruck,  Koronare Herzkrankheit, '
        + 'Schlaganfall' },
  { headline: 'Blut',
    content: 'Anämie, Leukämie, Gerinnungsstörungen oder Bluterkrankheit' },
  { headline: 'Gehirn und zentrales Nervensystem',
    content: 'Alzheimer, Demenz, Epilepsie, Infantile Cerebralparese, Lähmungen, Parkinson, Multiple Sklerose, '
        + 'Polyneuropathie, Hirn- oder Hirnhautentzündung, Poliomyelitis, Schädel-Hirn-Verletzungen' },
  { headline: 'Innere Organe',
    content: 'Colitis ulcerosa, Hepatitis, erhöhte Leberwerte, innere Blutungen, Morbus Crohn, Nierenentzündung, '
        + 'Nierenversagen, Pankreatitis' },
  { headline: 'Psychische Erkrankungen und Verhaltensstörungen',
    content: 'Depressionen, Manien, Neurosen und psychosomatische Störungen, Psychosen, Schizophrenie oder '
        + 'Persönlichkeitsstörungen wie Borderline-Syndrom, Paranoia, Selbsttötungsversuch, Hirnorganisches '
        + 'Psychosyndrom, Autismus' },
  { headline: 'Funktionsbeeinträchtigung der Sinnesorgane',
    content: 'Blindheit, Taubheit, Schwerhörigkeit, Sehschwäche ab 7 Dioptrien, Glaukom, Katarakt, Netzhautablösung, '
        + 'Retinopathie, Uveitis' },
  { headline: 'Hormon-/Stoffwechselerkrankungen',
    content: 'Diabetes mellitus, Gicht' },
  { headline: 'Bewegungsapparat',
    content: 'Abnutzung, Amputation, Brüche/Risse, Verschleiß, Entzündungen, Fehlstellungen, Verletzungen, '
        + 'Verkrümmungen, Versteifungen, Vorfälle, Arthrose, Glasknochenkrankheit, Ischiasbeschwerden, Hexenschuss, '
        + 'Lumbago, Morbus Bechterew, Osteoporose, Osteopenie, Rheuma, Oberschenkelhalsbruch, Polyarthritis, '
        + 'Spina bifida, Knochenmarkentzündung/Osteomyelitis, immer wiederkehrende Verrenkungen '
        + '(habituelle Luxationen)' },
  { headline: 'Sonstige Gesundheitsstörungen oder -zustände',
    content: 'Transplantation, AIDS-/HIV-Infektion, Apnoe, Dialysepflichtigkeit, Bewusstlosigkeit, Schlafapnoe,'
        + ' Suchterkrankung (Alkohol, Drogen, Tabletten), Zirrhose, Krebs/Tumor, Tuberkulose, '
        + 'Hodgkin-Krankheit/Lymphogranulomatose, Down-Syndrom, Hydrozephalus/Wasserkopf' },
];

</script>
