import { Preferences } from '@capacitor/preferences';

interface PreferenceKeys {
  PushToken: string,
  ShouldLockApp: boolean,
  IsAppLocked: boolean,
}

const cache = new Map<keyof PreferenceKeys, string>();

export const getPreference = async <T extends keyof PreferenceKeys, K extends PreferenceKeys[T]>(key: T, defaultValue: K): Promise<K> => {  if (cache.has(key)) {
    const val = cache.get(key);
    if (val != null) {
      return JSON.parse(val) as K;
    } else {
      cache.delete(key);
    }
  }

  const value = (await Preferences.get({ key }))?.value;

  if (!value) return defaultValue;

  return JSON.parse(value) as K;
};


export const setPreference = async <T extends keyof PreferenceKeys, K extends PreferenceKeys[T]>(key: T, value: K): Promise<void> => {
  const serialized = JSON.stringify(value);
  await Preferences.set({ key, value: serialized });
  cache.set(key, serialized);
};

export const removePreference = async (key: keyof PreferenceKeys): Promise<void> => {
  await Preferences.remove({ key });
};
