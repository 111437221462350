<template>
  <div class="section">
    <div class="container">
      <div class="row text-center">
        <div class="col-12">
          <h2 class="mb-5 md:mb-14">
            Welche Beiträge fallen in der gesetzlichen und privaten Krankenversicherung an?
          </h2>
        </div>
      </div>

      <div class="row ">
        <div class="col-12 col-md-6">
          <template
            v-for="({text, title}, i) in beitraege"
            :key="title"
          >
            <h5
              :class="{ 'pt-2.5': i > 0 }"
              class="font-bold"
              v-text="title"
            />
            <BulletPoint :item="{ text }" />
          </template>
        </div>
        <div class="col-12 col-md-6 mt-7 md:mt-0">
          <Kinder />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BulletPoint from '@/bedarf/components/BulletPoint.vue';
import Kinder from '@/bedarf/components/gesundheitsvorsorge/pkv/Kinder.vue';

const beitraege = [
  {
    title: 'Beiträge',
    text: `Die Beiträge der gesetzlichen Krankenversicherung werden in Abhängigkeit vom Einkommen berechnet.
            Gutverdiener bezahlen besonders hohe Beiträge. In der privaten Krankenversicherung ermittelt sich
            der Beitrag hingegen aufgrund der gewünschten Leistungen, des Gesundheitszustands und des Eintrittsalters
            des Versicherten.`,
  },
  {
    title: 'Elternzeit',
    text: `Während gesetzlich Pflichtversicherte in der GKV in der Elternzeit beitragsfrei versichert sind, müssen
            freiwillige Mitglieder Beiträge entrichten. Als privat Versicherter entrichten Sie in diesem Zeitraum
            ebenfalls Beiträge. Da der Arbeitgeberzuschuss entfällt, empfehlen sich Tarife, die während der Elternzeit
            günstiger werden.`,
  },
  {
    title: 'Kinder',
    text: `In der gesetzlichen Krankenversicherung sind Kinder beitragsfrei versichert. In der privaten
            Krankenversicherung fällt hingegen für jedes Kind ein individueller Beitrag an.`,
  },
];
</script>
