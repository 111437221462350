<template>
  <div
    class="floating-action-btn bg-blue-500 hover:bg-blue-700 cursor-pointer text-white box-shadow flex
    justify-center items-center z-20"
    :class="[rounded ? 'rounded-full' : 'rounded-lg']"
    :style="style"
  >
    <slot>
      <DsIcon
        name="plus"
        size="2x"
        class="text-white"
      />
    </slot>
  </div>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue';
import { computed } from 'vue';
import { DsIcon } from '@demvsystems/design-components';

const sizeMap = {
  'xs': '2rem',
  '1x': '3rem',
  '2x': '3.25rem',
  '3x': '3.5rem',
  '4x': '3.75rem',
  '5x': '4rem',
};

interface Props {
  size?: 'xs' | '1x' | '2x' | '3x' | '4x' | '5x',
  width?: string,
  height?: string,
  rounded?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  size: '3x',
  width: undefined,
  height: undefined,
});

const style = computed<CSSProperties>(() => (
  props.width && props.height
    ? { width: props.width, height: props.height }
    : { width: sizeMap[props.size], height: sizeMap[props.size] }
));
</script>

<style scoped>
.floating-action-btn {
  bottom: calc(5rem + env(safe-area-inset-bottom))
}
</style>
