import type { Filter } from "@/application/filter/type/Filter";
import { ref } from "vue";

export function useActivatableFilter<T> (filter: Filter<T>, startActive: boolean = false)
{
    const active = ref(startActive)

    const wrappedFilter = (item: T) => {
        if (!active.value) {
            return true
        }
        return filter(item)
    }

    return {
        filter: wrappedFilter,
        active
    }
}
