import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 72 191"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"#1e7696\" d=\"M1.8 179s-1 3.6-1 4.1 1.5 5.8 1.4 6.2c-.1.3 2.9 1 2.9 1l2.3-3.4s1.6-2 1.3-2.9-1-5.5-1-5.5zm40.1-1.2s.2 3.8.3 4.2c.2.4 3.3 5 3.3 5.4s3.1 0 3.1 0l1.1-4s.9-2.4.3-3.2-2.8-4.8-2.8-4.8z\"></path><path fill=\"#EEC7B8\" d=\"M69.9 88.7c1.05-.97.65-3.11-.89-4.77s-3.64-2.22-4.69-1.24c-1.05.97-.66 3.11.88 4.77s3.64 2.22 4.7 1.24\"></path><path fill=\"#176b8a\" d=\"m30.9 36.4 6.5.6 13.2 28.5 18.8 18.9-5 2.1-18.5-15.6s-4.6-8.7-5-9.3c-.2-.4-4.8-13.1-8.5-17-2.3-2.5-1.5-8.2-1.5-8.2\"></path><path fill=\"#333\" d=\"m7.2 99 1.7 37.5-8 43.2 8.4.4 8.6-38.6 4.3-34.8 2.6.2s2.2 28.6 2.2 29.3 14.5 43.5 14.5 43.5 9.8-.4 9.1-.7c-.7-.4-12.9-44.3-12.9-44.3s1.4-41.1 1.4-40.4c-.2.6-31.9 4.7-31.9 4.7\"></path><path fill=\"#E2C7C0\" d=\"m19.9 29.1-.6 4.9-5-.1L27 58.4l3.3-23-2.6-.2.2-4.3z\"></path><path fill=\"#F9D8CD\" d=\"M24.04 32.61c7.3-1.19 12.13-8.87 10.78-17.15C33.47 7.17 26.46 1.42 19.15 2.6c-7.3 1.2-12.13 8.88-10.78 17.16 1.35 8.29 8.36 14.04 15.67 12.85\"></path><path fill=\"#BCA67A\" d=\"M17.7 17.4s10.2-2.5 13.3-3c0 0 3.3 11.6 1.8 15.2 0 0 14-5-.8-26.1 0 0-18.7-5.8-24.5 8 0 0-1.6 8.4 2.7 19.3.1 0 4.4-10.3 7.5-13.4\"></path><path fill=\"#1e7696\" d=\"m14.2 33.6 9.7 14.9 3.7-1.2 2.7-11.9 5.2 1.6 3.2 18.4-.7 15 5.3 27.1-37.6 3.4.8-27.2.5-35.5z\"></path><path fill=\"#EEC7B8\" d=\"M32.45 98.15c1.15-.87.97-3.03-.4-4.84-1.36-1.8-3.4-2.57-4.54-1.7-1.14.86-.97 3.03.4 4.84 1.36 1.8 3.4 2.57 4.54 1.7\"></path><path fill=\"#176b8a\" d=\"m7.3 38.2-3.6 5.5 4.5 30.1 19.2 22.1 5.2-2.8L15.1 71s-2.2-13.2-2.5-13.8c-.2-.4.9-7.5.2-12.7-.5-3.5-5.5-6.3-5.5-6.3\"></path><path fill=\"#E5E5E5\" d=\"m7.8 22.4 29.8-9.2-2.4-5.3-28 11.4z\"></path><path fill=\"#EAEAEA\" d=\"m7.1 19.5 21.1-5.6 6.8-2.4-27.9 4.1z\"></path><path fill=\"#DDD\" d=\"m8 24.6 30.8-8.5-2.3-5.3-29 10.8zm7.1-10.2 6.3-.9 13.7-5.6L33.7 6 7.3 15.6z\"></path>", 13)
  ])))
}
export default { render: render }