<script setup lang="ts">
import { DsButton } from "@demvsystems/design-components";
import { useEnvInfo } from "@/application/composables/envInfo";
import { newAppVersion, openAppStore } from "@/application/plugins/appUpdate";
import SvgVue from "@/application/components/SvgVue";
import ActionSheet from "@/application/components/ActionSheet.vue";
import { isWebPlatform } from "@/application/utils/envInfo";

const { versionString, liveUpdateChannel } = useEnvInfo();
const playStoreUrl = 'https://play.google.com/store/apps/details?id=de.demv.finanzmanager.app';
</script>

<template>
  <Transition name="fade">
    <div
      v-if="!!newAppVersion"
      class="overlay bg-white relative min-h-dvh flex flex-col"
    >
      <div class="welcome-image sticky top-0" />

      <ActionSheet
        class="-mt-10 relative grow"
        title="Zeit für ein Update"
      >
        <div class="w-32 mx-auto mt-10 mb-5">
          <SvgVue icon="bannerVertical.svg" />
        </div>
        <div class="text-gray-700 text-sm text-center mb-10">
          Version <span class="font-medium">{{ newAppVersion }}</span> verfügbar.
        </div>
        <div class="text-center mb-10">
          <DsButton
            :href="isWebPlatform ? playStoreUrl : undefined"
            :handler="!isWebPlatform ? openAppStore : undefined"
            size="lg"
          >
            Jetzt updaten
          </DsButton>
        </div>
        <div
          class="grow flex flex-col justify-end text-gray-300 text-center pb-safe mb-4"
          style="font-size: 0.65rem;"
        >
          Version: {{ versionString }} ({{ liveUpdateChannel }})
        </div>
      </ActionSheet>
    </div>
  </Transition>
</template>

<style scoped>
.welcome-image {
  height: 50dvh;
  background-size: cover;
  background-position: center center;
  background-image: url('../../../../assets/welcome.jpg');
}
</style>
