import { captureException } from '@sentry/vue';
import { defineStore } from 'pinia';

import { getDocuments } from '@/api/documents/documentsApi';
import type { Document } from '@/api/documents/types';
import { resolveRelations } from '@/application/utils/api/resolveRelations';
import type { NestedDocuments } from "@/contracts/types/types";
import { call } from "@/api/lib/integration";
import { FetchError } from "@/api/lib/errors";
import type { Ref } from "vue";
import { ref } from "vue";

export const useDocumentStore = defineStore('document', () => {
  const documents: Ref<Document[]> = ref([]);
  const error: Ref<null | Error> = ref(null);
  const loading = ref(false);

  function removeUserDocument(userDocumentId: number) {
    const index = documents.value?.findIndex((document) => document.userDocumentId === userDocumentId);
    if (index === undefined || index < 0) return;
    documents.value?.splice(index, 1);
  }

  function updateUserDocument(userDocumentId: number, document: Document) {
    const index = documents.value?.findIndex((storeDocument) => storeDocument.userDocumentId === userDocumentId);
    if (index === undefined || index < 0) return;
    documents.value?.splice(index, 1, document);
  }

  async function fetchDocuments() {
    documents.value = [];
    loading.value = true;
    error.value = null;
    await call(
        getDocuments(),
        (data) => {
          const resolved = resolveRelations(data) as NestedDocuments;
          documents.value = resolved.documents ? resolved.documents.map((document) => document) : [];
        },
        (err) => {
          if (err instanceof FetchError) {
            error.value = err;
          }
          captureException(error);
        },
    );
    loading.value = false;
  }

  function $reset() {
    documents.value = [];
    error.value = null;
    loading.value = false;
  }

  return {
    $reset,
    documents,
    error,
    loading,
    removeUserDocument,
    updateUserDocument,
    fetchDocuments
  }
})

