import { defineStore } from 'pinia';
import type { News, NewsAndContent } from "@/api/news/types";
import { getAllNews, getNews } from "@/api/news/newsApi";
import { call } from "@/api/lib/integration";

export type NewsStore = {
  news: News[],
  current: NewsAndContent | null,
  initiallyFetched: boolean,
};

export const useNewsStore = defineStore('newsStore', {
  state: (): NewsStore => ({
    initiallyFetched: false,
    news: [],
    current: null,
  }),
  actions: {
    async fetchAllNews() {
      await call(getAllNews(), (news) => { this.news = news });
    },
    async fetchNews(id: number) {
      await call(getNews({ id }), (news) => { this.current = news });
    },
  },
});
