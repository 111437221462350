<template>
  <div>
    <section
      v-if="kinder.ausPw.length > 0"
      class="mb-5"
    >
      <div
        v-for="({ clientId, vorname, nachname, zusammenLebend }, i) in kinder.ausPw"
        :key="clientId"
      >
        <h2 class="mb-3 md:mb-5 checkup-h2">
          {{ vorname }} {{ nachname }} lebt in meinem Haushalt
        </h2>

        <OptionGroup
          has-icons
          :options="[
            {label: 'Ja', labelClasses: `${zusammenLebend ? 'text-green-700' : 'flex flex-col'}`, value: true, icon: 'icomoon/check', iconClass: `check ${zusammenLebend ? 'fill-green-700' : ''}`},
            {label: 'Nein', labelClasses: `${!zusammenLebend ? 'text-red-700' : 'flex flex-col'}`, value: false, icon: 'icomoon/close', iconClass: `close ${!zusammenLebend ? 'fill-red-700' : ''}`}
          ]"
          :value="zusammenLebend"
          option-width-classes="w-1/3 sm:w-1/4 md:w-1/6"
          @change="setzusammenLebend(i, $event)"
        />
      </div>
    </section>

    <section
      v-if="!kinder.hasAusPwZusammenLebend"
      class="mb-5"
    >
      <div class="row flex justify-center">
        <div class="col-md-12">
          <h2 class="mb-3 md:mb-5 checkup-h2">
            Ich habe Kinder und lebe mit diesen in einem Haushalt
          </h2>
          <DsFieldError
            v-for="error in formErrors.neueKinder"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </div>
      </div>

      <OptionGroup
        has-icons
        :options="[
          {label: 'Ja', labelClasses: `${form.neueKinder ? 'text-green-700' : 'flex flex-col'}`, value: true, icon: 'icomoon/check', iconClass: `check ${form.neueKinder ? 'fill-green-700' : ''}`},
          {label: 'Nein', labelClasses: `${!form.neueKinder ? 'text-red-700' : 'flex flex-col'}`, value: false, icon: 'icomoon/close', iconClass: `close ${!form.neueKinder ? 'fill-red-700' : ''}`}
        ]"
        :value="form.neueKinder"
        option-width-classes="w-1/3 sm:w-1/4 md:w-1/6"
        @change="setNeueKinder"
      />
    </section>

    <div
      v-if="kinder.zusammenLebend.length > 0"
      class="flex flex-col w-full md:w-1/2 mx-auto"
    >
      <div class="flex flex-row justify-center py-1">
        <DsButton
          icon="add"
          variant="clear"
          size="sm"
          @click="addChild"
        >
          Weitere Kinder hinzufügen
        </DsButton>
      </div>

      <DsTabs v-model:active-tab="selected">
        <DsTab
          v-for="({ kind, index }) in kinderZusammenlebend"
          :key="index"
          :tab-id="index"
          :title="kind.vorname || `Kind ${index + 1}`"
          :icon="hasKindErrors(index) ? 'exclamation-circle' : undefined"
        >
          <KindKachel
            v-if="kindForm"
            :index="selected"
            :form="kindForm"
            :form-errors="kindErrors"
            @delete="removeChild"
            @clear="clear('kinder', selected, $event)"
          />
        </DsTab>
      </DsTabs>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DsButton, DsFieldError, DsTab, DsTabs } from '@demvsystems/design-components';
import { computed, nextTick, ref } from 'vue';

import OptionGroup from '@/checkup/components/OptionGroup.vue';
import KindKachel from '@/checkup/components/steps/Kind.vue';
import { useStep } from '@/checkup/state';
import { kind as kindInitData } from '@/checkup/state/base';
import { Step } from '@/checkup/utils/steps';
import type { Kind } from '@/checkup/types/types';

const { form, formErrors, clear } = useStep(Step.KINDER);
const { form: grunddatenForm } = useStep(Step.GRUNDDATEN);

const kinderZusammenlebend = computed(() => form.value.kinder.reduce(
  (acc: { kind: Kind, index: number }[], kind, i) => (kind.zusammenLebend
    ? [...acc, { kind, index: i }]
    : acc),
  [],
));

function getZusammenLebendKindIndex(first = false) {
  if (first) {
    for (let i = 0; i < form.value.kinder.length; i++) {
      if (form.value.kinder[i].zusammenLebend === true) {
        return i;
      }
    }
  } else {
    for (let i = form.value.kinder.length - 1; i >= 0; i--) {
      if (form.value.kinder[i].zusammenLebend === true) {
        return i;
      }
    }
  }
  return 0;
}

const selected = ref<number>(getZusammenLebendKindIndex(true));
const kindForm = computed(() => form.value.kinder[selected.value]);
const kindErrors = computed(() => (formErrors.value.kinder && formErrors.value.kinder[selected.value]
  ? formErrors.value.kinder[selected.value]
  : {}));

const kinder = computed<{ ausPw: Kind[], zusammenLebend: Kind[], hasAusPwZusammenLebend: boolean }>(() => {
  const ausPw: Kind[] = [];
  const zusammenLebend: Kind[] = [];
  let hasAusPwZusammenLebend = false;
  for (let index = 0; index < form.value.kinder.length; index += 1) {
    const k = form.value.kinder[index];
    if (k.wurdeGeladen) ausPw.push(k);
    if (k.zusammenLebend) {
      zusammenLebend.push(k);
    }
    if (k.wurdeGeladen && k.zusammenLebend) hasAusPwZusammenLebend = true;
  }
  return { ausPw, zusammenLebend, hasAusPwZusammenLebend };
});

const addChild = () => {
  form.value.kinder.push({
    ...kindInitData,
    nachname: grunddatenForm.value.nachname,
    zusammenLebend: true,
    wurdeGeladen: false,
  });
  nextTick(() => {
    selected.value = getZusammenLebendKindIndex();
  });
};

const removeChild = (index: number) => {
  clear('kinder', index);
  if (form.value.kinder[index].wurdeGeladen) return;
  form.value.kinder.splice(index, 1);

  if (form.value.kinder.length === 0) {
    selected.value = 0;
    form.value.neueKinder = false;
  } else {
    selected.value = getZusammenLebendKindIndex();
  }
};

const setNeueKinder = (value) => {
  form.value.neueKinder = value;
  if (value) {
    addChild();
  } else {
    form.value.kinder = [];
    selected.value = 0;
  }
  clear('neueKinder');
};

const setzusammenLebend = (index, value) => {
  form.value.kinder[index].zusammenLebend = value;
  selected.value = value ? index : getZusammenLebendKindIndex();
  clear('kinder', index);
};

const hasKindErrors = (index) => formErrors.value.kinder
    && formErrors.value.kinder[index]
    && Object.keys(formErrors.value.kinder[index]).length > 0;
</script>
