<template>
  <div class="flex flex-col md:flex-row justify-between gap-2">
    <div
      v-for="{ name, minLabel, maxLabel, max, format, text } in sliders"
      :key="name"
      class="flex flex-col"
    >
      <span class="text-center font-semibold my-10 md:pb-2">
        {{ text }}
      </span>
      <Slider
        :min-label="minLabel"
        :max-label="maxLabel"
        :format="format"
        :max="max"
        :model-value="props[name]"
        @update:model-value="emit(`update:${name}`, $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import Slider from '@/application/components/Slider.vue';

const sliders = [
  {
    name: 'cashreserve',
    text: 'Vorhandene Cashreserve reicht bis zu',
    minLabel: '0 Monate',
    maxLabel: 'über 12 Monate',
    format: { suffix: ' M' },
    max: 12,
  },
  {
    name: 'monatlicherUeberschuss',
    text: 'Monatlicher Überschuss für Anlagen',
    minLabel: '0 €',
    maxLabel: 'über 2.000 €',
    format: { suffix: ' €' },
    max: 2000,
  },
  {
    name: 'maximaleRisikotoleranz',
    text: 'Die maximale Risikotoleranz beträgt',
    minLabel: '0 %',
    maxLabel: '100 %',
    format: { suffix: ' %' },
    max: 100,
  },
] as const;

const props = defineProps({
  cashreserve: {
    type: Number,
    default: 0,
  },
  maximaleRisikotoleranz: {
    type: Number,
    default: 0,
  },
  monatlicherUeberschuss: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits([
  'update:cashreserve',
  'update:monatlicherUeberschuss',
  'update:maximaleRisikotoleranz',
]);
</script>
