import { ContractState } from '@/contracts/types/types';
import { ContractTypeId } from '@/api/contracts/types';

type ContractStateWithoutAktiv = Exclude<ContractState, ContractState.AKTIV>;

export const contractStatus: Record<ContractStateWithoutAktiv, string> = {
  [ContractState.STORNIERT]: 'Storniert',
  [ContractState.BEITRAGSFREI]: 'Beitragsfrei',
  [ContractState.SONSTIGE]: 'Sonstige',
  [ContractState.RUHEND]: 'Ruhend',
};

export const contractType: Record<ContractTypeId.ANTRAG, string> = {
  [ContractTypeId.ANTRAG]: 'Antrag gestellt',
};
