<template>
  <div>
    <DsForm :validation-errors="errors">
      <DsFormGroup
        validation-name="password"
        label="Passwort bestätigen"
        help="Bevor Sie fortfahren müssen Sie ihr Passwort bestätigen."
        class="my-5"
      >
        <DsInput
          v-model="form.password"
          size="lg"
          data-testid="confirm-input"
          type="password"
        />
      </DsFormGroup>

      <div class="text-right">
        <DsButton
          size="lg"
          data-testid="confirm-button"
          :handler="submit"
        >
          Bestätigen
        </DsButton>
      </div>
    </DsForm>
  </div>
</template>

<script setup lang="ts">
import { DsButton, DsForm, DsFormGroup, DsInput } from '@demvsystems/design-components';
import { useForm } from "@/api/lib/integration";
import { confirmPassword } from "@/api/auth/authApi";

const emit = defineEmits(['confirmed']);

const { form, errors, submit } = useForm(
  confirmPassword,
  { password: '' },
  () => emit('confirmed'),
);

defineExpose({ form, errors });
</script>
