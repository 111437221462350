import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 54 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M25.76 23.12a4.23 4.23 0 0 0-4.17 3.63h-1.93a4.23 4.23 0 1 0 0 1.2h1.93a4.23 4.23 0 1 0 4.17-4.83m-10.28 7.2a3.03 3.03 0 0 1-3.02-3.03 3.03 3.03 0 0 1 3.02-3.02 3.03 3.03 0 0 1 3.03 3.02 3.07 3.07 0 0 1-3.03 3.02zm10.28 0a3.03 3.03 0 0 1-3.02-3.03 3.03 3.03 0 0 1 3.02-3.02c1.64 0 3.03 1.33 3.03 3.02s-1.4 3.02-3.03 3.02zm-14.81-6.6c0 .37-.24.6-.6.6H5.2c-.36 0-.6-.23-.6-.6s.24-.6.6-.6h5.14c.37 0 .61.24.61.6M53.34.38H1.2C.84.38.6.62.6.98V20.4c0 .36.24.6.6.6h44.63v2.06H30.36c-.36 0-.6.24-.6.6s.24.6.6.6h15.48v5.7c0 .35.24.6.6.6s.61-.25.61-.6V21h6.29c.36 0 .6-.24.6-.6V.99a.6.6 0 0 0-.6-.6zm-.6 19.41H1.81V1.6h50.91v18.2z" }, null, -1)
  ])))
}
export default { render: render }