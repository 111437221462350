<template>
  <div class="container graph">
    <div class="row">
      <div class="col-xs-12 col-sm-8 offset-sm-2">
        <p class="text-center">
          Ihr Monatliches Nettoeinkommen
        </p>
      </div>
    </div>

    <div class="flex flex-row justify-center mb-5">
      <slot />
    </div>

    <Bar
      v-if="showLohnfortzahlung"
      left-label="Lohn&shy;fortzahlung durch Arbeitgeber"
    >
      100 % des Nettoeinkommens
    </Bar>

    <Bar
      :ratio="ratio.krankentagegeldLuecke"
      :is-updating="isUpdating"
      left-label="Krankengeld (GKV)"
      right-label="Ver&shy;sorgungs&shy;lücke"
    >
      {{ format(krankentagegeld) }}

      <template #stack>
        <div>
          {{ format(krankentagegeldLuecke) }}
        </div>
      </template>
    </Bar>

    <Bar
      v-if="showErwerbsminderungsrente"
      :ratio="ratio.erwerbsrenteLuecke"
      :is-updating="isUpdating"
      left-label="Volle Erwerbs&shy;minderungs&shy;rente <sup>*</sup>"
      right-label="Ver&shy;sorgungs&shy;lücke"
    >
      {{ format(erwerbsminderungsrenteVoll) }}

      <template #stack>
        <div>
          {{ format(erwerbsminderungsrenteVollLuecke) }}
        </div>
      </template>
    </Bar>

    <Bar
      v-if="showErwerbsminderungsrente"
      :ratio="ratio.erwerbsrenteHalbLuecke"
      :is-updating="isUpdating"
      left-label="Halbe Erwerbs&shy;minderungs&shy;rente <sup>**</sup>"
      right-label="Ver&shy;sorgungs&shy;lücke"
    >
      {{ format(erwerbsminderungsrenteHalb) }}

      <template #stack>
        <div>
          {{ format(erwerbsminderungsrenteHalbLuecke) }}
        </div>
      </template>
    </Bar>

    <Bar
      :ratio="ratio.berufsunfaehigkeitLuecke"
      left-label="Bei Berufsunfähigkeit"
      right-label="Ver&shy;sorgungs&shy;lücke"
    >
      <template #stack>
        <div>
          {{ format(money) }}
        </div>
      </template>
    </Bar>

    <div
      v-if="showErwerbsminderungsrente"
      class="mb-5"
    >
      <small>
        * Volle Erwerbsminderungsrente liegt vor bei unter 3 Stunden pro Tag
        Arbeitsfähigkeit<br>
      </small>
      <small>
        ** Halbe Erwerbsminderungsrente liegt vor bei 3 bis 6 Stunden pro Tag
        Arbeitsfähigkeit
      </small>
    </div>
  </div>
</template>

<script>
import Bar from '@/application/utils/chart/ChartBarHorizontal.vue';
import { format } from '@/application/utils/money';

import { props } from './props';

export default {
  components: {
    Bar,
  },

  props,

  computed: {
    ratio() {
      return {
        krankentagegeldLuecke: (this.krankentagegeldLuecke / this.money) * 100,
        erwerbsrenteLuecke: (this.erwerbsminderungsrenteVollLuecke / this.money) * 100,
        erwerbsrenteHalbLuecke: (this.erwerbsminderungsrenteHalbLuecke / this.money) * 100,
        berufsunfaehigkeitLuecke: 100,
      };
    },
  },

  methods: {
    format,
  },
};
</script>

<style lang="scss" scoped>

  $white: #FFF;
  $black: #000;
  $blue: #4181D2;
  $blue-light: #D8E7F7;

  .column {
    display: flex;
    width: 100%;

    .half,
    .full {
      font-size: 0.875rem;
      margin: 10px 0;
      min-height: 10px;
      text-align: center;
      transition: flex-grow 125ms ease-in-out;
      vertical-align: middle;
    }

    .half {
      background-color: $blue-light;
      border: 2px dashed $blue;
      border-left-width: 0;
      padding: 5px;
    }

    .full {
      background-color: $blue;
      color: $white;
      flex: 1 0 auto;
      padding: 7px 5px;
    }
  }

  .label {
    font-size: 0.875rem;
  }

</style>
