<template>
  <div>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
            <div class="text-center mb-14">
              <h2>Welche Form der Pflegeversicherung passt zu mir?</h2>
              <p>
                Es gibt viele verschiedene Möglichkeiten, um privat vorzusorgen.<br>
                Beantworten Sie die folgenden Fragen und Sie erfahren, welche Produkte
                am besten zu Ihnen passen.
              </p>
            </div>

            <option-group-list
              v-model="selectionYesNo"
              :options="yesNo"
              horizontal-options
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h2 class="mt-14 text-center">
              Auf Basis Ihrer Angaben sind folgende Produkte geeignet und angemessen:
            </h2>
            <div class="card-deck mt-7 text-center">
              <div
                v-for="sparte in sparten"
                :key="sparte.id"
                :class="inArray(sparte.id, result.sparten)
                  ? [
                    'bg-secondary',
                    'text-white',
                    $style.shadow
                  ] : [
                    'text-muted',
                    $style.disabled
                  ]"
                class="card"
              >
                <div class="card-body px-1.5">
                  <div class="card-text font-bold">
                    <font-awesome-icon
                      v-show="inArray(sparte.id, result.sparten)"
                      :icon="['far', 'check-circle']"
                    />
                    <font-awesome-icon
                      v-show="!inArray(sparte.id, result.sparten)"
                      :icon="['far', 'times-circle']"
                      class="text-danger"
                    />
                    <!-- eslint-disable vue/no-v-html -->
                    <span
                      class="ml-1.5"
                      v-html="mapBreakableName(sparte.name)"
                    />
                    <!-- eslint-enable vue/no-v-html -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div
      v-if="canStoreValues"
      class="col-12 text-center py-7 bg-dark text-light print:hidden"
    >
      <p class="lead">
        Wenn Sie fertig sind, können Sie Ihre
      </p>
      <button
        type="button"
        class="btn-light-lg"
        @click="storeValues"
      >
        Eingaben speichern
      </button>

      <div
        v-if="valuesSaved !== null"
        class="mt-5"
      >
        <div v-if="valuesSaved === 'pending'">
          <span class="spinner spinner--white" />
          Wird gespeichert...
        </div>
        <div v-else-if="valuesSaved === true">
          <font-awesome-icon
            :icon="['far', 'check-circle']"
            size="lg"
          />
          Gespeichert!
        </div>
        <div v-else-if="valuesSaved === false">
          <font-awesome-icon
            :icon="['far', 'times-circle']"
            size="lg"
          />
          Fehler beim Speichern
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { storeQuiz } from '@/api/pflegevorsorge/pflegevorsorgeApi';
import { getPflegevorsorgeType } from '@/api/quiz/quizApi';
import OptionGroupList from '@/application/components/Option/OptionGroupList.vue';
import { arrayToObjectWithDefaults } from '@/application/utils/transformers';
import { call } from "@/api/lib/integration";

export default {
  components: {
    OptionGroupList,
    FontAwesomeIcon,
  },

  props: {
    sparten: {
      type: Array,
      required: true,
    },
    yesNo: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      canStoreValues: true,
      valuesSaved: null,
      selectionYesNo: arrayToObjectWithDefaults(this.yesNo),
      result: {
        sparten: [],
      },
    };
  },

  watch: {
    selectionYesNo: {
      deep: true,
      handler() {
        this.fetchEvaluation();
      },
    },
  },

  mounted() {
    this.fetchEvaluation();
  },

  methods: {
    async fetchEvaluation() {
      this.result = await getPflegevorsorgeType({ fragen: this.selectionYesNo });
    },
    inArray(item, array) {
      return array.includes(item);
    },
    mapBreakableName(name) {
      switch (name) {
        case 'Pflegekostenversicherung':
          return 'Pflegekostenn&shy;versicherung';
        case 'Pflegetagegeldversicherung':
          return 'Pflegetagegeld&shy;versicherung';
        case 'Pflegerentenversicherung':
          return 'Pflegerenten&shy;versicherung';
        default:
          return name;
      }
    },
    async storeValues() {
      if (!this.canStoreValues) {
        return;
      }

      this.valuesSaved = 'pending';
      await call(
        storeQuiz({ fragen: this.selectionYesNo }),
        () => { this.valuesSaved = true; },
        () => { this.valuesSaved = false; },
      );
    },
  },
};
</script>

<style lang="scss" module>

.shadow {
  box-shadow: 0 3px 20px -2px rgba(#000, 0.45);
}

.icon {
  height: 50px;
}

.inlineCheck {
  left: 10px;
  position: absolute;
  top: 10px;
}

.disabled {
  opacity: 0.5;

  .inlineCheck {
    color: #c34f4a;
  }
}
</style>
