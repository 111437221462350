import type { SetupContext, VNode } from 'vue';
import { Text } from 'vue';

// This was looked up from composition API type declarations as it is not exported.
// Therefore it MAY be subject to change in the future. We should keep an eye on it.
type Slot = (...arguments_: unknown[]) => VNode[];

export const getSlotFromContext = (context: SetupContext, slotName: string): Slot | undefined => {
  const { slots } = context;
  if (!slots && !(slotName in slots)) {
    return;
  }
   
  return slots[slotName];
};

export const isTextVNode = (vnode: VNode): boolean => vnode.type === Text;
