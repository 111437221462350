export function scrollToId(id: string) {
  const element = document.getElementById(id);

  if (element !== null) {
    const { top } = element.getBoundingClientRect();
    window.scrollTo({ top: top + window.scrollY - 63, behavior: 'smooth' });
  }

  // always return false to prevent default action of anchor tags
  return false;
}
