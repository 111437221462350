<template>
  <div
    v-if="isActive"
    ref="el"
    :aria-hidden="!isActive"
    :class="{active: isActive}"
    role="tabpanel"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { inject, onMounted, onUnmounted, ref } from 'vue';
import { useTabs } from '@/application/composables/useTabs';
import type { Emitter } from 'mitt';
import type { EventType } from 'mitt';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
});

const isActive = ref(false);

const el = ref(null);

const bus = inject<Emitter <Record<EventType, unknown>>>('bus');

const { TAB_LOADED, SELECT_TAB } = useTabs();

bus?.on(SELECT_TAB, activate);

function activate(name) {
  isActive.value = props.name === name;
}

onMounted(() => {
  bus?.emit(TAB_LOADED, props.name);
});

onUnmounted(() => {
  bus?.off(SELECT_TAB, activate);
});
</script>
