<template>
  <div class="row justify-center mt-7">
    <div class="col-sm-12 col-lg-11">
      <div class="row">
        <div class="col-sm-12 col-lg-4">
          <h6 class="text-center lg:text-left">
            Versicherungssume
          </h6>
          <MoneyInput
            v-model="versicherungssumme"
            placeholder="z.B. 100.000€"
            class="w-48"
          />

          <h6 class="pt-7 text-center lg:text-left">
            Progression
          </h6>
          <Slider
            v-model="progressionValue"
            :no-ui-slider-options="progressionOptions"
            :tooltips="false"
            class="mx-3 mt-2.5 mb-7"
            hide-min-max-label
          />
          <h6 class="pt-7 text-center lg:text-left">
            Gliedertaxe
            <popover-icon>
              <div class="text-center">
                <h6><strong>Hinweis!</strong></h6>
                Allen Körperteilen wird bei Funktionsverlust ein prozentualer Wert zugewiesen.
                Je höher diese Gliedertaxe ist, umso höher ist Ihr Leistungsanspruch.
              </div>
            </popover-icon>
          </h6>

          <div class="mx-3 mt-2.5 mb-7">
            <div class="gliedertaxeInput">
              <Slider
                v-model="gliedertaxeIndex"
                :no-ui-slider-options="gliedertaxeOptions"
                :tooltips="false"
                class="mx-5 mt-2.5 mb-7"
                hide-min-max-label
              />
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-7 lg:mt-0">
          <img
            class="mann lg:block hidden"
            alt="Mann"
            src="../../../../../../assets/images/bedarfsinfo/unfallversicherung/mann.jpg"
          >
        </div>
        <div class="col-sm-12 col-lg-4">
          <table class="table">
            <tr
              v-for="(percentage, name) in gliederData"
              :key="name"
            >
              <td>{{ name }}</td>
              <td v-text="getCalculation(name)" />
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, unref } from 'vue';

import MoneyInput from '@/application/components/form-elements/MoneyInput/MoneyInput.vue';
import PopoverIcon from '@/application/components/PopoverIcon.vue';
import Slider from '@/application/components/Slider.vue';
import { format } from '@/application/utils/money';

const GLIEDERTAXE_STANDARD = 'standard';
const GLIEDERTAXE_ERHOEHT = 'erhöht';

const gliedertaxeData = [GLIEDERTAXE_STANDARD, GLIEDERTAXE_ERHOEHT];

const gliedertaxeOptions = {
  snap: true,
  range: {
    min: 0,
    max: 1,
  },
  pips: {
    mode: 'range',
    density: 100,
    format: {
      to: (i) => gliedertaxeData[i],
    },
  },
};

const progressionOptions = {
  snap: true,
  range: {
    'min': 0,
    '25%': 225,
    '50%': 300,
    '75%': 500,
    'max': 1000,
  },
  pips: {
    mode: 'range',
    density: 100,
    format: {
      to: (value) => `${value}%`,
    },
  },
};

const gliederData = {
  'Auge': [0.5, 0.6],
  'Gehör auf einem Ohr': [0.3, 0.4],
  'Geschmackssinn': [0.05, 0.15],
  'Arm': [0.7, 0.8],
  'Hand': [0.55, 0.7],
  'Zeigefinger': [0.1, 0.2],
  'Daumen': [0.2, 0.3],
  'Bein': [0.7, 0.8],
  'Fuß': [0.4, 0.5],
  'Große Zehe': [0.05, 0.15],
};

const progressionValue = ref(0);
const gliedertaxeIndex = ref(0);
const versicherungssumme = ref(100_000);

function getCalculation(key) {
  const percentage = gliederData[key][unref(gliedertaxeIndex)];
  const progression = unref(progressionValue) === 0 ? 100 : unref(progressionValue);
  return format(Math.floor(unref(versicherungssumme) * percentage * (progression / 100)));
}
</script>

<style lang="scss" scoped>

  .mann {
    display: block;
    margin: 0 auto;
    max-width: 120px;
  }

  .labelstyle {
    font-size: 0.85rem !important;
    font-weight: bold;
  }

  .table {
    width: 100%;

    td {
      padding: 0.2rem 0.5rem;
    }

    td:last-child {
      text-align: right;
    }

    tr:nth-child(even) td {
      background-color: #F2F2F5;
    }
  }

  .toggleInfo {
    user-select: none;
  }

  .moneyInput,
  .gliedertaxeInput {
    max-width: 100%;
    min-width: 150px;
    width: 50%;
  }
</style>

<style lang="scss">

.slider-marker {
  top: 0;
  position: absolute;
  background: #d2d5db;
  height: 14px;
  width: 1px;
  transform: translate(-0.5px, 1px);
}

.slider-value {
  top: 0;
  font-size: 0.8rem;
  position: absolute;
  transform: translate(-50%, 20px);
  font-weight: bold;
}
</style>
