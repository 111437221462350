import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 75 75"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"37.5\" cy=\"37.5\" r=\"37.5\" fill=\"#FEFEFE\"></circle><g fill=\"#343A40\" clip-path=\"url(#a)\"><path fill-rule=\"evenodd\" d=\"m56.8 48.59.08-.17 1.02-1.57 4.24 2.83-2.82-10.7 7.4-11.42a1.5 1.5 0 0 0-.48-2.2l-5.12-3.38a1.64 1.64 0 0 0-2.16.47l-2.83 4.33c-.78-3.15-1.65-6.3-2.47-9.45-.31-1.17-.7-1.5-1.89-1.26-10.45 1.58-20.9 3.08-31.34 4.65-.7.07-1.42.16-2.29.31l3.46 2.2-1.02 1.5c-.1.27-.26.51-.47.7a1620 1620 0 0 1-11.78 18.2c-.55.86-.4 1.25.39 1.73 3.54 2.28 7.07 4.56 10.52 6.87 1.9 1.26 6.58-8.02 7.62-9.52 1.05-1.49 2.47-1.96 3.93-1.26l.48-3.07.7-3.85 4.12-.82.4.55.22-4.01h4.48l.92 14.32.16 2.91-3.86 1.5-.24.7-.08 1.27v.7h16.67z\" clip-rule=\"evenodd\"></path><path d=\"M55 56.85H13.34V60H55z\"></path><path fill-rule=\"evenodd\" d=\"m29.44 54 .45-1.44 1.8.15 2.42.14.17-1.86 1.03-8.03 1.8 2.74 1.66 2.56.01-2.88.4-14.18-1.69 11.02-1.87-3.76-1.6-3.26-.95 3.66-2.36 8.93-2.13-.9-1.89-.8-1.31 2.4-2.8 4.9z\" clip-rule=\"evenodd\"></path></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M8 16h59v44H8z\"></path></clipPath></defs>", 3)
  ])))
}
export default { render: render }