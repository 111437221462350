import { defineStore } from 'pinia';
import { call } from '@/api/lib/integration';
import { getActionById, getActions } from '@/api/actions/actionsApi';
import type { Action } from '@/api/actions/types';
import { FetchError } from '@/api/lib/errors';
import { captureException } from '@sentry/vue';

export type ActionStore = {
  actions: Action[],
  loading: boolean,
  fetchError: FetchError | null,
  updateError: FetchError | null,
}

export const useActionStore = defineStore('action', {

  state: (): ActionStore => ({
    actions: [],
    loading: false,
    fetchError: null,
    updateError: null,
  }),

  getters: {
    getActionById: (state) => (id: number) => state.actions.find(action => action.id === id),
  },

  actions: {
    async fetchActions() {
      this.loading = true;
      this.fetchError = null;
      await call(
        getActions(),
        (actions) => {
          this.actions = actions
        },
        (error) => {
          if (error instanceof FetchError) {
            this.fetchError = error;
          }
          captureException(error);
        },
      );
      this.loading = false;
    },

    async fetchActionById(id: number) {
      this.loading = true;
      this.fetchError = null;
      await call(
        getActionById({ id }),
        (action) => {
          const existingAction = this.actions.find(a => a.id === id);
          if (!existingAction) {
            this.actions = [...this.actions, action]
          }
        },
        (error) => {
          if (error instanceof FetchError) {
            this.fetchError = error;
          }
          captureException(error);
        },
      );
      this.loading = false;
    },
  },
});
