import type { DemvWerteResponse, Gesellschaft, Sparte } from './types';
import { useGet } from "@/api/lib/integration";
import type { MaklerInfo } from "@/checkup/types/types";
import type { MaklerOrAgencyIdentifier } from "@/api/zugang/types";
import type { BaseDataStoreState } from "@/user/store/baseDataStore";

sessionStorage.removeItem('demv-gesellschaften');
sessionStorage.removeItem('demv-sparten');


export const getBaseData = useGet<void, BaseDataStoreState>('/api/base-data');
export const getMaklerInfo = useGet<{ maklerOrAgency: MaklerOrAgencyIdentifier, uid: string }, MaklerInfo>(`/api/makler-info/{maklerOrAgency}/{uid}`);
export const getGesellschaften = useGet<void, Gesellschaft[]>('/api/gesellschaft');
export const getSparten = useGet<void, Sparte[]>('/api/sparte');
export const getDemvWerte = useGet<void, DemvWerteResponse>('/api/demv-werte')
