<template>
  <SpartenWrapper
    :text="subtitle"
    image="images/bedarfsinfo/ambulante-krankenzusatzversicherung/arm"
    :versorgungen="data.bedarfsinfo.versorgungen"
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    versorgungen-headline="Wie bin ich aktuell bei einer Ambulanten Krankenzusatzversicherung geschützt?"
    versorgungen-description="Ambulante Krankenzusatzversicherung"
  >
    <section class="section">
      <div class="container">
        <div class="row text-center">
          <div class="col-12">
            <h3 class="mb-7">
              Erfolgt über die gesetzliche Krankenkasse die Erstattung aller
              Behandlungskosten?
            </h3>
            <p>
              Nein, etliche Behandlungen sind über die gesetzliche Krankenkasse <strong>nicht oder nur zum Teil
                erstattungsfähig</strong>.
            </p>
            <p>Dazu gehören beispielsweise:</p>
          </div>
        </div>

        <div class="row justify-center">
          <div class="col-12 col-md-9">
            <div class="row justify-center mt-5 mb-7">
              <div
                v-for="{ title, icon } in nonRefundable"
                :key="title"
                class="flex flex-col items-center text-center w-52 m-4"
              >
                <SvgVue
                  :icon="`bedarfsinfo/ambulante-krankenzusatzversicherung/${icon}`"
                  class="fluid-img mb-5"
                  style="max-width: 90px"
                />
                <p>
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <strong v-html="title" />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row text-center">
          <div class="col-12">
            <h3 class="mb-7">
              Wie bekomme ich den Status eines Privatpatienten?
            </h3>
          </div>
        </div>

        <div class="row border-b mt-2.5 pb-7 mb-7">
          <div class="col-12 col-md-6">
            <h6><strong>Ambulante Ergänzungsversicherung</strong></h6>
            <p>
              Teilweise führen Ärzte Behandlungen durch, die von der gesetzlichen Krankenkasse nicht getragen
              werden. Diese Kosten werden ganz oder teilweise von der Ergänzungsversicherung übernommen.
            </p>
            <p class="mb-0">
              Der Arzt rechnet die erstattungsfähigen Leistungen weiterhin mit der gesetzlichen Krankenkasse
              ab.
            </p>
          </div>
          <div class="col-12 flex items-center md:hidden justify-center my-7">
            <i
              class="fas fa-arrow-down"
              data-fa-transform="grow-14"
              style="color: #2360B0;"
            />
          </div>
          <div class="col-12 col-md-6 flex">
            <div class="media flex items-center flex-grow">
              <div class="media-left mr-7 hidden md:block">
                <i
                  class="fas fa-arrow-right fa-2x"
                  style="color: #2360B0;"
                />
              </div>
              <div class="media-body text-secondary h3 text-2xl mb-0">
                Sie behalten den Status eines <strong>gesetzlich Versicherten</strong>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-7">
          <div class="col-12 col-md-6 order-1 md:order-3">
            <h6><strong>Ambulante Zusatzversicherung</strong></h6>
            <p>
              Der Arzt stellt Ihnen für alle Behandlungen eine Rechnung. Diese leiten Sie an Ihre gesetzliche
              Krankenkasse weiter und bitten um anteilige Erstattung. Über die Zusatzversicherung werden im
              Rahmen des Versicherungsschutzes die verbleibenden Kosten erstattet.
            </p>
            <p class="mb-0">
              Der Arzt rechnet alle Behandlungen nach der Gebührenordnung für Privatpatienten ab.
            </p>
          </div>
          <div class="col-12 flex items-center md:hidden justify-center my-7 order-2">
            <i
              class="fas fa-arrow-down"
              data-fa-transform="grow-14"
              style="color: #2360B0;"
            />
          </div>
          <div class="col-12 col-md-6 flex order-3 md:order-1">
            <div class="media flex items-center md:mr-5 flex-grow">
              <div class="media-body text-secondary h3 text-2xl mb-0 md:text-right">
                Sie haben beim Arzt den Status eines <strong>Privatpatienten</strong>
              </div>
              <div class="media-right ml-7 hidden md:block">
                <i
                  class="fas fa-arrow-left fa-2x"
                  style="color: #2360B0;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Tabs
      title="Was benötige ich wirklich?"
      has-dark-bg-tabs
      has-separated-tabs
      initial-tab="Status Privatpatient"
    >
      <Tab name="Status Privatpatient">
        <div class="row text-center justify-center mb-7">
          <div class="col-12 col-md-6">
            <h3 class="mb-5">
              Benötige ich den Status eines Privatpatienten?
            </h3>
            <p>
              Der Versicherungsstatus entscheidet in Deutschland über den Umfang der Behandlung und die
              Wartezeit bis zur Terminvergabe.
            </p>
          </div>
        </div>

        <Ambulant />
      </Tab>
      <Tab name="Ambulante Ergänzungsversicherung">
        <div class="row text-center justify-center mb-7">
          <div class="col-12 col-md-10">
            <h3 class="mb-5">
              Oder reicht mir eine ambulante Ergänzungsversicherung?
            </h3>
            <p>
              Die ambulante Ergänzungsversicherung bietet Ihnen zwar nicht den Status eines
              Privatpatienten. Dafür werden auch Leistungslücken der gesetzlichen Krankenkassen im Bereich
              Zahnersatz oder bei einer Krankenhausbehandlung erstattet. Je nach Tarif sind folgende
              Bereiche erstattungsfähig:
            </p>
          </div>
        </div>

        <div class="row">
          <div
            v-for="(icon, i) in icons"
            :key="icon"
            :class="[`order-${i * 2}`, `order-lg-${i}`]"
            class="col-12 col-lg-4 text-center flex items-end justify-center"
          >
            <SvgVue
              :icon="icon"
              class="fluid-img w-full"
              style="max-width: 200px"
            />
          </div>

          <div
            v-for="({ title, items }, i) in treatments"
            :key="title"
            class="col-12 col-lg-4"
            :class="[`order-${i * 2 + 1}`, `order-lg-${i + 3}`]"
          >
            <div
              class="row justify-center"
              :class="{ 'mb-5 lg:mb-0': i < treatments.length -1 }"
            >
              <div class="col-12 col-md-8 col-lg-12">
                <h4
                  class="my-5"
                  v-text="title"
                />
                <BulletPoint
                  v-for="item in items"
                  :key="item"
                  :item="{ text: item }"
                />
              </div>
            </div>
          </div>
        </div>
      </Tab>
    </Tabs>

    <section class="section">
      <div class="container">
        <div class="row ">
          <div class="col-12 col-lg">
            <h4 class="mb-5">
              Kann ich eine Versicherung abschließen, obwohl bereits eine Behandlung angeraten
              wurde oder ich Vorerkrankungen habe?
            </h4>
            <p>
              Um den Beitrag für die Versichertengemeinschaft stabil zu halten, begrenzen die Versicherungen
              die Leistungen in der Anfangszeit. So können Leistungen erstmals nach einer Wartezeit von
              üblicherweise drei Monaten für ambulante Behandlungen und acht Monaten für ambulante
              Entbindungen oder Psychotherapie in Anspruch genommen werden.
            </p>
            <p class="mb-0">
              Im Antrag müssen Sie zudem über Ihren Gesundheitszustand Auskunft geben. Kunden mit laufender
              Behandlung oder schlechter Prognose werden durch die Versicherer meist abgelehnt.
            </p>
          </div>
          <div class="col-1 hidden lg:block" />
          <div class="col-12 col-lg mt-7 lg:mt-0">
            <h4 class="mb-5">
              Wie entwickelt sich mein Beitrag?
            </h4>
            <div
              style="max-width: 484px;"
              class="mx-auto"
            >
              <div class="svg-abs svg-abs--tarifverlauf">
                <SvgVue icon="bedarfsinfo/beitrag.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import BulletPoint from '@/bedarf/components/BulletPoint.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import Ambulant from '@/bedarf/components/gesundheitsvorsorge/ambulant/Ambulant.vue';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';
import Tab from '@/application/components/Tab.vue';
import Tabs from '@/application/components/Tabs.vue';
import SvgVue from '@/application/components/SvgVue';

interface Props {
  data: Bedarfsinfo
}

defineProps<Props>();

const subtitle = `
  In zahlreichen Gesundheitsreformen wurden die Leistungen der gesetzlichen Krankenkassen immer weiter
  reduziert. Ob Sie die Zuzahlungen selber tragen sollten oder ob sich eine Versicherung lohnt,
  erfahren Sie jetzt.`;

const icons = [
  'bedarfsinfo/ambulante-krankenzusatzversicherung/hausarzt.svg',
  'bedarfsinfo/krankenhauszusatzversicherung/kkh-specific.svg',
  'bedarfsinfo/ambulante-krankenzusatzversicherung/zahnarzt.svg',
];

const nonRefundable = [
  { title: 'Brille und Kontaktlinsen', icon: 'brille.svg' },
  { title: 'Heilpraktiker&shy;leistungen', icon: 'hand.svg' },
  { title: 'Naturheil&shy;verfahren', icon: 'blatt.svg' },
  { title: 'Impfungen', icon: 'spritze.svg' },
  { title: 'Vorsorge&shy;untersuchungen', icon: 'herz.svg' },
  { title: 'Arznei- und Verbandmittel, Fahrtkosten und Hilfsmittel (Zuzahlungen)', icon: 'meds.svg' },
];

const treatments = [
  {
    title: 'Ambulante Behandlungen',
    items: [
      'Brille und Kontaktlinsen',
      'Vorsorgeuntersuchungen und Impfungen',
      'Heilpraktikerleistungen und Naturheilverfahren',
      'Arznei- und Verbandmittel, Fahrtkosten und Hilfsmittel (Zuzahlungen)',
    ],
  },
  {
    title: 'Krankenhausbehandlung',
    items: [
      'Erstattung der Zuzahlung im Krankenhaus',
      'Freie Krankenhauswahl',
      'Einbettzimmer',
    ],
  },
  {
    title: 'Zahnbehandlung',
    items: [
      'Zahnersatz',
      'Professionelle Zahnreinigung',
    ],
  },
];
</script>
