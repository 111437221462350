import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 39 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M32.1 2.35C28.32-.31 23.51-.15 19.22.76a28.8 28.8 0 0 0-9.27 3.65l.13-.07a1 1 0 0 0-.65-.24h-.07c-4 .4-6.24 3.74-7.24 7.35a16.44 16.44 0 0 0 2.82 14.2l-.03-.04a16.44 16.44 0 0 0 10.92 6.11h.08c5 .69 10.06-1.82 14-4.8s7.5-6.72 7.59-11.81c-.15-2.7-.72-5.22-1.65-7.55l.06.16a11.24 11.24 0 0 0-3.79-5.35zm-2 21.78c-6.2 5.52-14.79 8.07-21.7 2.31a13.8 13.8 0 0 1-4.8-10.79v.02-.39c0-2.52.72-4.88 1.98-6.86l-.03.05a1 1 0 0 0 1.67.68C12.34 3.9 21.76.45 28.88 2.87c4.21 1.4 5.25 5.84 6.12 9.66 1.15 5-1.31 8.4-4.89 11.61z" }, null, -1),
    _createElementVNode("path", { d: "M17.89 14.64a59.5 59.5 0 0 0-.2 11.31l-.01-.2c.06.83 2 .79 1.94 0a61.6 61.6 0 0 1 .25-11.03l-.02.28c.1-.83-1.86-1.16-2-.35zm1.53-7.95a7.9 7.9 0 0 0-.76 3.77v-.02c0 .32 1.62 1.36 1.6 1v-.24c0-1.22.27-2.36.76-3.39l-.02.05c.12-.24-1.48-1.41-1.6-1.18z" }, null, -1)
  ])))
}
export default { render: render }