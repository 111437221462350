<template>
  <div>
    <div class="flex flex-row items-center gap-1">
      <DsButton
        icon="minus"
        variant="clear"
        :disabled="count <= min"
        data-testid="decrement"
        @click="decrement"
      />
      <DsInput
        :id="name"
        ref="dsInput"
        v-model="count"
        :size="size"
        :align="align"
        immediate
        class="w-16"
      />
      <DsButton
        icon="plus"
        variant="clear"
        :disabled="count >= max"
        data-testid="increment"
        @click="increment"
      />
    </div>
    <DsFieldError
      v-if="error.length > 0"
      class="mt-2"
    >
      {{ error }}
    </DsFieldError>
  </div>
</template>

<script setup lang="ts">
import type { InputAlignment, InputSize } from '@demvsystems/design-components';
import { DsButton, DsFieldError, DsInput } from '@demvsystems/design-components';
import AutoNumeric from 'autonumeric';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';

interface Props {
  value: number | null;
  name: string;
  allowNull?: boolean;
  min: number;
  max: number;
  anOptions?: AutoNumeric.Options;
  size?: InputSize;
  align?: InputAlignment;
  error?: unknown[]
}

const props = withDefaults(defineProps<Props>(), {
  anOptions: undefined,
  size: 'base',
  align: 'center',
  error: () => [],
  value: null,
});

const emit = defineEmits(['input']);

const dsInput = ref<typeof DsInput>();

let autoNumeric: AutoNumeric | null = null;

onMounted(() => {
  if (!dsInput.value) {
    throw new Error('Input ref is undefined');
  }
  autoNumeric = new AutoNumeric(dsInput.value.input, props.value, {
    minimumValue: String(props.min),
    maximumValue: String(props.max),
    decimalPlaces: 0,
    emptyInputBehavior: props.allowNull ? 'null' : 'press',
    unformatOnHover: false,
    ...props.anOptions,
  });
});

const count = computed<number>({
  get: () => props.value ?? 0,
  set: (value: number | null) => (value !== null ? emit('input', Number(value)) : null),
});

watch(() => props.value, (nextValue) => {
  if (autoNumeric) {
    autoNumeric.set(nextValue);
  }
});

onUnmounted(() => {
  autoNumeric?.remove();
  autoNumeric = null;
});

const increment = () => {
  if (count.value < props.max) {
    count.value += 1;
  }
};

const decrement = () => {
  if (count.value > 0) {
    count.value -= 1;
  }
};
</script>
