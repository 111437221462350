<template>
  <div
    ref="wrapper"
    :class="[$style.wrapper, {[$style.over50]: percentage > 50}]"
  >
    <div :class="$style.slice">
      <div
        ref="bar"
        :class="$style.bar"
      />
      <div :class="$style.fill" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      default: 0,
    },
  },

  watch: {
    percentage() {
      this.setPercentage();
    },
  },

  mounted() {
    this.setPercentage();
  },

  methods: {
    setPercentage() {
      const degree = Math.round((360 / 100) * this.percentage);
      this.$refs.bar.style.transform = `rotate(${degree}deg)`;
    },
  },
};
</script>

<style lang="scss" module>

//gauge from http://firchow.net/css3-prozentanzeige-kreis/
$circle-width: 0.15em;

%rect-auto {
  clip: rect(auto, auto, auto, auto);
}

%pie {
  border: $circle-width solid #636873;
  border-radius: 50%;
  clip: rect(0, 0.5em, 1em, 0);
  height: 1 - (2 * $circle-width);
  position: absolute;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in;
  width: 1 - (2 * $circle-width);
}

%pie-fill {
  transform: rotate(180deg);
}

.wrapper {
  background-color: #B4B8C0;
  border-radius: 50%;
  font-size: 100px;
  height: 1em;
  position: relative;
  width: 1em;

  *,
  *::before,
  *::after {
    box-sizing: content-box;
  }

  &::after {
    background-color: #FFF;
    border-radius: 50%;
    content: '';
    display: block;
    height: 1 - (2 * $circle-width);
    left: $circle-width;
    position: absolute;
    top: $circle-width;
    width: 1 - (2 * $circle-width);
  }

  .slice {
    clip: rect(0, 1em, 1em, 0.5em);
    height: 1em;
    position: absolute;
    width: 1em;
  }

  .bar {
    @extend %pie;
  }

  &.over50 {
    .slice {
      @extend %rect-auto;
    }

    &.bar::after {
      @extend %pie-fill;
    }

    .fill {
      @extend %pie;
      @extend %pie-fill;
    }
  }
}
</style>
