<template>
  <div class="flex flex-col bg-gray-50 min-h-full fill">
    <div class="container">
      <div class="flex flex-col items-center mt-14 text-center">
        <h1>Ihr Beratungsverlauf</h1>
        <p>im Folgenden finden Sie die Historie zum Verlauf der Beratung.</p>
      </div>
    </div>

    <section class="section">
      <div class="container">
        <div class="flex flex-row justify-center">
          <div
            v-if="verlauf.length <= 0"
            class="py-14 text-center lead text-muted"
          >
            Es liegen zur Zeit keine Beratungen von Ihnen vor.
          </div>
          <div
            v-else
            class="w-full lg:w-1/2"
          >
            <div class="c-timeline">
              <div
                v-for="link in verlauf"
                :key="link.link"
                class="c-timeline__entry"
              >
                <div class="c-timeline__time">
                  {{ link.date }}
                </div>
                <div class="c-timeline__description">
                  <p class="text-sm">
                    Sie haben einen Link mit Informationen zu den
                    folgenden Themen erhalten:
                  </p>
                  <div class="mb-2.5">
                    <div
                      v-for="topic in link.topics"
                      :key="topic.name"
                      class="flex flex-row gap-1 items-center mt-1.5 mb-1.5"
                    >
                      <DsIcon
                        name="arrow-right"
                        size="xs"
                        class="h-2.5 w-2.5 rounded-full p-1 text-blue-700 bg-blue-100 mb-0.5 mr-3"
                      />
                      <span>{{ topic.name }}</span>
                      <DsIcon
                        v-if="topic.files.length + topic.links.length > 0"
                        name="paperclip"
                        variant="regular"
                        size="sm"
                        title="Enthält Anhänge"
                        class="text-gray-500 ml-1"
                      />
                    </div>
                  </div>

                  <div class="text-right">
                    <RouterLink
                      v-slot="{ navigate }"
                      :to="link.link"
                      custom
                    >
                      <DsButton :handler="navigate">
                        Anzeigen
                      </DsButton>
                    </RouterLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">

import { storeToRefs } from 'pinia';

import { useBedarfStore } from '@/bedarf/store/bedarfStore';
import { DsButton, DsIcon } from '@demvsystems/design-components';

const { fetchBeratungsdaten } = useBedarfStore();
const { verlauf } = storeToRefs(useBedarfStore());

if (verlauf.value.length <= 0) {
  fetchBeratungsdaten(null);
}
</script>
