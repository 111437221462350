<template>
  <section>
    <h2 class="mb-7 checkup-h2 text-center">
      Ich besitze folgende Fahrzeuge
    </h2>

    <div class="container max-w-2xl flex flex-row justify-center gap-2 md:gap-5 flex-wrap">
      <FahrzeugInput
        v-for="fahrzeug in fahrzeuge"
        :key="fahrzeug.key"
        :value="form[fahrzeug.key]"
        :error="formErrors[fahrzeug.key]"
        :fahrzeug-id="Number(fahrzeug.id)"
        :label="fahrzeug.label"
        :name="`fahrzeug-${fahrzeug.id}-left-column`"
        @input="set(fahrzeug.key, $event)"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import FahrzeugInput from '@/checkup/components/steps/FahrzeugInput.vue';
import { Fahrzeug } from '@/checkup/utils/demvWerte';
import { demvWerte, useStep } from '@/checkup/state';
import { Step } from '@/checkup/utils/steps';

const { form, formErrors, set } = useStep(Step.FAHRZEUGE);

const fahrzeuge = Object
  .entries(demvWerte.fahrzeugTypen)
  .filter(([key]) => (Number.parseInt(key, 10) !== Fahrzeug.SCHLEPPER))
  .map(([value, desc], index) => ({ id: value, key: Object.keys(form.value)[index], label: desc }));
</script>
