import { debounce } from 'lodash-es';
import type { Ref } from 'vue';
import { ref, watch } from 'vue';

import { getGesetzlicheRente, getMehrfachSparrate, getPensions } from '@/api/rechner/rechnerApi';
import type { Pension, Rate } from '@/api/rechner/types';
import type { Loadable } from '@/application/composables/types';
import { useRequest } from "@/api/lib/integration";

export const usePension = (
  pension: Ref<Pension>,
  dienstzeitbeginn: Ref<number>,
  besoldungVorPension: Ref<number>,
  pensionseintritt: Ref<number>,
): Loadable => {

  const { call, loading } = useRequest(getPensions, (data) => {
    [pension.value] = data
  })

  watch([dienstzeitbeginn, pensionseintritt, besoldungVorPension], debounce(async () => {

    await call({
      dienstzeitbeginn: dienstzeitbeginn.value,
      pensionseintritte: [pensionseintritt.value],
      besoldungVorPension: besoldungVorPension.value
    })

  }, 500), { immediate: true });

  return { loading };
};

export const useMehrfachSparraten = (
  ansparzins: Ref<number>,
  entnahmezins: Ref<number>,
  gap: Ref<number>,
  eintrittsalter: Ref<number>,
  age: number,
  intervals: number[],
): Loadable & { rates: Ref<Rate[]> } => {

  const rates = ref<Rate[]>([]);
  const { call, loading } = useRequest(getMehrfachSparrate, (data) => {
    rates.value = data
  })

  watch(
    [ansparzins, entnahmezins, gap, eintrittsalter],
    debounce(async () => {
      await call({
        ansparzinssatz: ansparzins.value,
        entnahmezinssatz: entnahmezins.value,
        monatlicheEntnahme: gap.value,
        renteneintrittsalter: eintrittsalter.value,
        alter: age,
        intervalle: intervals
      })
    }, 500),
    { immediate: true },
  );

  return { rates, loading };
};

export const useGesetzlicheRenteRechner = (
  age: number,
  income: Ref<number>,
  inflation: Ref<number>,
  eintrittsalter: Ref<number>,
  rente: Ref<number>,
): Loadable => {
  const { call, loading } = useRequest(getGesetzlicheRente, (data) => {
    rente.value = data
  })

  watch([income, eintrittsalter, inflation], debounce(async () => {

    const gehalt = income.value * 12;

    if (age <= 70) {
      await call({
        alter: age,
        renteneintritt: age > eintrittsalter.value ? age : eintrittsalter.value,
        gehaltssteigerung: inflation.value,
        gehalt: gehalt,
        gehaltIstNetto: true
      })
    }

  }, 500), { immediate: true });

  return { loading };
};
