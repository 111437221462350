import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 51 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M2.76 7.62c1.7 2.68 6.4 8.79 14.49 10.04l28.54.72 2.26-7.35zm14.28 11.61C5.82 17.51.66 7.11.64 7.08L0 5.82 50.13 9.6 46.93 20z\"></path><path d=\"M12.35 6.78 18.05 1l16.7 1.28.02.71.2 6.18-1.56.05-.18-5.47-14.59-1.12-5.18 5.27z\"></path><path d=\"m27.05 2.7-2.13 6-1.47-.53 2.13-6.01zm10.62 1.77a.78.78 0 0 0-.82.75c-.02.43.3.8.73.83l-.02 1.23-.07 1.85c-.02.44.32.8.75.82s.79-.33.8-.77l.12-3.05 10.34.56c.43.03.8-.3.82-.75s-.3-.8-.74-.83l-10.46-.56a.78.78 0 0 0-1.35-.08zm1.45.08\"></path><path d=\"M47.34 10.08c-.03.44.3.81.73.84s.8-.31.82-.75l.28-4.64c.03-.44-.3-.81-.73-.84s-.8.3-.82.74zM4.32 17.6c1.03 5.66 5.8 5.7 10.9 4.74a86 86 0 0 0 3.9-.86c3.46-.8 6.71-1.56 8.99-1l.35.1.16.31c2.33 4.58 5.55 3.64 10.29 2.27 3-.86 6.54-1.88 10.84-1.95l.02 1.58c-4.08.06-7.53 1.06-10.43 1.9-5.36 1.55-9.04 2.61-11.93-2.73-1.93-.33-4.84.34-7.93 1.06-1.3.3-2.64.62-3.99.87-5.9 1.11-11.43 1-12.7-6l1.52-.29z\"></path><path d=\"M4.32 22.55c1.03 5.66 5.8 5.7 10.9 4.74a86 86 0 0 0 3.9-.86c3.46-.8 6.71-1.56 8.99-1l.35.1.16.31c2.33 4.58 5.55 3.64 10.29 2.27 3-.86 6.54-1.89 10.84-1.96l.02 1.59c-4.08.06-7.53 1.06-10.43 1.9-5.36 1.55-9.04 2.6-11.93-2.74-1.93-.32-4.84.35-7.93 1.07-1.3.3-2.64.62-3.99.87-5.9 1.11-11.43 1-12.7-6l1.52-.29z\"></path>", 5)
  ])))
}
export default { render: render }