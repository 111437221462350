<template>
  <!-- TODO enable hover styles -->
  <div
    id="dropzone"
    class="w-full p-5 rounded bg-light text-center"
    @drop.prevent="(ev) => onInput(ev.dataTransfer.files)"
    @dragover.prevent="() => {}"
  >
    <div class="inline-block space-y-3">
      Datei/-en <span
        v-if="!isMobile"
        class="mr-1.5"
      >hierher ziehen oder</span>
      <label
        for="file-upload-button"
        class="mb-0 pb-0 inline-block"
      >
        <DsButton
          icon="folder-open"
          variant="outline"
          data-testid="auswählen-button"
          @click.prevent="$refs.fileInput.click()"
        >
          auswählen
        </DsButton>
        <input
          id="file-upload-button"
          ref="fileInput"
          type="file"
          :accept="accept"
          class="hidden"
          multiple
          @dragover.prevent="() => {}"
          @dragleave.prevent="() => {}"
          @change="(ev) => onInput(ev.target.files)"
          @drop.prevent="(ev) => onInput(ev.target.files)"
        >
      </label>
    </div>
    <div
      v-if="isMobile"
      class="my-1.5"
    >
      oder
    </div>
    <div
      v-if="isMobile"
      class="inline-block"
    >
      ein Foto
      <label
        for="capture-button"
        class="mb-0 pb-0 inline-block"
      >
        <DsButton
          icon="camera"
          variant="outline"
          data-testid="auswählen-button"
          @click.prevent="$refs.cameraInput.click()"
        >
          aufnehmen
        </DsButton>
        <input
          id="capture-button"
          ref="cameraInput"
          type="file"
          accept="image/*"
          capture="environment"
          class="hidden"
          multiple
          @dragover.prevent="() => {}"
          @dragleave.prevent="() => {}"
          @change="(ev) => onInput((ev.target as HTMLInputElement).files)"
          @drop.prevent="(ev) => onInput((ev.target as HTMLInputElement).files)"
        >
      </label>
    </div>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { DsButton } from '@demvsystems/design-components';
import { ref } from 'vue';
import { useDetectMobile } from "@/application/composables/detectMobile";

defineProps({
  accept: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['input']);

const { isMobile } = useDetectMobile();

const fileInput = ref<HTMLInputElement | null>(null);
const cameraInput = ref<HTMLInputElement | null>(null);

const onInput = (files: FileList|null) => {
  if (files === null) return;

  emit('input', files);
  if (cameraInput.value) cameraInput.value.value = '';
  if (fileInput.value) fileInput.value.value = '';
};
</script>
