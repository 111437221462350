export const props = {
  money: {
    type: Number,
    default: null,
  },
  krankentagegeld: {
    type: Number,
    required: true,
  },
  krankentagegeldLuecke: {
    type: Number,
    required: true,
  },
  erwerbsminderungsrenteVoll: {
    type: Number,
    required: true,
  },
  erwerbsminderungsrenteVollLuecke: {
    type: Number,
    required: true,
  },
  erwerbsminderungsrenteHalb: {
    type: Number,
    required: true,
  },
  erwerbsminderungsrenteHalbLuecke: {
    type: Number,
    required: true,
  },
  isUpdating: {
    type: Boolean,
    default: false,
  },
  showLohnfortzahlung: {
    type: Boolean,
    default: true,
  },
  showErwerbsminderungsrente: {
    type: Boolean,
    default: true,
  },
};
