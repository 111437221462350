import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 55 55"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "27.5",
      cy: "27.5",
      r: "27.5",
      fill: "#4181D2"
    }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      stroke: "#f5f5f5",
      "stroke-miterlimit": "10",
      "stroke-width": "2",
      d: "M11.8 37.3S39 27 44.2 19.2",
      opacity: ".6"
    }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      stroke: "#fff",
      "stroke-miterlimit": "10",
      "stroke-width": "3",
      d: "M43.5 13v24.3H10.2"
    }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      stroke: "#fff",
      "stroke-miterlimit": "10",
      "stroke-width": "2",
      d: "m11.3 36.7 3.4-5.5 3.3.9 3.4-4.5 4.6 2.8 1.5-7.3 3.7 2.1 2.6-6.1 3 4.7 4-6.4 2.4.7"
    }, null, -1)
  ])))
}
export default { render: render }