<template>
  <div class="py-5 flex flex-col flex-grow bg-light">
    <div class="container">
      <DsButton
        variant="clear"
        icon="chevron-left"
        :handler="goBack"
      >
        Zurück
      </DsButton>
    </div>

    <ContractDetails
      v-if="contract && depot === undefined"
      class="py-7"
      :contract="contract"
    />

    <DepotDetails
      v-if="depot"
      class="py-7"
      :depot="depot"
    />
  </div>
</template>

<script setup lang="ts">
import { watch } from 'vue';
import { useRoute } from 'vue-router';

import { DsButton } from '@demvsystems/design-components';
import { useUnreadHintStore } from '@/application/store/unreadHintStore';
import { UnreadHintType } from '@/api/unreadHint/types';
import { useUnreadHint } from '@/application/composables/unreadHint';
import { useGoBack } from "@/application/router/utils/goBack";
import { useContractStore } from "@/contracts/stores/contractStore";
import ContractDetails from "@/contracts/components/ContractDetails.vue";
import DepotDetails from "@/contracts/components/DepotDetails.vue";

const { goBack } = useGoBack()
const { params } = useRoute();
const contractId = Number(params.id)
const { isUnread } = useUnreadHint(UnreadHintType.Contract, contractId);

const contractStore = useContractStore()
const depot = contractStore.findDepot(contractId)
const contract = contractStore.findContract(contractId)

watch(isUnread, (val) => val &&
  setTimeout(async () => {
    await useUnreadHintStore().removeUnreadHints([{ type: UnreadHintType.Contract, object_id: Number(params.id) }], true)
  }, 1000), { immediate: true }
);
</script>
