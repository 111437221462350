import type { AltersvorsorgeType, PflegevorsorgeType } from '@/api/quiz/types';
import type { Altersvorsorge, AltersvorsorgeFragen } from '@/bedarf/components/altersvorsorge/altersvorsorge/types';
import { useGet } from "@/api/lib/integration";

export const getAltersvorsorgeType = useGet<{
  fragen: AltersvorsorgeFragen,
  cashreserve: Altersvorsorge['cashreserve'],
  monatlicherUeberschuss: Altersvorsorge['monatlicherUeberschuss'],
  maximaleRisikotoleranz: Altersvorsorge['maximaleRisikotoleranz'],
}, AltersvorsorgeType>('/api/quiz/altersvorsorgeType');

export const getPflegevorsorgeType = useGet<{ fragen: Record<string, unknown>}, PflegevorsorgeType>('/api/quiz/pflegevorsorge');
