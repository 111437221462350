<template>
  <section
    v-if="slugs.length > 1"
    class="section print:hidden"
  >
    <div class="container">
      <div class="flex flex-row justify-between items-center">
        <RouterLink
          v-slot="{navigate}"
          :to="previousSparte"
          custom
        >
          <DsButton
            :handler="navigate"
            icon="chevron-left"
          >
            Vorheriges Thema
          </DsButton>
        </RouterLink>

        <RouterLink
          v-slot="{navigate}"
          :to="nextSparte"
          custom
        >
          <DsButton
            :handler="navigate"
            icon="chevron-right"
            icon-align="right"
          >
            Nächstes Thema
          </DsButton>
        </RouterLink>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { DsButton } from '@demvsystems/design-components';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { slugify } from '@/application/router/utils/utils';
import { useBedarfStore } from '@/bedarf/store/bedarfStore';

const { themen } = storeToRefs(useBedarfStore());
const route = useRoute();

const sparte = computed(() => slugify((route.name ?? '') as string));
const slugs = computed(() => themen.value.reduce<string[]>(
  (accumulator, group) => [...accumulator, ...group.themen.map((thema) => thema.slug)],
  [],
));
const i = computed(() => slugs.value.indexOf(sparte.value));

const previousSparte = computed(() => {
  const previous = i.value <= 0 ? slugs.value.length - 1 : i.value - 1;
  return `/mein-bedarf/${route.params.bedarfHash}/${slugs.value[previous]}`;
});

const nextSparte = computed(() => {
  const next = i.value >= slugs.value.length - 1 ? 0 : i.value + 1;
  return `/mein-bedarf/${route.params.bedarfHash}/${slugs.value[next]}`;
});

defineExpose({ slugs, i });
</script>
