<script setup lang="ts">
import type { BankAccount } from "@/api/bankAccount/types";
import { Finanzmanager } from '@/application/router/types/types';
import { DsIcon } from '@demvsystems/design-components';
import CardMeta from '@/contracts/components/CardMeta.vue';
import { computed } from "vue";

interface Props {
  account: BankAccount,
}

const props = defineProps<Props>();

const contractsMetaLabel = computed(() => props.account.contractIds.length > 1
  ? `Hinterlegt bei ${props.account.contractIds.length} Verträgen`
  : 'Hinterlegt bei einem Vertrag');
</script>

<template>
  <RouterLink
    v-slot="{ navigate }"
    custom
    :to="{ name: Finanzmanager.BANK_ACCOUNT_DETAILS, params: { accountId: account.id } }"
  >
    <div
      class="relative cursor-pointer flex flex-col border border-gray-200 rounded-lg px-3 py-3 hover:shadow-md"
      @click="navigate"
    >
      <span
        v-if="account.main"
        class="absolute -top-3 -right-2 badge badge-pill badge-secondary"
      >
        Standard-Bankverbindung
      </span>

      <div class="flex flex-col justify-between">
        <span class="font-medium text-base text-gray-900 mb-2">
          {{ account.iban }}
        </span>
        <span class="text-sm text-gray-700">
          {{ account.bankName }}
        </span>
        <span class="text-sm text-gray-700">
          {{ account.owner }}
        </span>
      </div>
      <CardMeta
        v-if="account.contractIds.length > 0"
        class="py-1.5"
      >
        <div
          class="flex flex-row items-center gap-2 ml-auto"
          :title="contractsMetaLabel"
        >
          <span class="text-xs">{{ contractsMetaLabel }}</span>
          <DsIcon
            name="file-contract"
            variant="light"
            size="lg"
          />
        </div>
      </CardMeta>
    </div>
  </RouterLink>
</template>
