<template>
  <div class="mt-2.5">
    <small class="text-muted">
      Erlaubt sind Bilder, Dokumente (pdf, doc/x, xls/x) und
      ZIP-Archive mit einer Größe von maximal {{ prettyPrintSize(MAX_UPLOAD_SIZE) }}
    </small>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { MAX_UPLOAD_SIZE } from '@/documents/composables/documentUpload'
import { prettyPrintSize } from '@/application/utils/prettyPrintSize';

export default defineComponent({
  setup() {
    return {
      MAX_UPLOAD_SIZE,
      prettyPrintSize,
    };
  },
});
</script>
