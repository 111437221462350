import type { PropType } from 'vue';
import { defineComponent, h } from 'vue';

import type { Depot } from '@/api/contracts/types';
import GridTable, { type GridTableItem } from '@/application/components/GridTable.vue';
import ContractStatus from '@/contracts/components/ContractStatus.vue';
import { formatDecimal } from '@/application/utils/money';

export default defineComponent({
  name: 'DepotDetailsTable',
  props: {
    depot: {
      type: Object as PropType<Depot>,
      required: true,
    },
  },
  setup(props) {
    const items: GridTableItem[] = [
      {
        title: 'Vertragsnummer',
        value: h('b', props.depot.id),
      },
      {
        title: 'Status',
        value: h(ContractStatus, { status: props.depot.status }),
      },
      {
        title: 'Monatliche Sparrate',
        value: props.depot.monthly_payment_rate
          ? h('b', formatDecimal(props.depot.monthly_payment_rate))
          : null,
      },
      {
        title: 'Depotwert',
        value: formatDecimal(props.depot.depot_evaluation),
      },
    ];

    return () => h(GridTable, { items, mutedTitle: true, rightAlignedValue: true });
  },
});
