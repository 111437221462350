<template>
  <div class="flex flex-col flex-grow w-full bg-gray-50">
    <DocumentIndex @open-modal="showUploadModal = true" />

    <DocumentUploadModal
      :show="showUploadModal"
      @close="showUploadModal = false"
    />

    <FloatingActionButton
      class="fixed right-4 md:hidden"
      rounded
      @click="showUploadModal = true"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import FloatingActionButton from '@/application/components/FloatingAction/FloatingActionButton.vue';
import DocumentUploadModal from '@/documents/components/DocumentUploadModal.vue';
import DocumentIndex from '@/documents/components/DocumentsView.vue';

const showUploadModal = ref(false);
</script>
