import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 1416 1176"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "742",
      cy: "584.7",
      r: "570.1",
      fill: "#4181D2"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "761.2",
      cy: "455.8",
      r: "131.9",
      style: {"fill":"#fff"},
      transform: "rotate(-45 761.22 455.86)"
    }, null, -1),
    _createElementVNode("path", {
      d: "M761.2 620.6c-136.2 0-246.6 133.5-246.6 298.2 196 4.5 246.5 5.7 493.2 0 0-164.7-110.4-298.2-246.6-298.2",
      style: {"fill":"#fff"}
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      "fill-opacity": ".85",
      d: "M1044.6 375.8c-93.3 1.5-149.9 103.4-102 183.4l82.3 137.3c11.4 19 39 18.5 49.8-.8l77.8-139.9c45.3-81.5-14.6-181.5-107.9-180m2 200.5c-43.6 0-78.9-35.3-78.9-78.9s35.3-78.9 78.9-78.9 78.9 35.3 78.9 78.9-35.3 78.9-78.9 78.9m-500.8-17.2 55.6-100c32.4-58.3-10.4-129.8-77.1-128.7s-107.1 73.9-72.9 131.1l58.8 98.1c8.1 13.6 27.9 13.3 35.6-.5m-76.6-141.9a56.4 56.4 0 1 1 56.4 56.4c-31.1.1-56.4-25.2-56.4-56.4m-41.3 166c-49 .8-78.7 54.3-53.5 96.3l43.2 72.1c6 10 20.5 9.7 26.1-.4l40.8-73.5c23.9-42.8-7.6-95.3-56.6-94.5m1.1 105.2c-22.9 0-41.5-18.6-41.5-41.5s18.6-41.5 41.5-41.5 41.5 18.6 41.5 41.5c-.1 23-18.6 41.5-41.5 41.5"
    }, null, -1)
  ])))
}
export default { render: render }