import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 39 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M17.97 9.63V8.18h-3.49a5.4 5.4 0 0 0-1.07-2.64l2.45-2.42-1.04-1.03-2.43 2.42a5.5 5.5 0 0 0-2.67-1.1V0H8.25v3.42c-.98.14-1.88.54-2.63 1.13L3.15 2.09 2.11 3.12l2.5 2.47a5.4 5.4 0 0 0-1.04 2.6H0v1.44h3.6c.15.94.53 1.8 1.1 2.51L2.11 14.7l1.03 1.02 2.58-2.56c.73.54 1.59.9 2.52 1.04v3.62h1.47v-3.6a5.5 5.5 0 0 0 2.56-1.02l2.54 2.52 1.04-1.02-2.53-2.5a5.4 5.4 0 0 0 1.13-2.57h3.51zM9.02 12.8A4 4 0 0 1 5 8.8a4.02 4.02 0 0 1 8.04.01 4 4 0 0 1-4.02 3.98zm12.3 3.76h-4.05l-2.23 4.1h4.05zm5.35-.97 2.65-4.87-3.71-.01-2.66 4.88zm-.54.97h-3.71l-2.24 4.1h3.72zm-8.71 9.17h3.71l2.24-4.1h-3.72zm-.54.98-2.83 5.2 3.71.01 2.84-5.2zm1.67-5.07h-4.04l-2.24 4.1h4.05zm-6.81 5.07L8.92 31.9l4.03.02 2.84-5.2zm16.93-5.07h-4.2l-2.24 4.1h4.21zM24.51 10.7h-4.04l-2.67 4.89h4.05zm7.46 4.89 2.64-4.85-4.2-.01-2.65 4.86zm-.53.97h-4.21L25 20.66h4.2z" }, null, -1),
    _createElementVNode("path", { d: "M37.84 28.22h-3v-1.56h3a.71.71 0 0 0 0-1.41h-3V23c-2.4 0-4.36 1.6-4.7 3.7H21.7l-2.84 5.21 4.2.02 2.2-4.02h4.87c.3 2.13 2.29 3.8 4.72 3.8v-2.09h2.98c.4 0 .72-.31.72-.7s-.32-.71-.72-.71z" }, null, -1)
  ])))
}
export default { render: render }