<template>
  <SpartenWrapper
    image="images/bedarfsinfo/pflegevorsorge/demv-head-pflegeversicherung"
    :text="subtitle"
    :dokumente="data.bedarfsinfo.dokumente"
    :versorgungen="data.bedarfsinfo.versorgungen"
    :links="data.bedarfsinfo.links"
    versorgungen-headline="Wie bin ich aktuell bei einem Pflegefall abgesichert?"
    versorgungen-description="Pflegeversicherung"
  >
    <section class="section">
      <div class="container">
        <div class="row pb-7">
          <div class="col-12 text-center">
            <h2>Mit welchen Zuzahlungen muss ich im Pflegefall rechnen?</h2>
          </div>
        </div>

        <PfvChart />
      </div>
    </section>

    <Bedarf />

    <Wohnformen />

    <JumboImage image="images/bedarfsinfo/pflegevorsorge/demv_hand_pflegeversicherung" />

    <Anspruch />

    <Quiz
      :sparten="data.sparten"
      :yes-no="data.yesNo"
    />
  </SpartenWrapper>
</template>

<script setup lang="ts">
import JumboImage from '@/bedarf/components/JumboImage.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import Anspruch from '@/bedarf/components/gesundheitsvorsorge/pflegevorsorge/Anspruch.vue';
import Bedarf from '@/bedarf/components/gesundheitsvorsorge/pflegevorsorge/Bedarf.vue';
import PfvChart from '@/bedarf/components/gesundheitsvorsorge/pflegevorsorge/chart/index.vue';
import Quiz from '@/bedarf/components/gesundheitsvorsorge/pflegevorsorge/Quiz.vue';
import Wohnformen from '@/bedarf/components/gesundheitsvorsorge/pflegevorsorge/Wohnformen.vue';
import type { PflegevorsorgeInfo } from '@/bedarf/types/bedarfsinfo';

interface Props {
  data: PflegevorsorgeInfo
}

defineProps<Props>();

const subtitle = `
  Eine immer bessere medizinische Versorgung
  ermöglicht eine immer höhere Lebenserwartung. Leider sind mit steigendem Alter immer
  mehr Menschen auf Pflege angewiesen. Um
  bestmögliche finanzielle Vorkehrungen für Sie
  und Ihre Angehörigen zu treffen, finden Sie
  nachfolgend wichtige Informationen.`;
</script>
