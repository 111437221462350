import { getPollingState } from "@/api/polling/pollingApi";
import type { Ref } from "vue";
import { ref, onUnmounted } from "vue";
import type { PollingTopicIdentifier, PollingTopicState } from "@/api/polling/types";
import { useUserStore } from "@/user/store/userStore";

const pollState: Ref<Record<PollingTopicIdentifier, PollingTopicState<unknown>>> = ref({
    'handlung': {
        value: undefined,
        meta: undefined
    },
    'userNotification': {
        value: undefined,
        meta: undefined
    },
    'contract': {
        value: undefined,
        meta: undefined
    },
})

let listenerCount = 0;

// Global listeners object, shared across components
const listeners: Record<PollingTopicIdentifier, Set<(newValue: PollingTopicState<unknown>) => void>> = {
    'handlung': new Set(),
    'userNotification': new Set(),
    'contract': new Set(),
};

let pollingInterval: ReturnType<typeof setTimeout> | undefined

async function poll() {
    const userStore = useUserStore();

    if (!userStore.isAuthenticated) {
        return
    }

    if (userStore.isAppLocked) {
      return
    }

    if (listenerCount <= 0) {
      return
    }

    const polled = await getPollingState();
    if (polled instanceof Error) {
        return;
    }

    for (const key of Object.keys(polled)) {
        const oldValue = pollState.value[key].value;
        const newValue = polled[key].value;

        pollState.value[key].value = newValue;
        pollState.value[key].meta = polled[key].meta;

        if (oldValue !== newValue && listeners[key]) {
            listeners[key].forEach(callback => callback(pollState.value[key]));
        }
    }
}

export function usePolling(interval: number = 10000) {

    if (!pollingInterval) {
        pollingInterval = setInterval(poll, interval);
    }

    function listenFor(key: PollingTopicIdentifier, callback: (newValue: PollingTopicState<unknown>) => void) {
        if (listeners[key]) {
            listeners[key].add(callback);
            listenerCount++;
        }

        onUnmounted(() => {
            if (listeners[key]) {
                listeners[key].delete(callback);
                listenerCount--;
            }
        });
    }

    return {
        pollState,
        listenFor
    };
}
