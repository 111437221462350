<template>
  <!-- eslint-disable max-len -->
  <SpartenWrapper
    image="images/bedarfsinfo/diensthaftpflicht/blonde-female-executive-posing-with-smile-and-arms-crossed-during-brainstorm-with-managers"
    text="Fügen Sie bei der Ausübung Ihres Berufes
          einem Dritten einen Schaden zu, so sind Sie
          verpflichtet, diesen zu ersetzen – unabhängig
          von der Schadenhöhe. Eine Betriebshaftpflicht
          nimmt Ihnen das finanzielle Risiko und wehrt
          unberechtigte Ansprüche ab."
    :versorgungen="data.bedarfsinfo.versorgungen"
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    versorgungen-headline="Wie bin ich aktuell bei einem Diensthaftpflichtschaden geschützt?"
    versorgungen-description="Diensthaftpflicht"
  >
    <!-- eslint-enable max-len -->
    <section class="section">
      <DamageExamplesSection
        class="pb-10"
        :data="damageExamples"
        headline="Wann leistet die Diensthaftpflicht?"
        subline="Die Diensthaftpflicht übernimmt die finanziellen Kosten durch unbeabsichtigte Fahrlässigkeit,
               die Sie Ihrem Dienstherrn oder einem Dritten im Rahmen Ihrer Amtstätigkeit zugefügt haben."
        second-headline="Schadenbeispiele - welche Kosten werden erstattet?"
        is-image
      />
    </section>

    <section class="section pt-0">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 mt-2.5 md:mt-0">
            <NiceToKnow
              :bullets="[{
                title: 'Info:',
                text: 'Die Diensthaftpflicht kann in der Regel gegen einen Zusatzbeitrag in der Privathaftpflicht ' +
                  'versichert werden.',
              }]"
            />
          </div>
          <div class="col-12 col-md-6 mt-2.5 md:mt-0">
            <NiceToKnow
              :bullets="[{
                title: 'Info:',
                text: 'Zusätzlich weist die Versicherung unberechtigte Forderungen ab und setzt dieses ' +
                  'gerichtlich durch (sogenannter passiver Rechtsschutz).'
              }]"
            />
          </div>
        </div>
      </div>
    </section>

    <JumboImage
      image="images/bedarfsinfo/diensthaftpflicht/cropped-anonymous-officer-typing-on-white-keyboard"
    />

    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
            <div class="text-center pb-7">
              <h2>Welche zusätzlichen Leistungen bieten Top-Tarife?</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <BulletPointList
              :items="[
                {'title': 'Vermögensschadenhaftpflicht', 'text': 'Versichert werden Schäden, denen kein Sach- ' +
                  'oder Personenschaden vorausging. Besonders für Entscheidungsträger wichtig, die beraten, ' +
                  'ausfertigen und Gelder einziehen. Kassenfehlbeträge können versichert werden.'},
                {'title': 'Abhandenkommen von Ausrüstungsgegenständen', 'text': 'Beispielsweise wird der Verlust ' +
                  'mobiler Erfassungsgeräte oder Funkgeräte erstattet.'},
                {'title': 'Waffenbesitz zu dienstlichen Zwecken', 'text': 'Versichert sind Schäden durch den Besitz ' +
                  'und des Gebrauchs von Waffen und Munition zu dienstlichen Zwecken.'},
              ]"
            />
          </div>
          <div class="col-12 col-md-6">
            <BulletPointList
              :items="[
                {'title': 'Verlust von Dienstschlüsseln', 'text': 'Versichert ist der Verlust der überlassenen ' +
                  'Dienstschlüssel, oftmals auch für Schließanlagen, für Transponder und Codekarten.'},
                {'title': 'Dienstfahrzeug-Regress', 'text': 'Bei Dienstfahrten haftet der Dienstherr ' +
                  'bei einem Unfall gegenüber eines Dritten. Nimmt der Dienstherr den Beamten in Regress, ' +
                  'ist dies abgesichert. Zudem gilt der Regress bei Schäden am eigenen Fahrzeug als ' +
                  'mitversichert.'},
                {'title': 'Nachhaftung', 'text': 'Es gilt die Nachhaftung nach Ausscheiden aus dem Dienst für ' +
                  'Schäden aus der bisherigen dienstlichen Tätigkeit als versichert.'},
              ]"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
            <div class="text-center pb-7">
              <h2>Wann habe ich keinen Versicherungsschutz?</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <BulletPointList
              :items="[
                {'text': 'Lehrtätigkeiten im Ausland, die dauerhaft ausgeübt werden.'},
                {'text': 'Bei vorsätzlich herbeigeführten Schäden.'},
                {'text': 'Bei Schäden an versicherungspflichtigen Fahrzeugen.'},
              ]"
            />
          </div>
          <div class="col-12 col-md-6">
            <BulletPointList
              :items="[
                {'text': 'Bußgelder im Straßenverkehr und ähnliche Dinge.'},
                {'text': 'Bei Verhängung von Geldstrafen und Bußgeldern.'},
              ]"
            />
          </div>
        </div>

        <div class="row justify-center mt-14">
          <div class="col-md-10 col-lg-6">
            <NiceToKnow
              :bullets="[{
                title: 'Info',
                text: 'Diese Aufzählung beinhaltet wesentliche Leistungsausschlüsse und ist nicht abschließend. ' +
                  'Sofern einzelne Leistungspunkte für Sie wichtig sind, prüfen wir gerne Sondertarife.'
              }]"
            />
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import DamageExamplesSection from '@/bedarf/components/DamageExamplesSection.vue';
import JumboImage from '@/bedarf/components/JumboImage.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';

import WomanIllusatrion from '../../../../../../assets/images/bedarfsinfo/diensthaftpflicht/woman-illustration.jpg';
import DHKind from '../../../../../../assets/images/bedarfsinfo/diensthaftpflicht/DH-Kind.jpg';
import ManComputerIllustration from '../../../../../../assets/images/bedarfsinfo/diensthaftpflicht/man-computer-illustration.jpg';

interface Props {
  data: Bedarfsinfo,
}

defineProps<Props>();

const damageExamples = [
  {
    icon: WomanIllusatrion,
    title: 'Sachschaden',
    description: 'Als Mitarbeiterin der Stadt verlieren Sie am Wochenende auf einem Fest Ihren Schlüsselbund mit'
      + ' Dienstschlüsseln. Die Diensthaftpflicht übernimmt die Kosten für den Austausch der Schließanlage.',
  },
  {
    icon: DHKind,
    title: 'Personenschaden',
    description: 'Bei einem Schulausflug verletzt sich ein Kind im Schwimmbad schwer und die Eltern klagen aufgrund '
      + 'der Aufsichtspflichtverletzung auf Schadenersatz. Die Diensthaftpflicht übernimmt die Behandlungskosten '
      + 'und das Schmerzensgeld.',
  },
  {
    icon: ManComputerIllustration,
    title: 'Vermögensschaden',
    description: 'Sie verursachen in der Behörde, in der Sie arbeiten, einen größeren EDV-Schaden indem Sie '
      + 'versehentlich Schadsoftware ins Netzwerk der Behörde herunterladen. Es entstehen Kosten zur Beseitigung '
      + 'der Schadsoftware.',
  },
];
</script>
