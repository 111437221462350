import type { RouteRecordRaw } from "vue-router";
import { Finanzmanager } from "@/application/router/types/types";
import MainLayout from "@/application/layouts/MainLayout.vue";
import BankAccountDetailsPage from '@/user/bankAccount/pages/BankAccountDetailsPage.vue';

export const bankAccountRoutes: RouteRecordRaw[] = [
  {
    path: '/bank-accounts',
    component: MainLayout,
    children: [
      {
        path: ':accountId',
        name: Finanzmanager.BANK_ACCOUNT_DETAILS,
        component: BankAccountDetailsPage,
        meta: {
          menuTitle: 'Bankverbindung',
          title: 'Bankverbindung',
        },
      },
    ]
  }
]
