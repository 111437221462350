import type { Ref } from 'vue';
import { ref } from 'vue';

import type { DocumentType } from '@/api/common/types';
import { getUploadableDocumentTypes } from '@/api/documents/documentsApi';
import { call } from "@/api/lib/integration";

export const useDocumentTypes = (): {
  documentTypes: Ref<DocumentType[]>,
  fetchDocumentTypes: () => Promise<void>,
} => {
  const documentTypes = ref<DocumentType[]>([]);

  const fetchDocumentTypes = async () => {
    await call(
      getUploadableDocumentTypes(),
      (data) => { documentTypes.value = data },
    );
  };

  return {
    documentTypes,
    fetchDocumentTypes,
  };
};
