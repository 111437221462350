import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';

import { PasswordHealth } from './types';
import type { PasswordHealthService } from './types';

export class ZXCVBNService implements PasswordHealthService {
  private classification: PasswordHealth | null = null;

  private score: number | null = null;

  private zxcvbnOptionsSet = false;

  constructor(private password = '') {}

  public setPassword(password: string): void {
    this.password = password;
    this.classification = null;
    this.score = null;
  }

  public async estimate(): Promise<void> {
    await this.setZxcvbnOptions();

    const { score } = zxcvbn(this.password);

    this.score = score;
    this.classification = this.classify();
  }

  public getClassification(): PasswordHealth | null {
    return this.classification;
  }

  private classify() {
    switch (this.score) {
      case 4:
        return PasswordHealth.VeryStrong;
      case 3:
        return PasswordHealth.Strong;
      case 2:
        return PasswordHealth.Moderate;
      case 1:
        return PasswordHealth.Weak;
      default:
        return PasswordHealth.VeryWeak;
    }
  }

  private async setZxcvbnOptions(): Promise<void> {
    if (this.zxcvbnOptionsSet) return;

    const { dictionary, translations } = await import('@zxcvbn-ts/language-de');
    const { adjacencyGraphs } = await import('@zxcvbn-ts/language-common');

    zxcvbnOptions.setOptions({
      dictionary,
      translations,
      graphs: adjacencyGraphs,
    });

    this.zxcvbnOptionsSet = true;
  }
}
