import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 98.89 98.89"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"49.44\" cy=\"49.44\" r=\"49.44\" fill=\"#fff\"></circle><g fill=\"#343a40\"><path d=\"m76.48 87.1-.31-.54-.02-.02-31.32-55.57 26.71-18.71-4.56-6.57-28.01 19.45-.04-.05L2.64 43.05l3.55 7.17 35.14-17.5 34.58 54.6\"></path><path d=\"M29.8 60.25 35.43 57l13.68 23.72-5.65 3.25z\"></path><path d=\"m33.51 42.7 14.01 11.81-26.13 15.06-3.19-18.04m37.4 26.79-17.36 10 2.62-3.77 10.16-5.86zm6.1-44.47 3.07-1.77L72.22 45l-3.08 1.78z\"></path><path d=\"m63.72 24.29 7.63 6.43-14.23 8.2-1.74-9.83m20.37 14.6-9.45 5.45 1.42-2.05 5.54-3.2z\"></path></g>", 2)
  ])))
}
export default { render: render }