import { defineStore } from 'pinia';

import { getAll, remove } from '@/api/unreadHint/unreadHintsApi';
import type { UnreadHint } from '@/api/unreadHint/types';
import { UnreadHintType } from '@/api/unreadHint/types';
import { useRequest } from "@/api/lib/integration";

export type UnreadHintStore = {
  [key in UnreadHintType]: Set<number>
};

export const useUnreadHintStore = defineStore('unreadHint', {
  state: (): UnreadHintStore => ({
    [UnreadHintType.Contract]: new Set(),
    [UnreadHintType.Document]: new Set(),
    [UnreadHintType.News]: new Set(),
  }),
  getters: {
    menuHints: (state) => Object
      .values(UnreadHintType)
      .reduce((accumulator, unreadHintType) => {
        accumulator[unreadHintType] = state[unreadHintType].size;
        return accumulator;
      }, {}) as { [key in UnreadHintType]: number },
  },
  actions: {
    async fetchUnreadHints() {

      const { call } = useRequest(getAll, (data) => {
        this[UnreadHintType.Contract].clear();
        this[UnreadHintType.Document].clear();
        this[UnreadHintType.News].clear();

        if (! data) {
          return;
        }

        Object.entries(data).forEach(([unreadHint, object_ids = null]) => {
          object_ids?.forEach((id) => this[unreadHint].add(id));
        });
      })

      await call();
    },
    async removeUnreadHints(unreadHints: UnreadHint[], immediate = true) {

      const { call } = useRequest(remove, () => {
        if (immediate) {
          unreadHints.forEach(({ object_id, type }) => {
            if (object_id) {
              this[type].delete(object_id);
            } else {
              this[type].clear();
            }
          });
        }
      })

      await call(unreadHints)
    },
  },
});
