import { format, parseISO } from 'date-fns';

// Parse the given date, with optional dots in between sections
const parseDateRegex = /([0-3]?\d)\.?([01]?\d)\.?(\d{2,4})/;

/**
 * Fills the given string with a zero if it's below 10.
 *
 * @param string
 * @return string
 */
const zeroFill = (string) => {
  const number = Number.parseInt(string, 10);
  return number < 10 ? `0${number}` : number;
};

export const formatDate = (string) => format(parseISO(string), 'dd.MM.yyyy');

/**
 * Parses the given date string and returns a DD.MM.YYYY string.
 *
 * @param string
 * @return string
 */
export const parseDate = (string) => {
  const match = string.match(parseDateRegex);
  if (match === null) {
    return string;
  }

  const [, day, month, year] = match;

  // We fail if it's either too short or day/month is/are zero
  if (Number.parseInt(day, 10) === 0 || Number.parseInt(month, 10) === 0) {
    return string;
  }

  // Check if we have a full year already
  let yearValue;
  if (year.length === 4) {
    yearValue = year;
  } else {
    const yearDigit = year.slice(-2);
    const currentYear = new Date().getFullYear();
    let century = currentYear.toString().slice(0, 2);

    // If the given year is within a range of 20 years, we assume the last century
    if (yearDigit > ((currentYear % 100) + 20)) {
      century -= 1;
    }
    yearValue = zeroFill(century + yearDigit);
  }

  return `${zeroFill(day)}.${zeroFill(month)}.${yearValue}`;
};
