import { defineStore } from 'pinia';
import { reactive, ref } from 'vue';

import { storeAltersvorsorge } from '@/api/altersvorsorge/altersvorsorgeApi';
import type { Altersvorsorge } from '@/bedarf/components/altersvorsorge/altersvorsorge/types';
import { call } from "@/api/lib/integration";

type WithoutDefaults = 'anlegerorientierung' | 'produkterfahrung' | 'tarifGarantie';

type AvWithDefaults = Omit<Altersvorsorge, WithoutDefaults>;
type AvWithoutDefaults = Pick<Altersvorsorge, WithoutDefaults>;

type AvNonNullable = {
  [K in keyof AvWithDefaults]: NonNullable<Altersvorsorge[K]>
};

const removeNullEntries = (av: Partial<AvWithDefaults>): AvNonNullable => (
  Object.fromEntries(Object.entries(av).filter(([, v]) => v !== null)) as AvNonNullable
);

export enum StorageStatus {
  NONE,
  PENDING,
  SUCCESS,
  FAILURE,
}

export const useAltersvorsorgeStore = defineStore('altersvorsorge', () => {
  const storageStatus = ref<StorageStatus>(StorageStatus.NONE);

  const altersvorsorge = reactive<AvNonNullable & AvWithoutDefaults>({
    ansparzinssatz: 5,
    bestehendeVorsorge: 0,
    versorgungswerk: 0,
    entnahmezinssatz: 4,
    freiwilligeEinzahlung: false,
    gesetzlicheRente: 0,
    inflation: 2,
    rentenbedarf: 80,
    rentenbeginn: 67,
    pension: 0,
    pensionsbedarf: 80,
    besoldungVorPension: 0,
    pensionseintritt: 2050,
    dienstzeitbeginn: new Date().getFullYear(),
    cashreserve: 0,
    maximaleRisikotoleranz: 0,
    monatlicherUeberschuss: 0,
    anlegerorientierung: null,
    produktwissen: [],
    produktwissenInfo: [],
    produkterfahrung: null,
    tarifGarantie: null,
    fragen: {
      arbeitgeberunabhaengig: null,
      freie_verwendung_todesfall: null,
      garantierte_rente: null,
      hartz_4_sicher: null,
      kapital_auszahlungsoption: null,
      staatliche_foerderungen: null,
      verfuegbar_bei_rentenbeginn: null,
    },
  });

  /**
   * Patches altersvorsorge values that have defaults.
   *
   * Ignores properties with a null value so that defaults are not overwritten.
   *
   * @param values altersvorsorge values to patch
   */
  const patchDefaults = (values: Partial<AvWithDefaults>) => {
    Object.assign(altersvorsorge, removeNullEntries(values));
  };

  const store = async (): Promise<void> => {
    storageStatus.value = StorageStatus.PENDING;

    await call(
      storeAltersvorsorge(altersvorsorge),
      () => { storageStatus.value = StorageStatus.SUCCESS },
      () => { storageStatus.value = StorageStatus.FAILURE },
    )
  };

  return {
    altersvorsorge,
    storageStatus,

    store,
    patchDefaults,
  };
});
