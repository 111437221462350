<template>
  <div>
    <section class="mb-12 md:mb-20">
      <div class="flex flex-col items-center justify-center">
        <h2 class="mb-5 checkup-h2">
          Ich betreibe folgende Hobbys
        </h2>
        <div
          v-for="hobby in hobbies"
          :key="hobby.key"
        >
          <DsFieldError
            v-for="error in formErrors[hobby.key]"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </div>
      </div>
      <div class="OptionIconGroupCheck">
        <div class="flex flex-row gap-5 justify-center flex-wrap">
          <FormCheckBox
            v-for="hobby in hobbies"
            :key="hobby.key"
            :model-value="form[hobby.key]"
            :label="hobby.label"
            :icon="hobby.icon"
            @update:model-value="set(hobby.key, $event)"
          />
        </div>
      </div>
    </section>

    <section class="mb-7">
      <div class="row flex justify-center">
        <div class="col-md-12">
          <h2 class="mb-7 checkup-h2">
            Ich besitze folgende Tiere
          </h2>
        </div>
      </div>

      <div class="flex flex-col md:flex-row justify-center items-center gap-8">
        <div
          class="rounded border w-60 bg-white"
          :class="Number(form.hundAnzahl) > 0 ? 'border-blue-500 border-2' : 'border-gray-200'"
          :style="Number(form.hundAnzahl) > 0 ? '' : 'padding: 1px'"
        >
          <div class="flex flex-row justify-between items-center p-2">
            <div class="flex flex-col items-center pl-2">
              <FontAwesomeIcon
                :icon="['fat', 'dog-leashed']"
                class="h-8"
                :class="Number(form.hundAnzahl) > 0 && 'text-secondary'"
              />
              <label
                for="hundAnzahl"
                class="mt-1 inline-block"
              >
                Hund
              </label>
            </div>
            <CountInput
              :error="formErrors.hundAnzahl"
              label="Hund"
              name="hundAnzahl"
              :value="form.hundAnzahl"
              :min="0"
              :max="20"
              @input="set('hundAnzahl', $event)"
            />
          </div>
        </div>
        <div
          class="rounded border w-60 bg-white"
          :class="Number(form.pferdAnzahl) > 0 ? 'border-blue-500 border-2' : 'border-gray-200'"
          :style="Number(form.pferdAnzahl) > 0 ? '' : 'padding: 1px'"
        >
          <div class="flex flex-row justify-between items-center p-2">
            <div class="flex flex-col items-center pl-2">
              <FontAwesomeIcon
                :icon="['fat', 'horse-saddle']"
                class="h-8"
                :class="Number(form.pferdAnzahl) > 0 && 'text-secondary'"
              />
              <label
                for="pferdAnzahl"
                class="mt-1 inline-block"
              >
                Pferd
              </label>
            </div>
            <CountInput
              :error="formErrors.pferdAnzahl"
              label="Hund"
              name="hundAnzahl"
              :value="form.pferdAnzahl"
              :min="0"
              :max="20"
              @input="set('pferdAnzahl', $event)"
            />
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="row flex justify-center">
        <div class="col-md-12">
          <h2 class="mt-7 mb-3 md:mb-5 checkup-h2">
            Ich schließe einen zukünftigen Auslandsaufenthalt aus
          </h2>
          <DsFieldError
            v-for="error in formErrors.auslandsaufenthaltAusgeschlossen"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </div>
      </div>

      <OptionGroup
        has-icons
        :options="[
          {label: 'Ja', labelClasses: `${form.auslandsaufenthaltAusgeschlossen ? 'text-green-700' : 'flex flex-col'}`, value: true, icon: 'icomoon/check', iconClass: `check ${form.auslandsaufenthaltAusgeschlossen ? 'fill-green-700' : ''}`},
          {label: 'Nein', labelClasses: `${!form.auslandsaufenthaltAusgeschlossen ? 'text-red-700' : 'flex flex-col'}`, value: false, icon: 'icomoon/close', iconClass: `close ${!form.auslandsaufenthaltAusgeschlossen ? 'fill-red-700' : ''}`}
        ]"
        :value="form.auslandsaufenthaltAusgeschlossen"
        option-width-classes="w-1/3 sm:w-1/4 md:w-1/6"
        @change="set('auslandsaufenthaltAusgeschlossen', $event)"
      />
    </section>
  </div>
</template>

<script setup lang="ts">
import { DsFieldError } from '@demvsystems/design-components';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import CountInput from '@/checkup/components/CountInput.vue';
import FormCheckBox from '@/checkup/components/FormCheckbox.vue';
import OptionGroup from '@/checkup/components/OptionGroup.vue';
import { Hobbies } from '@/checkup/utils/demvWerte';
import { demvWerte, useStep } from '@/checkup/state';
import { Step } from '@/checkup/utils/steps';

const { form, formErrors, set } = useStep(Step.FREIZEIT);

const werte = demvWerte.hobbies;

const hobbies = [
  { label: werte[Hobbies.JAGDSPORT], icon: 'jagdsport', key: 'jagdsport' },
  { label: werte[Hobbies.LUFTSPORT], icon: 'luftsport', key: 'luftsport' },
  { label: werte[Hobbies.WASSERSPORT], icon: 'wassersport', key: 'wassersport' },
  { label: werte[Hobbies.CAMPING], icon: 'camping', key: 'camping' },
];
</script>
