export enum Anrede {
  HERR = 1,
  FRAU = 2,
  FIRMA = 3,
  LEERE_ANREDE = 4,
}

export enum Fahrzeug {
  PKW = 1,
  MOTORRAD = 2,
  MOFA_MOPED = 3,
  ANHAENGER = 4,
  QUAD = 5,
  LKW = 6,
  OLDTIMER = 7,
  WOHNMOBIL = 8,
  E_BIKE = 9,
  FAHRRAD = 10,
  DROHNE = 11,
  BOOT = 12,
  SCHLEPPER = 13,
}

export enum Familienstand {
  LEDIG = 1,
  VERHEIRATET = 2,
  GETRENNT_LEBEND = 3,
  GESCHIEDEN = 4,
  VERWITWET = 5,
  UNBEKANNT = 6,
  EINGETRAGENE_LEBENSPARTNERSCHAFT = 7,
  LEBENSGEMEINSCHAFT = 8,
}

export enum Hobbies {
  JAGDSPORT = 1,
  LUFTSPORT = 2,
  WASSERSPORT = 3,
  CAMPING = 4,
}

export enum ImmobilienTyp {
  HAUS = 1,
  WOHNUNG = 2,
  FREIES_GRUNDSTUECK = 3,
}

export enum Krankenversicherungsstatus {
  GESETZLICH = 1,
  PRIVAT = 2,
  FREIE_HEILFUERSORGE = 3,
  BEIHILFE = 4,
}

export enum Taetigkeitsstatus {
  ANGESTELLTER = 2,
  SELBSTSTAENDIGER = 3,
  BEAMTER_AUF_LEBENSZEIT = 5,
  BEAMTER_AUF_PROBE = 8,
  ANGESTELLTER_OEFFENTL_DIENST = 10,
  GGF_MIT_ENTGELTFORTZAHLUNG = 11,
  VORSTAND = 12,
  SCHULAUSBILDUNG = 13,
  BERUFSAUSBILDUNG = 14,
  STUDIUM = 15,
  HAUSFRAU_HAUSMANN = 17,
  ELTERNZEIT = 18,
  RENTNER = 19,
  ARBEITSSUCHEND = 20,
  MINIJOB = 22,
  SONSTIGE = 23,
  ERWERBSUNFAEHIG = 24,
  GGF_OHNE_ENTGELTFORTZAHLUNG = 25,
}

export enum Wohnsituation {
  ZUR_MIETE = 1,
  IN_EIGENTUM = 2,
  BEI_ELTERN = 3,
  BEI_ANDERER_PERSON = 4,
}
