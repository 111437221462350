<script setup lang="ts" generic="T extends ChoiceData">

import type { ActionBlockProps, ChoiceData } from '@/api/actions/types';
import { DsRadioButton, DsRadioGroup } from '@demvsystems/design-components';

defineProps<ActionBlockProps<T>>();

const model = defineModel({ type: String, default: '' });
</script>

<template>
  <div class="p-3 my-2 rounded-md border border-gray-100">
    <DsRadioGroup
      v-model="model"
      class="space-y-5"
    >
      <DsRadioButton
        v-for="({ id, label }) in baustein.data.options"
        :key="id"
        :value="id"
      >
        {{ label }}
      </DsRadioButton>
    </DsRadioGroup>
  </div>
</template>
