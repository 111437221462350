import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 45 45"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"22.6\" cy=\"22.6\" r=\"21.6\" fill=\"#4181D2\"></circle><path d=\"M11.8 16.5v18.6h7.4V16.5zM17 33h-1.2v1.2h-.9V33h-1.2v-1h1.2v-1.2h.9V32H17zm1.8-4.1h-6.6v-8h6.6zm-9.9-.3v6.5h2.4v-6.5zm1.9 5.4h-.5v.5h-.5V34h-.5v-.4h.5v-.5h.4v.5h.5zm.2-2.2H9v-1.4h2z\" style=\"fill:#fff;\"></path><path d=\"m8.2 28.2 2.7.5-.2-.3.9-6.3-.5-.7h.1l.1-.3-.7-.2-.1.3h.1l-.7.6s-1.2 5.8-1.3 6z\" style=\"fill:#fff;\"></path><circle cx=\"25.1\" cy=\"11.4\" r=\"6.6\" style=\"fill:#fff;\"></circle><path d=\"M25.1 19.7c-1.2 0-3.3.5-4.4.9.1 2.5.1 4 .2 6.2.1 3 0 4.2-.1 8.1 4.5.1 8.4 0 16.7-.2.1-8.3-5.5-15-12.4-15\" style=\"fill:#fff;\"></path>", 5)
  ])))
}
export default { render: render }