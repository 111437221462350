// Generated by ts-to-zod
import { z } from "zod";

export const newsSchema = z.object({
  id: z.number(),
  title: z.string(),
  createdAt: z.string(),
  isUnread: z.boolean(),
  isArchived: z.boolean(),
});

export const newsResponseSchema = z.array(newsSchema);

export const newsShowResponseSchema = z.object({
  news: newsSchema,
  content: z.string(),
});
