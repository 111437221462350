import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"#FFD27A\" d=\"M14.76 11.98c0 .23.2.4.42.4s.4-.17.43-.4l.69-10.8A1.1 1.1 0 0 0 15.19 0c-.66 0-1.15.56-1.11 1.18z\"></path><path fill=\"#B4CEEF\" d=\"m3.33 6.53 8.13 7.15c.16.13.43.13.59-.03s.16-.43.03-.59L4.93 4.93a1.14 1.14 0 0 0-1.63-.07c-.46.53-.43 1.25.03 1.67\"></path><path fill=\"#FFD27A\" d=\"m27.04 27.1-8.13-7.15c-.17-.13-.43-.13-.6.03s-.16.43-.03.6l7.16 8.12c.42.5 1.17.5 1.63.07.46-.52.42-1.24-.03-1.67\"></path><path fill=\"#B4CEEF\" d=\"m30.82 15.7-10.8.7c-.23 0-.4.19-.4.42s.17.39.4.42l10.8.69A1.1 1.1 0 0 0 32 16.82c0-.66-.56-1.15-1.18-1.11z\"></path><path fill=\"#4181D2\" d=\"M18.32 13.68c.16.17.42.17.59.04l8.13-7.16c.49-.42.49-1.17.06-1.63a1.12 1.12 0 0 0-1.63.07l-7.15 8.13c-.16.13-.16.39 0 .55\"></path><path fill=\"#FFD27A\" d=\"M12.4 2.8a1.01 1.01 0 1 1-2.02 0 1.01 1.01 0 0 1 2.03 0z\"></path><path fill=\"#4181D2\" d=\"M25.8 22.3a.69.69 0 1 1-1.38 0 .69.69 0 0 1 1.38 0\"></path><path fill=\"#FFD27A\" d=\"M17.37 15.28a.46.46 0 1 1-.91 0 .46.46 0 0 1 .91 0\"></path><path fill=\"#4181D2\" d=\"M18.71 8.55a.69.69 0 1 1-1.37 0 .69.69 0 0 1 1.37 0\"></path><path fill=\"#B4CEEF\" d=\"M29.29 12.38a.69.69 0 1 1-1.37 0 .69.69 0 0 1 1.37 0\"></path><path fill=\"#4181D2\" d=\"m22.76 14.27-.26-1.24-.98.85z\"></path><path fill=\"#B4CEEF\" d=\"m19.27 2.94-1.5.69 1.33.98z\"></path><path fill=\"#4181D2\" d=\"m10.29 6.37-1.24-.33.32 1.24z\"></path><path fill=\"#B4CEEF\" d=\"m27.92 20.86.94-.88-1.24-.36z\"></path><path fill=\"#4181D2\" d=\"m16.69 17.3-.4 1.25 1.28-.3z\"></path><path fill=\"#B4CEEF\" d=\"m11.98 10.19.36.2.5-1.74-.86-.03z\"></path><path fill=\"#FFD27A\" d=\"m23.74 19.4.06.29 1.28-.46-.33-.55zm2.38-9.25L27 9.11l-.58-.3-.5 1.12z\"></path><path fill=\"#B4CEEF\" d=\"m14.66 14.46-.59-.29-.49 1.11.2.26z\"></path><path fill=\"#FFD27A\" d=\"m21.71 6.37-.49-.82-1.5.95.07.46z\"></path><path fill=\"#4181D2\" d=\"M9.6 14.8a1.58 1.58 0 0 0-2.55.45L.1 30.66a.94.94 0 0 0 1.24 1.24l15.41-6.95a1.56 1.56 0 0 0 .46-2.55z\"></path>", 20)
  ])))
}
export default { render: render }