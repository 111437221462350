import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 25 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M25 24.36c0-.22-.02-.43-.04-.64a9.71 9.71 0 0 0-7.14-8.15h-.05a1.4 1.4 0 0 1-1.11-1.17 5.77 5.77 0 0 0 1.5-3.14c.68-.78.7-1.68.72-2.34q0-.525-.21-.9c.32-.5.47-1.14.42-1.93a8 8 0 0 0 1.87-3.41.69.69 0 0 0-1.12-.68c-.53.46-.88.34-2.14-.4A9.6 9.6 0 0 0 12.54 0c-4.9 0-5.87 2.8-6.06 3.77a3.35 3.35 0 0 0-1.08 2.4 3 3 0 0 0 .85 1.93c-.1.22-.16.5-.15.82.01.7.03 1.66.84 2.47a5.77 5.77 0 0 0 1.47 3c-.15.85-1.03 1.15-1.15 1.18a9.71 9.71 0 0 0-7.18 8.79C.04 25.14 0 25.93 0 26.7A4.27 4.27 0 0 0 3.55 31a53.5 53.5 0 0 0 17.98 0 4.27 4.27 0 0 0 3.56-4.28c-.01-.78-.04-1.57-.08-2.35zM9.55 15.33c.86.54 1.87.86 2.94.87h.11a5.6 5.6 0 0 0 2.9-.85c.22.46.53.8.87 1.05-.43.63-1.47 1.62-3.83 1.62-2.4 0-3.45-1.03-3.86-1.66.34-.26.65-.6.86-1.03zm-1.52-4.8c-.46-.39-.53-.82-.55-1.45a.67.67 0 0 0 .62-.1.68.68 0 0 0 .27-.73c-.1-.38-.15-1.03.02-1.23.04-.05.17-.06.26-.05.25.01.48-.1.61-.3.66-.92 3.54-.26 5.97.42.38.1.75.16 1.1.16.1.2.23.53.18.91a.69.69 0 0 0 .68.78h.04c.03 0 .14-.01.28-.05 0 .61-.02 1.14-.47 1.57a.68.68 0 0 0-.21.43 4.4 4.4 0 0 1-1.38 2.8 4.24 4.24 0 0 1-2.86 1.14h-.09a4.34 4.34 0 0 1-4.23-3.86.7.7 0 0 0-.24-.44M21.3 29.66a52.2 52.2 0 0 1-17.51 0 2.9 2.9 0 0 1-2.42-2.92 59 59 0 0 1 .12-2.85 8.44 8.44 0 0 1 5.95-6.93c.51.87 1.89 2.44 5.1 2.44s4.6-1.57 5.1-2.44a8.44 8.44 0 0 1 6 7.48c.04.76.06 1.53.07 2.3a2.9 2.9 0 0 1-2.41 2.91z" }, null, -1)
  ])))
}
export default { render: render }