import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 93.33 93.33"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "46.67",
      cy: "46.67",
      r: "46.67",
      fill: "#4181D2"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#FFF",
      d: "M54.27 70.68s-6.74-27.87 26.65-29.46c0 0-18.41 11.29-26.65 29.46"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#FFF",
      d: "M55.58 72.17c5.51 7.34 24.3 2.51 25.31-30.96 0 0-20.11 15.19-25.31 30.96m-7.16 1.17s-37.75 9.7-36.08-50.4c0 0 12.91 34.05 36.08 50.4"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#FFF",
      d: "M50.56 71.13C60.93 61.87 56.64 27.71 12.33 23c0 0 17.88 37.43 38.23 48.13"
    }, null, -1)
  ])))
}
export default { render: render }