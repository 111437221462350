<template>
  <Tab :name="name">
    <div class="flex">
      <div class="col-12 col-lg-9 p-0">
        <h3>Dread-Disease-Versicherung</h3>
        <div class="my-5">
          Die folgende Grafik gibt einen Überblick über die häufigsten schweren Erkrankungen in Deutschland.
        </div>
        <div class="flex flex-col my-7">
          <div class="mb-2.5">
            Benötige ich eine Dread-Disease-Versicherung?
          </div>
          <SvgVue icon="bedarfsinfo/berufsunfaehigkeit/dread-disease.svg" />
        </div>

        <h3>Worauf muss ich bei dieser Versicherung achten?</h3>
        <div class="my-5">
          Bei dieser Versicherung kommt es nicht nur auf die Anzahl der versicherten Krankheiten an. Besonders
          wichtig ist, wie die jeweilige Krankheit definiert ist.
        </div>
        <div class="my-5">
          <strong>Laut Versicherungsbedingungen muss die Krankheit ein bestimmtes Stadium erreichen, bevor Sie
            Anspruch auf die Versicherungssumme haben.</strong>
        </div>
        <div class="mt-5 mb-7">
          Diese Versicherung leistet, sofern Sie eine bestimmte schwere Erkrankung erleiden. Mit der ärztlichen
          Diagnose erhalten Sie eine Einmalsumme, über die Sie frei verfügen können.
        </div>
      </div>
      <div class="hidden lg:flex items-center col-3 ml-5">
        <img
          src="../../../../../../assets/bedarfsinfo/einkommenssicherung/frau.jpg"
          alt="Frau"
          style="max-width: 100%;"
          loading="lazy"
        >
      </div>
    </div>

    <template
      v-for="{ title, diseases } in diseasesCategories"
      :key="title"
    >
      <h3 v-text="title" />
      <div class="flex flex-wrap mt-5 pb-7">
        <div
          v-for="disease in diseases"
          :key="disease"
          class="col-12 col-sm-6 col-lg-3"
        >
          <BulletPoint
            small
            :item="{ text: disease }"
          />
        </div>
      </div>
    </template>

    <NiceToKnow
      classes="mb-0"
      :bullets="niceToKnow"
    />
  </Tab>
</template>

<script setup lang="ts">
import BulletPoint from '@/bedarf/components/BulletPoint.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import Tab from '@/application/components/Tab.vue';
import SvgVue from "@/application/components/SvgVue";

interface Props {
  name: string
}

defineProps<Props>();

const commonDiseases = [
  'Krebs',
  'Herzinfarkt',
  'Demenz',
  'Parkinson',
  'Schlaganfall',
  'Multiple Sklerose',
  'Alzheimer',
  'Operation Herzklappen',
];

const brainAndNervalDiseases = [
  'Bakterielle Hirnhautentzündung',
  'Creutzfeldt-Jakob Krankheit',
  'Corticobasale Degeneration',
  'Hirngewebeentzündung',
  'Hirnvenenthrombose',
  'Hirntumor',
  'Epilepsie',
  'Neurosarkoidose',
];

const otherDiseases = [
  'Blindheit',
  'Gehörlosigkeit',
  'Erkrankung des Herzmuskels',
  'HIV durch Berufsausübung',
  'Kinderlähmung',
  'Sprachverlust',
  'Koma',
  'Schwere Kopfverletzungen',
];

const diseasesCategories = [
  { title: 'Häufige Krankheiten', diseases: commonDiseases },
  { title: 'Gehirn und Nervensystem Erkrankungen', diseases: brainAndNervalDiseases },
  { title: 'Andere Erkrankungen', diseases: otherDiseases },
];

const niceToKnow = [
  {
    title: 'Hinweis:',
    text: `Je nach Anbieter, schwankt die Anzahl der versicherten Krankheiten.<br><br>Diese Versicherung leistet
          nicht bei psychischen Erkrankungen, daher können auch Personen mit einer psychischen Vorerkrankungen
          versichert werden.`,
  },
];

</script>
