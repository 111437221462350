import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M16.31 0a15.94 15.94 0 1 0 0 31.91A15.94 15.94 0 0 0 16.3 0zm0 31.02A15.07 15.07 0 1 1 16.32.9a15.07 15.07 0 0 1 0 30.12z" }, null, -1),
    _createElementVNode("path", { d: "M12.89 9.38a.98.98 0 1 1-1.96 0 .98.98 0 0 1 1.96 0m8.75 0a.98.98 0 1 1-1.95 0 .98.98 0 0 1 1.95 0M9.33 21.57c0-.24.15-.3.34-.15 0 0 3.1 2.9 6.55 2.9s6.56-2.9 6.56-2.9c.18-.16.33-.1.33.15s-.15.58-.33.75c0 0-3.05 2.91-6.56 2.91s-6.56-2.92-6.56-2.92a1.12 1.12 0 0 1-.33-.73z" }, null, -1)
  ])))
}
export default { render: render }