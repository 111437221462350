import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M16 32.04A16 16 0 1 1 32.04 16v.02c0 8.85-7.17 16.02-16.02 16.02zM16 .9A15.09 15.09 0 1 0 31.12 16 15.1 15.1 0 0 0 16.02.91H16z" }, null, -1),
    _createElementVNode("path", { d: "M18.17 24.25h-5.18a.46.46 0 1 1 0-.92h5.18a.46.46 0 1 1 0 .92M18 25.34h-4.84a.46.46 0 1 1 0-.92H18c.26 0 .46.2.46.46 0 .25-.2.46-.46.46m-.2 1.05h-4.45a.46.46 0 1 1 0-.92h4.45a.46.46 0 1 1 0 .92M17 27.7h-2.84a.46.46 0 1 1 0-.92v.01h2.85c.25 0 .45.2.45.46 0 .25-.2.46-.45.46zm1.1-5.16V21.1s4.44-4.42 4.71-8.5c0 0 .17-7.85-7.23-7.85S8.36 12.6 8.36 12.6c.25 4.09 4.71 8.5 4.71 8.5v1.44h5.1z" }, null, -1)
  ])))
}
export default { render: render }