<template>
  <SpartenWrapper
    :text="subtitle"
    image="images/bedarfsinfo/diensthaftpflicht/beautiful-young-psychotherapist-is-making-notes"
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    :versorgungen="data.bedarfsinfo.versorgungen"
  >
    <section class="section pb-0">
      <div class="container">
        <div class="text-center mb-7">
          <h2>Was passiert, wenn ich (länger) erkranke?</h2>
        </div>

        <div class="row">
          <div class="col-12 pb-14 text-center">
            Während das Risiko dienstunfähig zu werden unterschätzt wird, wird die Versorgung durch den Staat
            oft überschätzt. Egal in welcher Lebensphase die Dienstunfähigkeit eintritt. Sie ist fast immer mit
            finanziellen Einbußen verbunden. Insbesondere als Beamter auf Widerruf und Beamter auf Probe ist die
            Versorgungslücke erheblich.
          </div>
        </div>
      </div>
    </section>

    <section class="section py-0">
      <Dienstunfaehigkeit :dienstbezuege="data.dienstbezuege" />
    </section>

    <section class="section">
      <div class="container">
        <div class="text-center mb-7">
          <h2>Wann bin ich tatsächlich dienstunfähig?</h2>
        </div>

        <div class="row">
          <div class="col-12 pb-14 text-center">
            Dienstunfähigkeit liegt dann vor, wenn Sie nicht mehr in der Lage sind, die
            Ihnen übertragenen dienstlichen Pflichten zu erfüllen.
          </div>
        </div>
      </div>
    </section>

    <section class="section pt-0">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 mt-2.5 md:mt-0">
            <BulletPointList :items="whenApplicable" />
          </div>
          <div class="col-12 col-md-6 mt-2.5 md:mt-0 flex items-center">
            <img
              src="../../../../../../assets/images/bedarfsinfo/diensthaftpflicht/chart-versorgungsbericht.jpg"
              alt="Chart-Versorgungsbericht"
              class="max-w-full"
            >
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
            <div class="text-center pb-7">
              <h2>Worauf muss ich bei dieser Versicherung achten?</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <BulletPointList :items="payAttention1" />
          </div>
          <div class="col-12 col-md-6">
            <BulletPointList :items="payAttention2" />
          </div>
        </div>
      </div>
    </section>

    <WarumJetzt />
  </SpartenWrapper>
</template>

<script setup lang="ts">
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import WarumJetzt from '@/bedarf/components/WarumJetzt.vue';
import Dienstunfaehigkeit from '@/bedarf/components/gesundheitsvorsorge/dienstunfaehigkeit/Chart.vue';
import type { DienstunfaehigkeitsInfo } from '@/bedarf/types/bedarfsinfo';

interface Props {
  data: DienstunfaehigkeitsInfo
}

defineProps<Props>();

const subtitle = `
  Das Risiko aufgrund einer Krankheit Einkommensverluste zu erleiden, kann jeden treffen. Die
  staatlichen Sicherungssysteme sichern den Lebensstandard nicht. Im Folgenden finden Sie einen
  Überblick, optimal vorzusorgen.`;

const whenApplicable = [
  {
    title: '6-3-6-Regel',
    text: `Wer in Folge einer Erkrankung innerhalb eines Zeitraumes von sechs Monaten mehr als drei Monate keinen Dienst
            getan hat und bei dem innerhalb der nächsten sechs Monate die Dienstfähigkeit nicht wieder vollständig
            hergestellt werden kann, gilt als dienstunfähig.`,
  },
  {
    title: 'Psychologische Erkrankungen',
    text: `Mit großem Abstand sind neurologische Erkrankungen für eine Dienstunfähigkeit verantwortlich. Ein klares
            Indiz dafür, dass es jeden treffen kann und nicht nur Beamte mit riskanten Hobbys wie Bergsteigen oder
            Tiefseetauchen.`,
  },
];

const payAttention1 = [
  {
    title: 'Echte vs. unechte Dienstunfähigkeitsklausel',
    text: 'Bei der echten Dienstunfähigkeitsklausel schließt sich der Versicherer der Entscheidung des Dienstherrn an.',
  },
  {
    text: 'Bei der unechten DU-Klausel behält sich der Versicherer vor die Dienstunfähigkeit selber zu prüfen.',
  },
  {
    title: 'Vollständige Dienstunfähigkeitsklausel',
    text: `Als Beamter auf Widerruf oder Beamter auf Probe sollte die Entlassung aus dem Dienst aus gesundheitlichen
            Gründen mitversichert sein.`,
  },
  {
    title: 'Dienstunfähigkeitsklausel ergänzt Berufsunfähigkeitsversicherung',
    text: `Die Dienstunfähigkeitsklausel sollte die Berufsunfähigkeitsversicherung ergänzen und diese nicht ersetzen.
            Nur so ist eine Besserstellung durch die Dienstunfähigkeitsklausel gewährleistet und es wird sowohl im Falle
            der Berufsunfähigkeit, als auch bei einer Dienstunfähigkeit geleistet.`,
  },
];

const payAttention2 = [
  {
    title: 'Spezielle Dienstunfähigkeitsklausel bei bestimmten Berufsgruppen',
    text: `Bestimmte Berufsgruppen wie Polizisten mit Schusswaffengebrauch oder Feuerwehrleute die einen Atemmaskentest
            bestehen müssen, benötigen eine spezielle Dienstunfähigkeitsklausel.`,
  },
  {
    title: 'Leistung bei Teildienstfähigkeit',
    text: `Der Dienstherr hat das Recht den Beamten aus gesundheitlichen Gründen auf Teilzeit zu setzen. Dadurch werden
            auch die Bezüge im entsprechenden Umfang gekürzt.`,
  },
  {
    title: 'Richtiges Endalter versichern',
    text: `Bestenfalls leistet die Dienstunfähigkeitsversicherung bis zum 67. Lebensjahr. Anschließend erhalten Sie
            Pension.`,
  },
];
</script>
