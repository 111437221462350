import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 75 75"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"37.5\" cy=\"37.5\" r=\"37.5\" fill=\"#fff\"></circle><g fill=\"#343A40\" fill-rule=\"evenodd\" clip-path=\"url(#a)\" clip-rule=\"evenodd\"><path d=\"M52.1 26.74V13.05a1.59 1.59 0 0 0-1-1.45 1.6 1.6 0 0 0-.61-.1h-6.13a1.61 1.61 0 0 0-1.61 1.55v5.13l-7.17-6.53c-.86-.81-1.32-.81-2.25-.07-7.88 7.07-15.83 14.07-23.71 21.07-.55.46-1.09.94-1.62 1.47h3.94v1.8c.07.27.1.56.1.85 0 7.24 0 14.46-.08 21.7 0 1.09.31 1.32 1.32 1.24h12.5c1 0 1.24-.3 1.24-1.24-.08-3.66 0-7.24 0-10.89a2.9 2.9 0 0 1 1.54-2.87l-.08-.32 2.55-1.55 1.08-1.1 1.4.55.3.16 3.7 1.56h1.56l1.46-.55-.3-.93-6.9-7.93 3.02-3.26 7.03 5.05c-.85-3.97-1.62-7.93-2.43-11.82l-.47-2.72 4.33-1.17 5.56 16.64 1-.23.55-.15 3.93-5.99-3.75-4.2Z\"></path><path d=\"m32.79 46.57 3.86 2.44h.08l.23.08h.7l1.55 2.57-.24-2.44 2.78.24 1.08.7 1.7 1.16-2.93 4.13-.24.23v.23l.31 3.35-4.78 5.44 5.48-5.05.08-.09v-.15l.46-3.1 3.63-3.82 2.55-1.25 1.15 1.33.86-.78.23 3.03-.92 5.22-.09.3.24.16 4.56 4.5-3.4-4.97 1.46-4.98v-.24l.24-3.58 5.49 2.65.23.16h.07l6.8-.08-6.41-1.4-3.93-2.73.63-3.8 5.1-4.83-5.56 2.65-1.94-.08c.77-4.07 3.09-6.38 3.87-10.34l-5.4 8.14-.32-1.1-3.1.87-6.25-18.71c1.24 6.14 2.43 12.28 3.63 18.43l-2.93-.62-8.44-6.08 7.33 8.24.93 3.65-3.79 1.18-2.3.08-4.19-1.72-.3-.15-.16.23-2.86 1.74z\"></path><path d=\"m48.24 43.07 2.32-1.01.7 2.64 2.23.16.16 1.01.92 1.17-1.77 2.64-1.7-1.25-1.55 1.02-2.47-1.17L46 49.92l-2.08-2.8 1.77-1.64-.85-3.96z\"></path></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M8 11h58v54H8z\"></path></clipPath></defs>", 3)
  ])))
}
export default { render: render }