<template>
  <label
    class="inline-block"
    :class="[{ [styles.active]: checked }, styles.base]"
  >
    <input
      class="RadioInput-input"
      type="radio"
      :name="name"
      :value="value"
      :checked="checked"
      @change="emit('change', value)"
    >
    <span v-if="tile || smallTile">{{ label }}</span>
    <div v-else-if="image || smallImage">
      <img
        :src="imageSrc"
        class="OptionImage-image inline-block"
        :class="{ 'OptionImage-imageTile': smallImage }"
      >
      <p>{{ label }}</p>
    </div>
    <template
      v-else
    >
      <span class="OptionIcon__icon self-center">
        <slot :checked="checked" />
      </span>
      <span class="OptionIcon__label">{{ label }}</span>
    </template>
  </label>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue';
import { computed } from 'vue';

const props = defineProps({
  value: {
    type: [String, Number, Object, Boolean] as PropType<string | number | object | boolean | null>,
    default: null,
  },
  checked: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  tile: {
    type: Boolean,
    default: false,
  },
  smallTile: {
    type: Boolean,
    default: false,
  },
  image: {
    type: Boolean,
    default: false,
  },
  imageSrc: {
    type: String,
    default: null,
  },
  smallImage: {
    type: Boolean,
    default: false,
  },
  smallMargin: {
    type: Boolean,
    default: false,
  },
  labelClasses: {
    type: String,
    default: 'flex flex-col',
  },
});

const emit = defineEmits(['change']);

const styles = computed(() => {
  let active = '';
  const base: string[] = [];
  if (props.tile || props.smallTile) {
    active = 'OptionTile--active';
    base.push('OptionTile', 'OptionTile--radio');
    if (props.smallTile) base.push('OptionTile--smallTile');
  } else if (props.image || props.smallImage) {
    active = 'OptionImage--active';
    base.push('OptionImage', 'OptionImage--radio');
  } else {
    active = 'OptionIcon--active';
    base.push('OptionIcon', 'OptionIcon--radio');
  }
  if (props.smallMargin) base.push('OptionTile--smallMargin');
  if (props.labelClasses) base.push(props.labelClasses);
  return { base, active };
});
</script>
