<template>
  <div
    class="text-center"
    :style="!checked ? 'padding: 1px' : ''"
  >
    <label
      :for="id"
      class="rounded-md px-4 py-2 cursor-pointer bg-white inline-block"
      :class="{
        'border-2 border-blue-500 text-blue-700': checked,
        'border border-grey-200': !checked
      }"
    >
      <input
        :id="id"
        v-model="checked"
        type="checkbox"
        class="hidden"
      >

      <div class="h-10 w-10 mx-auto flex align-center">
        <SvgVue
          class="h-100 w-100"
          :icon="`icomoon/${icon}`"
          :class="checked ? 'fill-blue-500' : 'fill-gray-500'"
        />
      </div>

      <span>
        <slot name="label">
          {{ label }}
        </slot>
      </span>
    </label>
  </div>
</template>

<script lang="ts" setup>
import { nanoid } from 'nanoid';
import { computed } from 'vue';

import SvgVue from '@/application/components/SvgVue';

type Props = {
  modelValue: number | boolean | null | undefined,
  label: string | null,
  icon: string,
};

const props = withDefaults(defineProps<Props>(), {
  label: null,
});

const emit = defineEmits(['update:modelValue']);

const id = `checkbox-${nanoid()}`;

const checked = computed({
  get() {
    return props.modelValue === null ? false : !!props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>
