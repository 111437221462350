import type { StepActions } from '@/checkup/types/types';

export enum Step {
  GRUNDDATEN = 'Grunddaten',
  BERUF = 'Beruf',
  WOHNEN = 'Wohnen',
  FINANZEN = 'Finanzen',
  GESUNDHEIT = 'Gesundheit',
  PARTNER = 'Partner',
  KINDER = 'Kinder',
  FAHRZEUGE = 'Fahrzeuge',
  FREIZEIT = 'Freizeit',
  ABSCHLUSS = 'Abschluss',
}

// To be independent of the Step enum order we
// use a mapping that associates numbers to steps
export const StepOrder: Step[] = [
  Step.GRUNDDATEN,
  Step.BERUF,
  Step.FINANZEN,
  Step.WOHNEN,
  Step.GESUNDHEIT,
  Step.FAHRZEUGE,
  Step.FREIZEIT,
  Step.PARTNER,
  Step.KINDER,
  Step.ABSCHLUSS,
];

export function getNextStep(step: Step): Step | null {
  const index = StepOrder.indexOf(step);
  const next: number | null = index + 1;

  return next >= StepOrder.length ? null : StepOrder[next];
}

export function getPreviousStep(step: Step): Step | null {
  const index = StepOrder.indexOf(step);
  const previous: number | null = index - 1;

  return previous < 0 ? null : StepOrder[previous];
}

export function getAdjacentSteps(step: Step): StepActions {
  return {
    previous: getPreviousStep(step),
    next: getNextStep(step),
  };
}

export function getStepIndex(step: Step): number {
  return StepOrder.indexOf(step);
}
