import { PushNotifications } from "@capacitor/push-notifications";
import { captureException } from "@sentry/vue";
import { getDeviceName , isIos, isWebPlatform } from "@/application/utils/envInfo";
import { registerPushDevice, unregisterPushDevice } from "@/api/pushRegistration/pushRegistrationApi";
import { getPreference, setPreference } from "@/application/plugins/preferences";
import { routeToTopic } from "@/api/lib/routerRef";
import { debounce } from "lodash-es";

export const unregisterPushNotifications = async () => {
  const deviceToken = await getPreference('PushToken', '');

  if (deviceToken) {
    await unregisterPushDevice({ deviceToken });
  }

  if (isWebPlatform) return;

  await PushNotifications.unregister();
}

export const registerPushNotifications = async () => {
  if (isWebPlatform) return;

  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== 'granted') return;

  await PushNotifications.register();
}

const notifyBackendAboutNewPushRegistration = debounce(
  async (token: string) => {
    console.info('Registration token: ', token);
    const name = await getDeviceName();
    await Promise.all([
      setPreference('PushToken', token),
      registerPushDevice({ token, name, provider:  isIos ? 'apns' : 'fcm' }),
    ]);
  },
  300,
);

export async function addPushNotificationListeners(): Promise<void> {
  if (isWebPlatform) return;

  await PushNotifications.removeAllListeners()

  await PushNotifications.addListener('registration', async (token) => {
    notifyBackendAboutNewPushRegistration(token.value);
  });

  await PushNotifications.addListener('registrationError', (err) => {
    console.error('Registration error: ', err.error);
    captureException(new Error(err.error));
  });

  await PushNotifications.addListener('pushNotificationReceived', notification => {
    console.log('Push notification received: ', notification);
  });

  await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
    if (notification.actionId === 'tap') {
      const { topic } = notification.notification.data as {topic?: string};

      if (topic) {
        routeToTopic(topic);
      }
    }
  });
}
