import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 98.89 98.89"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"49.44\" cy=\"49.44\" r=\"49.44\" fill=\"#fff\"></circle><path fill=\"#fff\" stroke=\"#343a40\" stroke-miterlimit=\"10\" stroke-width=\"4\" d=\"m5.24 43.97 33-16.34 35.33 58.34\"></path><g fill=\"#343a40\"><path stroke=\"#fff\" stroke-miterlimit=\"10\" stroke-width=\"6\" d=\"m66.69 12.27 18.9 12.29-32.86 50.59-18.9-12.29z\"></path><path d=\"m34.78 79.78.25-4.38.25-4.38 3.67 2.41 3.66 2.4-3.91 1.97z\"></path></g>", 3)
  ])))
}
export default { render: render }