<template>
  <form @submit.prevent="submit">
    <template v-if="!successful">
      <div class="flex flex-col gap-3">
        <PasswordConditionsCheck :password="form.password" />
        <DsFormGroup label="Passwort">
          <DsInput
            id="new-password"
            v-model="form.password"
            name="new-password"
            placeholder="Ihr neues Passwort"
            size="lg"
            type="password"
            autocomplete="off"
            immediate
            required
            @focus="resetErrors(['password'])"
          />

          <DsFieldError
            v-for="error in formErrors.password"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </DsFormGroup>
        <DsFormGroup label="Passwort bestätigen">
          <DsInput
            id="new-password-confirm"
            v-model="form.password_confirmation"
            name="new-password-confirm"
            placeholder="Ihr neues Passwort bestätigen"
            size="lg"
            type="password"
            autocomplete="off"
            immediate
            required
            @focus="resetErrors(['password_confirmation'])"
          />
          <DsFieldError
            v-for="error in formErrors.password_confirmation"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </DsFormGroup>
        <DsFormGroup
          label="Login E-Mail Adresse"
          help="
          Nach Änderung der Adresse muss diese zuerst bestätigt werden.
          Nach dem Speichern erhalten Sie eine E-Mail mit einem Link.
          Mit Klick auf den Link ist die Änderung durchgeführt.
          Sofern Sie keine E-Mail erhalten, prüfen Sie bitte Ihren Spam-Ordner."
        >
          <DsInput
            v-model="form.email"
            immediate
            placeholder="Ihre E-Mail-Adresse"
            icon="envelope"
            type="email"
            size="lg"
            required
            class="mb-8"
            @focus="resetErrors(['email'])"
          />
          <DsFieldError
            v-for="error in formErrors.email"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </DsFormGroup>
      </div>
    </template>
    <div
      v-else
      class="flex items-center mb-5"
    >
      <div
        class="bg-green-100 text-green-700 mx-auto shrink-0 flex items-center justify-center h-12 w-12
        rounded-full sm:mx-0 sm:h-12 sm:w-12"
      >
        <DsIcon name="check" />
      </div>
      <span class="ml-4">{{ successMessage }}</span>
    </div>

    <div class="text-right mt-5">
      <div
        v-show="!successful"
        class="flex flex-row items-center"
        :class="passwordHealth !== null ? 'justify-between' : 'justify-end'"
      >
        <span
          v-if="passwordHealth !== null"
          data-testid="password-health"
          :class="getClassificationColor(passwordHealth)"
          class="font-bold"
          v-text="getClassificationDescription(passwordHealth)"
        />

        <DsButton
          :disabled="loading || !wasMutated"
          size="lg"
          type="submit"
        >
          Zugangsdaten ändern
        </DsButton>
      </div>
      <DsButton
        v-show="successful"
        size="lg"
        @click="resetForm"
      >
        Zurück
      </DsButton>
    </div>
  </form>
</template>

<script setup lang="ts">
import { DsButton, DsFieldError, DsFormGroup, DsIcon, DsInput } from '@demvsystems/design-components';
import type { PropType } from 'vue';
import { computed, ref, watch } from 'vue';

import { updateUserLoginData } from '@/api/user/userApi';
import { useFormHelper } from '@/application/composables/formHelper';
import PasswordConditionsCheck from '@/user/components/PasswordConditionsCheck.vue';
import { inject } from '@/application/serviceContainer';
import { passwordHealthServiceKey } from '@/application/serviceContainer/keys';
import { PasswordHealth } from '@/application/utils/security/types';
import { useRequest } from "@/api/lib/integration";
import { ValidationError } from "@/api/lib/errors";

const passwordHealthService = inject(passwordHealthServiceKey);
const props = defineProps({
  loginEmail: { required: true, type: String as PropType<string> },
});

const { loading, form, formErrors, clearSome, assignErrors, getMutated, wasMutated, resetErrors } = useFormHelper({
  email: props.loginEmail,
  password: '',
  password_confirmation: '',
});

const waitingForEmailConfirmation = ref(false);
const waitingForPasswordConfirmation = ref(false);
const successMessage = ref('');
const passwordHealth = ref<PasswordHealth | null>(null);
const successful = computed(() => successMessage.value.length > 0);

watch(() => form.password, async (password) => {
  if (password.length === 0) {
    passwordHealth.value = null;
    return;
  }

  passwordHealthService.setPassword(password);
  await passwordHealthService.estimate();

  passwordHealth.value = passwordHealthService.getClassification();
});

function resetForm() {
  successMessage.value = '';
  resetErrors(['email', 'password', 'password_confirmation']);
}

const { call } = useRequest(updateUserLoginData, (data) => {
  const messages: string[] = [];

  if (waitingForEmailConfirmation.value && data.messages.email) {
    messages.push(data.messages.email);
  }

  if (waitingForPasswordConfirmation.value && data.messages.password) {
    messages.push(data.messages.password);
  }

  successMessage.value = messages.join(' ');
  clearSome(['password', 'password_confirmation']);

}, (error) => {
  if (error instanceof ValidationError) {

    assignErrors(error.errors);

  } else {

    throw error;

  }
})

const submit = async () => {

  loading.value = true;

  try {
    const mutatedValues = getMutated();
    waitingForEmailConfirmation.value = "email" in mutatedValues
    waitingForPasswordConfirmation.value = "password" in mutatedValues

    await call(mutatedValues)

  }  finally {
    loading.value = false;
    waitingForEmailConfirmation.value = false;
    waitingForPasswordConfirmation.value = false;
  }
};

function getClassificationColor(classification: PasswordHealth | null): string {
  switch (classification) {
    case PasswordHealth.VeryWeak:
    case PasswordHealth.Weak:
      return 'text-danger';
    case PasswordHealth.Moderate:
      return 'text-warning';
    case PasswordHealth.Strong:
    case PasswordHealth.VeryStrong:
      return 'text-success';
    default:
      return '';
  }
}

function getClassificationDescription(classification: PasswordHealth | null): string {
  switch (classification) {
    case PasswordHealth.VeryWeak:
      return 'sehr schwach';
    case PasswordHealth.Weak:
      return 'schwach';
    case PasswordHealth.Moderate:
      return 'mittelmäßig';
    case PasswordHealth.Strong:
      return 'stark';
    case PasswordHealth.VeryStrong:
      return 'sehr stark';
    default:
      return '';
  }
}

</script>
