<template>
  <div
    :class="classes"
    :style="{ height: `${height}px` }"
    class="column flex flex-col"
  >
    <h6
      v-if="title"
      v-text="title"
    />
    <div
      v-if="$slots.stack"
      :style="{ height: `${ratio}%` }"
      class="half flex flex-col justify-center items-center"
    >
      <slot name="stack" />
    </div>
    <div
      v-if="$slots.default"
      class="full flex flex-col justify-center items-center"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    classes: {
      type: Array,
      default: () => ['col'],
    },
    title: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 420,
    },
    ratio: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/../css/variables';

.column {
  flex: 1 0 calc(20% - 24px);
  flex-basis: calc(20% - 24px); // IE 11 fix
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  text-align: center;

  .half,
  .full {
    font-size: 14px;
    hyphens: auto;
    padding: 10px 5px;
    text-align: center;
    transition: height 0.3s;
  }

  .half {
    background-color: $light-blue;
    border: 2px dashed $blue;
    border-bottom-width: 0;
    height: 20%;
  }

  .full {
    align-content: center;
    background-color: $blue;
    color: $white;
    flex: 1 0 auto;
  }
}
</style>
