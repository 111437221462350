<template>
  <SpartenWrapper
    :text="subtitle"
    image="images/bedarfsinfo/d-o/header"
    alt="Goldene Balkenwaage mit Laptop im Hintergrund"
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    :versorgungen="data.bedarfsinfo.versorgungen"
    versorgungen-headline="Wie gut sind wir bei unserer D&O-Versicherung versichert?"
    versorgungen-description="D&O"
  >
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h2>Wann leistet die D&O?</h2>
            </div>

            <div class="text-center pb-7">
              <p>
                In Kapitalgesellschaften tragen Entscheidungsträger wie Geschäftsführer
                einer GmbH oder Vorstände und Aufsichtsräte von Aktiengesellschaften
                eine erhebliche Verantwortung. Auch andere sogenannte Organmitglieder,
                wie beispielsweise ehrenamtlich tätige Vorstände eines Vereins, sind
                betroffen und haften unter Umständen für Fehlentscheidungen...
              </p>
            </div>
          </div>
        </div>

        <div class="row justify-center">
          <div class="col-md-10 col-lg-6 flex flex-col justify-around">
            <BulletPointList :items="objectives" />
          </div>
        </div>

        <div class="row justify-center mt-14">
          <div class="col-md-10 col-lg-6">
            <NiceToKnow :bullets="objectivesInfo" />
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h3>Schadenbeispiele - welche Kosten werden erstattet?</h3>
            </div>
          </div>
        </div>

        <div class="row justify-center">
          <div class="col-md-10 flex flex-col justify-around">
            <BulletPointList :items="damageExamples" />
          </div>
        </div>

        <div class="row justify-center mt-14">
          <div class="col-md-10 col-lg-6">
            <NiceToKnow :bullets="damageExamplesInfo" />
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h3>Welche zusätzlichen Leistungen bieten Top-Tarife?</h3>
            </div>
          </div>
        </div>

        <div class="row justify-center">
          <div class="col-lg-10">
            <div class="row">
              <div class="col-md-6 flex flex-col justify-between">
                <BulletPointList :items="additionals1" />
              </div>
              <div class="col-md-6 flex flex-col justify-between">
                <BulletPointList :items="additionals2" />
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h3>Wann habe ich keinen Versicherungsschutz?</h3>
            </div>
          </div>
        </div>

        <div class="row justify-center">
          <div class="col-md-10 col-lg-8 flex flex-col justify-between">
            <BulletPointList :items="noCoverage" />
          </div>
        </div>

        <div class="row mt-14">
          <div class="col-12 col-md-6">
            <NiceToKnow :bullets="noCoverageInfo" />
          </div>

          <div class="col-12 col-md-6">
            <NiceToKnow :bullets="exclusionsInfo" />
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';

interface Props {
  data: Bedarfsinfo
}

defineProps<Props>();

const subtitle = `
  In Kapitalgesellschaften haften Entscheidungsträger unter
  bestimmten Umständen für Fehlentscheidungen mit dem Privatvermögen
  in unbegrenzter Höhe. Eine D&O nimmt Ihnen das finanzielle Risiko
  ab.`;

const objectives = [
  { text: 'in unbegrenzter Höhe mit dem Privatvermögen.' },
  { text: 'gesamtschuldnerisch, auch bei Pflichtverletzungen anderer Organmitglieder.' },
  { text: 'sowohl gegenüber Gesellschaftern, als auch gegenüber Kunden.' },
];

const objectivesInfo = [
  {
    title: 'Tipp',
    text: `Sollte der Betrieb oder der Verein keine Unternehmens-D&O für die haftenden Mitarbeiter anbieten, kann auch
            eine persönliche D&O abgeschlossen werden.`,
  },
];

const damageExamples = [
  {
    title: 'Innenhaftung',
    text: `Ein Vorstandsmitglied genehmigt die Installation einer neuen IT-Anlage. Später zeigt sich, dass die Anlage
            den Firmenzwecken nicht genügt. Teure Nachbesserungen sind notwendig, während der Betrieb stillsteht.`,
  },
  {
    title: 'Gesamtschuldnerische Haftung',
    text: `Der Mitarbeiter einer Baufirma eröffnet eine neue Filiale, ohne zuvor die Wirtschaftlichkeit zu prüfen. Die
            Geschäftsführerin haftet mit ihrem Kollegen gesamtschuldnerisch, weil sie das Handeln des Mitarbeiters nicht
            hinreichend kontrolliert hat.`,
  },
  {
    title: 'Außenhaftung (Ansprüche Dritter)',
    text: `Ein Unternehmen kann einen Kredit nicht zurückzahlen. Der Geschäftsführer hat die Bank vor der Vergabe des
            Kredits nicht ausreichend über die Finanzen des Unternehmens aufgeklärt. Nun verlangt die Bank vom
            Geschäftsführer die Zahlung des Kredits plus Zinsen.`,
  },
];

const damageExamplesInfo = [
  {
    title: 'Info:',
    text: `Bei Streitigkeiten im Innenverhältnis besteht eine Beweislastumkehr. Der Beschuldigte muss beweisen, dass
            keine Pflichtverletzung begangen wurde.`,
  },
];

const additionals1 = [
  { text: 'Gehaltsfortzahlung bis zur festgelegten Obergrenze.' },
  { text: 'Unterstützung in Standes-, Disziplinar- und Aufsichtsverfahren.' },
  { text: 'Kostenübernahme bei außergerichtlichen Streitigkeiten.' },
];

const additionals2 = [
  { text: `Rückwärtsdeckung (in der Vergangenheit entstandene Schäden, die zum Zeitpunkt der Antragstellung unbekannt
            sind).` },
  { text: 'Nachmeldefristen.' },
  { text: 'Weltweiter Versicherungsschutz.' },
];

const noCoverage = [
  { text: `Für den vorgeschriebenen Pflichtselbstbehalt von AG-Vorständen. Diese müssen das Selbstbehaltrisiko selbst
            abschließen.` },
  { text: 'Bei vorsätzlicher und wissentlicher Pflichtverletzung.' },
  { text: `Bei Ansprüchen wegen Vertragsstrafen, Bußgeldern und Entschädigungen aufgrund einer strafrechtlichen
            Verurteilung.` },
];

const noCoverageInfo = [
  {
    title: 'Info:',
    text: 'Unternehmen in der Rechtsform einer GbR können keinen Versicherungsschutz erhalten.',
  },
];

const exclusionsInfo = [
  {
    title: 'Info:',
    text: `Diese Aufzählung beinhaltet wesentliche Leistungsausschlüsse und ist nicht abschließend. Sofern einzelne
            Leistungspunkte für Sie wichtig sind, prüfen wir gerne Sondertarife.`,
  },
];
</script>
