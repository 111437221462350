<template>
  <SpartenWrapper
    image="images/bedarfsinfo/auslandskv/auslandskrankenversicherung-titelbild"
    :text="subtitle"
    :versorgungen="data.bedarfsinfo.versorgungen"
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    versorgungen-headline="Wie bin ich im Ausland versichert?"
    versorgungen-description="Auslandskrankenversicherung"
  >
    <Privat v-if="data.isPrivat" />

    <Gesetzlich v-else />

    <JumboImage image="images/bedarfsinfo/auslandskv/mutter-kind" />

    <section class="section">
      <div class="container">
        <div class="row mb-5">
          <div class="col">
            <h2 class="pb-5">
              Worauf muss ich bei dieser Versicherung achten?
            </h2>
          </div>
        </div>

        <div class="row mb-5 items-center">
          <div class="col-md-6">
            <h5 class="font-bold">
              Geltungsbereich
            </h5>
            <p>
              Die Versicherung muss für das Urlaubsland gelten in das Sie reisen.
              Aufgrund der hohen Behandlungskosten sind Tarife, die Versicherungs-schutz
              für die USA und Kanada bieten, häufig teurer.
            </p>
            <h5 class="font-bold">
              Vorerkrankungen
            </h5>
            <p>
              Bei Antragstellung sind üblicherweise keine Gesundheitsfragen auszufüllen.
              Vorerkrankungen, die zu Reisebeginn die Reisefähigkeit nicht einschränken,
              sollten mitversichert sein. Chronisch kranke Personen können zur
              Sicherheit vor der Reise ein Attest einholen, das die Reisefähigkeit
              bestätigt.
            </p>
            <h5 class="font-bold">
              Reisedauer
            </h5>
            <p>
              Die meisten Tarife bieten Versicherungsschutz für maximal 42, 56 oder 70
              zusammenhängende Reisetage. Kalkulieren Sie einen zeitlichen Puffer ein,
              da sich Reisen aufgrund einer Erkrankung unerwartet verlängern können.
            </p>
            <h5 class="font-bold">
              Kostenübernahme
            </h5>
            <p>
              Wichtig ist die Kostenübernahme im ambulanten und stationären Bereich. Im
              dentalen Bereich sollten mindestens schmerzstillende Zahnbehandlungen und
              Reparaturen des Zahnersatzes mitversichert sein. Zudem ist es wichtig,
              dass der medizinisch notwendige Rücktransport nach Deutschland
              mitversichert ist.
            </p>
          </div>
          <div class="col-md-6 text-center">
            <img
              class="w-full px-14 sm:px-0"
              src="../../../../../../assets/images/bedarfsinfo/auslandskv/familie.jpg"
              style="max-width: 450px;"
              alt="Eine schwangere Frau mit Mann und Tochter"
            >
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <NiceToKnow :bullets="travelType" />
          </div>
          <div class="col-md-6">
            <NiceToKnow :bullets="transport" />
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import JumboImage from '@/bedarf/components/JumboImage.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import Gesetzlich from '@/bedarf/components/gesundheitsvorsorge/ausland/Gesetzlich.vue';
import Privat from '@/bedarf/components/gesundheitsvorsorge/ausland/Privat.vue';
import type { AuslandsKVInfo } from '@/bedarf/types/bedarfsinfo';

interface Props {
  data: AuslandsKVInfo
}

defineProps<Props>();

const subtitle = `
  Sind Sie privat oder beruflich im Ausland unterwegs und erkranken Sie, so sind die Behandlungskosten im Regelfall
  durch Sie selber zu tragen. Mit einer Auslandskrankenversicherung können Sie sich kostengünstig gegen dieses
  finanzielle Risiko schützen.
`;

const travelType = [
  {
    title: 'Hinweis',
    text: 'Gute Tarife versichern sowohl private, als auch beruflich veranlasste Reisen.',
  },
];

const transport = [
  {
    title: 'Hinweis',
    text: `Gute Tarife versichern statt des medizinisch notwendigen Rücktransportes auch den medizinisch sinnvollen
            Rücktransport.`,
  },
];
</script>
