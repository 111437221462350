export const props = {
  /**
   * Changes the display mode to be a row instad of columns for every item.
   */
  horizontalOptions: {
    type: Boolean,
    default: false,
  },

  /**
   * Changes the display mode to be a row instad of columns for every whole quiz.
   */
  horizontalQuiz: {
    type: Boolean,
    default: false,
  },

  /**
   * Allows multiple selection of items.
   */
  multiple: {
    type: Boolean,
    default: false,
  },

  /**
   * An array with valid objects.
   * Each item can either be a string or an object.
   */
  options: {
    type: Array,
    required: true,
  },
};
