import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 62 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M9.95 23.74H3.8c-.35 0-.7-.28-.7-.7s.28-.7.7-.7h6.14a.7.7 0 0 1 0 1.4zm52.27-1.97c0 .99-.21 1.9-.56 2.75l-.28.78a3.32 3.32 0 0 1-3.04 2.04h-3.88a4.71 4.71 0 0 1-4.65 4.02 4.77 4.77 0 0 1-4.66-4.02H20.32a4.71 4.71 0 0 1-4.66 4.02 4.77 4.77 0 0 1-4.65-4.02c-.07 0-.14.07-.22.07H8.11a.7.7 0 0 1 0-1.4h2.68c.08 0 .15 0 .22.06a4.66 4.66 0 0 1 4.65-4.02c2.33 0 4.3 1.77 4.66 4.02h24.9a4.71 4.71 0 0 1 4.66-4.02c2.33 0 4.3 1.77 4.65 4.02h3.88c.78 0 1.41-.42 1.77-1.13l.28-.77c.28-.7.42-1.48.42-2.26v-2.68c0-.78-.56-1.41-1.34-1.48l-10.37-1.13a.53.53 0 0 1-.5-.35l-4.37-7.2c0-.07-.07-.07-.07-.14a2.12 2.12 0 0 0-1.97-1.41h-7.62a.78.78 0 0 0-.78.77v14.25c0 .15-.07.22-.07.36.07.07.07.2.07.35a.7.7 0 0 1-.7.7H21.8c-.35 0-.7-.28-.7-.7s.28-.7.7-.7h10.65V8.37c0-1.2 1-2.2 2.19-2.2h7.55c1.4 0 2.75.93 3.24 2.27l4.17 6.84 10.01.99a2.84 2.84 0 0 1 2.61 2.82zm-43.17 4.87a3.31 3.31 0 1 0-6.63-.01 3.31 3.31 0 0 0 6.63 0zm34.14 0a3.31 3.31 0 1 0-6.62-.01 3.31 3.31 0 0 0 6.62 0zM36.61 16.19c-.7 0-1.27-.56-1.27-1.27V9.64c0-.35.29-.7.7-.7h4.74c1.12 0 2.11.7 2.46 1.76l2.61 4.52c.15.21.15.5 0 .7s-.35.36-.63.36h-8.6v-.08zm.15-1.34h7.26l-2.05-3.52c0-.07-.07-.07-.07-.14a1.2 1.2 0 0 0-1.12-.85h-4.03v4.51zm-6.92 5.72H.71a.7.7 0 0 1-.71-.7V1.23c0-.35.28-.7.7-.7h29.21c.35 0 .7.28.7.7v18.62c-.06.36-.35.7-.77.7zm-.63-18.63H1.4v17.29h27.8V1.94z" }, null, -1)
  ])))
}
export default { render: render }