<template>
  <SpartenWrapper
    :text="subtitle"
    image="images/bedarfsinfo/zahnzusatzversicherung/lachende-frau"
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    :versorgungen="data.bedarfsinfo.versorgungen"
  >
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2">
            <h2 class="text-center mb-7">
              Welche Zuzahlungen erwarten mich beim Zahnarzt?
            </h2>

            <Table :value="data.bedarfsinfo.erstattungssatz" />

            <h3 class="mb-5 text-center">
              Brauche ich wirklich eine Zahnzusatzversicherung?
            </h3>

            <BulletPoint
              v-for="text in isItNeeded"
              :key="text"
              :item="{ text }"
            />
          </div>

          <div class="col-12 col-xl-6 offset-xl-3">
            <NiceToKnow
              classes="mt-7 mb-0"
              :bullets="niceToKnowNeed"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section bg-dark text-light text-center">
      <Teeth />
    </section>

    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <h4 class="mb-7">
              Warum ist es wichtig, dass die Erstattung auch ohne Vorleistung
              der gesetzlichen Krankenversicherung erfolgt?
            </h4>
            <p>
              Die Leistungen der gesetzlichen Krankenversicherung sind nicht garantiert.
              In den 1990er Jahren wurden bereits zweimal bestimmte Leistungen für Zahnersatz gestrichen
              (wurde nach einigen Jahren rückgängig gemacht). Haben Sie einen Tarif, der nur leistet,
              sofern die gesetzliche Krankenversicherung einen Teil erstattet hat, laufen Sie Gefahr,
              dass bei einer weiteren Reform des Gesundheitssystems die Zahnzusatzversicherung
              nicht oder nur noch eingeschränkt leistet.
            </p>

            <NiceToKnow
              classes="mt-7 flex-col sm:flex-row"
              :bullets="niceToKnowErstattung"
            />
          </div>
          <div class="col-lg-6 mt-5 lg:mt-0">
            <h4 class="mb-7">
              Wie entwickelt sich mein Beitrag?
            </h4>
            <p>
              Mit dem Alter werden zunehmend mehr Zahnbehandlungen durchgeführt.
              Die meisten Versicherer erhöhen daher zu vorab festgelegten Zeitpunkten die Beiträge.
              Sie zahlen somit nur den Anteil, der für den Versicherungsschutz wirklich notwendig ist.
              Wünschen Sie sich hingegen konstante Beiträge, kommen Tarife, die Altersrückstellungen
              bilden, in Frage. Diese sind deutlich teurer und legen Teile der Beiträge für das Alter zurück.
            </p>

            <SvgVue icon="bedarfsinfo/beitrag.svg" />
          </div>
        </div>
      </div>
    </section>

    <JumboImage image="images/bedarfsinfo/zahnzusatzversicherung/lachende-maenner" />

    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 offset-lg-2">
            <h3 class="text-center mb-7">
              Kann ich eine Zahnzusatzversicherung abschließen,
              obwohl bereits eine Behandlung angeraten ist?
            </h3>
            <p>
              Um den Beitrag für die Versichertengemeinschaft stabil zu halten,
              begrenzen die Versicherungen die Leistungen in der Anfangszeit.
              So können Leistungen erstmals nach einer Wartezeit von üblicherweise drei Monaten
              für Zahnbehandlungen und acht Monaten für Zahnersatz in Anspruch genommen werden.
            </p>
            <p>
              Zudem werden die Leistungen für Zahnersatz in den ersten Jahren
              über die sogenannte Zahnstaffel begrenzt.
            </p>
            <p>
              Im Antrag müssen Sie zudem über Ihren Gesundheitszustand Auskunft geben.
              Kunden mit laufender Behandlung oder schlechter Prognose
              werden durch die Versicherer meist abgelehnt.
            </p>
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import BulletPoint from '@/bedarf/components/BulletPoint.vue';
import JumboImage from '@/bedarf/components/JumboImage.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import Table from '@/bedarf/components/gesundheitsvorsorge/zahnzusatz/Table.vue';
import Teeth from '@/bedarf/components/gesundheitsvorsorge/zahnzusatz/Teeth.vue';
import type { ZahnzusatzInfo } from '@/bedarf/types/bedarfsinfo';
import SvgVue from "@/application/components/SvgVue";

interface Props {
  data: ZahnzusatzInfo
}

defineProps<Props>();

const subtitle = `
  Die Leistungen der gesetzlichen Krankenversicherung sind im Zuge zahlreicher Reformen immer stärker gekürzt worden.
  Insbesondere bei Zahnersatz lauern hohe Zuzahlungen.
`;

const isItNeeded = [
  'Über die gesetzliche Krankenversicherung erhalten Sie einen festen Zuschuss, der von dem Befund abhängt.',
  `Der Zuschuss beträgt 60 % der sogenannten Regelversorgung und steigert sich bei regelmäßiger Vorsorge (Bonusheft) auf
   maximal 75 %.`,
  'Die Regelversorgung muss funktional und wirtschaftlich sein.',
  'Der Eigenanteil hängt somit davon ab, für welche Art von Zahnersatz Sie sich entscheiden.',
];

const niceToKnowNeed = [
  {
    title: 'Fazit:',
    text: `Über die gesetzliche Krankenversicherung erhalten Sie nur einen Grundschutz und gerade bei Zahnersatz sind
            hohe Zuzahlungen weit oberhalb von 60 % des Rechnungsbetrages die Regel.`,
  },
];

const niceToKnowErstattung = [
  {
    title: 'Hinweis:',
    text: `Nur eine Zahnzusatzversicherung, die auch <b>ohne Vorleistung der gesetzlichen Krankenversicherung</b>
            Rechnungen erstattet, garantiert dauerhaft die Leistungen.`,
  },
];
</script>
