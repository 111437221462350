import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 100 100"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"50\" cy=\"50\" r=\"50\" fill=\"#fff\"></circle><g fill=\"#343A40\" fill-rule=\"evenodd\" clip-path=\"url(#a)\" clip-rule=\"evenodd\"><path d=\"M42.43 48.24H27.81a3.32 3.32 0 0 0-3.35-3.35V29.41a3.33 3.33 0 0 0 3.35-3.35h14.91v-2.49H21.88v27.15H42zm16.73-22.18h15a3.25 3.25 0 0 0 3.25 3.35v15.48a3.24 3.24 0 0 0-3.25 3.35h-15v2.48H80V23.57H59.16z\"></path><path d=\"M32.49 37.15a3.74 3.74 0 0 0 3.73 3.73A3.68 3.68 0 0 0 40 37.15a3.74 3.74 0 0 0-3.73-3.73 3.8 3.8 0 0 0-3.73 3.73zm36.99 0a3.78 3.78 0 0 0-7.55 0 3.78 3.78 0 0 0 7.55 0M44.15 23h13.67v28.29H44.15zm42.54 35.84c-.48-1.24-2.49-2-4.78-1.81-.62.11-1.23.27-1.82.48-2.77.76-17 5.73-17.2 5.83l-.29.09.19.19c1.63 1.34 2.39 2.78 2 3.92-.48 1.82-2.77 2.87-6.69 3.16a48 48 0 0 1-12.71-1c.6-.02 1.21.02 1.81.1 3.63 0 7.08 0 9.75-.19 4.11-.39 6-1.34 6.31-2.87.1-1.05-.48-1.91-1.91-2.58-1.62-.77-4.78-1.15-7.93-1.72a46 46 0 0 1-5.45-1c-5-1.34-10.14-2.58-16-2.2-1 .19-1.91.29-2.77.48A121 121 0 0 0 15.57 63l-.57.05 6.88 9.85c1.23-.29 2.47-.48 3.73-.58 1.53-.16 3.07-.13 4.59.1 2 .29 6.69 1.24 11.28 2.2s9.17 1.91 10.89 2.1c.99.18 2 .2 3 .09 2.2-.19 4.59-1.43 6.88-2.58.67-.47 1.44-.86 2-1.24L84.68 62c2.3-1.34 2.3-2.58 2-3.16z\"></path><path d=\"M50.27 41.73v-4.11A4.63 4.63 0 0 1 47.5 36a4.4 4.4 0 0 1-1-2.68 3.94 3.94 0 0 1 3.73-3.91v-1h1.47v1c.91.07 1.78.42 2.49 1a4.3 4.3 0 0 1 1.15 2.38l-2.49.29c-.19-.76-.48-1.34-1.15-1.53v3.73c1.2.34 2.33.93 3.3 1.72a4.86 4.86 0 0 1-.29 5.63 5.1 5.1 0 0 1-3 1.44v1.81h-1.44v-1.76a4.25 4.25 0 0 1-2.68-1.33 4.6 4.6 0 0 1-1.43-2.87l2.58-.29c.08.5.28.96.58 1.37.28.32.62.58 1 .76z\"></path><path d=\"M51.7 41.83c.46-.1.87-.37 1.15-.76.31-.34.48-.79.48-1.25.02-.4-.1-.8-.33-1.14a3 3 0 0 0-1.3-.68zm-1.43-10.32c-.37.16-.71.39-1 .67-.2.31-.29.68-.27 1.05-.01.34.09.67.29.95.24.34.6.58 1 .67z\"></path></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M15 23h71.82v53.88H15z\"></path></clipPath></defs>", 3)
  ])))
}
export default { render: render }