<template>
  <div
    class="items-center"
    :class="{'row': !isMobile}"
  >
    <div class="col-lg-7">
      <slot
        v-if="isMobile"
        name="bars-mobile"
      />
      <slot
        v-else
        name="bars-desktop"
      />
    </div>

    <div class="col-lg-5 self-center">
      <div class="row">
        <slot name="controls" />
      </div>
    </div>

    <slot name="rates" />
  </div>
</template>

<script setup lang="ts">
import { useDetectMobile } from "@/application/composables/detectMobile";

const { isMobile } = useDetectMobile();
</script>
