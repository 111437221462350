<template>
  <section class="section pt-0">
    <div class="container">
      <div class="row justify-center">
        <div
          v-for="({title, options, id}, index) in tarifQuizOptions.quiz"
          :key="index"
          class="row flex col-lg-10 col-xl-8 border-gray-200 py-2.5"
          :class="[
            horizontal ? 'md:justify-center' : 'items-center',
            (index !== tarifQuizOptions.quiz.length - 1) ? 'border-b' : ''
          ]"
        >
          <OptionItem
            icon="icomoon/check"
            icon-class="check"
            class="flex items-center"
            :checkbox="false"
            :model-value="hasOptionValueSelected(options)
              || questionManuallyUnfolded === index
              || (id !== undefined && id === selected)"
            @update:model-value="selectQuestion(index, id)"
          >
            <div
              class="text-center md:text-left"
              :class="[horizontal ? 'col-md-11' : 'col-md-6']"
            >
              {{ title }}
            </div>
          </OptionItem>

          <OptionGroup
            v-if="options && options.length > 0
              && (hasOptionValueSelected(options) || questionManuallyUnfolded === index)"
            v-model="selected"
            :options="options"
            class="mt-5"
            :class="{'col-md-6 md:mt-0': !horizontal, 'col-md-11 pl-md-4': horizontal}"
          />

          <div
            v-if="selected !== null
              && tarifAnswers[selected] !== null
              && (hasOptionValueSelected(options) || id === selected)"
            class="flex flex-col w-full items-center gap-2 my-5 text-center"
          >
            <span class="font-semibold text-sm mt-3">
              Auf Grundlage Ihrer Antwort eignet sich:
            </span>
            <DsButton
              href="#"
              variant="secondary"
              icon="arrow-right"
              @click.prevent="$emit('handleScrolling', tarifAnswers[selected])"
            >
              {{ tarifAnswers[selected] }}
            </DsButton>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

import OptionGroup from '@/application/components/Option/OptionGroup.vue';
import OptionItem from '@/application/components/Option/OptionItem.vue';
import { useAltersvorsorgeStore } from '@/bedarf/components/altersvorsorge/altersvorsorge/store';
import type { AvTarifQuizOption } from '@/bedarf/types/quizes';

import tarifQuizOptions from './tarifQuizOptions.json';
import { DsButton } from '@demvsystems/design-components';

interface Props {
  tarifGarantie: number | null,
  horizontal: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  tarifGarantie: null,
});

defineEmits(['handleScrolling']);

const tarifAnswers = {
  0: 'Klassik Rente neu',
  1: 'Hybrid Rente neu',
  2: 'Index-Hybrid-Rente',
  3: 'Garantiefonds-Rente',
  4: 'Externe dynamische Hybrid Rente',
  5: 'Interne dynamische Hybrid Rente',
  6: 'Bank-Garantierente',
  7: 'With-Profit-Rente',
  8: 'Variable Annuity',
  9: 'Fondsgebundene Rente ohne Garantie',
};

const { altersvorsorge } = useAltersvorsorgeStore();

altersvorsorge.tarifGarantie = props.tarifGarantie;

const selected = computed({
  get: () => altersvorsorge.tarifGarantie,
  set: (value) => { altersvorsorge.tarifGarantie = value; },
});

const questionManuallyUnfolded = ref<null | number>(null);

const selectQuestion = (index: number, id: number | undefined) => {
  if (questionManuallyUnfolded.value === index) {
    return;
  }

  questionManuallyUnfolded.value = index;

  // set the id when its a question without additional options
  selected.value = id ?? null;
};

const hasOptionValueSelected = (options: AvTarifQuizOption[] | undefined) => {
  if (selected.value === null || options === undefined) {
    return false;
  }

  return options.map(({ modelValue }) => modelValue).includes(selected.value);
};
</script>
