import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { Finanzmanager, MeinBedarf } from '@/application/router/types/types';
import { useUnreadHintStore } from '@/application/store/unreadHintStore';
import { useBaseDataStore } from '@/user/store/baseDataStore';
import { UnreadHintType } from "@/api/unreadHint/types";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import type { NavigationItem } from "@/application/menu/composables/types";

export function useMenuItems(): { items: ComputedRef<NavigationItem[]> } {
  const { isCompany } = storeToRefs(useBaseDataStore());
  const unreadHints = useUnreadHintStore();

  const items = computed(() => [
    {
      id: Finanzmanager.CHECKUP,
      title: 'Check-up',
      to: () => ({ name: Finanzmanager.CHECKUP }),
      unreadHints: null,
    },
    {
      id: Finanzmanager.BEDARF,
      title: 'Bedarf',
      to: () => {
        const route = useRoute();
        const { bedarfHash } = route.params;
        if (bedarfHash) {
          return { name: MeinBedarf.UEBERSICHT, params: { bedarfHash } };

        }
        return { name: Finanzmanager.BEDARF };
      },
      unreadHints: null,
    },
    {
      id: Finanzmanager.VERTRAEGE,
      title: 'Verträge',
      to: () => ({ name: Finanzmanager.VERTRAEGE }),
      unreadHints: unreadHints[UnreadHintType.Contract].size
    },
    {
      id: Finanzmanager.DOKUMENTE,
      title: 'Dokumente',
      to: () => ({ name: Finanzmanager.DOKUMENTE }),
      unreadHints: unreadHints[UnreadHintType.Document].size
    },
  ].filter(({ title }) => !(isCompany.value && title === 'Check-up')));

  return { items };
}
