<template>
  <div>
    <Beamter
      v-if="isBeamter"
      :data="data"
      :common-props="commonProps"
    />
    <Freiberufler
      v-else-if="isFreierBeruf"
      :data="data"
      :common-props="commonProps"
    />
    <Angestellter
      v-else
      :data="data"
      :common-props="commonProps"
    />
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

import Angestellter from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/views/Angestellter.vue';
import Beamter from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/views/Beamter.vue';
import type { CommonProps } from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/views/chartProps';
import Freiberufler from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/views/Freiberufler.vue';
import type { Altersvorsorge } from '@/bedarf/components/altersvorsorge/altersvorsorge/types';

export default defineComponent({
  components: {
    Beamter,
    Freiberufler,
    Angestellter,
  },

  props: {
    data: {
      type: Object as PropType<Altersvorsorge>,
      required: true,
    },
    commonProps: {
      type: Object as PropType<CommonProps>,
      required: true,
    },
    isBeamter: {
      type: Boolean,
      default: false,
    },
    isFreierBeruf: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
