import type { DataType } from '@aparajita/capacitor-secure-storage';
import { SecureStorage } from '@aparajita/capacitor-secure-storage'
import { captureException } from "@sentry/vue";
import { KeychainAccess } from "@aparajita/capacitor-secure-storage/dist/esm/definitions";

export async function configureSecureStorage(): Promise<void> {
  await Promise.all([
    SecureStorage.setDefaultKeychainAccess(KeychainAccess.afterFirstUnlockThisDeviceOnly),
    SecureStorage.setSynchronize(false),
  ])
}

export const SecretNames = {
  AccessToken: 'accessToken',
  ClientSecret: 'clientSecret',
  UserEmailAndPassword: 'userEmailAndPassword',
} as const;

type Secrets = typeof SecretNames[keyof typeof SecretNames];

const cache = new Map<string, DataType|null>();

export async function setSecret(name: Secrets, value: DataType): Promise<void> {
  return new Promise((resolve) => {
    SecureStorage.set(name, value)
      .then(() => {
        cache.set(name, value);
        resolve();
      })
      .catch((e) => {
        captureException(e);
        resolve();
      });
  })
}

export async function getSecret(name: Secrets): Promise<DataType|null> {
  return new Promise((resolve) => {
    if (cache.has(name)) {
      resolve(cache.get(name) ?? null);
      return;
    }

    SecureStorage.get(name)
      .then((value) => {
        cache.set(name, value);
        resolve(value);
      })
      .catch(async (e) => {
        captureException(e);
        await removeSecret(name);
        resolve(null);
      });
  })
}

export async function removeSecret(name: Secrets): Promise<boolean> {
  return new Promise((resolve) => {
    SecureStorage.remove(name)
      .then((e) => {
        cache.delete(name);
        resolve(e);
      })
      .catch((e) => {
        captureException(e);
        resolve(false);
      });
  })
}
