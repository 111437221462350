<template>
  <div>
    <section>
      <div class="row flex justify-center">
        <div class="col-md-12">
          <h2 class="mb-3 checkup-h2">
            Ich habe eine schwere Krankheit
            <DsButton
              class="ml-2"
              variant="outline"
              icon="info"
              size="sm"
              title="Weitere Infos anzeigen"
              @click="openModal"
            />
          </h2>
          <SchwereKrankheitenModal
            :show="showModal"
            @close-modal="closeModal"
            @open-modal="openModal"
          />
          <DsFieldError
            v-for="error in formErrors.hatSchwereKrankheit"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </div>
      </div>
      <div
        id="OptionHasIllness"
        class="OptionIconGroup"
      >
        <OptionGroup
          has-icons
          :options="[
            {label: 'Ja', labelClasses: `${form.hatSchwereKrankheit === 1 ? 'text-green-700' : 'flex flex-col'}`, value: 1, icon: 'icomoon/check', iconClass: `check ${form.hatSchwereKrankheit === 1 ? 'fill-green-700' : ''}`},
            {label: 'Nein', labelClasses: `${form.hatSchwereKrankheit === 0 ? 'text-red-700' : 'flex flex-col'}`, value: 0, icon: 'icomoon/close', iconClass: `close ${form.hatSchwereKrankheit === 0 ? 'fill-red-700' : ''}`}
          ]"
          :value="form.hatSchwereKrankheit"
          option-width-classes="w-1/3 sm:w-1/4 md:w-1/6"
          @change="set('hatSchwereKrankheit', $event)"
        />
      </div>
      <div v-if="form.hatSchwereKrankheit == 1">
        <div class="row flex justify-center mb-7">
          <div class="col-md-6 col-sm-10 col-xs-12">
            <DsFormGroup label="Diagnose">
              <DsTextarea
                v-model="form.diagnose"
                placeholder="Bitte geben Sie hier Ihre Diagnose ein."
                size="lg"
                :rows="8"
              />
              <DsFieldError
                v-for="error in formErrors.diagnose"
                :key="error"
              >
                {{ error }}
              </DsFieldError>
            </DsFormGroup>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="row flex justify-center">
        <div class="col-md-12">
          <h2 class="mt-7 mb-3 checkup-h2">
            Ich hatte einen schweren Unfall
          </h2>
          <DsFieldError
            v-for="error in formErrors.hatteUnfall"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </div>
      </div>
      <OptionGroup
        has-icons
        :options="[
          {label: 'Ja', labelClasses: `${form.hatteUnfall === 1 ? 'text-green-700' : 'flex flex-col'}`, value: 1, icon: 'icomoon/check', iconClass: `check ${form.hatteUnfall === 1 ? 'fill-green-700' : ''}`},
          {label: 'Nein', labelClasses: `${form.hatteUnfall === 0 ? 'text-red-700' : 'flex flex-col'}`, value: 0, icon: 'icomoon/close', iconClass: `close ${form.hatteUnfall === 0 ? 'fill-red-700' : ''}`}
        ]"
        :value="form.hatteUnfall"
        option-width-classes="w-1/3 sm:w-1/4 md:w-1/6"
        @change="set('hatteUnfall', $event)"
      />
    </section>
  </div>
</template>

<script setup lang="ts">
import { DsButton, DsFieldError, DsFormGroup, DsTextarea } from '@demvsystems/design-components';
import { ref } from 'vue';

import OptionGroup from '@/checkup/components/OptionGroup.vue';
import SchwereKrankheitenModal from '@/checkup/components/steps/SchwereKrankheitenModal.vue';
import { useStep } from '@/checkup/state';
import { Step } from '@/checkup/utils/steps';

const { form, formErrors, set } = useStep(Step.GESUNDHEIT);

const showModal = ref(false);
const openModal = () => {
  showModal.value = true;
};
const closeModal = () => {
  showModal.value = false;
};
</script>
