import { ref } from 'vue';
import { getLocalStorage, setLocalStorage } from '@/application/utils/localStorage';
import { storeToRefs } from 'pinia';
import { useBaseDataStore } from '@/user/store/baseDataStore';

const TTL = 60 * 60 * 24; // 1 day
const LS_KEY = 'first-login';

export function useFirstLogin() {

  const { isFirstLogin } = storeToRefs(useBaseDataStore());

  const show = ref(isFirstLogin.value && getLocalStorage(LS_KEY) === null);

  const close = () => {
    show.value = false;
    setLocalStorage(LS_KEY, false, TTL);
  };

  return { show, close };
}
