<script setup lang="ts">
import { DsModal } from '@demvsystems/design-components';
import type { BankAccount } from '@/api/bankAccount/types';
import ModalWrapper from '@/application/components/ModalWrapper.vue';
import EditBankAccount from "@/user/components/EditBankAccount.vue";

interface Props {
  show: boolean,
  bankAccount: BankAccount
}

defineProps<Props>();
const emit = defineEmits(['close']);
</script>

<template>
  <DsModal
    :show="show"
    title="Bankverbindung bearbeiten"
    hide-buttons
    @cancel="emit('close')"
  >
    <ModalWrapper>
      <EditBankAccount v-if="show" :bank-account="bankAccount" @close="emit('close')" />
    </ModalWrapper>
  </DsModal>
</template>
