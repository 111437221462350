<template>
  <section class="flex flex-col gap-5 border-x border-b rounded-b mb-7 w-full p-4 z-[1] bg-white">
    <section class="relative">
      <h2>
        Immobilie {{ Number(indexForTitle) + 1 }}
      </h2>
      <DsButton
        icon="xmark-large"
        icon-variant="regular"
        variant="clear"
        title="entfernen"
        class="absolute top-0 right-0"
        @click="$emit('delete', index)"
      />
    </section>

    <section>
      <OptionGroup
        has-images
        :options="immobilienTypen"
        :value="form.immobilienTyp"
        option-width-classes="w-1/3"
        @change="set('immobilienTyp', $event)"
      />

      <DsRadioGroup
        :model-value="form.fremdgenutzt === null ? '' : `${form.fremdgenutzt}`"
        :variant="isMobile ? 'button-col' : 'button'"
        class="w-full bg-white"
        @update:model-value="set('fremdgenutzt', $event === '' ? null : Number($event))"
      >
        <DsRadioButton
          v-for="{label, value} in [
            {label: 'Eigengenutzt', value: '0', smallTile: true },
            {label: 'Fremdgenutzt', value: '1', smallTile: true },
          ]"
          :key="label"
          :value="value"
          class="w-full"
          style="height: 48px"
        >
          {{ label }}
        </DsRadioButton>
      </DsRadioGroup>

      <div class="md:mb-5 mt-10 flex flex-row gap-5 justify-center">
        <FormCheckbox
          label="Heizöltank"
          :model-value="form.heizoeltank"
          icon="oiltank"
          @update:model-value="set('heizoeltank', $event ? 1 : 0)"
        />
        <FormCheckbox
          label="Photovoltaikanlage"
          :model-value="form.photovoltaikanlage"
          icon="solar"
          @update:model-value="set('photovoltaikanlage', $event ? 1 : 0)"
        />
      </div>
    </section>
  </section>
</template>

<script setup lang="ts">
import { DsButton, DsRadioButton, DsRadioGroup } from '@demvsystems/design-components';

import { useDetectMobile } from '@/application/composables/detectMobile';
import FormCheckbox from '@/checkup/components/FormCheckbox.vue';
import OptionGroup from '@/checkup/components/OptionGroup.vue';
import { ImmobilienTyp } from '@/checkup/utils/demvWerte';
import { demvWerte } from '@/checkup/state';
import type { Immobilie } from '@/checkup/types/types';

import HausImage from '../../../../../assets/images/immobilientyp-1.jpg';
import WohnungImage from '../../../../../assets/images/immobilientyp-2.jpg';
import GrundstueckImage from '../../../../../assets/images/immobilientyp-3.jpg';

interface Props {
  index: string | number;
  indexForTitle: string | number;
  form: Immobilie;
}

const props = defineProps<Props>();

const emits = defineEmits(['delete', 'clear']);

const { isMobile } = useDetectMobile();

const werte = demvWerte.immobilienTyp;

const set = (field, value) => {
  // eslint-disable-next-line vue/no-mutating-props
  props.form[field] = value;
  emits('clear', `${field}`);
};

const immobilienTypen = [
  {
    label: werte[ImmobilienTyp.FREIES_GRUNDSTUECK],
    value: ImmobilienTyp.FREIES_GRUNDSTUECK,
    smallImage: true,
    imageSrc: GrundstueckImage,
  },
  {
    label: werte[ImmobilienTyp.HAUS],
    value: ImmobilienTyp.HAUS,
    smallImage: true,
    imageSrc: HausImage,
  },
  {
    label: werte[ImmobilienTyp.WOHNUNG],
    value: ImmobilienTyp.WOHNUNG,
    smallImage: true,
    imageSrc: WohnungImage,
  },
];
</script>
