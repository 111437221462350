<template>
  <section class="section bg-light">
    <div class="container">
      <div class="row pb-7">
        <div class="col-12 text-center">
          <h2>Wo erfolgt die Versorgung, wenn ich pflegebedürftig bin?</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-8 pt-7">
          <div class="info-banner info-banner--250">
            <span class="text-secondary"><strong>73%</strong></span> zu Hause oder in<br> anderen Wohnformen
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="text-center pt-5 md:pt-0">
                <SvgVue
                  icon="bedarfsinfo/pflegevorsorge/house.svg"
                  class="p-pflege-house-size inline-block"
                />
              </div>

              <BulletPointList :items="atHome" />
            </div>
            <div class="col-12 col-md-6 mt-5 md:mt-0 ">
              <div class="text-center">
                <SvgVue
                  icon="bedarfsinfo/pflegevorsorge/house-2.svg"
                  class="p-pflege-house-size inline-block"
                />
              </div>

              <BulletPointList :items="other" />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-5 lg:mt-0 pt-7">
          <div class="info-banner info-banner--250">
            <span class="text-secondary"><strong>27%</strong></span> vollstationär im Heim
          </div>

          <div class="text-center">
            <SvgVue
              icon="bedarfsinfo/pflegevorsorge/house-3.svg"
              class="p-pflege-house-size inline-block"
            />
          </div>

          <BulletPointList :items="nursingHome" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import SvgVue from "@/application/components/SvgVue";

const atHome = [
  {
    title: 'Pflege zu Hause',
    text: 'Pflege durch Angehörige, Nachbarn, Freunde',
  },
  {
    text: 'Pflege durch ambulanten Pflegedienst',
  },
  {
    text: 'Private Pflegekräfte, z.B. aus Osteuropa',
  },
  {
    text: 'Tagsüber Betreuung im Pflegeheim und nachts zu Hause oder umgekehrt',
  },
];

const other = [
  {
    title: 'Andere Wohnformen',
    text: 'Senioren-Residenz mit ambulanter Betreuung',
  },
  {
    text: 'Betreutes Wohnen',
  },
  {
    text: 'Pflege-Wohngemeinschaft',
  },
  {
    text: 'Mehr-Generationen-Häuser',
  },
];

const nursingHome = [
  {
    title: 'Pflegeheim',
    text: 'Vollstationäres Pflegewohnheim (Pflege unter Verantwortung professioneller Pflegekräfte)',
  },
  {
    text: 'Vollstationäre Senioren-Residenz (jeweils in zugelassenen Einrichtungen)',
  },
];
</script>
