<template>
  <section class="section">
    <div class="container">
      <div class="row text-center mb-5">
        <div class="col-md-8 offset-md-2">
          <h2 class="pb-5">
            Benötige ich eine Auslandskranken&shy;versicherung?
          </h2>
          <p>
            Dank eines Sozialversicherungsabkommens mit der Europäischen Union, Island, Liechtenstein und
            Norwegen, werden in diesen Ländern Teile der Behandlungskosten übernommen.
          </p>
        </div>
      </div>
      <div class="row mb-7">
        <div class="md:w-1/2 mb-7 md:mb-0 px-3.5">
          <h5 class="font-bold">
            Diese Leistungen&hellip;
          </h5>
          <p>
            &hellip; sind begrenzt auf den Teil, den Bürger im Land von der dortigen Krankenversicherung
            bekämen.
          </p>
          <p>
            &hellip; können von der deutschen Krankenversicherung gekürzt werden, etwa um die höheren
            Verwaltungskosten auszugleichen.
          </p>
        </div>
        <div class="md:w-1/2 px-3.5">
          <h5 class="font-bold">
            Innerhalb Europas werden die Leistungen&hellip;
          </h5>
          <p>
            &hellip; um den im Land üblichen Eigenanteil gekürzt.
          </p>
          <p>
            &hellip; nicht bei - oft gestellten - privatärztlichen Rechnungen und Medikamenten übernommen.
          </p>
          <p>
            &hellip; nur für im Land zugelassene Ärzte erstattet.
          </p>
        </div>
      </div>
      <div class="row mb-7">
        <div class="col text-center text-primary">
          <p>Für alle übrigen Länder erstattet die gesetzliche Krankenkasse keinerlei Leistungen.</p>
        </div>
      </div>
      <div class="row mb-7">
        <div class="col text-center">
          <h2 class="pb-5">
            Fazit
          </h2>
          <p>
            Die gesetzliche Krankenkasse leistet in wenigen Ländern mit Sozialversicherungsabkommen häufig nur
            einen kleinen Teil der tatsächlichen Behandlungskosten. In den meisten Ländern erfolgt überhaupt
            keine Kostenübernahme.
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <NiceToKnow :bullets="hospitalDay" />
        </div>
        <div class="col-md-6">
          <NiceToKnow :bullets="transport" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';

const hospitalDay = [
  {
    title: 'Hinweis',
    text: `Ein einziger Krankenhaustag kostet in etlichen Ländern mehrere Tausend Euro. Ist ein Rücktransport,
            beispielsweise per Ambulanzflug notwendig, liegen die Kosten innerhalb Europas bei einem deutlich
            fünfstelligen Betrag.`,
  },
];

const transport = [
  {
    title: 'Hinweis',
    text: 'Für aus medizinischen Gründen veranlasste Rücktransporte erfolgt keine Kostenerstattung.',
  },
];
</script>
