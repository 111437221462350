import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  "fill-rule": "evenodd",
  "stroke-miterlimit": "10",
  "clip-rule": "evenodd",
  viewBox: "0 0 61 70"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"#fff\" fill-rule=\"nonzero\" stroke=\"#e5e5e5\" d=\"M53.23 35.26c.27-.96.5-1.88.7-2.77 6.75-29.2-21.9-21.81-21.9-21.81s-6.91-4.3-13.82-5.53c0 0-10.9-2.46-12 14.74 0 0-.3 1.64-.6 4.32-.9 8.32-1.8 26.77 6.33 37.9 0 0 5.56 6.3 8.1-1.67 3.29-10.28 1.08-14.08 5.2-15.63 0 0 3.88-2.65 7.4 16.4 0 0 4.38 13 20.13-24.85.01-.05.41-.96.45-1.1Z\"></path><path fill=\"#989898\" d=\"M23.2 22a5.78 5.78 0 0 0-6.35 5.16l-.32 3.07a5.78 5.78 0 0 0 5.15 6.35l12.88 1.33a5.78 5.78 0 0 0 6.35-5.15l.32-3.07a5.78 5.78 0 0 0-5.16-6.35z\"></path><path fill=\"#b2b2b2\" d=\"M22.7 26.18a2.98 2.98 0 0 0-.61 5.93l12.61 1.3a2.98 2.98 0 0 0 .62-5.92z\"></path><circle cx=\"28.52\" cy=\"29.88\" r=\"1.06\" fill=\"#666\"></circle><path fill=\"none\" stroke=\"gray\" d=\"M5.03 25.4s27.25 7.69 50.05 5.06\"></path>", 5)
  ])))
}
export default { render: render }