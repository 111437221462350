<template>
  <div class="container">
    <div class="flex flex-row justify-center mb-5">
      <div class="flex flex-col sm:flex-row items-center justify-center">
        <span class="font-semibold text-lg mr-5">
          Aktuelle Dienstbezüge:
        </span>
        <div class="flex flex-row gap-3">
          <MoneyInput
            v-model="income"
            size="lg"
            class="w-36"
          />
          <DsButton
            variant="secondary"
            icon="plus"
            size="lg"
            @click="income = income + 100"
          >
            100 €
          </DsButton>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-center mb-3">
      <div class="flex flex-col gap-1 w-full lg:w-2/3 px-4">
        <div
          v-for="item in legend"
          :key="item.label"
          class="flex flex-row items-center gap-3"
        >
          <span
            class="rounded w-4 h-4"
            :class="item.color"
          />
          <p class="mb-0">{{ item.label }}</p>
        </div>
      </div>
    </div>
    <div class="row justify-center">
      <div class="col-12 col-lg-8 w-full p-0">
        <svg
          class="graph"
          width="100%"
          :viewBox="`0 0 ${viewBox.width} ${viewBox.height}`"
        >
          <g :transform="translateContent">
            <rect
              class="fill-gray-100"
              x="0"
              y="0"
              :width="oneTick * xAxisLength"
              :height="yAxisLength"
            />
            <rect
              class="fill-gray-100"
              :x="oneTick * xAxisLength"
              y="0"
              :width="hasMindestruhegehalt ? minPensionXAxisLength - (oneTick * xAxisLength) : 3 * oneTick * xAxisLength"
              :height="minFactor < 1 ? (1 - minFactor) * yAxisLength : yAxisLength"
            />
            <text
              text-anchor="middle"
              :x="oneTick * xAxisLength / 2"
              :y="gapTextPosition"
              class="svg-text"
            >{{ format(income) }}</text>
            <polygon
              class="fill-gray-100"
              :points="finalGapPolygonPoints"
            />
            <text
              v-for="(line, i) in lines"
              :key="`line${line.position}`"
              text-anchor="end"
              :dx="i === 1 ? -((lastMinPensionIndex === 1 ? 3 : lastMinPensionIndex - 1) * oneTick * xAxisLength / 2) + 35 : 0"
              :x="line.position"
              :y="gapTextPosition"
              class="svg-text"
            >{{ line.gap ? format(line.gap) : null }}</text>
            <polygon
              :points="pensionGraphPolygonPoints"
              class="fill-blue-500"
            />
            <polygon
              :points="minPensionGraphPolygonPoints"
              :class="hasMindestruhegehalt ? 'fill-blue-300' : 'fill-blue-500'"
            />
            <text
              v-for="(line, i) in lines"
              :key="`pension${line.position}`"
              fill="white"
              text-anchor="end"
              :dx="i === 1 ? -((lastMinPensionIndex === 1 ? 3 : lastMinPensionIndex - 1) * oneTick * xAxisLength / 2) + 35 : 0"
              :x="line.position"
              :y="i === 1 ? minFactor > 1 ? yAxisLength / 2 : yAxisLength - yAxisLength * minFactor / 2 : (pensionTextPosition + (i > 0 ? -35 * i : 0))"
              class="svg-text"
            >{{ line.pensionsbetrag ? format(line.pensionsbetrag) : null }}</text>
          </g>
          <g :transform="translateX">
            <line
              class="grid"
              x1="0"
              x2="0"
              y1="0"
              :y2="yAxisLength"
            />
          </g>
          <g :transform="translateY">
            <line
              class="grid"
              x1="0"
              :x2="xAxisLength"
              y1="0"
              y2="0"
            />
            <line
              v-for="tick in xTicks"
              :key="`tickline${tick.position}`"
              :x1="tick.position"
              :x2="tick.position"
              y1="0"
              y2="8"
              class="grid"
            />
            <line
              v-for="line in lines"
              :key="`segment${line.position}`"
              :x1="line.position"
              :x2="line.position"
              y1="0"
              :y2="-yAxisLength"
              :class="line.class"
            />
            <text
              v-for="tick in xTicks"
              :key="`tickvalue${tick.position}`"
              :x="tick.position"
              y="30"
              text-anchor="middle"
              class="svg-text"
            >
              {{ tick.value }}
            </text>
          </g>
        </svg>
      </div>
    </div>
    <div class="flex flex-row justify-center">
      <p class="text-sm font-bold mb-0">Dienstjahre</p>
    </div>
    <div class="flex flex-row justify-center">
      <div class="w-full md:w-2/3 mt-5">
        <small class="flex">
          <span class="mr-1.5">*</span>
          <span>
            Es handelt sich um eine Hochrechnung. Das tatsächliche Ruhegehalt bei
            Dienstunfähigkeit kann anders ausfallen. Den exakten Wert erhalten Sie über
            ihren Dienstherrn.
          </span>
        </small>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { debounce } from 'lodash-es';
import { computed, ref, watch } from 'vue';

import { getPensions } from '@/api/rechner/rechnerApi';
import MoneyInput from '@/application/components/form-elements/MoneyInput/MoneyInput.vue';
import { format } from '@/application/utils/money';
import type { Pension } from '@/api/rechner/types';
import { DsButton } from '@demvsystems/design-components';
import { call } from "@/api/lib/integration";

type Tick = {
  position: number,
  value: string | number
};

interface Props {
  dienstbezuege: number;
}

const props = withDefaults(defineProps<Props>(),{
  dienstbezuege: 0,
});

const getSVGPolygonPoints = (coordinates: [number, number][]): string => (
  coordinates.map(([x, y]) => `${x},${y}`).join(' ')
);

const viewBox = {
  width: 800,
  height: 440,
};

const graphOrigin = ref({
  x: 25,
  y: 0,
});

const resolution = 8;
const oneTick = 1 / resolution;

const xAxisLength = 750;
const yAxisLength = 390;

const translateX = computed(
  () => `translate(${graphOrigin.value.x}, ${graphOrigin.value.y})`,
);
const translateY = computed(
  () => `translate(${graphOrigin.value.x}, ${+graphOrigin.value.y + yAxisLength})`,
);
const translateContent = computed(() => translateX.value);

// reactive variables and methods
const income = ref(props.dienstbezuege);
const pensions = ref<Pension[]>([]);
const gaps = ref([0, 0, 0, 0]);
const pensionLoading = ref(false);
const pensionError = ref(false);

const hasMindestruhegehalt = computed(() => {
  return pensions.value.some((pension: Pension) => pension.isMindestruhegehalt);
});

const minPension = computed(() =>
  pensions.value?.find((pension: Pension) => pension?.isMindestruhegehalt)?.pensionsbetrag
);

const firstPensionsbetrag = computed(() => {
  return pensions.value[0]?.pensionsbetrag ?? 0;
});

const lastPensionsbetrag = computed(() => {
  const lastIndex = pensions.value.length - 1;
  return pensions.value[lastIndex]?.pensionsbetrag ?? 0;
});

const lastMinPensionIndex = computed(() => {
  let lastIndex = 1;
  pensions.value.forEach((pension, index) => {
    if (pension.isMindestruhegehalt) {
      lastIndex = index;
    }
  });
  if (minPension.value) {
    return lastIndex + 4;
  }
  return lastIndex;
});

const minPensionXAxisLength = computed(() => {
  return minPension.value === lastPensionsbetrag.value
    ? xAxisLength
    : oneTick * lastMinPensionIndex.value * xAxisLength
});

const minFactor = computed(() => firstPensionsbetrag.value / income.value)
const maxFactor = computed(() => lastPensionsbetrag.value / income.value)

const getGaps = async (earnings: number) => {
  pensionLoading.value = true;
  pensionError.value = false;
  await call(
    getPensions({
      dienstzeitbeginn: 0,
      pensionseintritte: [20, 25, 30, 35, 40],
      besoldungVorPension: earnings,
    }),
    (data) => {
      pensions.value = data;
      [...gaps.value] = data.map((pension) => earnings - pension.pensionsbetrag);
    },
    () => { pensionError.value = true; },
  );
  pensionLoading.value = false;
};

watch(income, debounce(() => getGaps(+income.value), 400), { immediate: true });

// graph content
const dienstjahre = 40;

const finalGapPolygonPoints = computed(() => getSVGPolygonPoints(
  hasMindestruhegehalt.value ? [
    [minPensionXAxisLength.value, (1 - minFactor.value) * yAxisLength],
    [minPensionXAxisLength.value, 0],
    [xAxisLength, 0],
    [xAxisLength, (1 - maxFactor.value) * yAxisLength],
  ] : [
    [4 * oneTick * xAxisLength, (1 - minFactor.value) * yAxisLength],
    [4 * oneTick * xAxisLength, 0],
    [xAxisLength, 0],
    [xAxisLength, (1 - maxFactor.value) * yAxisLength],
  ]
));

const minPensionGraphPolygonPoints = computed(() => getSVGPolygonPoints([
  [oneTick * xAxisLength, yAxisLength],
  [oneTick * xAxisLength, (1 - minFactor.value) * yAxisLength],
  [minPensionXAxisLength.value, (1 - minFactor.value) * yAxisLength],
  [minPensionXAxisLength.value, yAxisLength], //[750, 390]
]));

const pensionGraphPolygonPoints = computed(() => getSVGPolygonPoints(
  hasMindestruhegehalt.value ? [
    [minPensionXAxisLength.value, yAxisLength],
    [minPensionXAxisLength.value, (1 - minFactor.value) * yAxisLength],
    [xAxisLength, (1 - maxFactor.value) * yAxisLength],
    [xAxisLength, yAxisLength],
  ] : [
    [minPensionXAxisLength.value, yAxisLength],
    [minPensionXAxisLength.value, (1 - minFactor.value) * yAxisLength],
    [4 * oneTick * xAxisLength, (1 - minFactor.value) * yAxisLength],
    [xAxisLength, (1 - maxFactor.value) * yAxisLength],
    [xAxisLength, yAxisLength],
  ]
));

const gapTextPosition = 30;
const pensionTextPosition = 365;

function getLastIndex<T>(data: T[], callback: (item: T) => boolean): number {
  for (let i = data.length - 1; i >= 0; i--) {
    if (callback(data[i])) {
      return i;
    }
  }

  return -1;
}

const xTicks: Tick[] = Array.from(
  { length: 9 },
  (_, i) => ({ position: (i / resolution) * xAxisLength, value: (i / resolution) * dienstjahre }),
);

const lines = computed(() => {
  const lastMindestruhegehaltIndex = getLastIndex(pensions.value, (pension => pension.isMindestruhegehalt));

  return [
    { position: (5 / dienstjahre) * xAxisLength, class: 'greyStroke' },
    ...pensions.value
      .filter((pension, index) => index >= lastMindestruhegehaltIndex)
      .map((pension) => ({
        position: (pension.pensionseintritt / dienstjahre) * xAxisLength,
        class: 'greyStroke',
        pensionsbetrag: pension.pensionsbetrag,
        pensionseintritt: pension.pensionseintritt,
        gap: income.value - pension.pensionsbetrag,
      })),
  ]
});

const legend = [
  { label: 'Versorgungslücke', color: 'bg-gray-200' },
  { label: 'Amtsunabhängiges Mindestruhegehalt bei Dienstunfähigkeit *', color: 'bg-blue-200' },
  { label: 'Amtsabhängiges Ruhegehalt bei Dienstunfähigkeit *', color: 'bg-blue-500' },
]
</script>

<style lang="scss" scoped>

.svg-text {
  @apply text-xl md:text-lg;
}

.greyStroke {
  stroke: rgba(0,0,0,0.1);
  stroke-width: 1px;
}

.graph {

  .grid {
    stroke: #ccc;
    stroke-dasharray: 0;
    stroke-width: 1;
  }

  .x-labels {
    text-anchor: middle;
  }
}
</style>
