<template>
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-10 offset-lg-1">
          <div class="text-center mb-7">
            <h3>Habe ich nicht über das Versorgungswerk Anspruch auf eine Berufsunfähigkeitsrente?</h3>
          </div>

          <div class="text-center mb-5">
            <p>
              Sie arbeiten in einem sogenannten freien Beruf und Ihre
              Versorgung wird von einem eigenständigen Versorgungswerk
              geregelt. Die zahlreichen Versorgungswerke bieten im Fall
              der Berufsunfähigkeit im Gegensatz zur gesetzlichen
              Rentenversicherung zudem Versicherungsschutz. Zudem gibt es
              weitere Vorteile:
            </p>
          </div>
        </div>
      </div>

      <div class="row justify-center">
        <div class="col-12 col-md-10 col-lg-8">
          <BulletPointList :items="versorgungswerkAdvantages" />
        </div>

        <p class="text-center mb-5 px-2.5">
          Allerdings hat die Berufsunfähigkeitsversicherung der Versorgungswerke auch einen entscheidenden Nachteil:
        </p>

        <div class="col-12 col-md-10 col-lg-8">
          <BulletPointList
            :items="versorgungswerkDisadvantages"
          />
        </div>
      </div>

      <div class="row mt-14">
        <div class="col-12 col-lg-10 offset-lg-1">
          <div class="text-center mb-2.5">
            <h3>Auszug aus dem Versorgungswerk der Steuerberater in Hessen:</h3>
          </div>
        </div>
      </div>

      <div class="row justify-center">
        <div class="col-10">
          <div class="row items-center">
            <div class="col-3 col-md-2 col-lg-1 flex justify-center">
              <SvgVue icon="bedarfsinfo/berufsunfaehigkeit/paragraph.svg" />
            </div>
            <div class="col-9 col-md-10 col-lg-11 flex flex-col justify-around">
              <p class="mt-7 mb-5">
                “Das Versorgungswerk gewährt seinen Mitgliedern Berufsunfähigkeitsrente,
                wenn diese wegen gesundheitlicher Beeinträchtigung auf Dauer oder auf Zeit nicht mehr in der Lage sind,
                jedwede Tätigkeit, die zur Mitgliedschaft in der jeweiligen berufsständigen Kammer befähigt, auszuüben.
                Anspruchsvoraussetzung ist schließlich die vollständige Einstellung der beruflichen Tätigkeit,
                nachgewiesen durch Rückgabe der Bestellung als Steuerberater.“
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-center mt-14">
        <div class="col-md-10 col-lg-6">
          <NiceToKnow :bullets="niceToKnow" />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import type { BulletPointItem } from '@/bedarf/components/types';
import SvgVue from "@/application/components/SvgVue";

const versorgungswerkAdvantages: BulletPointItem[] = [
  { icon: 'plus', text: 'Keine Gesundheitsprüfung' },
  {
    icon: 'plus', text: `
    Viele Versorgungswerke verzichten auf eine Wartezeit. In der gesetzlichen
    Rentenversicherung besteht eine Wartezeit von 60 Monaten, bevor Sie
    Anspruch auf die Erwerbsminderungsrente hätten.`
  },
];

const versorgungswerkDisadvantages: BulletPointItem[] = [
  {
    icon: 'minus', text: `
    Ein Anspruch auf Berufsunfähigkeitsrente entsteht ausschließlich bei
    vollständiger Berufsunfähigkeit. Eine vollständige Berufsunfähigkeit
    setzt im Regelfall die Rückgabe der Zulassung beziehungsweise der
    Approbation oder Einstellung der Tätigkeit voraus.`
  },
];

const niceToKnow = [
  {
    title: 'Info:', text: `
    Eine private Berufsunfähigkeitsversicherung zusätzlich zur
    Berufsunfähigkeitsversicherung des Versorgungswerks wird dringend
    empfohlen.`
  },
];
</script>
