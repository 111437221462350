import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M16.18 0a15.96 15.96 0 1 0 15.95 15.95C32.13 7.15 24.98 0 16.18 0m0 31.02A15.07 15.07 0 1 1 16.19.9a15.07 15.07 0 0 1-.01 30.12" }, null, -1),
    _createElementVNode("path", { d: "M25.82 15.51H6.53a.42.42 0 0 0-.44.44c0 5.56 4.53 10.1 10.09 10.1s10.09-4.54 10.09-10.1a.45.45 0 0 0-.45-.44m-9.64 9.69a9.22 9.22 0 0 1-9.2-8.76h18.4a9.22 9.22 0 0 1-9.2 8.76M12.76 9.38a.98.98 0 1 1-1.96 0 .98.98 0 0 1 1.96 0m8.8 0a.98.98 0 1 1-1.96 0 .98.98 0 0 1 1.96 0" }, null, -1)
  ])))
}
export default { render: render }