import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  "fill-rule": "evenodd",
  "stroke-linejoin": "round",
  "stroke-miterlimit": "2",
  "clip-rule": "evenodd",
  viewBox: "0 0 263 148"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"#f2f2f2\" d=\"m114.15 19.72 68.16 35.04H45.98z\"></path><path fill=\"#f2f2f2\" d=\"M45.07 46.91H181.4V148H45.07z\"></path><g fill-rule=\"nonzero\"><path fill=\"#4181D2\" d=\"M101.53 104h24.33v44h-24.33z\"></path><path fill=\"#00665a\" d=\"M101.53 104h24.33v5.91h-24.33z\"></path><path fill=\"#9d9e9e\" d=\"m155.2 104.44-83-.41 1.75-10.26 80.2.17 1.04 10.5Z\"></path></g><g fill-rule=\"nonzero\"><path fill=\"#B4CEEF\" d=\"M137.5 109.69h35.44v28.16H137.5z\"></path><path fill=\"#c4c4c4\" fill-opacity=\".6\" d=\"M137.45 109.69h35.49v9.23h-35.49z\"></path></g><g fill-rule=\"nonzero\"><path fill=\"#B4CEEF\" d=\"M137.8 55.17h28.08v22.31H137.8z\"></path><path fill=\"#c4c4c4\" fill-opacity=\".6\" d=\"M137.75 55.24h28.08v7.24h-28.08z\"></path><path fill=\"#B4CEEF\" d=\"M99.67 55.17h28.08v22.31H99.67z\"></path><path fill=\"#c4c4c4\" fill-opacity=\".6\" d=\"M99.62 55.24h28.08v7.24H99.62z\"></path><path fill=\"#B4CEEF\" d=\"M61.54 55.17h28.08v22.31H61.54z\"></path><path fill=\"#c4c4c4\" fill-opacity=\".6\" d=\"M61.5 55.24h28.08v7.24H61.5z\"></path></g><g fill=\"#114273\"><path d=\"m210.2 59.78-96.05-49.39-3.8 7.38 96.05 49.38z\"></path><path d=\"M114.14 10.4 18.08 59.75l3.79 7.37 96.06-49.34z\"></path></g><path fill=\"#4d4d4d\" fill-rule=\"nonzero\" d=\"M215.92 129.1h6.5V148h-6.5z\"></path><path fill=\"gray\" fill-rule=\"nonzero\" d=\"M193.42 96.6h51.5v32.5h-51.5z\"></path><g fill=\"#fff\"><path fill-rule=\"nonzero\" d=\"M216.78 113.57h22.75v2.18h-22.75zm0 4.17h17.3v2.03h-17.3zm-.02 4.04h13.4v2.18h-13.4z\"></path><text x=\"193.94\" y=\"128.33\" font-family=\"&#39;ArialMT&#39;,&#39;Arial&#39;,sans-serif\" font-size=\"31.58\">+</text></g><text x=\"194.39\" y=\"107.06\" fill=\"#fff\" font-family=\"&#39;Inter var&#39;, &#39;ui-sans-serif&#39;\" font-size=\"12\">Hausarzt</text>", 10)
  ])))
}
export default { render: render }