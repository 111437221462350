import type { RouteLocationNormalized, RouteRecordName, RouteRecordRaw } from 'vue-router';

import type { Step } from '@/checkup/utils/steps';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';
import type { MeinBedarf } from '@/application/router/types/types';
import { useBedarfStore } from '@/bedarf/store/bedarfStore';
import { meinBedarfSparteGuard } from "@/application/router/guards/meinBedarfSparteGuard";

export function slugify(sparte: MeinBedarf | string): string {
  return sparte
    .toLowerCase()
    .replace('&', '')
    .replace(' ', '-')
    .replace('ä', 'ae')
    .replace('ö', 'oe')
    .replace('ü', 'ue');
}

export function provideBedarfsinfoProps(): { data: Bedarfsinfo } {
  return { data: useBedarfStore().getBedarfsinfo() };
}

export function decorateSpartenRoutes(records: Omit<RouteRecordRaw, 'path'>[]): RouteRecordRaw[] {
  // @ts-ignore when using props here vue-router's type definition go mad and tell us
  // that we are missing redirect options. Needs to be fixed upstream
  return records.map((record: RouteRecordRaw & { name: MeinBedarf }) => ({
    ...record,
    beforeEnter: meinBedarfSparteGuard,
    path: slugify(record.name),
    meta: {
      title: record.name,
      bgGray: false,
    },
    props: provideBedarfsinfoProps,
  }));
}

export function nameAndPath(step: Step): { path: string, name: string | Step } {
  return { path: `${step.toLowerCase()}`, name: step };
}

function getName(acc: RouteRecordName[], route: RouteRecordRaw) {
  if (route.name != null) {
    acc.push(route.name);
  }

  route.children?.forEach((child) => getName(acc, child))
}

export function getRouteNames(routeRecords: RouteRecordRaw[]): Set<RouteRecordName> {
  const names: RouteRecordName[] = [];
  routeRecords.forEach((route) => getName(names, route))

  return new Set(names);
}

export function isCheckupRoute({ name }: RouteLocationNormalized, checkupRouteNames: Set<RouteRecordName>): boolean {
  return name !== undefined && name !== null && checkupRouteNames.has(name);
}
