<template>
  <Tabs
    v-cloak
    title="Benötige ich tatsächlich eine Pflegeversicherung?"
    has-separated-tabs
    has-dark-bg-tabs
    has-small-buttons
    initial-tab="Persönliches Risiko"
  >
    <Tab name="Persönliches Risiko">
      <div class="row">
        <div class="col-12 text-center mb-7">
          <h3>Wie hoch ist mein persönliches Risiko, pflegebedürftig zu werden?</h3>
        </div>
      </div>

      <div class="flex flex-col gap-4 md:flex-row text-center">
        <div class="md:relative w-full md:w-1/2 md:text-left mb-5 md:mb-0">
          <div class="inline-block">
            <div class="md:absolute top-5 md:-right-10 lg:right-10 left-auto mb-5 lg:mb-0 w-full md:w-60 bg-white bg-opacity-70 rounded p-2">
              <h4 class="text-[40px] lg:text-[72px] text-secondary m-0">
                67%
              </h4>
              <span class="text-lg">Zwei von drei Frauen werden im Laufe ihres Lebens pflegebedürftig!</span>
            </div>
            <img
              src="../../../../../../assets/images/bedarfsinfo/pflegevorsorge/junge-frau.jpg"
              alt="Junge Frau"
              class="h-64 md:h-96 lg:h-[500px] inline-block"
            >
          </div>
        </div>
        <div class="md:relative w-full md:w-1/2 md:text-right">
          <div class="inline-block">
            <div class="md:absolute bottom-2 md:-left-10 lg:left-10 right-auto mb-5 lg:mb-0 w-full md:w-60 bg-white bg-opacity-70 rounded p-2">
              <h4 class="text-[40px] lg:text-[72px] text-secondary m-0">
                48%
              </h4>
              <span class="text-lg">Fast jeder zweite Mann wird im Laufe seines Lebens pflegebedürftig!</span>
            </div>
            <img
              src="../../../../../../assets/images/bedarfsinfo/pflegevorsorge/junger-mann.jpg"
              alt="Junger Mann"
              class="h-64 md:h-96 lg:h-[500px] inline-block"
            >
          </div>
        </div>
      </div>

      <div class="row my-5">
        <div class="col-12 text-center">
          <small><span class="text-muted">Quelle: Barmer GEK (2013): Pflegereport 2013</span></small>
        </div>
      </div>
    </Tab>
    <Tab name="Risiko Pflegekosten">
      <div class="row">
        <div class="col-12 text-center mb-7">
          <h3>Wie hoch ist mein persönliches Risiko, pflegebedürftig zu werden?</h3>
        </div>
      </div>
      <div class="row ">
        <div class="col-12 col-md-5">
          <div class="text-center">
            <img
              src="../../../../../../assets/images/bedarfsinfo/pflegevorsorge/alte-frau.jpg"
              alt="Alte Frau"
              class="h-52 lg:h-72"
            >
          </div>

          <BulletPointList
            :items="[
              { title: 'Ursachen für steigende Pflegekosten', text: 'Steigende Lebenserwartung' },
              { text: 'Zunehmende Anzahl an Pflegerentnern, die finanziert werden müssen' },
            ]"
          />
        </div>
        <div class="col-12 col-md-2 flex items-center justify-center my-7 md:my-0">
          <div class="p-pflege-arrow -rotate-90" />
        </div>
        <div class="col-12 col-md-5">
          <div class="text-center">
            <img
              src="../../../../../../assets/images/bedarfsinfo/pflegevorsorge/junge-frau2.jpg"
              alt="Junge Frau"
              class="h-52 lg:h-72"
            >
          </div>

          <BulletPointList
            :items="[
              {
                title: 'Auswirkung für jüngere Generationen',
                text: 'Weniger junge Personen müssen für die Finanzierung der Älteren aufkommen'
              },
              { text: 'Fehlende Mittel für Vermögensaufbau und Lebensgestaltung' },
            ]"
          />
        </div>
      </div>
      <div class="row mt-7">
        <div class="col-12 text-center mb-2.5">
          <h4>Pflegekosten sind ein unkalkulierbares Risiko</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="flow-bar mt-5">
            <div
              v-for="({ title, text }, i) in risks"
              :key="title"
              :class="[
                `flow-bar-${i % 2 === 0 ? 'right' : 'left'}`,
                { 'flow-bar-last': i === risks.length - 1, },
              ]"
            >
              <div
                class="flow-bar-item rounded shadow p-2.5 px-5"
                :class="{ 'bg-blue-500 text-light': i === risks.length - 1 }"
              >
                <div :class="{ 'text-white': i === risks.length - 1 }">
                  <!-- eslint-disable vue/no-v-html -->
                  <strong v-html="title" />
                  <!-- eslint-enable vue/no-v-html -->
                </div>
                {{ text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Tab>
    <Tab name="Mangelnde Vorsorge">
      <div class="row">
        <div class="col-12 text-center mb-7">
          <h3>Viele Bürger haben nicht ausreichend vorgesorgt</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-8 mb-7 lg:mb-0">
          <div
            class="mx-auto"
            style="max-width: 650px"
          >
            <div class="svg-abs svg-abs--pflegevorsorge-1">
              <SvgVue icon="bedarfsinfo/gesundheitsvorsorge/pflegevorsorge-1.svg" />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div
            class="mx-auto"
            style="max-width: 335px"
          >
            <div class="svg-abs svg-abs--pflegevorsorge-2">
              <SvgVue icon="bedarfsinfo/gesundheitsvorsorge/pflegevorsorge-2.svg" />
            </div>
          </div>
        </div>
      </div>
      <div class="row my-5">
        <div class="col-12 text-center">
          <small><span class="text-muted">Quelle: Meinungsplus der Technicker Krankenkasse 2016</span></small>
        </div>
      </div>
    </Tab>
    <Tab name="Pflegebedürftigkeit">
      <div class="row">
        <div class="col-12 text-center mb-7">
          <h3>Zahl der Pflegebedürftigen und Pflegekosten steigen dramatisch</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-8">
          <div
            class="mx-auto"
            style="max-width: 480px"
          >
            <div class="svg-abs svg-abs--pflegekosten">
              <SvgVue icon="bedarfsinfo/gesundheitsvorsorge/pflegekosten.svg" />
            </div>
            <div class="text-right">
              <small><span class="text-muted">Quelle: IGSF</span></small>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div
            class="mx-auto"
            style="max-width: 320px"
          >
            <img
              src="../../../../../../assets/images/bedarfsinfo/pflegevorsorge/alter-herr-rollstuhl.jpg"
              class="img-fluid"
              alt="Herr in Rollstuhl"
            >
          </div>
        </div>
      </div>
    </Tab>
  </Tabs>
</template>

<script setup lang="ts">
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import Tab from '@/application/components/Tab.vue';
import Tabs from '@/application/components/Tabs.vue';
import SvgVue from "@/application/components/SvgVue";

const risks = [
  {
    title: 'Ausgangs&shy;punkt',
    text: 'Pflegefall der Eltern',
  },
  {
    title: 'Folge 1',
    text: 'Vermögen der Eltern schrumpft',
  },
  {
    title: 'Folge 2',
    text: 'Kinder zahlen für Eltern',
  },
  {
    title: 'Folge 3',
    text: 'Vermögen der Kinder schrumpft',
  },
  {
    title: 'Folge 4',
    text: 'Kinder bilden keine Rücklagen für das Alter',
  },
  {
    title: 'Endergebnis',
    text: 'Das Risiko der Pflegekosten übertragt sich auf die nächste Generation!',
  },
];

</script>

<style scoped>
.flow-bar {
  margin: 0 auto;
  position: relative;
  width: 1px
}

.flow-bar-left,.flow-bar-right {
  width: 270px
}

@media (max-width: 767.98px) {
  .flow-bar-left,.flow-bar-right {
    left:0;
    margin-bottom: 25px;
    transform: translate(-50%)
  }
}

@media (min-width: 768px) {
  .flow-bar-left {
    transform:translate(-110%)
  }

  .flow-bar-right {
    right: 0;
    transform: translate(10%)
  }
}

.flow-bar-item:not(.bg-blue-500) {
  background: #fff;
}

.flow-bar-item {
  position: relative
}

.flow-bar-item:before {
  background-color: #4181d2;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  top: 100%;
  width: 100px;
  z-index: -1
}

.flow-bar-left .flow-bar-item:before {
  right: 50%;
  transform: translate(50%) rotate(90deg)
}

@media (min-width: 768px) {
  .flow-bar-left .flow-bar-item:before {
    right:0;
    transform: translate(80px) rotate(45deg)
  }
}

.flow-bar-right .flow-bar-item:before {
  left: 50%;
  transform: translate(-50%) rotate(-90deg)
}

@media (min-width: 768px) {
  .flow-bar-right .flow-bar-item:before {
    left:0;
    transform: translate(-80px) rotate(-45deg)
  }
}

.flow-bar-last .flow-bar-item:before {
  display: none
}
</style>
