import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';

export function validUntilLabel(dateString: string): string {
  const now = new Date();
  const expiryDate = new Date(dateString);

  const diffInDays = differenceInDays(expiryDate, now);
  const diffInHours = differenceInHours(expiryDate, now);

  if (diffInDays >= 4) {
    return `${diffInDays} Tage`;
  }

  if (diffInDays >= 2) {
    const remainingHours = diffInHours - (diffInDays * 24);
    if (remainingHours === 0) {
      return `${diffInDays} Tage`;
    }

    if (remainingHours === 1) {
      return `${diffInDays} Tage und eine Stunde`;
    }

    return `${diffInDays} Tage und ${remainingHours} Stunden`;
  }


  if (diffInDays >= 1 && diffInDays < 2) {
    return `einen Tag und ${diffInHours % 24} Stunden`;
  }

  if (diffInHours > 1) {
    return `${diffInHours} Stunden`;
  }

  const diffInMinutes = differenceInMinutes(expiryDate, now);
  if (diffInHours === 1) {
    return `eine Stunde und ${diffInMinutes % 60} Minuten`;
  }

  if (diffInMinutes > 1) {
    return `${diffInMinutes} Minuten`;
  }

  return 'weniger als eine Minute';
}
