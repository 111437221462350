<script setup lang="ts">
import type { SimpleMessageProps } from "@/application/components/SimpleMessageProps";
import LoadSpinner from "@/application/components/LoadSpinner.vue";
import { DsButton } from "@demvsystems/design-components";
import { onMounted, ref } from "vue";
import { useUserStore } from "@/user/store/userStore";
import type { ConfirmData } from "@/api/account/types";

const props = withDefaults(defineProps<SimpleMessageProps<ConfirmData, string>>(), {
  invalidatesAuth: false
})

const messageRef = ref('')
const isErrorRef = ref(false)
const isLoading = ref(true)

if (props.invalidatesAuth) {
  useUserStore().onLoggedOut();
}

onMounted(async () => {
  if (props.confirmToken === null) {
    messageRef.value = 'Es konnte kein Token zur Autorisierung gefunden werden.';
    isErrorRef.value = true
    isErrorRef.value = false

    return;
  }

  const result = await props.apiCall({ confirmToken: props.confirmToken });
  if (result instanceof Error) {
    messageRef.value = result.message ?? 'Ein unbekannter Fehler ist aufgetreten';
    isErrorRef.value = true
  } else {
    messageRef.value = result
  }
  isLoading.value = false;
});
</script>

<template>
  <div class="bg-gray-50 flex-fill">
    <div class="container text-center py-16">
      <LoadSpinner v-if="isLoading" />
      <div v-if="!isLoading">
        <span class="font-light text-lg block mb-4">{{ messageRef }}</span>
        <RouterLink :to="props.afterConfirmationRoute">
          <DsButton>Zur Startseite</DsButton>
        </RouterLink>
      </div>
    </div>
  </div>
</template>
