
import { Finanzmanager, MeinBedarf } from "@/application/router/types/types";
import Uebersicht from "@/bedarf/components/Uebersicht.vue";
import { decorateSpartenRoutes, slugify } from "@/application/router/utils/utils";
import Verlauf from "@/bedarf/components/Verlauf.vue";
import Altersvorsorge from "@/bedarf/components/altersvorsorge/altersvorsorge/Index.vue";
import Berufsunfaehigkeit from "@/bedarf/components/einkommenssicherung/berufsunfaehigkeit/Index.vue";
import Jagdhaftpflicht from "@/bedarf/components/sach/jagdhaftpflicht/Index.vue";
import Risikoleben from "@/bedarf/components/einkommenssicherung/risikoleben/Index.vue";
import Bauherrenhaftpflicht from "@/bedarf/components/sach/bauherrenhaftpflicht/Index.vue";
import Hausrat from "@/bedarf/components/sach/hausrat/Index.vue";
import KFZ from "@/bedarf/components/sach/kfz/Index.vue";
import Einkommenssicherung from "@/bedarf/components/einkommenssicherung/erwerbsunfaefigkeit/Index.vue";
import Luftfahrthaftpflicht from "@/bedarf/components/sach/luftfahrhaftpflicht/Index.vue";
import Photovoltaik from "@/bedarf/components/sach/photovoltaik/Index.vue";
import Privathaftpflicht from "@/bedarf/components/sach/privathaftpflicht/Index.vue";
import Rechtsschutz from "@/bedarf/components/sach/rechtsschutz/Index.vue";
import Tierhalterhaftpflicht from "@/bedarf/components/sach/tierhalterhaftpflicht/Index.vue";
import Unfall from "@/bedarf/components/sach/unfall/Index.vue";
import Gebaeude from "@/bedarf/components/sach/wohngebaeude/Index.vue";
import PKV from "@/bedarf/components/gesundheitsvorsorge/pkv/Index.vue";
import Krankenhauszusatz from "@/bedarf/components/gesundheitsvorsorge/krankenhauszusatz/Index.vue";
import Zahnzusatz from "@/bedarf/components/gesundheitsvorsorge/zahnzusatz/Index.vue";
import Pflegevorsorge from "@/bedarf/components/gesundheitsvorsorge/pflegevorsorge/Index.vue";
import AmbulanteKrankenzusatzversicherung from "@/bedarf/components/gesundheitsvorsorge/ambulant/Index.vue";
import AuslandsKV from "@/bedarf/components/gesundheitsvorsorge/ausland/Index.vue";
import Berufshaftpflicht from "@/bedarf/components/gewerbe/berufshaftpflicht/Index.vue";
import Cyber from "@/bedarf/components/gewerbe/cyber/Index.vue";
import Geschaeftsinhaltsversicherung from "@/bedarf/components/gewerbe/geschaeftsinhalt/Index.vue";
import GebaeudeGewerblich from "@/bedarf/components/gewerbe/gebaeude/Index.vue";
import Firmenrechtsschutz from "@/bedarf/components/gewerbe/firmenrechtsschutz/Index.vue";
import DO from "@/bedarf/components/gewerbe/do/Index.vue";
import Vermoegensschadenhaftpflicht from "@/bedarf/components/gewerbe/vermoegensschadenhaftpflicht/Index.vue";
import Betriebshaftpflicht from "@/bedarf/components/gewerbe/betriebshaftpflicht/Index.vue";
import Dienstunfaehigkeit from "@/bedarf/components/gesundheitsvorsorge/dienstunfaehigkeit/Index.vue";
import Diensthaftpflicht from "@/bedarf/components/sach/diensthaftpflicht/Index.vue";
import MainLayout from "@/application/layouts/MainLayout.vue";
import type { RouteRecordRaw } from "vue-router";
import { useBedarfStore } from "@/bedarf/store/bedarfStore";
import MeinBedarfWrapper from "@/bedarf/components/MeinBedarfWrapper.vue";

export const bedarfRoutes: RouteRecordRaw[] = [
  {
    path: '/mein-bedarf',
    component: MainLayout,
    meta: {
      menuTitle: 'Bedarf',
      title: 'Bedarf',
    },
    children: [
      {
        path: '',
        name: Finanzmanager.BEDARF,
        beforeEnter: async () => {
            if (useBedarfStore().verlauf.length === 0) {
              await useBedarfStore().fetchBeratungsdaten(null);
            }

            if (useBedarfStore().verlauf.length === 0) {
                return { name: MeinBedarf.VERLAUF };
            } else {
              return useBedarfStore().verlauf[0].link;
            }
        },
        component: {},
      },
      {
        path: slugify(MeinBedarf.VERLAUF),
        name: MeinBedarf.VERLAUF,
        component: Verlauf,
        props: true,
        meta: {
          title: 'Beratungsverlauf',
          bgGray: true,
        },
      },
      {
        path: ':bedarfHash',
        component: MeinBedarfWrapper,
        props: true,
        beforeEnter: async (to) => {
          await useBedarfStore().fetchBeratungsdaten(to.params.bedarfHash as string);
          if (useBedarfStore().themen.length === 0) {
            return { name: MeinBedarf.VERLAUF };
          }
        },
        meta: {
          title: 'Persönlicher Bereich',
        },
        children: [
          {
            path: '',
            name: MeinBedarf.UEBERSICHT,
            component: Uebersicht,
            meta: {
              bgGray: true,
            }
          },
          ...decorateSpartenRoutes([
            {
              name: MeinBedarf.ALTERSVORSORGE,
              component: Altersvorsorge,
            },
            {
              name: MeinBedarf.BERUFSUNFAEHIGKEIT,
              component: Berufsunfaehigkeit,
            },
            {
              name: MeinBedarf.KRANKENTAGEGELD,
              component: Berufsunfaehigkeit,
            },
            {
              name: MeinBedarf.JAGDHAFTPFLICHT,
              component: Jagdhaftpflicht,
            },
            {
              name: MeinBedarf.RISIKOLEBEN,
              component: Risikoleben,
            },
            {
              name: MeinBedarf.BAUHERRENHAFTPFLICHT,
              component: Bauherrenhaftpflicht,
            },
            {
              name: MeinBedarf.HAUSRAT,
              component: Hausrat,
            },
            {
              name: MeinBedarf.KFZ,
              component: KFZ,
            },
            {
              name: MeinBedarf.DREAD_DISEASE,
              component: Einkommenssicherung,
            },
            {
              name: MeinBedarf.ERWERBSUNFAEHIGKEIT,
              component: Einkommenssicherung,
            },
            {
              name: MeinBedarf.GRUNDFAEHIGKEIT,
              component: Einkommenssicherung,
            },
            {
              name: MeinBedarf.MULTI_RISK,
              component: Einkommenssicherung,
            },
            {
              name: MeinBedarf.LUFTFAHRTHAFTPFLICHT,
              component: Luftfahrthaftpflicht,
            },
            {
              name: MeinBedarf.PHOTOVOLTAIKVERSICHERUNG,
              component: Photovoltaik,
            },
            {
              name: MeinBedarf.PRIVATHAFTPFLICHT,
              component: Privathaftpflicht,
            },
            {
              name: MeinBedarf.RECHTSSCHUTZ,
              component: Rechtsschutz,
            },
            {
              name: MeinBedarf.TIERHALTERHAFTPFLICHT,
              component: Tierhalterhaftpflicht,
            },
            {
              name: MeinBedarf.UNFALL,
              component: Unfall,
            },
            {
              name: MeinBedarf.GEBAEUDEVERSICHERUNG,
              component: Gebaeude,
            },
            {
              name: MeinBedarf.PKV,
              component: PKV,
            },
            {
              name: MeinBedarf.KRANKENHAUSZUSATZ,
              component: Krankenhauszusatz,
            },
            {
              name: MeinBedarf.ZAHNZUSATZ,
              component: Zahnzusatz,
            },
            {
              name: MeinBedarf.PFLEGEVORSORGE,
              component: Pflegevorsorge,
            },
            {
              name: MeinBedarf.AMBULANTE_KRANKENZUSATZ,
              component: AmbulanteKrankenzusatzversicherung,
            },
            {
              name: MeinBedarf.AUSLANDSKV,
              component: AuslandsKV,
            },
            {
              name: MeinBedarf.BERUFSHAFTPFLICHT,
              component: Berufshaftpflicht,
            },
            {
              name: MeinBedarf.CYBER,
              component: Cyber,
            },
            {
              name: MeinBedarf.GESCHAEFTSINHALTSVERSICHERUNG,
              component: Geschaeftsinhaltsversicherung,
            },
            {
              name: MeinBedarf.GEBAEUDE_GEWERBLICH,
              component: GebaeudeGewerblich,
            },
            {
              name: MeinBedarf.FIRMENRECHTSSCHUTZ,
              component: Firmenrechtsschutz,
            },
            {
              name: MeinBedarf.DO,
              component: DO,
            },
            {
              name: MeinBedarf.GEBAEUDE_GEWERBLICH,
              component: GebaeudeGewerblich,
            },
            {
              name: MeinBedarf.VERMOEGENSSCHADENHAFTPFLICHT,
              component: Vermoegensschadenhaftpflicht,
            },
            {
              name: MeinBedarf.BETRIEBSHAFTPFLICHT,
              component: Betriebshaftpflicht,
            },
            {
              name: MeinBedarf.DIENSTUNFAEHIGKEIT,
              component: Dienstunfaehigkeit,
            },
            {
              name: MeinBedarf.BETRIEBSHAFTPFLICHT,
              component: Betriebshaftpflicht,
            },
            {
              name: MeinBedarf.DIENSTHAFTPFLICHT,
              component: Diensthaftpflicht,
            },
          ]),
        ]
      }
    ],
  }
]
