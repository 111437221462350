import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 75 75"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"37.5\" cy=\"37.5\" r=\"37.5\" fill=\"#FEFCFC\"></circle><g fill=\"#343A40\" fill-rule=\"evenodd\" clip-path=\"url(#a)\" clip-rule=\"evenodd\"><path d=\"m19.32 54.58-.6.08a2.53 2.53 0 0 1-1.9-.68 2.84 2.84 0 0 1-.78-2.04V37.62a7.98 7.98 0 0 1 3.77-5.35l-.49 22.3Zm18.93-24.5h7.84c.45 0 11.04 0 11.91 8.59V59.7a3.15 3.15 0 0 1-3.24 3.02 3.16 3.16 0 0 1-3.26-3.02V36.72H50v27.26H25.8v-8.35h11.77a4.11 4.11 0 0 0 4.22-3.94 4.15 4.15 0 0 0-4.22-3.94H34.7l-5.12-17.67z\"></path><path d=\"m20.6 54.66.53-23a28 28 0 0 1 7.1-1.28L33.5 49c1.05 2.19 1.88 4.98 1.43 5.58-.08.08-.08.08-.23.08zm14.55-5.28h2.42a2.65 2.65 0 1 1 0 5.28h-1.29c.38-1.44-.52-3.95-1.13-5.28m3.1-21.33h-.68a10.14 10.14 0 0 1-9.74-10.1 10.06 10.06 0 1 1 17.3 7c-1.8 1.88-4.27 3-6.88 3.1\"></path></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M16 8h42v56H16z\"></path></clipPath></defs>", 3)
  ])))
}
export default { render: render }