<template>
  <div class="row">
    <div class="col-12 col-lg text-center lg:text-right mb-5 md:mb-0 pb-5">
      <slot name="title">
        {{ title }}
      </slot>
    </div>
    <div class="col-12 col-lg pb-2.5">
      <div class="px-14 md:px-0">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },
  },
});
</script>
