import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  "fill-rule": "evenodd",
  "stroke-miterlimit": "10",
  "clip-rule": "evenodd",
  viewBox: "0 0 60 70"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      "fill-rule": "nonzero",
      stroke: "#e5e5e5",
      d: "M53.21 35.26c9.21-32.41-21.2-24.58-21.2-24.58s-6.9-4.3-13.82-5.53c0 0-10.9-2.46-12 14.74 0 0-5 27.48 5.71 42.23 0 0 5.56 6.3 8.11-1.68 3.28-10.28 1.07-14.08 5.2-15.63 0 0 3.87-2.65 7.4 16.4 0 0 4.37 13 20.12-24.85.04-.05.44-.96.48-1.1Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#d49188",
      "fill-rule": "nonzero",
      d: "M54.28 35.76c4.9-26.42-22.66-12.67-22.66-12.67a10.54 10.54 0 0 0-8.58-8c-4.21-.68-15.43-3.27-16.56 13.95 0 0-6.08 18.97 5.27 33.7 0 0 5.88 6.3 8.57-1.67 3.47-10.28 1.13-14.08 5.5-15.63 0 0 2.87-1.6 6.43 16.33 0 0 5 13.05 21.63-24.75 0-.06.37-1.11.4-1.26"
    }, null, -1),
    _createElementVNode("path", {
      "fill-rule": "nonzero",
      d: "M40.18 9.3s-3.9 8.2-2.8 9.88c1.08 1.67-3.73 9-2.9 9.43.85.42 4.6 2 4.86 1.5.25-.5 10.95-6.26 11.37-8.1a12.74 12.74 0 0 1 3.25-5.08c1.47-1.41-2.53-7.63-13.78-7.63"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#c10202",
      "fill-rule": "nonzero",
      d: "M42.95 38.34s.4-16-13.78-8.46C15.68 21.14 15.11 37 15.11 37c-3.5 17.5.21 28 .21 28q.9.15 1.8 0c-.68-29.67 10.6-26.8 10.6-26.8.9-.2 13.8-2.63 8 25.7.98-.13 1.9-.55 2.63-1.21s6.58-6.62 4.6-24.36Z"
    }, null, -1)
  ])))
}
export default { render: render }