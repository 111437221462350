import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  "fill-rule": "evenodd",
  "stroke-miterlimit": "10",
  "clip-rule": "evenodd",
  viewBox: "0 0 58 73"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      "fill-rule": "nonzero",
      d: "M32.4 11.52A43.7 43.7 0 0 0 20 6.81C15.65 5.88 9.09 4.35 8 21.56c0 0-5 27.48 5.71 42.23 0 0 5.56 6.3 8.11-1.68C25.12 51.82 22.91 48 27 46.48a1 1 0 0 1 .66-.12"
    }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      stroke: "#e5e5e5",
      d: "M32.4 11.52A43.7 43.7 0 0 0 20 6.81C15.65 5.88 9.09 4.35 8 21.56c0 0-5 27.48 5.71 42.23 0 0 5.56 6.3 8.11-1.68C25.12 51.82 22.91 48 27 46.48a1 1 0 0 1 .66-.12"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fffcdc",
      "fill-rule": "nonzero",
      stroke: "#fff",
      d: "M27.61 46.36c1.25.15 4.09 2.23 6.74 16.51 0 0 4.37 13 20.12-24.85l.46-1.11c9.22-32.41-21.2-24.57-21.2-24.57s-.52-.32-1.42-.82"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#f7d6aa",
      "fill-rule": "nonzero",
      stroke: "#5e4424",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "2",
      d: "M28.8 35.32c1.21.07 4.6 1.85 7.32 10.87 0 0 4.36 8.15 19.08-16.11 0 0 .4-.62.43-.71 1-2.8-16.94 12.28-26.16 2.81"
    }, null, -1)
  ])))
}
export default { render: render }