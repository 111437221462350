<script setup lang="ts" generic="T extends ExternalLinkData">

import type { ActionBlockProps, ExternalLinkData } from '@/api/actions/types';
import { DsButton } from '@demvsystems/design-components';
import { logAction } from '@/api/actions/actionsApi';

const props = defineProps<ActionBlockProps<T>>();

async function logExternalLink() {
  await logAction({
    actionId: props.actionId,
    context: props.baustein.type,
    data: props.baustein.data,
  });
}
</script>

<template>
  <DsButton
    :href="baustein.data.url"
    variant="secondary"
    external
    class="my-3 mr-auto"
    @click="logExternalLink"
  >
    {{ baustein.data.label }}
  </DsButton>
</template>
