<template>
  <div>
    <section class="mb-14">
      <div class="row flex justify-center">
        <div class="col-md-12">
          <h2 class="mb-3 md:mb-5 checkup-h2">
            Ich lebe mit meinem Partner in einem Haushalt
          </h2>

          <DsFieldError
            v-for="error in formErrors.anrede"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </div>
      </div>
      <OptionGroup
        has-icons
        :options="[
          {label: 'Ja', labelClasses: `${form.zusammenLebend ? 'text-green-700' : 'flex flex-col'}`, value: true, icon: 'icomoon/check', iconClass: `check ${form.zusammenLebend ? 'fill-green-700' : ''}`},
          {label: 'Nein', labelClasses: `${!form.zusammenLebend ? 'text-red-700' : 'flex flex-col'}`, value: false, icon: 'icomoon/close', iconClass: `close ${!form.zusammenLebend ? 'fill-red-700' : ''}`}
        ]"
        :value="form.zusammenLebend"
        option-width-classes="w-1/3 sm:w-1/4 md:w-1/6"
        @change="setZusammenLebend($event)"
      />
    </section>
    <div class="flex flex-col w-full md:w-1/2 mx-auto">
      <Partner
        v-if="form.zusammenLebend && form.partner"
        :form="form.partner"
        :form-errors="partnerErrors"
        class="mb-7"
        @clear="clear('partner', null, $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { DsFieldError } from '@demvsystems/design-components';
import { computed } from 'vue';

import OptionGroup from '@/checkup/components/OptionGroup.vue';
import Partner from '@/checkup/components/steps/Partner.vue';
import { useStep } from '@/checkup/state';
import { partner as partnerBase } from '@/checkup/state/base';
import { Step } from '@/checkup/utils/steps';

const { form, formErrors, clear, set } = useStep(Step.PARTNER);

const partnerErrors = computed(() => (formErrors?.value?.partner ?? {}));

const setZusammenLebend = (value: boolean) => {
  set('zusammenLebend', value);
  form.value.partner = value ? { ...partnerBase } : null;
  clear('partner');
};

</script>
