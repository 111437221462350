import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 35.85 72.5"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M26.39 38.62s3-4.77 7.59-1.88l1.87 28.92h-1.1l-2-27.94s-2.67-1.37-5.56 1.68z\" style=\"fill:#333;\"></path><ellipse cx=\"5.2\" cy=\"70.11\" rx=\"4.64\" ry=\"2.11\" style=\"fill:#4181d2;\" transform=\"rotate(-15.7 5.3 70.62)\"></ellipse><ellipse cx=\"17.45\" cy=\"68.17\" rx=\"3.91\" ry=\"2.22\" style=\"fill:#4181d2;\" transform=\"rotate(-15.78 17.47 68.17)\"></ellipse><path d=\"m4.91 39.24-.63 28.93h5l1.94-22.67 1.38.19 3.63 21.07h4.69l-2.75-30.02H5.31z\" style=\"fill:#333;\"></path><ellipse cx=\"2.06\" cy=\"43.23\" rx=\"2.06\" ry=\"3.21\" style=\"fill:#d3a590;\"></ellipse><ellipse cx=\"29.39\" cy=\"35.13\" rx=\"2.06\" ry=\"3.21\" style=\"fill:#d3a590;\" transform=\"rotate(-10.72 29.4 35.12)\"></ellipse><path d=\"M6.07 13.48S2.63 14.85.59 21.11V41h3l.74-15.63v12.95h15.24V22.67l4.16 2.58 3.26 9 3.69-1.47-4.07-10.6-7-8.11S17 11.57 16 12.35s-9.93 1.13-9.93 1.13\" style=\"fill:#4181d2;\"></path><circle cx=\"11.23\" cy=\"8.83\" r=\"6.94\" style=\"fill:#d3a590;\"></circle><circle cx=\"4.25\" cy=\"6.57\" r=\"2.19\" style=\"fill:#eee;\"></circle><circle cx=\"5.31\" cy=\"4.38\" r=\"2.19\" style=\"fill:#eee;\"></circle><circle cx=\"8.63\" cy=\"2.19\" r=\"2.19\" style=\"fill:#eee;\"></circle><circle cx=\"10.82\" cy=\"2.19\" r=\"2.19\" style=\"fill:#eee;\"></circle><circle cx=\"13.01\" cy=\"2.19\" r=\"2.19\" style=\"fill:#eee;\"></circle><circle cx=\"15.59\" cy=\"3.61\" r=\"2.19\" style=\"fill:#eee;\"></circle><circle cx=\"17.39\" cy=\"5.79\" r=\"2.19\" style=\"fill:#eee;\"></circle><circle cx=\"7.09\" cy=\"3.05\" r=\"2.19\" style=\"fill:#eee;\"></circle>", 16)
  ])))
}
export default { render: render }