import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 50.92 60.45"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      stroke: "#e5e5e5",
      "stroke-miterlimit": "10",
      d: "M48.68 30.73c9.21-32.41-21.2-24.58-21.2-24.58S20.57 1.85 13.66.62c0 0-10.9-2.46-12 14.74 0 0-5 27.48 5.71 42.23 0 0 5.56 6.3 8.11-1.68 3.28-10.28 1.07-14.08 5.2-15.63 0 0 3.87-2.65 7.4 16.4 0 0 4.37 13 20.12-24.85.04-.05.44-.96.48-1.1z"
    }, null, -1)
  ])))
}
export default { render: render }