<template>
  <SpartenWrapper
    :text="subtitle"
    image="images/bedarfsinfo/cyber-versicherung/header"
    alt="Schreibtisch mit schwebenden Symbolen"
    :dokumente="data.bedarfsinfo.dokumente"
    :versorgungen="data.bedarfsinfo.versorgungen"
    :links="data.bedarfsinfo.links"
    versorgungen-headline="Wie gut sind wir bei Cyberattacken versichert?"
    versorgungen-description="Cyberversicherung"
  >
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <h2 class="text-center mb-7">
              Was zählt alles zu Cyber-Risiken?
            </h2>

            <p class="text-center mb-7">
              Der Umfang von Cyber-Risiken ist extrem umfangreich und wächst mit neuen
              Technologien mit. Einige der häufigsten Cyber-Attacken sind:
            </p>
          </div>
        </div>

        <div class="row justify-center">
          <div class="col-lg-8 col-md-10 flex flex-col">
            <BulletPoint
              v-for="risk in cyberrisks"
              :key="risk"
              :item="{ text: risk }"
            />
          </div>
        </div>

        <div class="row justify-center my-14">
          <div class="col-md-10 col-lg-6">
            <NiceToKnow :bullets="assessment" />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center mb-7">
              <h3>Um welche Leistungen kann ich den Versicherungsschutz individuell erweitern?</h3>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 flex flex-col justify-between">
            <BulletPoint
              v-for="benefit in extraBenefits1"
              :key="benefit"
              :item="{ text: benefit }"
            />
          </div>
          <div class="col-12 col-md-6 flex flex-col justify-between">
            <BulletPoint
              v-for="benefit in extraBenefits2"
              :key="benefit"
              :item="{ text: benefit }"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section bg-dark text-light">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <h3 class="text-center pb-7">
              Wann habe ich keinen Versicherungsschutz?
            </h3>

            <p class="text-center">
              Die Cyber-Versicherung kommt beispielsweise nicht für Schäden auf, die von
              Mitarbeitenden, z. B. Geschäftsführern, Inhabern oder Vorständen,
              vorsätzlich herbeigeführt wurden.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row justify-center mb-14">
          <div class="col-md-10 col-lg-6">
            <NiceToKnow :bullets="noCoverage" />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center mb-7">
              <h3>Schadenbeispiele - welche Kosten werden erstattet?</h3>
            </div>
          </div>
        </div>

        <div class="row justify-center">
          <div class="col-lg-8 col-md-10 flex flex-col">
            <BulletPoint
              v-for="example in damageExamples"
              :key="example"
              :item="{ text: example }"
            />
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import BulletPoint from '@/bedarf/components/BulletPoint.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';

interface Props {
  data: Bedarfsinfo
}

defineProps<Props>();

const subtitle = `
  Im Zuge der notwendigen Digitalisierung von Unternehmen, werden immer mehr Geschäftsbereiche von außen angreifbar.
  Cyberattacken, auch auf Mitarbeiter im Homeoffice, nehmen jedes Jahr erheblich zu und führen zu erheblichen Risiken.
`;

const cyberrisks = [
  `Phishing, wo der Empfänger eine E-Mail erhält und die dazu verleiten soll, Zugriff auf vertrauliche Daten, wie
    beispielsweise Zugangsdaten, zu erhalten.`,
  `Social Engineering, wo der Empfänger so manipuliert wird, dass dieser beispielsweise vertrauliche
    Produktinformationen heraus gibt oder eine Finanztransaktion freigibt.`,
  `Die Installation von Malware, die ohne Wissen des Nutzers im Hintergrund vertrauliche Informationen abgreift oder
    Systeme beschädigt.`,
  'Angriffe auf Web-Applikationen, um beispielsweise Kundendaten oder andere sensible Daten zu gewinnen.',
];

const assessment = [
  {
    title: 'Hinweis',
    text: `Die Cyber-Versicherung prüft im Schadenfall, ob und in welcher Höhe der Kunde schadenersatzpflichtig gemacht
            werden kann und wehrt unberechtigte Ansprüche ab.`,
  },
];

const extraBenefits1 = [
  'Sofort-Analyse durch einen erfahrenen IT-Experten',
  'Sofort-Maßnahmen zur Schadensbegrenzung durch ein IT-Team',
  'Sofern möglich Wiederherstellung der Daten und Systeme',
];

const extraBenefits2 = [
  'Krisen- und PR-Beratung, um gegenüber Kunden den Vorfall professionell einzuordnen',
  'Kompensation der Umsatzverluste der durch die Cyber-Attacke verursachten Betriebsunterbrechung',
  'Übernahme von Schadensersatzansprüchen bei der Verletzung von Persönlichkeitsrechten',
];

const noCoverage = [
  {
    title: 'Info',
    text: `Diese Aufzählung beinhaltet wesentliche Leistungsausschlüsse und ist nicht abschließend. Sofern einzelne
            Leistungspunkte für Sie wichtig sind, prüfen wir gerne Sondertarife.`,
  },
];

const damageExamples = [
  `Ein in einer E-Mail versteckter Trojaner sperrt den Zugriff auf das Intranet und es wird eine Lösegeldforderung
    gestellt.`,
  `Nach einer Cyberattacke sind alle Rechner der Mitarbeiter infiltriert und müssen komplett neu aufgesetzt werden, um
    die Arbeit wieder aufnehmen zu können.`,
  `Es besteht der Verdacht eines Hackerangriffs. Es wird vom Cyber-Versicherer ein Forensik-Dienstleister vermittelt, um
    eine Bedrohungsanalyse, die Untersuchungen auf Manipulationen im Filesystem, die Analyse der Netzwerkverbindungen
    und eine Risikoabschätzung vorzunehmen.`,
];
</script>
