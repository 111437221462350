import type { PropType } from 'vue';
import { defineComponent, h } from 'vue';

export const List = defineComponent({
  props: {
    list: {
      type: Array as PropType<unknown[]>,
      required: true,
    },

    element: {
      type: String,
      default: 'div',
    },

    classes: {
      type: Array as PropType<string[]>,
      default: () => [],
    },

    beforeClasses: {
      type: Array as PropType<string[]>,
      default: () => [],
    },

    afterClasses: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },

  setup(props, { slots }) {
    if (!slots.default) throw new Error('Default slot is empty but is needed for rendering list items');

    const defaultSlot = slots.default;

    return () => h(props.element, props.list.map((item, index) => {
      const classes = [...props.classes];

      if (props.list.length > 1) {
        if (props.beforeClasses.length > 0 && index > 0) {
          classes.push(...props.beforeClasses);
        }

        if (props.afterClasses.length > 0 && index < props.list.length - 1) {
          classes.push(...props.afterClasses);
        }
      }

      const vnodes = defaultSlot({
        item,
        index,
      });

      vnodes.forEach((vnode) => {
        if (vnode.props) {
           
          vnode.props.class += ` ${classes.join(' ')}`;
        }
      });

      return vnodes;
    }));
  },
});
