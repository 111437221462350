<template>
  <div
    :style="{ flexGrow: heightSanitized }"
    :class="[{
      'default': !(light || dashedOutline || empty),
      'light': light,
      'empty': empty,
      'dashed-outline': dashedOutline,
      'dashed-outline-horizontal': dashedOutline && horizontal,
      // bootstrap
      'bg-secondary': !dashedOutline,
      'text-white': !dashedOutline,
      'mx-5': !horizontal,
      'items-center': horizontal,
    }]"
    class="animated p-2.5 flex flex-col justify-center"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    height: {
      type: Number,
      required: true,
    },

    dashedOutline: {
      type: Boolean,
      default: false,
    },

    empty: {
      type: Boolean,
      default: false,
    },

    light: {
      type: Boolean,
      default: false,
    },

    horizontal: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    return {
      heightSanitized: computed(() => (
        Number.isFinite(props.height) && !Number.isNaN(props.height) ? props.height : 0
      )),
    };
  },
});
</script>

<style lang="scss" scoped>
  @import '@/../css/variables';

  .default {
      background-color: rgba($blue, 0.85) !important;
    }

  .light {
    background-color: rgba($blue, 0.7) !important;
  }

  .empty {
    background-color: $blue-light !important;
  }

  .dashed-outline {
    background-color: $blue-light;
    border: 2px dashed $blue;
    border-bottom-width: 0;

    &-horizontal {
      border-bottom-width: 2px;
      border-left-width: 0;
    }
  }

  .animated {
    transition: flex-grow 125ms ease-in-out;
  }
</style>
