import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 27 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M25.5 25.5a114 114 0 0 1-9.41-10.16A97 97 0 0 0 23.8 4.35c1.37-2.49-2.01-5.2-3.4-2.62a77.5 77.5 0 0 1-7.03 10.15 124 124 0 0 1-7-10.45C4.95-.99 1.66 1.84 3.1 4.26c2.32 3.88 4.75 7.59 7.45 11.05C7.13 19.49 3.71 23.67 1 28.38c-1.46 2.48 1.93 5.1 3.39 2.62 2.58-4.36 5.73-8.3 8.95-12.22a117 117 0 0 0 9.65 10.36c2.01 1.94 4.54-1.79 2.52-3.64z" }, null, -1)
  ])))
}
export default { render: render }