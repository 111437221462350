<template>
  <SpartenWrapper
    image="images/bedarfsinfo/luftfahrthaftpflicht/luftfahrthaftpflichtversicherung-titelbild"
    text="Flugdrohnen, Kopter und Multicopter erfreuen sich
          immer größerer Beliebtheit. Egal ob als Hobby von
          Privatpersonen oder beim gewerblichen Einsatz von
          Fotografen. Sie haften für Personen-, Sach- und
          Vermögensschäden. Eine Luftfahrthaftpflichtversicherung
          schützt Sie vor dem finanziellen Risiko."
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    :versorgungen="data.bedarfsinfo.versorgungen"
    versorgungen-headline="Wie gut bin ich versichert?"
    versorgungen-description="Luftfahrthaftpflicht"
  >
    <section class="section">
      <div class="container">
        <DamageExamplesSection
          class="pb-10"
          :data="damageExamples"
          headline="Benötige ich diese Versicherung?"
          subline="Der Gesetzgeber verpflichtet Drohnenbesitzer zum Abschluss einer Haftpflichtversicherung. Die
                   Luftfahrthaftpflichtversicherung trägt die finanziellen Kosten durch Schäden, die Sie einem Dritten
                   unbeabsichtigt zugefügt haben."
          second-headline="Schadenbeispiele - welche Kosten werden erstattet?"
        />

        <div class="row mt-7">
          <div class="col-12 col-md-6">
            <NiceToKnow
              :bullets="[{
                title: 'Hinweis!',
                text: 'Wenn Sie mehrere Flugobjekte gleichzeitig benutzen, sollten Sie dies vorher mit dem ' +
                  'Versicherer besprechen. Es gibt Tarife, wo generell nur ein Flugobjekt genutzt werden darf.'
              }]"
            />
          </div>
          <div class="col-12 col-md-6">
            <NiceToKnow
              :bullets="[{
                title: 'Hinweis!',
                text: 'Bis zu einem definierten Maximalgewicht, lassen sich Flugobjekte wie Drohnen meist ' +
                  'beitragsfrei in der Privathaftpflicht mitversichern.'
              }]"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row text-center">
          <div class="col-12">
            <h3 class="mb-5">
              Worauf muss ich achten, wenn ich eine Drohne besitze?
            </h3>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-12">
            <p class="mb-0">
              Seit dem 01.10.2017 hat der Gesetzgeber den Flugraum für Drohnen zusätzlich reguliert.
            </p>
            <p>In der Drohnen-Verordnung ist geregelt, was Besitzer von Drohnen beachten müssen.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <List title="Kennzeichnungspflicht">
              Ab einem Gewicht von 0,25 kg muss an der Drohne eine Plakette mit den Adressdaten des
              Besitzers angebracht werden.
            </List>
            <List title="Drohnenführerschein">
              Ab einem Gewicht von 2,0 kg benötigt der Drohnenpilot den Nachweis über die besonderen Flugkenntnisse.
            </List>
          </div>
          <div class="col-12 col-md-6">
            <List title="Erlaubnispflicht">
              Ab einem Gewicht von 5,0 kg wird eine Erlaubnis der Landesluftfahrtbehörde benötigt.
              Zudem dürfen Flughöhen von mehr als 100 m nur mit behördlicher Ausnahmeerlaubnis überflogen werden.
            </List>
            <List title="Flugverbot">
              Über Kontrollzonen von Flughäfen, Wohngrundstücken, Industrieanlagen, Menschenansammlungen,
              Einsatzorten der Polizei und Naturschutzgebieten besteht grundsätzlich ein Flugverbot.
            </List>
          </div>
          <SvgVue
            icon="bedarfsinfo/luftfahrthaftpflicht/flugverbot.svg"
            class="img-fluid w-full mt-5"
            style=""
          />
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="row text-center mt-14">
          <div class="col-12">
            <h3 class="mb-7">
              Wann habe ich keinen Versicherungsschutz?
            </h3>
          </div>
        </div>
        <div class="row mt-2.5">
          <div class="col-12 col-md-6 mx-auto">
            <List>Bei Verstoß gegen behördliche oder gesetzliche Auflagen</List>
            <List>Bei Inbetriebnahme außerhalb der Sichtweite des Flugobjektes</List>
            <List>Bei Schäden am eigenen Flugobjekt</List>
            <List>Oftmals gibt es ein Mindestalter von mitversicherten Personen</List>
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import SvgVue from '@/application/components/SvgVue';
import DamageExamplesSection from '@/bedarf/components/DamageExamplesSection.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';
import List from '@/application/components/List.vue';

interface Props {
  data: Bedarfsinfo,
}

defineProps<Props>();

const damageExamples = [
  {
    icon: 'bedarfsinfo/luftfahrthaftpflicht/sachschaden.svg',
    title: 'Sachschaden',
    description: 'Beim steuern des Flugobjektes verlieren Sie die Kontrolle über Ihre Drohne, welche in dessen Folge '
      + 'auf ein Auto stürzt und dieses stark beschädigt.',
  },
  {
    icon: 'bedarfsinfo/luftfahrthaftpflicht/personenschaden.svg',
    title: 'Personenschaden',
    description: 'Nach dem Kontrollverlust über das Flugobjekt, stürzt dieses auf einen Menschen und fügt diesem '
      + 'Kopfverletzungen zu. Die Luftfahrthaftpflichtversicherung übernimmt die Behandlungs- und Folgekosten und …',
  },
  {
    icon: 'bedarfsinfo/luftfahrthaftpflicht/vermoegensschaden.svg',
    title: 'Vermögensschaden',
    description: '… darüber hinaus das Schmerzensgeld sowie die Kosten für den Verdienstausfall der verletzten Person.',
  },
];

</script>
