import { isCheckupRoute } from "@/application/router/utils/utils";
import { checkupRouteNames } from "@/application/router/checkup";
import type { NavigationGuardWithThis } from "vue-router";
import { checkupStepGuard } from "@/application/router/guards/checkupStepGuard";

export const checkupBeforeGuard: NavigationGuardWithThis<undefined> = (to, from) => {
  if (isCheckupRoute(to, checkupRouteNames)) {
    return checkupStepGuard(to, from);
  }

  return true;
}
