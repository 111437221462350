<template>
  <section class="container flex flex-col w-full md:w-2/3 text-center pb-7">
    <section class="mb-7">
      <DsIcon
        name="comments"
        variant="light"
        size="5x"
      />
      <p class="text-lg mt-5">
        Sie haben Fragen, Anmerkungen, Wünsche oder Kritik?
      </p>
      <DsFormGroup label="Anmerkungen:">
        <DsTextarea
          v-model="form.anmerkungen"
          size="lg"
          :rows="7"
        />
        <DsFieldError
          v-for="error in formErrors.anmerkungen"
          :key="error"
        >
          {{ error }}
        </DsFieldError>
      </DsFormGroup>
    </section>
    <section class="flex flex-col items-center mb-7">
      <div class="flex flex-row justify-center items-center gap-2 flex-wrap">
        <div
          id="formError.hatAgbZugestimmt"
          class="flex flex-row space-x-2"
        >
          <DsCheckbox
            id="hatAgbZugestimmt"
            v-model="form.hatAgbZugestimmt"
          />
          <label
            class="text-sm sm:text-base text-start inline-block"
            for="hatAgbZugestimmt"
          >
            Ich stimme der <a
              href="#"
              @click.prevent="showDatenschutz = true"
            >Datenschutzerklärung</a> zu.
          </label><br>
        </div>
      </div>
      <DsFieldError
        v-for="error in formErrors.hatAgbZugestimmt"
        :key="error"
      >
        {{ error }}
      </DsFieldError>
    </section>

    <DsModal
      :show="showDatenschutz"
      title="Datenschutz"
      cancel-only
      size="lg"
      @cancel="showDatenschutz = false"
    >
      <PrivacyText />
    </DsModal>

    <BrokerContact />
  </section>
</template>

<script setup lang="ts">
import {
  DsCheckbox,
  DsFieldError,
  DsFormGroup,
  DsIcon, DsModal,
  DsTextarea,
} from '@demvsystems/design-components';
import BrokerContact from '@/checkup/components/BrokerContact.vue';
import PrivacyText from "@/legal/components/PrivacyText.vue";
import { useStep } from '@/checkup/state';
import { Step } from '@/checkup/utils/steps';
import { ref } from 'vue';

const { form, formErrors } = useStep(Step.ABSCHLUSS);
const showDatenschutz = ref(false);
</script>
