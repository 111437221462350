import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 55 55"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "27.5",
      cy: "27.5",
      r: "27.5",
      fill: "#4181D2"
    }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      stroke: "#fff",
      "stroke-miterlimit": "10",
      "stroke-width": "3",
      d: "M43 12.3v24.3H9.6"
    }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      stroke: "#fff",
      "stroke-miterlimit": "10",
      "stroke-width": "2",
      d: "M41.1 12.3s1.1 22.9-31.5 22.4"
    }, null, -1)
  ])))
}
export default { render: render }