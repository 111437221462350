import type { Step } from '@/checkup/utils/steps';
import type { CheckupErrors, CheckupStateReferenceValue } from '@/checkup/types/types';

type ClearErrorFunction<S extends Step> = (field: keyof CheckupStateReferenceValue<S>,
  index?: number | null,
  nestedField?: string) => void;

function isEmpty(object) {
  return object
        && Object.keys(object).length === 0
        && Object.getPrototypeOf(object) === Object.prototype;
}

function clean(object: Record<string, unknown>) {
  Object
    .entries(object)
    .forEach(([k, v]) => {
      if (v && typeof v === 'object') {
        // @ts-ignore
        clean(v);
      }
      if ((v && typeof v === 'object' && Object.keys(v).length === 0) || (v === null)) {
        if (Array.isArray(object)) {
          // @ts-ignore
          object[k] = undefined;
        } else {
          delete object[k];
        }
      }
    });
}

export function clearErrorHandler<S extends Step>(
  errors: CheckupErrors[S],
): ClearErrorFunction<S> {
  return (field, index = null, nestedField = '') => {
    if (!errors.value) return;
    if (!(field in errors.value.errors)) return;

    // Get the type of field, extract its keys and define it as a new type
    // that is usable as an index key.
    const tField = field as keyof typeof field;

    if (index != null && nestedField) {
      // remove prop from object inside an array
      const t = errors.value.errors[tField][index];
      delete t[nestedField];
      // @ts-ignore
      if (errors.value.errors[tField].every((e) => e == null || isEmpty(e))) {
        delete errors.value.errors[tField];
      }
    } else if (index == null && nestedField) {
      // remove prop from a nested object
      const t = errors.value.errors[tField];
      delete t[nestedField];
    } else if (index != null) {
      // remove an object from an array
      errors.value.errors[tField].splice(index, 1);
      // @ts-ignore
      if (errors.value.errors[tField].every((e) => e == null || isEmpty(e))) {
        delete errors.value.errors[tField];
      }
    } else {
      // remove a field from the top level object
      delete errors.value.errors[tField];
    }

    clean(errors.value);

    if (errors.value.errors == null) {
      errors.value = null;
    }
  };
}
