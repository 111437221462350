import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 51 62"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g fill=\"none\" fill-rule=\"evenodd\"><path stroke=\"#333\" stroke-width=\"1.5\" d=\"M49.93 16.2C48.6 7.1 46.45 5.15 37.65 3.84 32.95 3.14 28.4 1 23.71.78 11.05.18.91 10.13.8 22.75.7 34.77.78 46.8.78 59.05c5.44.51 11.22 1.1 15.88 1.53\"></path><path stroke=\"#333\" stroke-width=\"1.5\" d=\"M44.2 29.3c-2.74-2.73-4.67-6.3-6.14-7.76L6.52 36.45c.1 6.61 2.3 12.52 8.04 17.6 5.28 4.68 10.6 4.7 16.52 3.07 7.03-1.93 11.25-6.7 12.23-13.9.63-4.59.62-9.27.89-13.91\"></path><path stroke=\"#333\" stroke-width=\"1.33\" d=\"M49.93 15.87v43.18c-5.44.51-10.93 1.1-15.58 1.53\"></path><path stroke=\"#333\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\".6\" d=\"M20.75 50.06s5.43 2.16 10.42 0\"></path><path fill=\"#333\" d=\"M18.67 37.36c0 .77-.72 1.4-1.6 1.4-.89 0-1.6-.63-1.6-1.4s.71-1.4 1.6-1.4c.88 0 1.6.63 1.6 1.4m18.48.19c0 .77-.72 1.4-1.6 1.4-.89 0-1.6-.63-1.6-1.4s.71-1.4 1.6-1.4c.88 0 1.6.62 1.6 1.4\"></path></g>", 1)
  ])))
}
export default { render: render }