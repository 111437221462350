import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 61 59"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g fill=\"none\" fill-rule=\"evenodd\"><path stroke=\"#424242\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M58.03 31.48c0 7.01-2.06 13.4-5.42 18.15-3.68 5.22-8.94 8.48-14.77 8.48-21.2 0-20.2-31.07-20.2-26.9C38.7 17.49 47.98 15.44 47.98 15.44c2.67 8.45 10.06 11.46 10.06 16.04z\"></path><path stroke=\"#424242\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.52\" d=\"M17.64 30.73C14.05 35.66.76 36.1.76 36.1 11.1 32.49 12.8 22.5 12.8 22.5c.84-27.28 27-21.16 27-21.16s27.83-2.5 18.24 33.73\"></path><path stroke=\"#424242\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.53\" d=\"M18.26 40.12s-6.03-4.23-1.77-8.2\"></path><path stroke=\"#333\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\".63\" d=\"M31.93 45.98s5.78 2.3 11.08 0\"></path><path fill=\"#333\" fill-rule=\"nonzero\" d=\"M29.71 32.49c0 .82-.76 1.48-1.7 1.48s-1.7-.66-1.7-1.48.76-1.49 1.7-1.49 1.7.67 1.7 1.49m19.65.2c0 .82-.76 1.48-1.7 1.48s-1.7-.66-1.7-1.49c0-.81.76-1.48 1.7-1.48s1.7.67 1.7 1.49\"></path></g>", 1)
  ])))
}
export default { render: render }