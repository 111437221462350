<template>
  <JumboImage
    :image="image"
    :alt="alt"
  >
    <div
      class="flex flex-col relative lg:absolute lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-1/2 lg:right-1/4
      xl:right-1/3 rounded bg-blue-500 p-5 m-4 mb-0 lg:m-0 lg:bg-opacity-95 max-w-2xl lg:max-w-sm xl:max-w-lg
      md:mx-auto self-center text-white hyphens-auto"
    >
      <h2 class="leading-9 mb-3 lg:mb-5">
        {{ title }}
      </h2>
      <p class="my-0">
        {{ text }}
      </p>
    </div>
  </JumboImage>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { RouteLocationNormalizedLoaded } from 'vue-router';
import { useRoute } from 'vue-router';

import JumboImage from './JumboImage.vue';

interface Props {
  text: string,
  image: string,
  alt?: string,
}

withDefaults(defineProps<Props>(), {
  alt: '',
});

const route: RouteLocationNormalizedLoaded = useRoute();

const title = computed(() => route.meta.title);
</script>
