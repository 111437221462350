import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 335 407"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<text font-size=\"16\" transform=\"translate(50.88 20.16)\"> Haben Sie für einen Pflegefall </text><text font-size=\"16\" transform=\"translate(69.34 39.36)\"> ausreichend vorgesorgt? </text><path fill=\"#4181D2\" fill-opacity=\".6\" d=\"M149.16 78.31s12.44-.6 23.48 2.03c.04 2.75-23.54 134.6-23.54 134.6z\"></path><path fill=\"#4181D2\" fill-opacity=\".8\" d=\"m149.68 214.92 23.99-134.68s67.07 6 100.87 79.34c0 0 32.72 58.34-9 127.32z\"></path><path fill=\"#4181D2\" d=\"m148.6 214.82.07-136.51-.42-.01c-76.04 0-137.68 61.64-137.68 137.68s61.64 137.68 137.68 137.68c49.64 0 93.13-26.28 117.35-65.67z\"></path><text fill=\"#4181D2\" font-size=\"16\" transform=\"translate(7.1 394.38)\"> 66% </text><text font-size=\"16\" transform=\"translate(47 394.38)\"> Nein </text><text fill=\"#4181D2\" font-size=\"16\" transform=\"translate(118.1 394.04)\"> 31% </text><text font-size=\"16\" transform=\"translate(158 394.04)\"> Ja </text><text fill=\"#4181D2\" font-size=\"16\" transform=\"translate(212.1 394.38)\"> 3% </text><text font-size=\"16\" transform=\"translate(241 394.38)\"> Weiß nicht </text><path fill=\"none\" d=\"M27.1 228.35h53v19h-53z\"></path><text fill=\"#FFF\" font-size=\"16\" transform=\"translate(27.1 239.7)\"> 66% </text><path fill=\"none\" d=\"M238.1 185.35h53v19h-53z\"></path><text fill=\"#FFF\" font-size=\"16\" transform=\"translate(238.1 196.7)\"> 31% </text><path fill=\"none\" d=\"M129.85 91.6h53v36.25h-53z\"></path><text fill=\"#FFF\" font-size=\"16\" transform=\"translate(152.25 102.96)\"> 3 </text><text fill=\"#FFF\" font-size=\"16\" transform=\"translate(150.02 118.96)\"> % </text>", 18)
  ])))
}
export default { render: render }