<template>
  <div class="row chart-border self-center flex-grow">
    <Bar
      :ratio="ratio.stationaer"
      title="Stationär"
    >
      <template #stack>
        <span>
          Versorgungslücke<br>
          {{ format(stationaer.luecke) }}<sup>1</sup>
        </span>
      </template>
      <span>
        Gesetzliche Pflegeversicherung<br>
        {{ format(stationaer.spv) }}<sup>2</sup>
      </span>
    </Bar>

    <Bar
      :ratio="ratio.ambulant"
      title="Ambulant"
    >
      <template #stack>
        <span>
          Versorgungslücke<br>
          {{ format(ambulant.luecke) }}<sup>1</sup>
        </span>
      </template>
      <span>
        Gesetzliche Pflegeversicherung<br>
        {{ format(ambulant.spv) }}<sup>3</sup>
      </span>
    </Bar>
  </div>
</template>

<script>
import Bar from '@/application/utils/chart/ChartBar.vue';
import { format } from '@/application/utils/money';

export default {
  components: {
    Bar,
  },

  props: {
    stationaer: {
      type: Object,
      required: true,
    },
    ambulant: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ratio() {
      return {
        stationaer: (this.stationaer.luecke / this.stationaer.total) * 100,
        ambulant: (this.ambulant.luecke / this.ambulant.total) * 100,
      };
    },
  },

  methods: {
    format,
  },
};
</script>

<style lang="scss" scoped>
  @import '@/../css/variables';

  .chart-border {
    position: relative;

    &::after {
      border-bottom: 2px solid $black;
      bottom: 0;
      content: '';
      left: 15px;
      position: absolute;
      right: 15px;
    }
  }
</style>
