<template>
  <div
    v-once
    class="container"
  >
    <div class="row class mb-14">
      <div class="col-lg-8 offset-lg-2">
        <h3>
          {{ title }}
        </h3>
      </div>
    </div>
    <div class="row justify-center">
      <div
        v-for="entry in teeth"
        :key="entry.title"
        class="col-6 col-sm-4 col-lg-3 mb-7 mt-2.5"
      >
        <SvgVue
          :icon="`bedarfsinfo/zahnzusatzversicherung/${entry.icon}`"
          style="height: 90px;"
          class="inline-block"
        />
        <br>
        {{ entry.title }} <br>
        {{ entry.price }} €
      </div>
    </div>
  </div>
</template>

<script setup>
import SvgVue from "@/application/components/SvgVue";

const title = `Die Grafik gibt Ihnen einen Überblick der häufigsten Zahnbehandlungen und die durchschnittlichen
Gesamtkosten`

const teeth = [
  {
    icon: 'wurzelbehandlung',
    title: 'Wurzelbehandlung',
    price: '300 - 1.600',
  },
  {
    icon: 'vollkeramikkrone',
    title: 'Vollkeramikkrone',
    price: '450 - 700',
  },
  {
    icon: 'implantat-keramikkrone',
    title: 'Implantat mit Keramikkrone',
    price: '3.000 - 3.500',
  },
  {
    icon: 'kieferorthopaedie',
    title: 'Kieferorthopädie',
    price: '1.300 - 2.200',
  },
  {
    icon: 'keramik-inlay',
    title: 'Keramik-Inlay',
    price: '500 - 1.000',
  },
  {
    icon: 'gold-inlay',
    title: 'Gold-Inlay',
    price: '450 - 600',
  },
  {
    icon: 'professionelle-zahnreinigung',
    title: 'Professionelle Zahnreinigung',
    price: '45 - 150',
  },
  {
    icon: 'kompositfuellung',
    title: 'Kompositfüllung',
    price: '120 - 300',
  },
];
</script>
