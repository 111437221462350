<template>
  <div>
    <div class="text-center mb-7">
      <p>Erstattungsatz</p>

      <div class="w-full mx-auto px-4">
        <div class="row">
          <div class="col-lg-8 offset-lg-2">
            <div class="mt-7 px-14 md:px-0">
              <Slider
                v-model="percentage"
                :min="0"
                :max="100"
                :step="5"
                :format="{suffix: '%'}"
                min-label="0%"
                max-label="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table Table">
        <colgroup>
          <col width="34%">
          <col width="23%">
          <col width="23%">
          <col width="23%">
        </colgroup>
        <thead>
          <tr>
            <th />
            <th class="text-right">
              Wurzelbehandlung
            </th>
            <th class="text-right">
              Inlay
            </th>
            <th class="text-right">
              Implantat
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Rechnungsbetrag</td>
            <td
              class="text-right"
              v-text="format(1600)"
            />
            <td
              class="text-right"
              v-text="format(600)"
            />
            <td
              class="text-right"
              v-text="format(3300)"
            />
          </tr>
          <tr>
            <td>GKV zahlt</td>
            <td
              class="text-right"
              v-text="format(350)"
            />
            <td
              class="text-right"
              v-text="format(40)"
            />
            <td
              class="text-right"
              v-text="format(420)"
            />
          </tr>
          <tr>
            <td>Eigenanteil ohne Zahnzusatz</td>
            <td
              class="text-right"
              v-text="format(1250)"
            />
            <td
              class="text-right"
              v-text="format(560)"
            />
            <td
              class="text-right"
              v-text="format(2880)"
            />
          </tr>
          <tr class="border-t font-bold">
            <td>Eigenanteil mit Zahnzusatz</td>
            <td
              class="text-right"
              v-text="calculate(1250, 1600)"
            />
            <td
              class="text-right"
              v-text="calculate(560, 600)"
            />
            <td
              class="text-right"
              v-text="calculate(2880, 3300)"
            />
          </tr>
        </tbody>
      </table>

      <div class="text-muted text-center text-sm mb-14">
        Berechnungsbeispiel berücksichtigt Tariferstattung auf Basis des Rechnungsbeitrages.
      </div>
    </div>
  </div>
</template>

<script>
import Slider from '@/application/components/Slider.vue';
import { format } from '@/application/utils/money';

export default {
  components: {
    Slider,
  },

  props: {
    value: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      percentage: this.value,
    };
  },

  methods: {
    format,
    calculate(max, ofValue) {
      const inversePercentage = this.percentage / 100;
      return format(Math.max(0, max - (ofValue * inversePercentage)));
    },
  },
};
</script>
