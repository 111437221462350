<template>
  <div class="row items-end">
    <div class="col-sm-4 col-md-5 text-center pr-0">
      <div
        :style="{ height: incomeToTotal * barHeight + 'px' }"
        data="testid-avbar-incomebar"
        class="bg-secondary text-white p-2.5 mb-2.5 mx-5 flex flex-col justify-center"
        style="min-height: 110px;"
      >
        <p>{{ labels.income }}</p>

        <popover
          ref="popover"
          :options="{ placement: 'left-start' }"
          title="Hinweis!"
        >
          {{ labels.popoverText }}
          <template #reference>
            <slot
              name="incomeBar"
            />
          </template>
        </popover>
      </div>

      Heute<br>&nbsp;
    </div>

    <div class="col-sm-4 col-md-2 self-center text-center pb-14 px-0">
      Inflation<br>
      <strong>{{ inflation }}%</strong>
      <div :style="{ transform: 'rotateZ(' + arrowAngle + 'deg)' }">
        <font-awesome-icon
          :icon="['fas', 'arrow-right']"
          size="3x"
        />
      </div>
    </div>

    <div class="col-sm-4 col-md-5 text-center pl-0">
      <div
        v-show="income <= 0"
        data="testid-avbar-segment-spacer"
        :style="{ height: spacerHeight + 'px' }"
      />

      <div
        data="testid-avbar-segment-container"
        :style="{
          height: monetaryNeedToTotal * barHeight + 'px',
          minHeight: minBarHeight + 'px' ,
        }"
        class="flex flex-col mb-2.5 justify-end"
      >
        <slot name="segmentsBar" />
      </div>

      Bedarf zu {{ labels.monetaryNeed }}
      <br>
      {{ format(monetaryNeed) }}
    </div>
  </div>
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import type { PropType } from 'vue';
import { computed, defineComponent, toRef } from 'vue';

import Popover from '@/application/components/Popover.vue';
import { useRatios } from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/composables/ratios';
import { format } from '@/application/utils/money';
import { getSlotFromContext, isTextVNode } from '@/application/utils/vue';

type Labels = {
  income: string,
  monetaryNeed: string,
  popoverText: string,
};

export default defineComponent({
  components: {
    FontAwesomeIcon,
    Popover,
  },

  props: {
    labels: {
      type: Object as PropType<Labels>,
      required: true,
    },

    income: {
      type: Number,
      required: true,
    },

    inflation: {
      type: Number,
      required: true,
    },

    gap: {
      type: Number,
      required: true,
    },

    monetaryNeed: {
      type: Number,
      required: true,
    },

    // bar configuration

    barHeight: {
      type: Number,
      default: 300,
    },

    segmentHeight: {
      type: Number,
      default: 70,
    },

    gapSegmentHeight: {
      type: Number,
      default: 200,
    },
  },

  setup(props, context) {
    const { incomeToTotal, monetaryNeedToTotal } = useRatios(
      toRef(props, 'income'),
      toRef(props, 'monetaryNeed'),
    );

    const segmentCount = computed(() => {
      const segmentsBar = getSlotFromContext(context, 'segmentsBar');
      return segmentsBar?.().filter((vnode) => !isTextVNode(vnode)).length ?? 0;
    });

    // by multiplying with -62 we never go above/below 62 degrees
    const arrowAngle = computed(() => (monetaryNeedToTotal.value - incomeToTotal.value) * -62);
    const spacerHeight = computed(() => props.barHeight - segmentCount.value * props.segmentHeight);
    const minBarHeight = computed(() => (
      props.gap > 0
        ? props.gapSegmentHeight
        : segmentCount.value * props.segmentHeight
    ));

    return {
      // computed
      arrowAngle,
      spacerHeight,
      minBarHeight,
      incomeToTotal,
      monetaryNeedToTotal,

      // methods
      format,
    };
  },
});
</script>
