import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 100 100"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M50 .9C22.7.9.6 23 .6 50.3S22.7 99.8 50 99.8s49.4-22.1 49.4-49.4S77.3.9 50 .9m.4 26.2L79.9 46l-1.8 6.6-3.1-2v21.8c-5.7-.7-10.2-.4-18.3 2.9-4.6 1.8-7.7 1.5-10.5 1.8-4 .3-13.4-4.1-13.5-4.1 0 0-3.3-1.2-7-1.1.1-3.2.1-21.3.1-21.3l-3.1 2-1.9-6.6zM36.6 75.6c10.6 5.8 24.7-1.3 24.8-1.3.6-.2 13-3.9 22.8 1.7l-.5.9c-9.5-5.3-21.8-1.7-21.9-1.6-.4.2-7.4 3.7-15.4 3.7-3.4 0-7-.6-10.3-2.4-10.6-5.8-22.1-.7-22.2-.7l-.4-.9c.1-.1 12-5.4 23.1.6m32.9 19c-4.5-3.2-10.3-1.4-11.4-1.1-1 .6-5.2 3-10.1 3-2.3 0-4.8-.6-7.1-2.1-5.5-3.7-11.4-.4-11.5-.4l-2-3.5c.3-.2 8.2-4.6 15.7.6 5.4 3.7 12.9-1 13-1l.4-.2c.3-.1 8.6-3.2 15.2 1.5zm9.8-7.1c-7.4-4.7-16.9-1.9-18-1.5-1.1.6-7.1 3.8-14 3.8-3.1 0-6.4-.7-9.5-2.6-8.8-5.5-18.2-.7-18.3-.6l-1.4-2.7c.4-.2 11.1-5.7 21.3.7 8.7 5.5 20.4-1.3 20.5-1.4l.3-.1c.5-.2 11.8-4 20.8 1.8zm4.1-5.4c-8.9-5-20.4-1.9-21.5-1.6-.9.5-7.8 3.8-15.7 3.8-3.5 0-7.1-.7-10.5-2.5-10.4-5.7-21.7-.7-21.8-.6l-.8-1.8c.5-.2 12.3-5.5 23.6.7 10.4 5.7 24.2-1.3 24.4-1.3l.2-.1c.5-.2 13.1-3.9 23.1 1.8z"
    }, null, -1)
  ])))
}
export default { render: render }