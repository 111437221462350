type Converter<T, K> = (value: T) => K;

const toNumber: Converter<string, number> = (value: string) => +value;
const toNumberOrNull: Converter<string, number | null> = (value: string) => {
  if (value === '') {
    return null;
  }
  return +value;
};

export enum ConversionFunction {
  StringToNumber = 'StringToNumber',
  StringToNumberOrNull = 'StringToNumberOrNull',
}

export const conversionFunctions = {
  [ConversionFunction.StringToNumber]: toNumber,
  [ConversionFunction.StringToNumberOrNull]: toNumberOrNull,
};

type ConversionFunctions = typeof conversionFunctions;

export function convertValues<
  T extends object, K extends Partial<Record<keyof T, keyof ConversionFunctions>>,
  R extends Record<keyof T, unknown>,
>(
  values: T,
  config: K,
): R {
  const converted: Record<keyof T, unknown> = {} as Record<keyof T, unknown>;
  Object.entries(values).forEach(([key, value]) => {
    const conversionFunction = config[key as keyof T] ? conversionFunctions[config[key]] : undefined;
    converted[key as keyof T] = conversionFunction ? conversionFunction(value) : value;
  });
  return converted as R;
}
