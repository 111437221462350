<template>
  <SpartenWrapper
    image="images/bedarfsinfo/gewerbliche-gebaeudeversicherung/header"
    :text="subtitle"
    alt="Blick aus dem Fenster auf Bürogebäude in diffusem Sonnenlicht"
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    :versorgungen="data.bedarfsinfo.versorgungen"
    versorgungen-headline="Wie gut ist unser Gebäude versichert?"
    versorgungen-description="Gewerbliche Gebäudeversicherung"
  >
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h2>Was zählt alles zu dem Gebäude?</h2>
            </div>

            <div class="text-center pb-7">
              <p>
                Das Gebäude selbst und alle fest mit dem Gebäude verbundenen Gegenstände werden hinzugezählt.
              </p>
            </div>
          </div>
        </div>

        <div class="row items-center">
          <div class="col-12 col-md-6 col-lg-8">
            <SvgVue
              icon="bedarfsinfo/gewerbliche-gebaeudeversicherung/gebaeude-1.svg"
              class="block mx-auto img-fluid my-5"
            />
          </div>

          <div class="col-12 col-md-6 col-lg-4 mt-sm-4 md:mt-0">
            <div>
              <BulletPointList
                title="Folgende Elemente können zusätzlich über die Gebäudeversicherung versichert werden:"
                :items="additionalElements"
              />
            </div>
          </div>
        </div>

        <div class="row mt-14">
          <div class="col-12 col-md-6">
            <NiceToKnow :bullets="excluded" />
          </div>

          <div class="col-12 col-md-6">
            <NiceToKnow :bullets="topTariffs" />
          </div>
        </div>

        <div class="row mt-14">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h3>Wann habe ich Anspruch auf Leistungen aus der Gebäudeversicherung?</h3>
            </div>

            <div class="text-center pb-7">
              <p>
                Kommt es zu Schäden am Gebäude, werden die Kosten erstattet, sofern
                eines dieser Ereignisse die Ursache war:
              </p>
            </div>
          </div>
        </div>

        <div class="row items-center">
          <div class="col-12 col-md-6 col-lg-4">
            <BulletPointList
              title="Die Gebäudeversicherung erstattet Schäden infolge von..."
              :items="claims"
            />
          </div>

          <div class="col-12 col-md-6 col-lg-8 mt-sm-4 md:mt-0">
            <SvgVue
              icon="bedarfsinfo/gewerbliche-gebaeudeversicherung/gebaeudeschaden.svg"
              class="block mx-auto img-fluid my-5"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section bg-dark text-light text-center">
      <div class="container">
        <div class="row pb-7">
          <div class="col">
            <div class="text-center">
              <h3>Um welche Leistungen kann ich den Versicherungsschutz individuell erweitern?</h3>
            </div>
          </div>
        </div>

        <div class="row justify-center">
          <div
            v-for="{ icon, text } in benefits"
            :key="text"
            class="col-12 col-md-6 col-lg-2 mb-7"
          >
            <SvgVue
              :icon="`bedarfsinfo/wohngebaeude/${icon}`"
              class="w-1/2 mb-5 inline-block"
            />
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="text" />
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h3>Wann habe ich keinen Versicherungsschutz?</h3>
            </div>
          </div>
        </div>

        <div class="row items-center">
          <div class="col-12 col-md-6 col-lg-8">
            <SvgVue
              icon="bedarfsinfo/gewerbliche-gebaeudeversicherung/gebaeude-2.svg"
              class="block mx-auto img-fluid my-5"
            />
          </div>

          <div class="col-12 col-md-6 col-lg-4 mt-sm-4 md:mt-0">
            <BulletPointList
              title="Die Gebäudeversicherung erstattet keine Schäden infolge von..."
              :items="noCoverage"
            />
          </div>
        </div>

        <div class="row mt-14">
          <div class="col-12 col-md-6">
            <NiceToKnow :bullets="newBuilding" />
          </div>

          <div class="col-12 col-md-6">
            <NiceToKnow :bullets="noCoverageHint" />
          </div>
        </div>

        <div class="row mt-14">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h3>Welche Zahlungen erhalte ich im Schadenfall?</h3>
            </div>
          </div>
        </div>

        <div class="row justify-center">
          <div class="col-lg-8 col-md-10 flex flex-col justify-center">
            <BulletPointList :items="damagePayment" />
          </div>
        </div>

        <div class="row mt-14">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h3>Schadenbeispiele</h3>
            </div>
          </div>
        </div>

        <div class="row justify-center">
          <div class="col-md-6 flex flex-col">
            <BulletPointList
              description="Infolge eines Rohrbruchs tritt Leitungswasser aus und beschädigt Fußboden und Wände."
              :items="examples1"
            />
          </div>

          <div class="col-md-6 flex flex-col">
            <BulletPointList
              description="
                 In ein Bürogebäude wird eingebrochen. Um sich Zutritt zu verschaffen haben die Einbrecher die Rolläden
                  beschädigt und ein Fenster eingeschlagen."
              :items="examples2"
            />
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';
import SvgVue from "@/application/components/SvgVue";

interface Props {
  data: Bedarfsinfo
}

defineProps<Props>();

const subtitle = `
  Entstehen Schäden an ihrer Immobilie, die beispielsweise durch Feuer,
  Blitzschlag, Sturm oder das Platzen eines Leitungswasserrohres ausgelöst
  werden, kommen erhebliche Kosten auf Sie zu. Mit einer Gebäudeversicherung
  übertragen Sie das finanzielle Risiko auf einen Versicherer.
`;

const additionalElements = [
  {
    title: 'Das Gebäude und Nebengebäude',
    text: 'Garagen, Carports und Schuppen',
  },
  {
    title: 'Gebäudezubehör',
    text: 'Terrassen, Müllcontainer, Klingel- und Briefkastenanlagen',
  },
  {
    title: 'Einbauten',
    text: 'Einbauküchen, Einbauschränke, Sanitäranlagen und fest verlegte Fußbodenbeläge',
  },
  {
    title: 'Zubehör',
    text: `Soweit es zur Ausstattung zählt und fest verbunden mit dem Gebäude ist, wie z. B. Klima- und
            Zentralheizungsanlagen oder elektrische Rollläden`,
  },
];

const excluded = [
  {
    title: 'Vom Versicherungsschutz ausgeschlossen',
    text: `Nicht versichert sind beispielsweise Einrichtungsgegenstände (Büromöbel, lose Teppiche, Vorhänge) oder
            Gebrauchsgegenstände (Elektrogeräte, Computer, Bilder). Diese müssen Sie über die
            Geschäftsinhaltsversicherung absichern.`,
  },
];

const topTariffs = [
  {
    title: 'Versicherungsschutz nur in Top-Tarifen',
    text: `Platzen Ableitungsrohre, Wasser- oder Heizungsrohre außerhalb des Grundstücks, besteht Versicherungsschutz
            nur in Top-Tarifen. Aufgrund hoher Kosten, die infolge eines Schadens entstehen, empfehlen wir das Risiko
            mitzuversichern.`,
  },
];

const claims = [
  {
    title: 'Feuer und Blitzschlag',
    text: 'z. B. durch eine brennende Zigarette',
  },
  {
    title: 'Explosion und Implosion',
    text: 'z. B. durch eine defekte Gasleitung',
  },
  {
    title: 'Leitungswasser',
    text: 'z. B. nach einem Wasserrohrbruch',
  },
  {
    title: 'Sturm und Hagel',
    text: 'z. B. nach einem Unwetter',
  },
];

const benefits = [
  {
    icon: 'elementarschaeden.svg',
    text: '<h5>Elementarschäden</h5><small>(z.B. Überschwemmung, Erdbeben oder Schneedruck)</small>',
  },
  {
    icon: 'glasbruch.svg',
    text: '<h5>Glasbruch</h5><small>(unabhängig von der Schadensursache)</small>',
  },
  {
    icon: 'regen-schmelwasser.svg',
    text: '<h5>Regen-/&#8203;Schmelzwasser</h5>',
  },
  {
    icon: 'ueberspannung.svg',
    text: '<h5>Überspannungs&shy;schäden</h5>',
  },
  {
    icon: 'unbenannte-gefahren.svg',
    text: '<h5>Unbenannte Gefahren</h5><small>(All-Risk-Deckung)</small>',
  },
];

const noCoverage = [
  {
    title: 'Schäden durch Unwetter',
    text: 'Viele Versicherer regulieren erst ab Windstärke 8',
  },
  {
    title: 'Vorsatz',
    text: 'Selbst und vorsätzlich verursachte Schäden ',
  },
  {
    title: 'Grobe Fahrlässigkeit',
    text: 'Hat im Regelfall eine Leistungskürzung zur Folge',
  },
  {
    title: 'Verschleiss und Abnutzung',
    text: 'Können nicht versichert werden',
  },
];


const newBuilding = [
  {
    title: 'Hinweis bei Neubauten',
    text: `Die Gebäudeversicherung leistet erst ab Bezugsfertigkeit der Immobilie. Zuvor wird im Regelfall eine
            prämienfreie Feuer-Rohbauversicherung über den späteren Gebäudeversicherer abgeschlossen. Diese leistet für
            Schäden durch Brand, Blitzschlag oder Explosion. Nicht mitversichert sind Schäden durch Leitungswasser,
            Sturm oder Hagel.`,
  },
];

const noCoverageHint = [
  {
    title: 'Hinweis',
    text: 'Diese Aufzählung beinhaltet wesentliche Leistungsausschlüsse und ist nicht abschließend. ',
  },
];

const damagePayment = [
  { text: `Neuwert bei Wiederaufbau: Kommt es zu einem Totalschaden an der Immobilie, leistet die Versicherung die
            Kosten, um die Immobilie in neuem Zustand aufzubauen.` },
  { text: `Zeitwert bei Auszahlung: Soll nach einem Totalschaden die Immobilie nicht wieder aufgebaut werden, wird das
            Alter und die Abnutzung der Immobilie bei der Ermittlung der Schadensumme berücksichtigt (einige Top-Tarife
            entschädigen auch den Neuwert).` },
  { text: 'Bei beschädigten Sachen werden die notwendigen Reparaturen erstattet.' },
];

const examples1 = [
  { text: 'Die Versicherung erstattet die Reparaturkosten, um das Leitungsrohr auszutauschen.' },
  {
    text: 'Es werden die Kosten zum Trocknen und neu Streichen der Wände sowie der Austausch des Fußbodens übernommen.',
  },
];

const examples2 = [
  { text: 'Es werden die Kosten für die beschädigten Rolläden und das Fenster übernommen.' },
  { text: 'Es werden die Kosten für die Bewachung des Bürogebäudes übernommen, bis das Fenster ersetzt wurde.' },
  { text: 'Es werden die Kosten für die gestohlenen Gegenstände übernommen.' },
];
</script>
