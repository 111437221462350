import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 98.89 98.89"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"49.44\" cy=\"49.44\" r=\"49.44\" fill=\"#fff\"></circle><g fill=\"#343a40\"><rect width=\"68.33\" height=\"37.67\" x=\"15.45\" y=\"33.3\" rx=\"4.67\"></rect><circle cx=\"61.45\" cy=\"52.63\" r=\"10\" stroke=\"#fff\" stroke-miterlimit=\"10\" stroke-width=\"5\"></circle><ellipse cx=\"27.87\" cy=\"36.13\" rx=\"5.08\" ry=\"4.92\" transform=\"rotate(-80.22 27.87 36.13)\"></ellipse></g>", 2)
  ])))
}
export default { render: render }