import type { RouteRecordRaw } from "vue-router";
import MainLayout from "@/application/layouts/MainLayout.vue";
import NotFound from "@/application/components/NotFound.vue";
import { Finanzmanager } from "@/application/router/types/types";

export const notFoundRoute: RouteRecordRaw[] = [
  {
    path: '/:pathMatch(.*)*',
    component: MainLayout,
    children: [
      {
        path: '',
        name: Finanzmanager.NOT_FOUND,
        component: NotFound,
        meta: {
          title: '404',
        },
      }
    ]
  },
]
