<template>
  <Tab :name="name">
    <h3>Grundfähigkeitsversicherung</h3>
    <div class="my-5">
      Im Falle, dass Sie aus gesundheitlichen Gründen keiner beruflichen Tätigkeit mehr nachgehen
      können,
      sichert Sie diese Versicherung finanziell ab. Sie erhalten Leistungen...
    </div>

    <div class="mb-6">
      <BulletPointList
        small
        :items="[
          {text: '... unabhängig von der Ursache der Erkrankung.'},
          {text: '... in Form einer monatlichen Rente, deren Höhe bei Vertragsabschluss vereinbart wird.'},
        ]"
      />
    </div>

    <h3>Ab wann habe ich Anspruch auf die versicherte Rentenhöhe?</h3>
    <div class="my-5">
      Die Grundfähigkeitsversicherung, die bei Verlust von klar definierten Grundfähigkeiten und in
      der Regel im
      Pflegefall leistet, ist eine besonders kostengünstige Absicherung der Arbeitskraft.
    </div>
    <div class="mb-5">
      Es sind bestimmte Grundfähigkeiten wie Hören, Sehen, Auto fahren und ähnliches versichert. Je
      nach Schwere der Einschränkung
      infolge des Verlustes der Fähigkeiten, müssen eine oder mehrere Fähigkeiten für einen Zeitraum
      von mindestens einem Jahr verloren
      gegangen sein, um die versicherte Rente zu erhalten (je Anbieter gibt es unterschiedliche
      Definitionen).
    </div>

    <div class="row">
      <div class="col-12 col-md-4 flex justify-end flex-col pr-0">
        <div
          v-for="ability in [
            'Anordnung gesetzlicher Betreuung',
            'Konzentration / Aufmerksamkeit',
            'Auffassung',
            'Auto fahren',
            'Hören',
            'Heben und Tragen',
            'Greifen',
            'Sitzen',
            'Stehen',
            'Gehen',
          ]"
          :key="ability"
          class="dashed-line dashed-line--right"
          :data-text="ability"
        />
      </div>
      <div class="col-12 col-md-4">
        <img
          src="../../../../../../assets/images/bedarfsinfo/einkommenssicherung/mann_gestreift.jpg"
          alt="Mann"
          class="w-full hidden md:block mx-auto"
          style="max-width: 250px"
          loading="lazy"
        >
        <span class="info-banner dude-caption">Leistungen auch bei Pflegebedürftigkeit oder Demenz möglich!</span>
      </div>
      <div class="col-12 col-md-4 flex justify-end flex-col pl-0">
        <div
          v-for="ability in [
            'Orientierung',
            'Handlungsplanung',
            'Gedächtnis',
            'Sehen',
            'Sprechen',
            'Arme bewegen',
            'Hände gebrauchen',
            'Knien oder Bücken',
            'Treppen steigen'
          ]"
          :key="ability"
          class="dashed-line dashed-line--left"
          :data-text="ability"
        />
      </div>
    </div>

    <div class="py-7">
      Die Leistungen sind zwar geringer als bei einer Berufsunfähigkeitsversicherung, dafür sind die
      Beiträge wesentlich günstiger, so dass auch Personen in Risikoberufen, wie Handwerker, sich den
      Versicherungsschutz leisten können.
    </div>

    <NiceToKnow
      classes="mb-0"
      :bullets="niceToKnow"
    />
  </Tab>
</template>

<script setup lang="ts">
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import Tab from '@/application/components/Tab.vue';

interface Props {
  name: string
}

defineProps<Props>();

const niceToKnow = [
  {
    title: 'Hinweis:',
    text: `Aufgrund der eindeutigen Beurteilungskriterien im Versicherungsfall ist die Leistungsprüfung
          wesentlich schneller als bei der Berufsunfähigkeitsversicherung. Die Grundfähigkeitsversicherung bildet
          lediglich eine Basisversicherung und empfiehlt sich, sofern eine Berufsunfähigkeits- oder
          Erwerbsunfähigkeitsversicherung nicht abgeschlossen werden kann.`,
  },
];
</script>

<style scoped>
.dashed-line {
  position: relative;
  z-index: 0;
  padding: 0;
  line-height: 1.5;
}

.dashed-line::before {
  content: attr(data-text);
  display: inline;
  background: white;
}

.dashed-line.dashed-line--left {
  text-align: left;
}

.dashed-line.dashed-line--right::before {
  padding-right: 0.25rem;
}

.dashed-line.dashed-line--left::before {
  padding-left: 0.25rem;
}

.dashed-line::after {
  content: '';
  display: block;
  height: 0;
  position: absolute;
  border-bottom: 1px dashed #9a9a9a;
  top: calc(50% - 1px);
  right: 0;
  left: 0;
  z-index: -1;
}

.dude-caption {
  display: none;
  width: 14rem;
  top: auto;
  bottom: 5px;
  position: relative;
  margin: 1rem 0;
}

@media (min-width: 768px) {
  .dude-caption {
    display: block;
    position: absolute;
    margin: 0;
  }

  .dashed-line {
    line-height: 1.4;
    padding: 0.5rem 0;
  }

  .dashed-line.dashed-line--left {
    text-align: right;
  }
}
</style>
