<template>
  <div class="chart">
    <div class="row items-end">
      <div :class="[barWidthClass, containerOffsetClass]" />
      <div
        v-if="showLohnfortzahlung"
        :class="barWidthClass"
      />
      <div :class="barWidthClass">
        <div class="info info--wide">
          Das Krankentagegeld schließt die Lücke
        </div>
      </div>
      <div :class="`col-${buInfoWidth}`">
        <div class="info info--narrow">
          Die Berufsunfähig&shy;keitsversicherung schließt die Lücke
        </div>
      </div>
    </div>

    <div class="graph row">
      <Bar
        :height="barHeight"
        :class="[barWidthClass, containerOffsetClass]"
      >
        <p :class="$style.ieOverflowFix">
          Monatliches Netto&shy;einkommen
        </p>
        <popover
          :class="$style.ieOverflowFix"
          :options="{placement: 'left-start'}"
          title="Hinweis!"
        >
          <span>
            Schreiben Sie Ihr Nettoeinkommen in das vorgegebene Feld
          </span>
          <template #reference>
            <slot />
          </template>
        </popover>
      </Bar>
      <Bar
        v-if="showLohnfortzahlung"
        :height="barHeight"
        :class="barWidthClass"
      >
        Lohn&shy;fortzahlung durch Arbeit&shy;geber 100 % des Netto&shy;einkommens
      </Bar>
      <Bar
        :height="barHeight"
        :class="barWidthClass"
        :ratio="ratio.krankentagegeldLuecke"
      >
        <template #stack>
          <span>Versorgungs&shy;lücke</span>
          <span
            v-show="isUpdating"
            class="spinner"
          />
          <span v-show="!isUpdating">{{ format(krankentagegeldLuecke) }}</span>
        </template>

        <span>Krankengeld (GKV)</span>
        <span
          v-show="isUpdating"
          class="spinner spinner--white"
        />
        <span v-show="!isUpdating">{{ format(krankentagegeld) }}</span>
      </Bar>
      <Bar
        v-if="showErwerbsminderungsrente"
        :height="barHeight"
        :class="barWidthClass"
        :ratio="ratio.erwerbsrenteLuecke"
      >
        <template #stack>
          <span>Versorgungs&shy;lücke</span>
          <span
            v-show="isUpdating"
            class="spinner"
          />
          <span v-show="!isUpdating">{{ format(erwerbsminderungsrenteVollLuecke) }}</span>
        </template>

        <span>Volle Erwerbs&shy;minderungs&shy;rente {{ legendMark('volle-rente') }}</span>
        <span
          v-show="isUpdating"
          class="spinner spinner--white"
        />
        <span v-show="!isUpdating">{{ format(erwerbsminderungsrenteVoll) }}</span>
      </Bar>
      <Bar
        v-if="showErwerbsminderungsrente"
        :height="barHeight"
        :class="barWidthClass"
        :ratio="ratio.erwerbsrenteHalbLuecke"
      >
        <template #stack>
          <span>Versorgungs&shy;lücke</span>
          <span
            v-show="isUpdating"
            class="spinner"
          />
          <span v-show="!isUpdating">{{ format(erwerbsminderungsrenteHalbLuecke) }}</span>
        </template>

        <span>Halbe Erwerbs&shy;minderungs&shy;rente {{ legendMark('halbe-rente') }}</span>
        <span
          v-show="isUpdating"
          class="spinner spinner--white"
        />
        <span v-show="!isUpdating">{{ format(erwerbsminderungsrenteHalb) }}</span>
      </Bar>
      <Bar
        :height="barHeight"
        :class="barWidthClass"
        :ratio="ratio.berufsunfaehigkeitLuecke"
      >
        <template #stack>
          <span>Versorgungs&shy;lücke</span>
          <span
            v-show="isUpdating"
            class="spinner"
          />
          <span v-show="!isUpdating">{{ format(money) }}</span>
        </template>
      </Bar>
    </div>

    <div class="row">
      <div
        :class="[`col-${maxColumnsUsed}`, containerOffsetClass]"
        class="line mb-5"
      />
    </div>

    <div class="row mb-2.5">
      <div :class="[barWidthClass, containerOffsetClass]" />
      <div
        v-if="showLohnfortzahlung"
        :class="barWidthClass"
      >
        <div class="double-arrow">
          Max. 6 Wochen
        </div>
      </div>
      <div :class="barWidthClass">
        <div
          class="double-arrow"
          style="white-space: nowrap;"
        >
          Max. 72 Wochen {{ legendMark('krankengeld') }}
        </div>
      </div>
    </div>
    <div class="row">
      <div :class="[barWidthClass, containerOffsetClass]" />
      <div :class="`col-${krankheitRangeWidth}`">
        <div class="double-arrow">
          Bei Krankheit
        </div>
      </div>
      <div
        v-if="showErwerbsminderungsrente"
        :class="`col-${colWidth*2}`"
      >
        <div class="double-arrow">
          Bei Erwerbsunfähigkeit
        </div>
      </div>

      <div :class="barWidthClass">
        <div class="double-arrow">
          Bei Berufsunfähigkeit
        </div>
      </div>
    </div>

    <div class="row">
      <div
        class="col"
        :class="containerOffsetClass"
      >
        <div class="mt-5 flex flex-col">
          <small v-if="visibleLegends.includes('volle-rente')">
            {{ legendMark('volle-rente') }}
            Volle Erwerbs&shy;minderungs&shy;rente liegt vor bei unter 3 Stunden pro Tag Arbeitsfähigkeit
          </small>
          <small v-if="visibleLegends.includes('halbe-rente')">
            {{ legendMark('halbe-rente') }}
            Halbe Erwerbs&shy;minderungs&shy;rente liegt vor bei 3 bis 6 Stunden pro Tag Arbeitsfähigkeit
          </small>
          <small v-if="visibleLegends.includes('krankengeld')">
            {{ legendMark('krankengeld') }}
            Die Bezugsdauer des Krankengeldes reduziert sich um die Dauer der Lohnfortzahlung durch den Arbeitgeber
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Popover from '@/application/components/Popover.vue';
import Bar from '@/application/utils/chart/ChartBar.vue';
import { format } from '@/application/utils/money';

import { props } from './props';

const GRID_SIZE = 12;

export default {
  components: {
    Popover,
    Bar,
  },

  props,

  data() {
    return {
      barHeight: 320,
    };
  },

  computed: {
    ratio() {
      return {
        krankentagegeldLuecke: (this.krankentagegeldLuecke / this.money) * 100,
        erwerbsrenteLuecke: (this.erwerbsminderungsrenteVollLuecke / this.money) * 100,
        erwerbsrenteHalbLuecke: (this.erwerbsminderungsrenteHalbLuecke / this.money) * 100,
        berufsunfaehigkeitLuecke: 100,
      };
    },

    columnCount() {
      let columns = 3;
      if (this.showLohnfortzahlung) {
        columns += 1;
      }
      if (this.showErwerbsminderungsrente) {
        columns += 2;
      }
      return columns;
    },

    colWidth() {
      return Math.floor(GRID_SIZE / this.columnCount);
    },

    maxColumnsUsed() {
      return this.columnCount * this.colWidth;
    },

    containerOffsetClass() {
      // get unused columns of GRID_SIZE to offset the grid by a half, so it is center
      return `offset-${Math.floor((GRID_SIZE - this.maxColumnsUsed) / 2)}`;
    },

    barWidthClass() {
      return `col-${this.colWidth}`;
    },

    buInfoWidth() {
      const colNumber = this.showErwerbsminderungsrente ? 3 : 1;
      return this.colWidth * colNumber;
    },

    krankheitRangeWidth() {
      const colNumber = this.showLohnfortzahlung ? 2 : 1;
      return this.colWidth * colNumber;
    },

    visibleLegends() {
      const visible = [];
      if (this.showErwerbsminderungsrente) {
        visible.push('volle-rente', 'halbe-rente');
      }
      visible.push('krankengeld');
      return visible;
    },
  },

  methods: {
    format,
    legendMark(id) {
      const i = this.visibleLegends.indexOf(id);
      if (i < 0) {
        return '*';
      }
      return '*'.repeat(i + 1);
    },
  },
};
</script>

<style lang="scss" module>

  $white: #FFF;
  $black: #000;

  .ieOverflowFix {
    max-width: 100%;
  }
</style>

<style lang="scss" scoped>

  $white: #FFF;
  $black: #000;
  $blue: #4181D2;
  $blue-light: #D8E7F7;

  .line {
    border-bottom: 2px solid $black;
  }

  .double-arrow {
    border-top: 2px solid $black;
    font-size: 90%;
    margin-left: 5px;
    margin-right: 5px;
    padding: 5px 0;
    position: relative;
    text-align: center;

    &::before,
    &::after {
      border: 5px solid transparent;
      border-left-width: 10px;
      border-right-width: 10px;
      content: '';
      display: block;
      position: absolute;
      top: -6px;
    }

    &::before {
      border-left-color: $black;
      right: -15px;
    }

    &::after {
      border-right-color: $black;
      left: -15px;
    }
  }

  .info {
    background: $white;
    border: 2px solid $blue;
    display: inline-block;
    font-size: 90%;
    left: -3%;
    padding: 5px;
    position: relative;
    text-align: center;
    transform: translateX(5px) rotateZ(-3deg);
    width: 106%;
    z-index: 1;

    &--wide {
      left: -20%;
      width: 130%;
    }

    &--narrow {
      left: 3%;
      width: 90%;
    }
  }

  .stack {
    display: flex;
    flex-direction: column;
    font-size: 90%;
  }
</style>
