import { trackEvent } from "@/application/utils/matomo";

export function useTrackSearch(name: string, category: string) {

    function trackSearch(action: string) {
        trackEvent({
            category: category,
            action: action,
            name: name,
        });
    }

    return { trackSearch }
}
