<template>
  <div>
    <Slider
      class="slider"
      :min="0"
      :max="2"
      :step="1"
      :orientation="orientation"
      :model-value="modelValue"
      :no-ui-slider-options="noUiSliderOptions"
      :tooltips="false"
      hide-min-max-label
      @update:model-value="emit('update:modelValue', $event)"
    />
    <div class="relative md:mb-5 flex-grow">
      <div
        v-for="(item, i) in options"
        :key="item.label"
        :style="styleObject(i)"
        :class="{
          'text-muted': i > modelValue,
          'labelHorizontal': !isVertical,
          'labelVertical': isVertical,
        }"
        class="flex items-start absolute cursor-pointer"
        @click="emit('update:model-value', i)"
      >
        <DsIcon
          :name="item.icon"
          :variant="item.iconVariant"
          class="w-2.5 h-2.5 p-1 rounded-full text-blue-700 bg-blue-100 shrink-0 my-0.5 mr-2"
        />
        <div class="media-body">
          <strong v-text="item.label" /><br>
          <span
            class="text-muted"
            v-text="item.text"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import { computed } from 'vue';

import Slider from '@/application/components/Slider.vue';
import { DsIcon } from '@demvsystems/design-components';

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
  orientation: {
    type: String,
    default: 'horizontal',
  },
  options: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['update:modelValue']);

const isVertical = computed(() => props.orientation==='vertical');

function styleObject(i) {
  const style = {
    opacity: i <= props.modelValue ? 1:0.4,
  };

  if (isVertical.value) {
    style.top = `${i * (1 / (props.options.length - 1)) * 100}%`;
  } else {
    style.left = `${i * (1 / (props.options.length - 1)) * 100}%`;
  }

  return style;
}

const noUiSliderOptions = {
  pips: {
    mode: 'steps',
    density: 100,
  },
};
</script>

<style lang="scss" scoped>
.slider {
  &::v-deep(.slider-marker) {
    background: #d2d5db;
    height: 16px;
    width: 1px;
    position: absolute;
    transform: translate(-0.5px, -3px);
  }

  &::v-deep(.slider-marker-vertical.slider-marker) {
    background: #d2d5db;
    width: 16px;
    height: 1px;
    position: absolute;
    transform: translate(0, -0.5px);
  }

  &::v-deep(.slider-value) {
    display: none;
  }

  &::v-deep(.slider-vertical) {
    height: 270px;
  }
}
</style>

<style lang="scss" scoped>
.labelHorizontal {
  padding-top: 2rem;
  transform: translateX(-50%);
  width: 250px;
}

.labelVertical {
  margin-left: 2rem;
  margin-top: -0.5rem;
  transform: translateY(-12%);
}
</style>
