<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2">
          <h2 class="text-center mb-7">
            Welche Zuzahlungen erwarten mich beim Zahnarzt?
          </h2>

          <ZZTable :value="value" />
        </div>
      </div>
    </div>

    <div class="text-center mb-14">
      <Teeth />
    </div>
  </div>
</template>

<script>
import ZZTable from '../zahnzusatz/Table.vue';
import Teeth from '../zahnzusatz/Teeth.vue';

export default {
  components: {
    ZZTable,
    Teeth,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
};
</script>
