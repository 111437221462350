<template>
  <div>
    <div v-if="!biometry?.isAvailable">
      <DsAlert type="info">Das Gerät unterstützt keine Entsperrmethoden oder hat diese nicht für die App freigegeben.</DsAlert>
    </div>

    <div v-else>
      <Switch
        :model-value="shouldLockApp"
        class="w-full"
        @update:model-value="userStore.setShouldLockApp($event)"
      >
        <div class=" font-normal">App beim Schließen automatisch sperren</div>
        <div class="text-xs text-gray-500">Erfordert beim Öffnen der App das Entsperren durch Finger-/Gesichtserkennung oder den Entsperrcode des Gerätes</div>
      </Switch>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DsAlert } from '@demvsystems/design-components';
import { storeToRefs } from "pinia";
import { useUserStore } from "@/user/store/userStore";
import Switch from "@/application/components/form/Switch.vue";

const userStore = useUserStore();
const { biometry, shouldLockApp } = storeToRefs(userStore);
</script>
