<template>
  <section class="section pb-0">
    <div class="container">
      <div class="row pb-7">
        <div class="col-12 text-center">
          <h2 class="mb-5">
            Wie wird festgestellt, ob ich Anspruch auf Leistungen aus der Pflegeversicherung habe?
          </h2>
          <p>
            Zur Beurteilung des Pflegegrades besuchen die Mitarbeiter des Medizinischen
            Dienstes der Krankenversicherungen (MDK)

            die Patienten. Bei privat Versicherten übernimmt diese Aufgabe Medicproof.
          </p>
          <p>Folgende sechs Module sind für die Begutachtung relevant:</p>
        </div>
      </div>
      <div class="row mb-14">
        <div class="col-12 col-md-10 offset-md-1">
          <FlippingList :items="abilityChecks" />
        </div>
      </div>
    </div>
    <div class="bg-light">
      <div class="container">
        <div class="row p-7 md:py-14 md:px-0">
          <div class="col-12 col-md-10 offset-md-1">
            <h3 class="mb-7">
              Ab wann haften Kinder für die Pflegekosten der Eltern?
            </h3>
            <p>
              Beim Bezahlen verlässt sich der Staat erstmal auf Sie!
            </p>
            <p>
              Bevor der Staat die Kosten für die Pflege übernimmt, stehen zunächst der
              Pflegebedürftige selbst und dann - je nach

              persönlicher Situation - auch der Ehepartner und die Kinder in der Pflicht.
            </p>
            <ol
              class="px-4 list-decimal"
            >
              <li>
                Der Pflegebedürftige selbst aus laufenden Einnahmen und Vermögen
              </li>
              <li>
                Ehegatten/Lebenspartner und Lebensgefährten aus laufenden Einnahmen und Vermögen
              </li>
              <li>
                Kinder/Adoptivkinder: hier gibt es seit 2020 eine neue Regelung: Kinder sind erst ab einem jährlichen
                Bruttoeinkommen von 100.000€ unterhaltspflichtig. Bei denen, die 100.000€ und mehr verdienen,
                prüft das Sozialamt die Leistungsfähigkeit. Dabei wird die gesamte wirtschaftliche Situation der
                Familie geprüft. Es kann auch das Einkommen von Ehegatten/Lebenspartnern und eventuell auch das des
                Lebensgefährten des Kindes herangezogen werden. Zum Lebensgefährten
                liegt noch keine finale Rechtssprechung derzeit vor.
              </li>
              <li>
                Das Sozialamt: wenn alle anderen Möglichkeiten ausgeschöpft sind
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import FlippingList from '@/bedarf/components/FlippingList.vue';

const abilityChecks = [
  {
    svgIcon: 'bedarfsinfo/pflegevorsorge/chair.svg',
    title: '<strong>Mobilität</strong> (Gewichtung: 10 %)',
    text: `Hierzu wird beispielsweise begutachtet, wie gut sich der Patient in der eigenen Wohnung fortbewegen kann, ob
            er Treppen steigen und sich alleine umsetzen kann.`,
  },
  {
    svgIcon: 'bedarfsinfo/pflegevorsorge/angry.svg',
    title: '<strong>Psychische Verhaltensweisen</strong> (Gewichtung: 15 %)',
    text: `Der Gutachter prüft zum Beispiel, ob der Patient unter Wahnvorstellungen leidet, verbal aggressiv ist oder
            sich selbstschädigend verhält.`,
  },
  {
    svgIcon: 'bedarfsinfo/pflegevorsorge/date.svg',
    title: '<strong>Alltagsgestaltung</strong> (Gewichtung: 15 %)',
    text: `Es wird unter anderem geprüft, ob der Patient fähig ist, seinen Tagesablauf zu gestalten, zu schlafen und im
            Voraus zu planen.`,
  },
  {
    svgIcon: 'bedarfsinfo/pflegevorsorge/orientation.svg',
    title: '<strong>Kognitive Fähigkeiten</strong> (Gewichtung: 15 %)',
    text: `Es wird unter anderem begutachtet, wie gut die örtliche Orientierung des Patienten ist und ob er fähig ist,
            Gefahren zu erkennen und sich an wesentliche Ereignisse zu erinnern.`,
  },
  {
    svgIcon: 'bedarfsinfo/pflegevorsorge/walk.svg',
    title: '<strong>Belastungen durch Krankheit und Therapie</strong> (Gewichtung: 20 %)',
    text: `Es wird beispielsweise begutachtet, inwieweit der Patient fähig ist, mit seiner Krankheit umzugehen und den
            therapiebedingten Anforderungen und Belastungen standzuhalten.`,
  },
  {
    svgIcon: 'bedarfsinfo/pflegevorsorge/toilet.svg',
    title: '<strong>Selbstversorgung</strong> (Gewichtung: 40 %)',
    text: `Während der Begutachtung wird festgestellt, in wie weit der Patient fähig ist, die tägliche Körperpflege
            alleine durchzuführen und ob er Probleme beim Essen, Trinken oder Benutzen einer Toilette hat.`,
  },
];
</script>
