import type { compressAccuratelyConfig } from 'image-conversion';
import { compressAccurately, EImageType } from 'image-conversion';

export const isCompressableImageFileType = (fileType: string): fileType is EImageType => (
  fileType === EImageType.GIF || fileType === EImageType.JPEG || fileType === EImageType.PNG
);

export const compressImage = async (
  file: File,
  maxFileSize: number,
  options: compressAccuratelyConfig = {},
): Promise<File> => {
  const compressedImageBlob = await compressAccurately(file, {
    size: maxFileSize,
    type: file.type as EImageType,
    ...options,
  });

  return new File([compressedImageBlob], file.name, { type: compressedImageBlob.type });
};
