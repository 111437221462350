import { Familienstand, ImmobilienTyp, Krankenversicherungsstatus } from '@/checkup/types/enums';
import type { AbschlussForm,
  BerufsdatenForm,
  FahrzeugeForm,
  FinanzenForm,
  FreizeitForm,
  GesundheitForm,
  GrunddatenForm,
  Immobilie,
  Kind,
  KinderForm,
  PartnerForm,
  Person,
  WohnenForm } from '@/checkup/types/types';

export const grunddatenForm: GrunddatenForm = {
  vorname: null,
  nachname: null,
  anrede: null,
  familienstand: Familienstand.UNBEKANNT,
};

export const berufsdatenForm: BerufsdatenForm = {
  netto: null,
  brutto: null,
  isBruttoJahresGehalt: false,
  isNettoJahresGehalt: false,
  krankenversicherungsstatus: Krankenversicherungsstatus.GESETZLICH,
  taetigkeitsstatus: [],
  beruf: null,
};

export const finanzenForm: FinanzenForm = {
  monatlichFreiesEinkommen: null,
  vermoegen: null,
  monatlicheVerbindlichkeiten: null,
  verbindlichkeiten: null,
  bemerkung: null,
};

export const wohnenForm: WohnenForm = {
  wohnsituation: null,
  immobilienbauGeplant: null,
  immobilien: [],
  geplantesBauDatum: null,
  wohnImmobilie: null,
  hatWeitereImmobilie: null,
};

export const immobilie: Immobilie = {
  immobilienTyp: ImmobilienTyp.HAUS,
  fremdgenutzt: 0,
  heizoeltank: 0,
  photovoltaikanlage: 0,
  geloescht: false,
  immobilienId: null,
  bauGeplant: 0,
  bauGeplantAm: null,
  wohnflaeche: null,
  eigentum: true,
  selbstbewohnt: null,
};

export const gesundheitForm: GesundheitForm = {
  hatSchwereKrankheit: -1,
  diagnose: null,
  hatteUnfall: -1,
};

export const fahrzeugeForm: FahrzeugeForm = {
  pkwAnzahl: 0,
  motorradAnzahl: 0,
  mofaAnzahl: 0,
  anhaengerAnzahl: 0,
  quadAnzahl: 0,
  lkwAnzahl: 0,
  oldtimerAnzahl: 0,
  wohnmobilAnzahl: 0,
  ebikeAnzahl: 0,
  fahrradAnzahl: 0,
  drohneAnzahl: 0,
  bootAnzahl: 0,
};

export const freizeitForm: FreizeitForm = {
  hundAnzahl: 0,
  pferdAnzahl: 0,
  auslandsaufenthaltAusgeschlossen: null,
  jagdsport: false,
  luftsport: false,
  wassersport: false,
  camping: false,
};

export const parterForm: PartnerForm = {
  zusammenLebend: null,
  partner: null,
};

export const partner: Person = {
  clientId: -1,
  anrede: null,
  vorname: null,
  nachname: null,
  geburtsdatum: null,
  taetigkeitsstatus: null,
  brutto: null,
  netto: null,
  isBruttoJahresGehalt: null,
  isNettoJahresGehalt: null,
  krankenversicherungsstatus: null,
  hatSchwereKrankheit: null,
  diagnose: null,
};

export const kinderForm: KinderForm = {
  neueKinder: null,
  kinder: [],
};

export const kind: Kind = {
  clientId: -1,
  wurdeGeladen: false,
  zusammenLebend: true,
  vorname: null,
  nachname: null,
  anrede: null,
  brutto: null,
  netto: null,
  geburtsdatum: null,
  taetigkeitsstatus: null,
  krankenversicherungsstatus: Krankenversicherungsstatus.GESETZLICH,
  isBruttoJahresGehalt: null,
  isNettoJahresGehalt: null,
  hatSchwereKrankheit: null,
  diagnose: null,
};

export const abschlussForm: AbschlussForm = {
  hatAgbZugestimmt: false,
  anmerkungen: null,
};
