<template>
  <SpartenWrapper
    :text="subtitle"
    :image="`images/bedarfsinfo/berufsunfaehigkeit/${image}`"
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    :versorgungen="data.bedarfsinfo.versorgungen"
    versorgungen-headline="Wie bin ich aktuell bei einer Berufsunfähigkeit geschützt?"
    versorgungen-description="Berufsunfähigkeitsversicherung"
  >
    <Intro
      :einkommen="data.bedarfsinfo.einkommen"
      :is-freier-beruf="data.bedarfsinfo.isFreierBeruf"
      :is-selbststaendig="data.bedarfsinfo.isSelbststaendig"
    />

    <ContentSwitch :current-route="$route.name as MeinBedarf" />

    <Statistics v-if="!isKrankentagegeld" />

    <Krankentagegeld v-if="isKrankentagegeld" />

    <FreieBerufe v-else-if="data.bedarfsinfo.isFreierBeruf || data.bedarfsinfo.isSelbststaendig" />

    <Default
      v-else
      :steuersatz="data.bedarfsinfo.steuersatz"
    />

    <JumboImage :image="`images/bedarfsinfo/berufsunfaehigkeit/${bottomImage}`" />

    <WarumJetzt />
  </SpartenWrapper>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import JumboImage from '@/bedarf/components/JumboImage.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import WarumJetzt from '@/bedarf/components/WarumJetzt.vue';
import ContentSwitch from '@/bedarf/components/einkommenssicherung/berufsunfaehigkeit/ContentSwitch.vue';
import Default from '@/bedarf/components/einkommenssicherung/berufsunfaehigkeit/Default.vue';
import FreieBerufe from '@/bedarf/components/einkommenssicherung/berufsunfaehigkeit/FreieBerufe.vue';
import Intro from '@/bedarf/components/einkommenssicherung/berufsunfaehigkeit/Intro.vue';
import Krankentagegeld from '@/bedarf/components/einkommenssicherung/berufsunfaehigkeit/Krankentagegeld.vue';
import Statistics from '@/bedarf/components/einkommenssicherung/berufsunfaehigkeit/Statistics.vue';
import type { BerufsunfaehigkeitInfo } from '@/bedarf/types/bedarfsinfo';
import { MeinBedarf } from '@/application/router/types/types';

interface Props {
  data: BerufsunfaehigkeitInfo,
}

const props = defineProps<Props>();

const route = useRoute();

const subtitle = `
  Das Risiko aufgrund einer Krankheit Einkommensverluste zu erleiden,
  kann jeden treffen. Die staatlichen Sicherungssysteme sichern den
  Lebensstandard nicht. Im Folgenden finden Sie einen Überblick,
  optimal vorzusorgen.
`;

const isKrankentagegeld = computed(() => route.name === MeinBedarf.KRANKENTAGEGELD);

const image = computed(() => {
  if (props.data.bedarfsinfo.isFreierBeruf || props.data.bedarfsinfo.isSelbststaendig) return 'man-with-plastered-leg';
  return 'business-woman-in-jeans';
});

const bottomImage = computed(() => (isKrankentagegeld.value ? 'handschlag-frau' : 'man-computer'));
</script>
