import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 45 45"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "22.6",
      cy: "22.6",
      r: "21.6",
      fill: "#4181D2"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      d: "m27.6 11.3-5.6-.9-5.6 1c-.1.2-.2.5-.2.8zm-4.2-1.2 5.2.8c-.3-.6-.6-1.1-1-1.6zm.2-.5-5.2-.9c-.7.6-1.3 1.3-1.7 2.1zm-7.5 3c-.1.4-.1.7-.1 1.1 0 3.6 2.9 6.6 6.6 6.6s6.6-2.9 6.6-6.6c0-.7-.1-1.3-.3-2zm8.5-3.2 2.5-.4a6.86 6.86 0 0 0-4.6-1.9c-1.5 0-2.8.5-3.9 1.3zm-2 11.8c-6.7 0-12.1 6.5-12.1 14.6 9.6.2 12.1.3 24.1 0 .1-8-5.3-14.6-12-14.6"
    }, null, -1)
  ])))
}
export default { render: render }