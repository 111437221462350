<script setup lang="ts">

import type { UnreadHintType } from "@/api/unreadHint/types";
import CardCollectionHeaderFrame from "@/application/components/CardCollectionHeaderFrame.vue";
import BulkRemoveUnreadHints from "@/application/components/BulkRemoveUnreadHints.vue";
import { DsButton } from "@demvsystems/design-components";
import { useUnreadHintStore } from "@/application/store/unreadHintStore";
import { ref } from "vue";

type Props = {
  modelValue: string,
  unreadHintsCount: number,
  unreadHintsType: UnreadHintType
  unreadHintsLabel: string
  searchEnabled: boolean
  searchPlaceholder: string
  createNewButtonLabel: string
  showContextInfo: boolean
}

defineProps<Props>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void,
  (e: 'openCreateModal'): void,
  (e: 'searchFocus'): void,
  (e: 'searchBlur'): void,
}>()

const { removeUnreadHints } = useUnreadHintStore();

const searchValue = ref<string>('')

const updateValue = (event) => {
  searchValue.value = event.target.value;
  emit('update:modelValue', event.target.value + '');
};
</script>

<template>
  <CardCollectionHeaderFrame>
    <div v-if="showContextInfo" class="hidden lg:block">
      <slot name="left" />
    </div>
    <div
      class="flex grow justify-end items-center space-x-2"
      :class="{'col-span-2': !showContextInfo, 'col-span-1': showContextInfo}"
    >
      <BulkRemoveUnreadHints
        v-if="unreadHintsCount > 0"
        :items="[unreadHintsLabel]"
        @select="removeUnreadHints([{ type: unreadHintsType }])"
      />
      <div class="w-full lg:w-80">
        <input
          type="text"
          :value="searchValue"
          :disabled="!searchEnabled"
          class="form-input block w-full flex-1 rounded-md border-0 py-1 px-3 text-gray-900 ring-1 ring-inset
          ring-gray-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 text-md leading-8"
          :placeholder="searchPlaceholder"
          @focus="() => emit('searchFocus')"
          @blur="() => emit('searchBlur')"
          @input="updateValue"
        >
      </div>
      <slot name="filter" />
      <DsButton
        class="hidden md:block"
        icon="plus"
        icon-align="right"
        @click="emit('openCreateModal')"
      >
        {{ createNewButtonLabel }}
      </DsButton>
    </div>
  </CardCollectionHeaderFrame>
</template>
