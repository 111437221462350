<template>
  <section class="section container">
    <div class="row">
      <div class="col-12 col-lg-6 sm:mb-14 lg:mb-0">
        <h3>Worauf muss ich bei dieser Versicherung achten?</h3>
        <BulletPointList :items="payAttentionFor" />
      </div>
      <div class="col-12 col-lg-6 sm:mb-14 lg:mb-0">
        <h3 class="mb-5">
          Wann bin ich tatsächlich berufsunfähig?
        </h3>
        <BulletPointList :items="whenBerufsunfaehig" />

        <hr class="my-7">

        <Taxes />

        <NiceToKnow
          :bullets="niceToKnow"
          classes="mt-7 mb-0"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import type { BulletPointItem } from '@/bedarf/components/types';
import Taxes from '@/bedarf/components/einkommenssicherung/berufsunfaehigkeit/Taxes.vue';

defineProps<{ steuersatz: number }>();

const payAttentionFor: BulletPointItem[] = [
  {
    title: 'Verzicht auf abstrakte Verweisung',
    text: 'Sie erhalten die Leistung, wenn Sie in Ihrem Beruf nicht mehr arbeiten können (vergleiche Definition).',
  },
  {
    title: 'Prognosezeitraum von sechs Monaten',
    text: 'Die Leistung beginnt, sofern Sie voraussichtlich länger als sechs Monate berufsunfähig sind.',
  },
  {
    title: 'Freie Arztwahl',
    text: 'Sie lassen sich von dem Arzt Ihres Vertrauens untersuchen und dieser stellt die Diagnose.',
  },
  {
    title: 'Richtiges Endalter versichern',
    text: `Bestenfalls leistet die Berufsunfähigkeitsversicherung bis zum 67. Lebensjahr.
        Anschließend erhalten Sie die gesetzliche Altersrente.`,
  },
  {
    title: 'Leistung bei grob fahrlässigen Verkehrsdelikten',
    text: 'Vermeiden Sie Konflikte über den Unterschied zwischen Fahrlässigkeit und grober Fahrlässigkeit.',
  },
  {
    title: 'Arbeitsunfähigkeitsklausel in der Berufsunfähigkeitsversicherung',
    text: `Sind Sie länger als sechs Monate arbeitsunfähig, haben Sie nicht automatisch den Status berufsunfähig.
        Mit einer Arbeitsunfähigkeitsklausel erhalten Sie dennoch die vollen Leistungen.`,
  },
];

const whenBerufsunfaehig: BulletPointItem[] = [
  { icon: 'check', text: 'Volle Rentenzahlung im Regelfall ab einem Berufsunfähigkeitsgrad von 50 %' },
  { icon: 'check', text: 'Leistungen unabhängig von der Krankheitsursache' },
  {
    icon: 'check', text: `„Berufsunfähigkeit liegt vor, wenn die versicherte Person wegen Krankheit,
        Unfall oder Kräfteverfalls, die nachzuweisen sind, <strong>voraussichtlich sechs Monate
        ununterbrochen</strong> außer Stande ist, <strong>ihren Beruf</strong> oder eine andere Tätigkeit
        auszuüben, die aufgrund ihrer Ausbildung und Erfahrung <strong>ausgeübt</strong> werden kann und
        ihrer bisherigen Lebensstellung entspricht.“`
  },
];

const niceToKnow = [
  {
    title: 'Tipp:', text: `
    Beiträge zur Berufsunfähigkeitsversicherung sind wie Krankenversicherungsbeiträge
    als Vorsorgeaufwendungen steuerlich absetzbar (Höchstgrenze 1.900 € für Angestellte
    bzw. 2.800 € für Selbständige).`
  },
];
</script>
