import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 595.3 623.7"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#333",
      d: "M562.1 500.8C563.5 458.1 438.3 400 438.3 400c-65-18.7-61.6-36.5-61.6-36.5v-46.7c35.6-29.4 58.9-78.1 58.9-133.1 0-89.7-61.8-162.5-138-162.5s-138 72.8-138 162.5c0 55.1 23.3 103.7 58.9 133.1v46.7s3.5 17.9-61.6 36.5c0 0-125.1 58.2-123.8 100.8v56.8s30.6 35.4 267.4 32.7c236.8 2.7 261.6-32.7 261.6-32.7z"
    }, null, -1)
  ])))
}
export default { render: render }