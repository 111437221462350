import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 1416 1176"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"#f2f2f2\" d=\"M1145.4 1007.1H272.2V385.9L417.4 309 712 172.9l299.4 150.5 134 73.7z\"></path><path fill=\"#4181D2\" d=\"M620.2 725.3H776v281.8H620.2z\"></path><path d=\"M845.8 766h227v180.3h-227z\" style=\"fill:#b4ceef;\"></path><path fill=\"#114273\" d=\"M620.2 725.3H776v37.8H620.2z\"></path><path fill=\"#9d9e9e\" d=\"m961.4 728.1-531.6-2.6 11.3-65.7 513.6 1.1z\"></path><path fill=\"#c4c4c4\" d=\"M836.5 1006.4H399.8l30.9-281.1 528.4 2.8zm-117-743.3L272.8 495.3l-2.1-97.7s446.7-218.1 449.9-221 424.8 231.6 424.8 231.6l.5 117.3z\" opacity=\".2\"></path><path d=\"M845.4 766.5h227v58.6h-227z\" style=\"opacity:.6;fill:#c4c4c4;\"></path><path d=\"M888.7 401h179.8v142.9H888.7z\" style=\"fill:#b4ceef;\"></path><path d=\"M888.4 401.4h179.9v46.4H888.4z\" style=\"opacity:.6;fill:#c4c4c4;\"></path><path d=\"M606.9 401h179.8v142.9H606.9z\" style=\"fill:#b4ceef;\"></path><path d=\"M606.6 401.4h179.9v46.4H606.6z\" style=\"opacity:.6;fill:#c4c4c4;\"></path><path d=\"M348.6 401h179.8v142.9H348.6z\" style=\"fill:#b4ceef;\"></path><path d=\"M348.3 401.4h179.9v46.4H348.3z\" style=\"opacity:.6;fill:#c4c4c4;\"></path><path d=\"m60.4 461.6 55.4 31.6 662.6-338.1-55.5-31.5z\" style=\"fill:#114273;\"></path><path d=\"m722.6 123.3-58.2 30.1 633.1 354.1 58.1-30.2z\" style=\"fill:#114273;\"></path>", 15)
  ])))
}
export default { render: render }