<template>
  <div>
    <div
      v-show="!disabled && showPopper"
      ref="popper"
      :class="$style.modal"
      :style="{ width: width + 'px' }"
      class="print:hidden"
    >
      <button
        :class="$style.close"
        type="button"
        class="close"
        @click="doClose"
      >
        &times;
      </button>

      <slot
        v-if="icon"
        name="icon"
      >
        <font-awesome-icon
          :class="$style.icon"
          :icon="['fas', 'exclamation-circle']"
        />
      </slot>

      <slot
        v-if="title.length > 0"
        name="title"
      >
        <h3
          class="mt-5 mb-2.5"
          v-text="title"
        />
      </slot>

      <slot />
    </div>

    <div ref="reference">
      <slot
        name="reference"
        @show="doShow"
      />
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Popper from 'popper.js';

export default {
  components: {
    FontAwesomeIcon,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    reference: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    defaultShow: {
      type: Boolean,
      default: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    icon: {
      type: Boolean,
      default: true,
    },
    width: {
      type: Number,
      default: 170,
    },
  },

  emits: ['show', 'hide', 'created'],

  data() {
    return {
      referenceElm: null,
      popperJs: null,
      showPopper: false,
      currentPlacement: '',
      popperOptions: {
        placement: 'bottom',
        gpuAcceleration: false,
        ...this.options,
      },
    };
  },

  watch: {
    show(value) {
      this.showPopper = value;
    },

    showPopper(show) {
      if (show) {
        this.$emit('show');
        this.$nextTick(this.updatePopper);
      } else {
        this.$emit('hide');
        this.doDestroy();
      }
    },
  },

  created() {
    this.showPopper = this.defaultShow;
  },

  mounted() {
    this.referenceElm = this.reference || this.$refs.reference.children[0] || null;
    this.popper = this.$refs.popper;
  },

  unmounted() {
    this.destroyPopper();
  },

  methods: {
    doToggle() {
      this.showPopper = !this.showPopper;
    },

    doShow() {
      this.showPopper = true;
    },

    doClose() {
      this.showPopper = false;
    },

    doDestroy() {
      if (this.showPopper) {
        return;
      }

      if (this.popperJs !== null) {
        this.popperJs.destroy();
        this.popperJs = null;
      }
    },

    updatePopper() {
      if (this.popperJs === null) {
        this.createPopper();
        return;
      }

      this.popperJs.scheduleUpdate();
    },

    createPopper() {
      if (this.referenceElm === null) return;

      this.$nextTick(() => {
        if (this.popperJs && this.popperJs.destroy) {
          this.popperJs.destroy();
        }

        this.popperOptions.onCreate = () => {
          this.$emit('created', this);
          this.$nextTick(this.updatePopper);
        };
        this.popperJs = new Popper(this.referenceElm, this.popper, this.popperOptions);
      });
    },

    destroyPopper() {
      this.showPopper = false;
      this.doDestroy();
    },
  },
};
</script>

<style lang="scss" module>
  @import '@/../css/variables';

  // Modal styles
  $popover-background-color: $white !default;
  $popover-border-color: $blue !default;
  $popover-border-width: 2px !default;

  // Arrow styles
  $popover-arrow-size: 7px !default;
  $popover-arrow-border-width: $popover-border-width !default;
  $popover-arrow-border-color: $popover-border-color !default;
  $popover-arrow-background-color: $popover-background-color !default;

  // Internal variables
  $arrow-border-size: $popover-arrow-size + $popover-arrow-border-width;

  .modal {
    background: $white;
    border-radius: 2px;
    box-shadow:
      0 0 0 $popover-border-width $popover-border-color,
      0 3px 15px -5px rgba($black, 0.8);
    color: $black;
    padding: 15px;
    position: absolute;
    text-align: center;
    z-index: 19;

    // Arrow
    &::before,
    &::after {
      border-color: transparent;
      border-style: solid;
      content: '';
      height: 0;
      margin: 7px;
      position: absolute;
      width: 0;
    }

    &::before {
      border-width: $arrow-border-size;
    }

    &::after {
      border-width: $popover-arrow-size;
    }
  }

  .modal[x-placement^='top'] {
    margin-bottom: $arrow-border-size;

    &::before,
    &::after {
      border-bottom-width: 0;
      left: 50%;
      margin-bottom: 0;
      margin-top: 0;
    }

    &::before {
      border-top-color: $popover-arrow-border-color;
      bottom: -$arrow-border-size;
      margin-left: -$arrow-border-size;
    }

    &::after {
      border-top-color: $white;
      bottom: -$popover-arrow-size;
      margin-left: -$popover-arrow-size;
    }
  }

  .modal[x-placement^='bottom'] {
    margin-top: $arrow-border-size;

    &::before,
    &::after {
      border-top-width: 0;
      left: 50%;
      margin-bottom: 0;
      margin-top: 0;
    }

    &::before {
      border-bottom-color: $popover-arrow-border-color;
      margin-left: -$arrow-border-size;
      top: -$arrow-border-size;
    }

    &::after {
      border-bottom-color: $popover-arrow-background-color;
      margin-left: -$popover-arrow-size;
      top: -$popover-arrow-size;
    }
  }

  .modal[x-placement^='right'] {
    margin-left: $arrow-border-size;

    &::before,
    &::after {
      border-left-width: 0;
      margin-left: 0;
      margin-right: 0;
      top: 50%;
    }

    &::before {
      border-right-color: $popover-arrow-border-color;
      left: -$arrow-border-size;
      margin-top: -$arrow-border-size;
    }

    &::after {
      border-right-color: $popover-arrow-background-color;
      left: -$popover-arrow-size;
      margin-top: -$popover-arrow-size;
    }
  }

  .modal[x-placement^='left'] {
    margin-right: $arrow-border-size;

    &::before,
    &::after {
      border-right-width: 0;
      margin-left: 0;
      margin-right: 0;
      top: 50%;
    }

    &::before {
      border-left-color: $popover-arrow-border-color;
      margin-top: -$arrow-border-size;
      right: -$arrow-border-size;
    }

    &::after {
      border-left-color: $popover-arrow-background-color;
      margin-top: -$popover-arrow-size;
      right: -$popover-arrow-size;
    }
  }

  .modal[x-placement^='right-start'],
  .modal[x-placement^='left-start'] {
    &::before {
      margin-top: 0;
      top: 0;
    }

    &::after {
      margin-top: 0;
      top: $popover-border-width;
    }
  }

  .close {
    position: absolute;
    right: 10px;
    top: 5px;
    z-index: 2;
  }

  .icon {
    color: $blue;
    font-size: 2.5rem;
  }
</style>
