import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 93.33 93.33"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "46.67",
      cy: "46.67",
      r: "46.67",
      fill: "#4181D2"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#FFF",
      d: "M28.25 18.46v50.5H48.5v-50.5zm14.21 44.7h-3.25v3.25h-2.58v-3.25h-3.25v-2.58h3.25v-3.25h2.58v3.25h3.25zm4.87-11.04h-18V30.29h18zm-27-.66v17.75h6.5V51.46zm5.21 14.67H24.1v1.45h-1.15v-1.45H21.5v-1.15h1.45v-1.45h1.15v1.45h1.45v1.15zm.59-6.17h-5.31v-3.88h5.31zm33.2 2.75v6h18v-6zm10.21 3.67H68.1v1.45h-1.15v-1.45H65.5v-1.15h1.45v-1.45h1.15v1.45h1.45v1.15z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#FFF",
      d: "m51.73 67.84 14.5-2.5-1.33-1.33-6.99-32.96-3.85-2.8.41-.05-.17-1.4-3.92.47.17 1.4.49-.06-2.63 4.06s4.33 30.83 4.17 32c-.18 1.17-.85 3.17-.85 3.17"
    }, null, -1)
  ])))
}
export default { render: render }