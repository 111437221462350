<template>
  <span
    :class="{'flex flex-col text-right': !inline}"
    :style="inline ? {display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end', alignItems: 'center'} : {}"
  >
    <span
      v-if="cost"
      class="whitespace-nowrap"
      :class="{'font-semibold': bold}"
    >{{ cost }}</span>
    <slot>
      <small
        v-if="intervalName"
        class="text-muted pl-1.5"
        style="white-space: nowrap;"
      >
        {{ intervalName }}
      </small>
    </slot>
  </span>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { formatDecimal } from '@/application/utils/money';

interface Props {
  amount: number | null,
  interval?: string | null,
  inline?: boolean,
  bold?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  inline: false,
  interval: null,
  bold: false,
});

const intervalMap = new Map([
  ['beitragsfrei', 'Beitragsfrei'],
  ['Einmalbetrag', '(Einmalig)'],
  ['halbjährlich', '/ Halbjahr'],
  ['jährlich', '/ Jahr'],
  ['monatlich', '/ Monat'],
  ['sonstiges', 'Sonstiges'],
  ['vierteljährlich', '/ Quartal'],
]);

const cost = computed(() => (props.amount !== null ? formatDecimal(props.amount) : ''));
const intervalName = computed(() => (props.interval !== null ? intervalMap.get(props.interval) : ''));
</script>
