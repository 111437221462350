import type { RouteLocationNormalized } from "vue-router";
import { useBedarfStore } from "@/bedarf/store/bedarfStore";
import { useGlobalLoading } from "@/application/composables/loading";

export async function meinBedarfSparteGuard (to: RouteLocationNormalized): Promise<boolean> {

  const { fetchBedarfsinfo } = useBedarfStore();
  const { loading } = useGlobalLoading();

  loading.value = true;
  const sparte = to.path
    .replace('/mein-bedarf', '')
    .split('/').pop() ?? '';

  const err = await fetchBedarfsinfo(to.params.bedarfHash as string, sparte);
  loading.value = false;

  return !(err instanceof Error);
}
