<template>
  <SpartenWrapper
    image="images/bedarfsinfo/bauherrenhaftpflicht/bauherren-haftpflicht-titelbild"
    text="Laut Bürgerlichem Gesetzbuch haften Bauherr, Architekt
          und Bauunternehmer gesamtschuldnerisch, sofern es auf
          dem Bau zu Schäden kommt. Somit können sich Geschädigte
          an alle involvierten Parteien wenden - unabhängig
          von der Hauptverantwortung."
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    :versorgungen="data.bedarfsinfo.versorgungen"
    versorgungen-description="Bauherrenhaftpflicht"
    versorgungen-headline="Wie gut bin ich als Bauherr abgesichert?"
  >
    <section class="section">
      <div class="container">
        <DamageExamplesSection
          :data="damageExamples"
          headline="Wann leistet die Bauherrenhaftpflicht?"
          subline="Errichten Sie eine Immobilie, so haften Sie als Bauherr für Schäden auf der Baustelle."
          second-headline="Schadenbeispiele - welche Kosten werden erstattet?"
        />

        <div class="row mt-7">
          <div class="col-12 col-md-6">
            <NiceToKnow
              classes="h-full"
              :bullets="[{
                title: 'Info',
                text: 'Zusätzlich weist die Versicherung unberechtigte Forderungen ab ' +
                  'und setzt dieses gerichtlich durch (sogenannter passiver Rechtsschutz).',
              }]"
            />
          </div>
          <div class="col-12 col-md-6 mt-2.5 md:mt-0">
            <NiceToKnow
              classes="mb-0"
              :bullets="[{
                title: 'Tipp!',
                text: 'Die Bauherrenhaftpflicht ist häufig in der Privathaftpflicht eingeschlossen. ' +
                  'Allerdings wird die Versicherungssumme begrenzt, so dass diese nur bei geringen ' +
                  'Bausummen genutzt werden kann. Bei Aus- oder Umbauten reicht die Privathaftpflicht häufig aus.',
              }]"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section pt-0">
      <div class="container">
        <div class="row text-center">
          <div class="col-12 mb-7">
            <h4 class="mb-7">
              Bauleistungsversicherung
            </h4>
            <p class="mb-0">
              Während der Bauphase können zahlreiche unvorhergesehene Ereignisse
              eintreten, weshalb sich die Fertigstellung
            </p>
            <p>
              verzögert und verteuren könnte. Welche Schäden von der
              Bauleistungsversicherung übernommen werden, erfahren Sie jetzt.
            </p>
          </div>
        </div>
        <div class="row mb-7">
          <div class="col-12 col-md-6">
            <h6 class="font-bold">
              Wann leistet die Bauleistungsversicherung?
            </h6>
            <div class="pb-7">
              <List>Bei Schäden infolge von Konstruktions- oder Materialfehlern</List>
              <List>Bei ungewöhnlichen Witterungsverhältnissen wie Sturm oder Hagel</List>
              <List>Bei unbekannten Eigenschaften des Baugrundes</List>
              <List>Bei Beschädigungen oder Zerstörungen durch Vandalismus</List>
              <List>Bei Diebstahl fest eingebauter Teile (zuschlagspflichtig)</List>
              <List>Bei höherer Gewalt und Elementarereignissen wie Erdbeben, Überschwemmungen und Hochwasser</List>
            </div>
            <NiceToKnow
              classes="mb-0"
              :bullets="[{
                title: 'Tipp!',
                text: 'Die Bauherrenhaftpflicht ist häufig in der Privathaftpflicht eingeschlossen. ' +
                  'Allerdings wird die Versicherungssumme begrenzt, so dass diese nur bei geringen Bausummen ' +
                  'genutzt werden kann. Bei Aus- oder Umbauten reicht die Privathaftpflicht häufig aus.',
              }]"
            />
          </div>
          <div class="col-12 col-md-6">
            <SvgVue
              icon="bedarfsinfo/bauherrenhaftpflicht/bauleistungsversicherung.svg"
              class="img-fluid mb-14"
              style=""
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 mb-2.5">
            <h6 class="font-bold">
              Welche Versicherungssumme benötige ich?
            </h6>
            <p class="mb-0">
              Die Höhe der Versicherungssumme ergibt sich aus der Bausumme.
            </p>
            <p>Zur Bausumme zählen alle Kosten, die beim Bau entstehen, wie beispielsweise:</p>
            <List>Kosten für das Bauwerk, einschließlich Personalkosten</List>
            <List>Kosten für Bauplanung (Architektur und Baustatik)</List>
            <List>Kosten der Außenanlagen</List>
            <List>Eigenleistungen</List>
          </div>
          <div class="col-12 col-md-6">
            <NiceToKnow
              :bullets="[{
                title: 'Tipp!',
                text: 'Der Beitragsaufschlag bei einer etwas höheren Bausumme ist gering. ' +
                  'Kalkulieren Sie in die Bausumme daher einen Puffer ein, da in den meisten ' +
                  'Fällen die tatsächliche Bausumme höher ausfällt. Der Grundstückspreis und die ' +
                  'Erschließungskosten des Grundstücks (Strom, Abwasser) zählen <b>nicht</b> zur Bausumme.',
              }]"
            />
          </div>
        </div>
        <div class="row text-center mt-14">
          <div class="col-12">
            <h5 class="mb-7">
              Welche zusätzlichen Leistungen bieten Top-Tarife?
            </h5>
          </div>
        </div>
        <div class="row mt-2.5">
          <div class="col-12 offset-md-1 col-md-5">
            <List>
              Die Suche der Schadenursache ist zeitaufwändig und Expertensache.
              Gute Tarife übernehmen diese Kosten.
            </List>
            <List>
              Kommt es zu Schädigungen oder Zerstörungen, können zusätzliche
              Kosten durch Aufräumarbeiten entstehen.
            </List>
          </div>
          <div class="col-12 col-md-5">
            <List>
              Sehr gute Tarife bieten bis zu bestimmten Höchstversicherungssummen
              auch bei Schäden infolge von weggespültem Erdreich.
            </List>
          </div>
        </div>
        <div class="row text-center mt-14">
          <div class="col-12">
            <h5 class="mb-7">
              Wann habe ich keinen Versicherungsschutz?
            </h5>
          </div>
        </div>
        <div class="row mt-2.5">
          <div class="col-12 offset-md-1 col-md-5">
            <List>Bei vorsätzlicher Missachtung gesetzlicher Sicherungspflichten</List>
            <List>Bei Schäden an gemieteten Sachen</List>
            <List>Diebstahl beweglicher Teile</List>
            <List>Vorsatz</List>
            <List>Normale Witterungseinflüsse</List>
          </div>
          <div class="col-12 col-md-5">
            <List>Bei Schäden durch Veränderung der Grundwasserverhältnisse</List>
            <List>Spezialgerüste</List>
            <List>Baugeräte inklusive Kleingeräte</List>
            <List>Krieg, innere Unruhen, Streik und Aussperrung</List>
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import SvgVue from '@/application/components/SvgVue';
import DamageExamplesSection from '@/bedarf/components/DamageExamplesSection.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';
import List from '@/application/components/List.vue';

interface Props {
  data: Bedarfsinfo,
}

defineProps<Props>();

const damageExamples = [
  {
    icon: 'bedarfsinfo/bauherrenhaftpflicht/sachschaden.svg',
    title: 'Sachschaden',
    description: 'Beim Abriss des alten Gebäudes beschädigt ein Bagger das Dach des Nachbargrundstücks. '
      + 'Die Versicherung übernimmt die Kosten für die Reparatur.',
  },
  {
    icon: 'bedarfsinfo/bauherrenhaftpflicht/personenschaden.svg',
    title: 'Personenschaden',
    description: 'Die angelieferten Dachbalken werden an der Grundstücksgrenze abgeladen und ein Fußgänger stolpert '
      + 'über diese und verletzt sich. Die Bauherrenhaftpflicht übernimmt die Behandlungs- und Folgekosten und …',
  },
  {
    icon: 'bedarfsinfo/bauherrenhaftpflicht/vermoegensschaden.svg',
    title: 'Vermögensschaden',
    description: '… darüber hinaus das Schmerzensgeld sowie die Kosten für den Verdienstausfall der verletzten Person.',
  },
];

</script>
