import type { Contract, ContractsResponse, Depot, DepotsResponse } from "@/api/contracts/types";
import type { Document, DocumentsResponse } from "@/api/documents/types";

export type LicencePlate = {
  unterscheidungszeichen: string | null,
  erkennungszeichen: string | null,
  erkennungsnummer: number | null,
  abschliessendeszeichen: 'H' | 'E' | null,
};

export type ContractData = {
  sparte_id: number | null,
  gesellschaft_id: number | null,
  vertragsnummer: string | null,
  beginn: string | null, // date in php
  ablaufdatum: string | null, // date in php
  beitrag: number | null,
  zahlweise: number | null,
  kennzeichen: LicencePlate
  notiz: string | null
};

export const ContractsPaymentType = {
  SINGLE_PAYMENT: 5,
  MONTHLY: 4,
  QUARTERLY: 3,
  SEMIANNUAL: 2,
  ANNUAL: 1,
  NOT_AVAILABLE: -1,
} as const

// references professionalworsk/protected/models/vertrag/ContractStatus.php
export enum ContractState {
  AKTIV = 1,
  STORNIERT = 2,
  BEITRAGSFREI = 3,
  SONSTIGE = 4,
  RUHEND = 5,
}

export const ContractPaymentName = {
  [ContractsPaymentType.NOT_AVAILABLE]: "Nicht gesetzt",
  [ContractsPaymentType.SINGLE_PAYMENT]: "Einmalig",
  [ContractsPaymentType.MONTHLY]: "Monatlich",
  [ContractsPaymentType.ANNUAL]: "Jährlich",
  [ContractsPaymentType.SEMIANNUAL]: "Halbjährlich",
  [ContractsPaymentType.QUARTERLY]: "Vierteljährlich",
}

export const PaymentTypeOptions = Object.values(ContractsPaymentType).filter(key => key > 0).map(key => {
  return {
    label: ContractPaymentName[key],
    value: key
  }
})

export type NestedContracts = Omit<ContractsResponse, 'contracts'> & { contracts: Contract[] };
export type NestedDepots = Omit<DepotsResponse, 'depots'> & { depots: Depot[] };
export type NestedDocuments = Omit<DocumentsResponse, 'documents'> & { documents: Document[] };

export type StringMap = { [key: string]: string };
