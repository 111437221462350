<template>
  <div>
    <!-- WOHNSITUATION -->
    <section class="mb-3">
      <div class="row flex justify-center">
        <div class="col-md-12">
          <h2 class="mb-3 md:mb-5 checkup-h2">
            Ich wohne
          </h2>
          <div v-if="formErrors.wohnImmobilie">
            <DsFieldError
              v-for="error in formErrors.wohnImmobilie.immobilienTyp"
              :key="error"
            >
              {{ error }}
            </DsFieldError>
          </div>
          <DsFieldError
            v-for="error in formErrors.wohnsituation"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </div>
      </div>

      <div class="flex justify-center">
        <DsRadioGroup
          :model-value="form.wohnsituation === null ? '' : `${form.wohnsituation}`"
          :variant="isMobile ? 'button-col' : 'button'"
          class="w-full lg:w-2/3 bg-white"
          @update:model-value="setWohnsituation($event === '' ? null : Number($event))"
        >
          <DsRadioButton
            v-for="{label, value} in wohnsituationen"
            :key="label"
            :value="`${value}`"
            class="w-full"
            style="height: 48px"
          >
            {{ label }}
          </DsRadioButton>
        </DsRadioGroup>
      </div>
    </section>

    <section
      v-if="besitztEigentum"
      class="flex flex-row justify-center mb-14"
    >
      <!-- IMMOBILIENTYP -->
      <div class="w-full lg:w-2/3 border border-gray-200 rounded-md p-4 bg-white">
        <OptionGroup
          has-images
          class="my-2.5"
          :options="immobilientypenWohngebaude"
          :value="form.wohnImmobilie?.immobilienTyp"
          option-width-classes="w-1/2 md:w-1/3 lg:w-1/3"
          @change="setWohnImmobilieField('immobilienTyp', $event)"
        />
        <h2 class="mb-3 md:mb-5 checkup-h2">
          {{ form.wohnImmobilie?.immobilienTyp === ImmobilienTyp.HAUS ? 'Das Haus' : 'Die Wohnung' }}
          verfügt über
        </h2>
        <div class="OptionIconGroupCheck">
          <div class="flex flex-row gap-3 justify-center mb-1 md:mb-2">
            <FormCheckbox
              :model-value="form.wohnImmobilie?.heizoeltank"
              label="Heizöltank"
              icon="oiltank"
              @update:model-value="setWohnImmobilieField('heizoeltank', $event ? 1 : 0)"
            />
            <FormCheckbox
              :model-value="form.wohnImmobilie?.photovoltaikanlage"
              label="Photovoltaikanlage"
              icon="solar"
              @update:model-value="setWohnImmobilieField('photovoltaikanlage', $event ? 1 : 0)"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- WEITERE IMMOBILIEN -->

    <section class="my-5">
      <div class="row flex justify-center pt-5">
        <div class="col-md-12">
          <h2
            class="mb-5 checkup-h2"
            v-text="
              besitztEigentum ?
                'Ich besitze weitere Immobilien' :
                'Ich besitze Immobilien'"
          />
          <DsFieldError
            v-for="error in formErrors.hatWeitereImmobilie"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </div>
      </div>

      <OptionGroup
        has-images
        :options="[
          {label: 'Ja', labelClasses: `${form.hatWeitereImmobilie ? 'text-green-700' : 'flex flex-col'}`, value: true, icon: 'icomoon/check', iconClass: `check ${form.hatWeitereImmobilie ? 'fill-green-700' : ''}`},
          {label: 'Nein', labelClasses: `${!form.hatWeitereImmobilie ? 'text-red-700' : 'flex flex-col'}`, value: false, icon: 'icomoon/close', iconClass: `close ${!form.hatWeitereImmobilie ? 'fill-red-700' : ''}`}
        ]"
        :value="form.hatWeitereImmobilie"
        option-width-classes="w-1/3 sm:w-1/4 md:w-1/6"
        @change="setHatWeitereImmobilie"
      />

      <div
        v-if="form.hatWeitereImmobilie"
      >
        <!-- When does this error happen?  -->
        <!-- <div class="row flex justify-center">
          <div class="col-md-6 col-sm-12 col-xs-12">
            <div
              id="immobilie_konnte_nicht_geloescht_werden_error"
              class="messageList messageList--error messageList--space-bottom hidden"
            >
              <div class="message">
                Die Immobilie konnte nicht gelöscht werden, es ist ein Fehler aufgetreten
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </section>

    <section
      v-if="form.hatWeitereImmobilie"
      class="flex flex-col w-full md:w-3/5 lg:w-1/2 mx-auto"
    >
      <div class="flex flex-row justify-center py-1">
        <DsButton
          icon="add"
          variant="clear"
          size="sm"
          @click="addImmobilie"
        >
          Weitere Immobilie hinzufügen
        </DsButton>
      </div>

      <DsTabs v-model:active-tab="selected">
        <DsTab
          v-for="({ immobilie, index }, indexForTitle) in nichtGeloeschteImmobilien"
          :key="index"
          :tab-id="index"
          :title="`Immobilie ${indexForTitle + 1}`"
        >
          <ImmobilieComp
            :key="immobilie.immobilienId || `i${index}`"
            :index="index"
            :index-for-title="indexForTitle"
            :form="immobilie"
            @delete="deleteImmobilie"
          />
        </DsTab>
      </DsTabs>
    </section>

    <!-- IMMOBILIENBAU GEPLANT -->
    <section>
      <div
        v-if="hasGeplanteImmobilien"
        class="row flex justify-center"
      >
        <div
          class="col-md-12"
        >
          <h2 class="my-5 checkup-h2">
            Sie haben folgende Bauvorhaben:
          </h2>
          <ul>
            <li
              v-for="(immobilie, index) in geplanteImmobilien"
              :key="index"
            >
              {{ immobilie.bauGeplantAm }}
            </li>
          </ul>
        </div>
      </div>

      <template v-else>
        <div class="w-full">
          <h2 class="mb-7 checkup-h2">
            In den nächsten 12 Monaten ist der Bau einer Immobilie geplant
          </h2>
          <DsFieldError
            v-for="error in formErrors.immobilienbauGeplant"
            :key="error"
          >
            {{ error }}
          </DsFieldError>
        </div>

        <OptionGroup
          id="formError.immobilienbauGeplant"
          has-icons
          :options="[
            {label: 'Ja', labelClasses: `${form.immobilienbauGeplant === 1 ? 'text-green-700' : 'flex flex-col'}`, value: 1, icon: 'icomoon/check', iconClass: `check ${form.immobilienbauGeplant === 1 ? 'fill-green-700' : ''}`},
            {label: 'Nein', labelClasses: `${form.immobilienbauGeplant === 0 ? 'text-red-700' : 'flex flex-col'}`, value: 0, icon: 'icomoon/close', iconClass: `close ${form.immobilienbauGeplant === 0 ? 'fill-red-700' : ''}`}
          ]"
          :value="form.immobilienbauGeplant"
          option-width-classes="w-1/3 sm:w-1/4 md:w-1/6"
          @change="set('immobilienbauGeplant', $event)"
        />
      </template>

      <!-- GEPLANTES BAUDATUM -->
      <div
        v-if="form.immobilienbauGeplant && !hasGeplanteImmobilien"
        class="flex flex-row justify-center"
      >
        <DsFormGroup label="Geplantes Datum">
          <DateInput
            v-model="form.geplantesBauDatum"
            size="lg"
          />
          <DsFieldError> {{ formErrors.geplantesBauDatum }} </DsFieldError>
        </DsFormGroup>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { DsButton,
  DsFieldError,
  DsFormGroup,
  DsRadioButton,
  DsRadioGroup,
  DsTab,
  DsTabs } from '@demvsystems/design-components';
import { computed, nextTick, ref, watch } from 'vue';

import DateInput from '@/application/components/form-elements/DateInput/DateInput.vue';
import { useDetectMobile } from '@/application/composables/detectMobile';
import FormCheckbox from '@/checkup/components/FormCheckbox.vue';
import OptionGroup from '@/checkup/components/OptionGroup.vue';
import ImmobilieComp from '@/checkup/components/steps/Immobilie.vue';
import { ImmobilienTyp, Wohnsituation } from '@/checkup/utils/demvWerte';
import { useStep } from '@/checkup/state';
import { immobilie as immobilieInitData } from '@/checkup/state/base';
import { Step } from '@/checkup/utils/steps';
import type { Immobilie } from '@/checkup/types/types';

import HausImage from '../../../../../assets/images/immobilientyp-1.jpg';
import WohnungImage from '../../../../../assets/images/immobilientyp-2.jpg';

const { isMobile } = useDetectMobile();

const { form, formErrors, set, clear } = useStep(Step.WOHNEN);

const geplanteImmobilien = computed(() => (
  form.value.immobilien.filter((immobilie: Immobilie) => immobilie.bauGeplant === 1 && !immobilie.geloescht)
));

const nichtGeloeschteImmobilien = computed(() => form.value.immobilien.reduce(
  (acc: { immobilie: Immobilie, index: number }[], immobilie, i) => (!immobilie.geloescht
    ? [...acc, { immobilie, index: i }]
    : acc),
  [],
));

function getImmoIndex(first = false) {
  if (first) {
    for (let i = 0; i < form.value.immobilien.length; i++) {
      if (form.value.immobilien[i].geloescht === false) {
        return i;
      }
    }
  } else {
    for (let i = form.value.immobilien.length - 1; i >= 0; i--) {
      if (form.value.immobilien[i].geloescht === false) {
        return i;
      }
    }
  }
  return 0;
}

const selected = ref<number>(getImmoIndex(true));

watch(() => form.value.immobilien.length, (length) => {
  form.value.hatWeitereImmobilie = length !== 0;
}, { immediate: true });

const setWohnsituation = (value: Wohnsituation | null) => {
  form.value.wohnImmobilie = value === Wohnsituation.IN_EIGENTUM ? { ...immobilieInitData } : null;
  set('wohnsituation', value);
};

const hasGeplanteImmobilien = computed(() => (
  geplanteImmobilien.value.length > 0
));

const besitztEigentum = computed(() => form.value.wohnsituation === Wohnsituation.IN_EIGENTUM);

const wohnsituationen = [
  { label: 'zur Miete', value: Wohnsituation.ZUR_MIETE, tile: true },
  { label: 'in meinem Eigentum', value: Wohnsituation.IN_EIGENTUM, tile: true },
  { label: 'bei meinen Eltern', value: Wohnsituation.BEI_ELTERN, tile: true },
  { label: 'bei einer anderen Person', value: Wohnsituation.BEI_ANDERER_PERSON, tile: true },
];

const immobilientypenWohngebaude = [
  {
    label: 'in einem Haus',
    value: ImmobilienTyp.HAUS,
    imageSrc: HausImage,
    image: true,
  },
  {
    label: 'in einer Wohnung',
    value: ImmobilienTyp.WOHNUNG,
    imageSrc: WohnungImage,
    image: true,
  },
];

const setWohnImmobilieField = (field, value) => {
  if (!form.value.wohnImmobilie) return;

  const wohnImmobilie = { ...form.value.wohnImmobilie };
  wohnImmobilie[field] = value;
  form.value.wohnImmobilie = wohnImmobilie;
  clear('wohnImmobilie', null, `${field}`);
};

const addImmobilie = () => {
  form.value.immobilien.push({ ...immobilieInitData });
  nextTick(() => {
    selected.value = getImmoIndex();
  });
};

const deleteImmobilie = (index: number) => {
  form.value.immobilien[index].geloescht = true;
  clear('immobilien', index);
  form.value.hatWeitereImmobilie = form.value.immobilien.some(({ geloescht }) => !geloescht);
  selected.value = form.value.immobilien.length === 0 ? 0 : getImmoIndex();
};

const setHatWeitereImmobilie = (value: Event) => {
  set('hatWeitereImmobilie', value);
  if (value) {
    if (form.value.immobilien.every((o) => o.geloescht)) {
      addImmobilie();
    }
  } else {
    for (let i = 0; i < form.value.immobilien.length; i++) {
      const immobilie = form.value.immobilien[i];
      immobilie.geloescht = true;
    }
    clear('immobilien');
  }
};
</script>
