import { useUserStore } from "@/user/store/userStore";
import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router";
import { Finanzmanager } from "@/application/router/types/types";

export async function authenticateGuard(to: RouteLocationNormalized, from: RouteLocationNormalized) {
  const { isAuthenticated, syncLoginState } = useUserStore();

  if (isAuthenticated === null) {
    await syncLoginState();
  }

  if (to.query?.requestAccessToken) {
    return to.name === Finanzmanager.LOGIN
      ? true
      : loginRedirect(to);
  }

  if (useUserStore().isAuthenticated) {
    if (to.name === Finanzmanager.LOGIN) return { name: Finanzmanager.VERTRAEGE };
    if (to.name === Finanzmanager.LOGOUT) {
      // when entered on /logout redirect to verträge
      if (!from.name) return { name: Finanzmanager.VERTRAEGE };

      // prevent navigation when from another route
      return false;
    }

    return true;
  }


  if (to.meta.public) return true;

  return loginRedirect(to);
}

function loginRedirect(to: RouteLocationNormalized): RouteLocationRaw {
  const { requestAccessToken = undefined, ...query } = { ...to.query };

  const route = Object.keys(query).length > 0
    ? `${to.path}?${new URLSearchParams(query as Record<string, string>)}`
    : `${to.path}`;

  return {
    name: Finanzmanager.LOGIN,
    query: { redirect: encodeURIComponent(route), requestAccessToken },
  };
}
