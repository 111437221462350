<template>
  <div
    v-if="show"
    class="bg-orange-100 z-50 shadow-xs py-3 bg-opacity-80"
  >
    <div class="container flex flex-row items-center justify-between gap-3 text-orange-800 text-sm md:text-base">
      <span>
        Sie haben noch kein Passwort gesetzt.
        <RouterLink :to="{ name: Finanzmanager.ACCOUNT }">
          <span class="font-semibold text-orange-800 underline underline-offset-2">Jetzt Passwort festlegen</span>
        </RouterLink>
      </span>
      <button
        type="button"
        title="Nachricht ausblenden"
        class="cursor-pointer px-3 py-0.5 rounded hover:bg-white border border-orange-100 hover:border-orange-800"
        @click="close"
      >
        <DsIcon name="times" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import { useBaseDataStore } from '@/user/store/baseDataStore';
import { getLocalStorage, setLocalStorage } from '@/application/utils/localStorage';
import { Finanzmanager } from '@/application/router/types/types';
import { DsIcon } from '@demvsystems/design-components';
import { useRoute } from "vue-router";

const TTL = 60 * 60 * 24; // 1 day
const LS_KEY = 'password-banner';

const { hasPasswortSet } = useBaseDataStore();
const route = useRoute();

const show = ref(!hasPasswortSet && getLocalStorage(LS_KEY) === null && route.meta.public !== true);

const close = () => {
  show.value = false;
  setLocalStorage(LS_KEY, false, TTL);
};
</script>
