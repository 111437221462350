<template>
  <div class="section">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <h3 class="mb-5">
            Leistungsumfang privates Krankentagegeld
          </h3>
          <BulletPointList
            :items="leistungsumfang"
          />

          <hr class="my-7">

          <h3 class="mb-5">
            Steuerliches
          </h3>
          <BulletPointList
            :items="taxRelated"
          />
        </div>
        <div class="col-12 col-md-6">
          <h3 class="mb-5">
            Mit der Arbeitsunfähigkeits&shy;bescheinigung entsteht der Leistungsanspruch
          </h3>
          <BulletPointList
            :items="arbeitsunfaehigkeit"
          />
          <div class="text-center mt-7">
            <SvgVue
              icon="bedarfsinfo/berufsunfaehigkeit/krankenschein.svg"
              class="w-full md:w-11/12 lg:w-10/12 xl:w-2/3 max-w-96"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import type { BulletPointItem } from '@/bedarf/components/types';
import SvgVue from "@/application/components/SvgVue";

const leistungsumfang: BulletPointItem[] = [
  { text: 'Monatliche Rentenzahlung<br>(Höhe und Leistungsdauer werden individuell vereinbart)' },
  { text: 'Leistungen unabhängig von der Krankheitsursache' },
  { text: 'Unbegrenzte Leistungsdauer solange vollständige Arbeitsunfähigkeit vorliegt' },
  { text: 'Leistungsende bei Eintritt der Berufsunfähigkeit oder wenn Versicherter wieder gesund ist' },
];

const taxRelated: BulletPointItem[] = [
  { text: 'Steuer- und abgabenfreie Auszahlung' },
  { text: 'Keine steuerliche Absetzbarkeit als Vorsorgeaufwendung' },
];

const arbeitsunfaehigkeit: BulletPointItem[] = [
  { text: `Die Leistungen werden i.d.R. nach einer vereinbarten Karenzzeit ausgezahlt
      (Angestellte ab dem 43. Tag, Selbständige meist ab dem 29. Tag)` },
];
</script>
