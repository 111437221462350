<template>
  <div class="row text-center">
    <div class="col-12">
      <h2 class="mb-7">
        Von wem wollen Sie im Krankenhaus behandelt werden?
      </h2>
    </div>

    <div
      v-for="{ image, title, text } in doctorTypes"
      :key="title"
      class="col-sm-6 col-lg-3"
    >
      <img
        :src="image"
        aria-hidden="true"
        alt="Krankenhauszusatzversicherung"
        class="img-fluid mb-5 inline-block"
        style="height: 200px"
      >
      <h4 v-text="title" />
      <!-- eslint-disable vue/no-v-html -->
      <p v-html="text" />
      <!-- eslint-enable vue/no-v-html -->
    </div>
  </div>

  <h3 class="mt-5 mb-7 text-center">
    Bitte wählen Sie, welche Behandlung Sie wünschen
  </h3>
</template>

<script setup lang="ts">
import AssistenzarztImage from '../../../../assets/images/bedarfsinfo/krankenhauszusatzversicherung/assistenzarzt.jpg';
import FacharztImage from '../../../../assets/images/bedarfsinfo/krankenhauszusatzversicherung/facharzt.jpg';
import OberarztImage from '../../../../assets/images/bedarfsinfo/krankenhauszusatzversicherung/oberarzt.jpg';
import ChefarztImage from '../../../../assets/images/bedarfsinfo/krankenhauszusatzversicherung/chefarzt.jpg';

const doctorTypes = [
  {
    image: AssistenzarztImage,
    title: 'Assistenzarzt',
    text: 'Befindet sich nach dem Studium in praktischer Ausbildung und hat meist wenig Praxiserfahrung.',
  },
  {
    image: FacharztImage,
    title: 'Facharzt',
    text: 'Hat mindestens fünf Jahre Praxiserfahrung und behandelt Patienten eigenverantwortlich.',
  },
  {
    image: OberarztImage,
    title: 'Oberarzt',
    text: `Hat die direkte Verantwortung für alle medizinischen Behandlungen der Abteilung, beaufsichtigt
           Assistenzärzte und steht Fach&shy;ärzten für Rückfragen zur Verfügung.`,
  },
  {
    image: ChefarztImage,
    title: 'Chefarzt',
    text: `Hauptverantwortlicher für alle medizinischen Behandlungen und für alle strukturellen
           Verwaltungs&shy;abläufe der jeweiligen Abteilung.`,
  },
];
</script>
