<template>
  <SpartenWrapper
    :text="subtitle"
    image="images/bedarfsinfo/firmen-rechtsschutz/header"
    alt="Ein Richter der einen Richterhammer benutzt"
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    :versorgungen="data.bedarfsinfo.versorgungen"
    versorgungen-headline="Wie gut sind wir bei unserer Rechtsschutzversicherung versichert?"
    versorgungen-description="Firmen-Rechtsschutz"
  >
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <h2 class="text-center mb-7">
              Welche Kosten entstehen bei einem verlorenen Rechtsstreit?
            </h2>
          </div>
        </div>

        <div class="row justify-center">
          <div class="col-lg-10 col-xl-9">
            <KostenRechtsstreit />
          </div>
        </div>

        <div class="row justify-center my-14">
          <div class="col-md-10 col-lg-6">
            <NiceToKnow :bullets="costsInfo" />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <h3 class="text-center mb-7">
              Welche Bereiche kann ich unter anderem versichern?
            </h3>

            <p class="text-center mb-7">
              Die Rechtsschutzversicherung lässt sich mittels der folgenden Bausteine passgenau zusammen stellen.
            </p>
          </div>
        </div>

        <div class="row justify-center">
          <div class="col-md-10 flex flex-col justify-around">
            <BulletPointList :items="buildingBlocks" />
          </div>
        </div>

        <div class="row mt-5 mb-7">
          <div class="col-12 col-lg-10 offset-lg-1">
            <h3 class="text-center">
              Wann habe ich keinen Versicherungsschutz?
            </h3>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 flex flex-col justify-between">
            <BulletPointList :items="noCoverage1" />
          </div>
          <div class="col-12 col-md-6 flex flex-col justify-between">
            <BulletPointList :items="noCoverage2" />
          </div>
        </div>

        <div class="row justify-center mt-14">
          <div class="col-md-10 col-lg-6">
            <NiceToKnow :bullets="noCoverageInfo" />
          </div>
        </div>
      </div>
    </section>

    <section class="section bg-dark text-light">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <h3 class="text-center pb-7">
              Wer ist versichert?
            </h3>

            <p class="text-center">
              Es sind die Geschäftsführung sowie alle Mitarbeitenden versichert. Dazu
              muss bei Antragstellung und in der Folge einmal pro Jahr die korrekte
              Mitarbeiteranzahl an den Versicherer gemeldet werden.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row mt-14 mb-7">
          <div class="col-12 col-lg-10 offset-lg-1">
            <h3 class="text-center">
              Schadenbeispiele
            </h3>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 flex flex-col justify-between">
            <p>Ein Arbeitnehmer hat die Kündigung erhalten und klagt auf Wiedereinstellung.</p>

            <BulletPointList :items="examples1" />
          </div>
          <div class="col-12 col-md-6 flex flex-col justify-between">
            <p>
              Der Kunde kauft einen Gebrauchtwagen und bereits nach kurzer Zeit stellt
              sich ein Motorschaden heraus. Der Kunde verlangt daher die Rückabwicklung
              des Kaufvertrages.
            </p>

            <BulletPointList :items="examples2" />
          </div>
        </div>

        <div class="row mt-5 mb-7">
          <div class="col-12 col-lg-10 offset-lg-1">
            <h3 class="text-center">
              Um welche Bausteine kann der Firmen-Rechtsschutz erweitert werden?
            </h3>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6 flex flex-col justify-between">
            <BulletPointList :items="extensions1" />
          </div>
          <div class="col-12 col-md-6 flex flex-col justify-between">
            <BulletPointList :items="extensions2" />
          </div>
        </div>

        <div class="row justify-center mt-14">
          <div class="col-md-10 col-lg-6">
            <NiceToKnow :bullets="extensionsInfo" />
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import KostenRechtsstreit from '@/bedarf/components/sach/rechtsschutz/KostenRechtsstreit.vue';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';

interface Props {
  data: Bedarfsinfo
}

defineProps<Props>();

const subtitle = `
  Kommt es zu einer gerichtlichen Auseinandersetzung, entstehen schnell Kosten in Höhe von mehreren tausend Euro oder
  sogar zehntausend Euro. Eine Rechtsschutz-versicherung übernimmt – unabhängig vom Ausgang des Verfahrens – die
  Gerichts- und Anwaltskosten sowie bei Bedarf ein kostenfreies Darlehen für eine zu stellende Kaution.`;

const costsInfo = [
  {
    title: 'Info',
    text: `Sofern aus Sicht des Rechtsschutzversicherers keine Möglichkeit besteht, den Rechtsstreit zu gewinnen, darf
            der Versicherer die Deckung des Rechtsstreits verweigern.`,
  },
];

const buildingBlocks = [
  {
    title: 'Firmenrechtsschutz',
    text: 'z. B. um gegen eine Kündigungsschutzklage eines Arbeitnehmers vorzugehen',
  },
  {
    title: 'Verkehrsrechtsschutz',
    text: 'z. B. um gegen ein verhängtes Fahrverbot vorzugehen',
  },
  {
    title: 'Immobilienrechtsschutz',
    text: 'z. B. um gegen fehlerhafte Abrechnungen der Abwassergebühren für Ihren Betrieb vorzugehen',
  },
];

const noCoverage1 = [
  { text: 'Vorsätzlich begangene Straftaten' },
  { text: 'Insolvenzverfahren' },
  { text: 'Enteignungs-, Flurbereinigungs- oder Planfest­stellungsverfahren' },
];

const noCoverage2 = [
  { text: 'Streit zwischen gemeinsam in einer Police versicherten Personen' },
  { text: 'Streitigkeiten vor Verfassungsgerichten bzw. vor internationalen Gerichtshöfen' },
  { text: 'Ansprüche gegen den Rechtsschutzversicherer selbst' },
];

const noCoverageInfo = [
  {
    title: 'Info',
    text: `Diese Aufzählung beinhaltet wesentliche Leistungsausschlüsse und ist nicht abschließend. Zudem besteht im
            Regelfall eine Wartezeit, bevor erstmalig Leistungen in Anspruch genommen werden können.`,
  },
];

const examples1 = [
  { text: 'Es werden die Kosten des eigenen Anwalts übernommen.' },
  { text: 'Es erfolgt die Kostenübernahme des gegnerischen Anwalts.' },
  { text: 'Es werden die Gerichtskosten übernommen.' },
];

const examples2 = [
  { text: 'Es werden die Kosten des eigenen Anwalts übernommen.' },
  { text: 'Es erfolgt die Kostenübernahme des gegnerischen Anwalts.' },
  { text: 'Es werden die Gerichtskosten übernommen.' },
  { text: 'Es erfolgt die Kostenübernahme für einen Sachverständigen.' },
];

const extensions1 = [
  { text: 'Vertrags-Check: Ein Anwalt prüft künftige und bereits abgeschlossene Verträge auf Rechtssicherheit.' },
  { text: 'Web-Check: spezialisierte Anwälte prüfen ihre Website.' },
  { text: 'AGB-Check: Ein spezialisierter Anwalt prüft ihre AGB.' },
];

const extensions2 = [
  { text: `Beratungs-Rechtsschutz für Arbeitgeber zum Beispiel rund um Betriebsvereinbarungen, Jugendschutz und
            Regelungen der Arbeitszeit.` },
  { text: 'Rechtsschutz für eine Generalunternehmerhaftung nach dem Mindestlohngesetz.' },
  { text: 'Forderungsmanagement: wichtig für z. B. Handwerker mit Kunden, welche ihre Rechnungen nicht begleichen.' },
];

const extensionsInfo = [
  {
    title: 'Tipp',
    text: `In eine Firmen-Rechtsschutz kann auch der private Rechtsschutzbereich eingeschlossen werden. Beachten Sie,
            dass bei mehreren aufeinanderfolgenden Schadensfällen Versicherer kündigen. Daher können getrennte
            Versicherungen für den privaten und gewerblichen Rechtsschutz sinnvoll sein.`,
  },
];
</script>
