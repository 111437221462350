<template>
  <SpartenWrapper
    :text="subtitle"
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    :versorgungen="data.bedarfsinfo.versorgungen"
    image="images/bedarfsinfo/krankenhauszusatzversicherung/arztzimmer"
    versorgungen-headline="Wie bin ich aktuell bei einem Krankenhausaufenthalt geschützt?"
    versorgungen-description="Stationäre Krankenzusatzversicherung"
  >
    <section class="section">
      <div class="container">
        <Behandlung />

        <div class="row">
          <Table class="col-xl-10 offset-xl-1" />
        </div>
      </div>
    </section>

    <JumboImage image="images/bedarfsinfo/krankenhauszusatzversicherung/kkh-bett" />

    <section class="section">
      <div class="container text-center">
        <h2 class="mb-7">
          In welchem Krankenhaus wollen Sie behandelt werden?
        </h2>

        <div class="row">
          <div class="col-12 col-lg-6 offset-lg-1">
            <div
              class="border-2 border-secondary p-5"
              style="transform: rotateZ(-3deg) translateY(20%)"
            >
              Als gesetzlich Versicherter entscheidet der Arzt oder Notfallmediziner,
              in welchem Krankenhaus Sie behandelt werden. Dabei muss das nächstgelegene,
              geeignete Krankenhaus empfohlen werden.
            </div>
          </div>
        </div>

        <div class="row">
          <div
            v-for="{ image, text, width, title } in hospitalTypes"
            :key="title"
            class="col-lg-4 mb-14 md:mb-0"
          >
            <div
              class="flex items-end justify-center mb-5"
              style="height: 279px"
            >
              <SvgVue
                :icon="`bedarfsinfo/krankenhauszusatzversicherung/${image}`"
                :style="{ width }"
              />
            </div>
            <h5 v-text="title" />
            <p v-text="text" />
          </div>
        </div>
      </div>
    </section>

    <section class="section bg-light">
      <div class="container">
        <div class="text-center mb-5">
          <h2>
            Wie entwickelt sich mein Beitrag?
          </h2>
        </div>

        <div class="row items-center">
          <div class="col-lg-6 mb-14 lg:mb-0">
            <SvgVue icon="bedarfsinfo/beitrag.svg" />
          </div>

          <div
            class="col-lg-6 text-justify"
            style="hyphens: auto"
          >
            <p>
              Mit dem Alter werden zunehmend mehr stationäre Behandlungen
              notwendig. Die meisten Versicherer erhöhen daher zu vorab festgelegten
              Zeitpunkten die Beiträge. Sie zahlen somit nur den Anteil,
              der für den Versicherungsschutz wirklich notwendig ist.
            </p>
            <p>
              Wünschen Sie sich konstante Beiträge, kommen Tarife, die Altersrück-
              stellungen bilden, in Frage. Diese sind deutlich teurer und legen Teile
              der Beiträge für das Alter zurück.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <h2 class="text-center mb-5">
          Kann ich eine Krankenzusatzversicherung abschließen,<br>
          obwohl ich bereits Vorerkrankungen habe?
        </h2>

        <div class="row">
          <div
            class="col-lg-8 offset-lg-2 text-justify"
            style="hyphens: auto"
          >
            <p>
              Um den Beitrag für die Versichertengemeinschaft stabil zu halten,
              begrenzen die Versicherungen die Leistungen in der Anfangszeit.
              So können Leistungen erstmals nach einer Wartezeit von üblicherweise
              drei Monaten für stationäre Behandlungen und acht Monaten für
              Entbindungen oder Psychotherapie in Anspruch genommen werden.
            </p>
            <p>
              Im Antrag müssen Sie zudem über Ihren Gesundheitszustand Auskunft geben.
              Kunden mit laufender Behandlung oder schlechter Prognose
              werden durch die Versicherer meist abgelehnt.
            </p>
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import Behandlung from '@/bedarf/components/Behandlung.vue';
import JumboImage from '@/bedarf/components/JumboImage.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import Table from '@/bedarf/components/gesundheitsvorsorge/krankenhauszusatz/Table.vue';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';
import SvgVue from "@/application/components/SvgVue";

interface Props {
  data: Bedarfsinfo
}

defineProps<Props>();

const subtitle = `
  Das Gesundheitssystem in Deutschland gilt als eines der besten der Welt. Doch gerade im Krankenhaus
  gibt es erhebliche Unterschiede, zwischen gesetzlichem und privatem Versicherungssystem.
`;

const hospitalTypes = [
  {
    image: 'kkh-basic.svg',
    title: 'Krankenhaus mit Basisversorgung',
    width: 200,
    text: 'Haben mindestens die Fachbereiche Chirurgie und Innere Medizin.',
  },
  {
    image: 'kkh-specific.svg',
    title: 'Krankenhaus mit Schwerpunktversorgung',
    width: 180,
    text: 'Verfügen über mehr Fachbereiche und erfüllen überregionale Schwerpunktaufgaben.',
  },
  {
    image: 'kkh-max.svg',
    title: 'Krankenhaus mit Maximalversorgung',
    width: 400,
    text: `Häufig Universitätskliniken, wo alle Fachbereiche zusammen arbeiten und neueste Erkenntnisse aus Forschung
            und Lehre in die Behandlung einfließen.`,
  },
];
</script>
