<template>
  <div
    v-for="entry in documents"
    :key="entry.id"
  >
    <button
      title="Zum Herunterladen der Datei klicken"
      type="button"
      class="flex flex-row gap-3 hover:bg-gray-100 w-full px-3 py-2.5 rounded"
      @click.prevent="() => setMediaRef(entry.urls.download, `${entry.name}.${entry.extension}`)"
    >
      <DsIcon
        :name="getFileIcon(entry.extension)"
        variant="light"
        size="lg"
        class="mt-1 text-gray-700"
      />
      <span class="flex flex-col text-left min-w-0">
        <b class="text-gray-800 truncate">
          {{ entry.type.name }}
        </b>
        <span class="text-gray-500 text-sm truncate">
          {{ entry.name }}.{{ entry.extension }}<br>
          {{ formatDate(entry.createdAt) }}
        </span>
      </span>
    </button>
  </div>
</template>

<script setup lang="ts">
import type { ContractDocument } from '@/api/contracts/types';
import { formatDate } from '@/application/utils/date';
import { DsIcon } from "@demvsystems/design-components";
import { useMediaPreviewStore } from "@/application/store/mediaPreviewStore";

interface Props {
  documents: ContractDocument[],
}

defineProps<Props>();

const { setMediaRef } = useMediaPreviewStore();

function getFileIcon(extension: string) {
  return extension === 'pdf' ? 'file-pdf' : 'paperclip';
}
</script>
