import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "27",
  height: "32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#00897B",
      "fill-rule": "nonzero",
      d: "M25.504 25.505c-3.333-3.165-6.468-6.582-9.416-10.16 2.757-3.524 5.43-7.056 7.719-10.992 1.37-2.489-2.01-5.205-3.389-2.626-1.998 3.603-4.474 6.884-7.034 10.156C10.923 8.537 8.555 5.11 6.385 1.432c-1.45-2.422-4.731.411-3.281 2.833 2.32 3.876 4.744 7.581 7.448 11.043-3.423 4.18-6.845 8.36-9.556 13.069-1.454 2.48 1.935 5.106 3.389 2.626 2.589-4.359 5.731-8.296 8.956-12.224 3.023 3.677 6.243 7.103 9.65 10.366 2.013 1.937 4.536-1.793 2.514-3.64z"
    }, null, -1)
  ])))
}
export default { render: render }