const TAILWIND_MD_BREAKPOINT = '768px';

export type OnMobileFn =  (isMobile: boolean) => void;

export function detectMobile(fn: OnMobileFn): void {
  const mediaQuery = window.matchMedia(`only screen and (max-width: ${TAILWIND_MD_BREAKPOINT})`);

  if (mediaQuery.addEventListener) {
    mediaQuery.addEventListener('change', (e) => fn(e.matches));
  } else {
    // from https://stackoverflow.com/a/60000747 to support Safari 12
    mediaQuery.addListener((e) => fn(e.matches));
  }

  fn(mediaQuery.matches);
}
