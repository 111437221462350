import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M16.31 0a15.94 15.94 0 1 0 0 31.91A15.94 15.94 0 0 0 16.3 0zm0 31.02A15.07 15.07 0 1 1 16.32.9a15.07 15.07 0 0 1 0 30.12z" }, null, -1),
    _createElementVNode("path", { d: "M20.58 24.13a9.5 9.5 0 0 1-4.27 1.07 9.22 9.22 0 0 1-9.2-9.2c0-.27-.18-.44-.44-.44s-.45.17-.45.44a10.11 10.11 0 0 0 14.76 8.93c.22-.13.31-.4.18-.57-.1-.23-.4-.32-.58-.23M12.89 9.38a.98.98 0 1 1-1.96 0 .98.98 0 0 1 1.96 0m8.75 0a.98.98 0 1 1-1.95 0 .98.98 0 0 1 1.95 0" }, null, -1)
  ])))
}
export default { render: render }