<template>
  <div class="container">
    <div class="row text-center">
      <div class="col-12">
        <h3 class="mb-7">
          Benötige ich eine Unfallversicherung?
        </h3>
        <p>
          In Deutschland gibt es rund 10 Mio. Unfallverletzte im Jahr.
          Wie sind Sie im Fall der Fälle
          <span v-if="(gesetzlichAngestellt || gesetzlichStudent) && !fuersorge">
            in der gesetzlichen Unfallversicherung</span>
          <span v-if="fuersorge && !(gesetzlichAngestellt || gesetzlichStudent)">
            über die Unfallfürsorge
          </span>
          versichert?
        </p>
      </div>
    </div>

    <div class="row mt-7 justify-center">
      <div
        v-if="gesetzlichAngestellt || gesetzlichStudent"
        :class="[$style.borderCol,
                 {'col-12 col-sm-12 col-xl-6': fuersorge || keinSchutz,
                  'col-12 col-lg-9': numberOfCols <= 2}]"
        class="col"
      >
        <h4
          :class="{[$style.heading]: needHeadingHeight}"
          class="text-center mb-7"
        >
          Durchschnittlicher Versicherungsschutz am Tag in der gesetzlichen Unfallversicherung
        </h4>

        <div class="row">
          <div
            v-if="gesetzlichAngestellt"
            :class="{'col-md-6': gesetzlichStudent}"
            class="col"
          >
            <versicherungsschutz-persona
              :covered-hours="8"
              :title="taetigkeitsName || 'Angestellte / Auszubildende'"
              :image="PersonMiddle"
            />
          </div>
          <div
            v-if="gesetzlichStudent"
            :class="{'col-12 col-md-6': gesetzlichAngestellt}"
            class="col mt-7 md:mt-0"
          >
            <versicherungsschutz-persona
              :covered-hours="6"
              :title="kind ? 'Kind / Schüler / Studenten' : taetigkeitsName || 'Schüler / Studenten'"
              :image="PersonYoung"
            />
          </div>
        </div>

        <div class="text-center mt-7">
          <p>
            <small>Die gesetzliche Unfallversicherung leistet
              bei Unfällen bei der Arbeit und in der Schule.
              Des Weiteren sind Unfälle auf direktem Hin- und Rückweg versichert.</small>
          </p>
        </div>
      </div>

      <div
        v-if="keinSchutz"
        :class="$style.borderCol"
        class="col-12 col-md"
      >
        <h4
          :class="{[$style.heading]: needHeadingHeight}"
          class="text-center mb-7"
        >
          Kein Versicherungsschutz in der gesetzlichen Unfallversicherung
        </h4>

        <versicherungsschutz-persona
          :covered-hours="0"
          :title="taetigkeitsName || 'Selbstständige / Freiberufler / Hausfrauen / Hausmänner'"
          :image="PersonOld"
        />
      </div>

      <div
        v-if="fuersorge"
        :class="$style.borderCol"
        class="col-12 col-md"
      >
        <h4
          :class="{[$style.heading]: needHeadingHeight}"
          class="text-center mb-7"
        >
          Durchschnittlicher Versicherungsschutz am Tag über die Unfallfürsorge
        </h4>

        <versicherungsschutz-persona
          :covered-hours="8"
          :title="taetigkeitsName || 'Beamte'"
          :image="ManProfile"
        />

        <div class="text-center mt-7">
          <p>
            <small>Die Unfallfürsorge leistet bei Unfällen während der dienstlichen
              Tätigkeit, bei Dienstreisen und Dienstgängen.</small>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VersicherungsschutzPersona from './VersicherungsschutzPersona.vue';
import PersonMiddle from '../../../../../../assets/images/bedarfsinfo/unfallversicherung/person-middle.jpg';
import PersonYoung from '../../../../../../assets/images/bedarfsinfo/unfallversicherung/person-young.jpg';
import PersonOld from '../../../../../../assets/images/bedarfsinfo/unfallversicherung/person-old.jpg';
import ManProfile from '../../../../../../assets/images/bedarfsinfo/unfallversicherung/man-profile.jpg';

const GROUP_UNKOWN = 0;
const GROUP_KEIN_SCHUTZ = 1;
const GROUP_GESTZLICH_STUDENT = 2;
const GROUP_GESTZLICH_ANGESTELLT = 3;
const GROUP_FUERSORGE = 4;

const groups = {
  [GROUP_GESTZLICH_ANGESTELLT]: [
    2, // Angestellter
    10, // Angestellter öffentl. Dienst
    11, // Geschäftsführender Gesellschafter mit Entgeltfortzahlung
    14, // Person in Berufsausbildung
  ],
  [GROUP_GESTZLICH_STUDENT]: [
    22, // Angestellter - Minijob
    13, // Person in Schulausbildung / Praktikum / FSJ
    15, // Person in Studium
  ],
  [GROUP_KEIN_SCHUTZ]: [
    20, // Arbeitssuchend
    25, // Geschäftsführender Gesellschafter ohne Entgeltfortzahlung
    24, // Erwerbsunfähig / nicht erwerbstätig
    17, // Hausfrau / Hausmann
    3, // Selbstständiger / Freiberufler
    23, // Sonstige
    12, // Vorstand
    18, // Person in Elternzeit
    19, // Rentner / Ruheständler
  ],
  [GROUP_FUERSORGE]: [
    5, // Beamter auf Lebenszeit
    8, // Beamter auf Widerruf / Probe / Zeit
  ],
};

export default {
  components: {
    VersicherungsschutzPersona,
  },

  props: {
    kind: {
      type: Boolean,
      default: false,
    },
    taetigkeitsId: {
      type: Number,
      default: -1,
    },
    taetigkeitsName: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      PersonMiddle,
      PersonYoung,
      PersonOld,
      ManProfile,
      groups,
      GROUP_GESTZLICH_ANGESTELLT,
      GROUP_GESTZLICH_STUDENT,
      GROUP_KEIN_SCHUTZ,
      GROUP_FUERSORGE,
      GROUP_UNKOWN,
    };
  },

  computed: {
    groupKey() {
      const group = Object.keys(this.groups)
        .find((key) => this.groups[key].includes(this.taetigkeitsId));

      if (!group) {
        return this.GROUP_UNKOWN;
      }

      return Number.parseInt(group, 10);
    },

    fuersorge() {
      return this.groupKey === this.GROUP_FUERSORGE || this.isUnkownGroup;
    },

    gesetzlichAngestellt() {
      return this.groupKey === this.GROUP_GESTZLICH_ANGESTELLT || this.isUnkownGroup;
    },

    gesetzlichStudent() {
      return this.groupKey === this.GROUP_GESTZLICH_STUDENT || this.kind || this.isUnkownGroup;
    },

    keinSchutz() {
      return this.groupKey === this.GROUP_KEIN_SCHUTZ || this.isUnkownGroup;
    },

    isUnkownGroup() {
      return this.groupKey === this.GROUP_UNKOWN;
    },

    numberOfCols() {
      const cols = [
        this.fuersorge,
        this.gesetzlichAngestellt,
        this.gesetzlichStudent,
        this.keinSchutz,
      ];
      return cols.reduce((accumulator, value) => (value ? accumulator + 1 : accumulator), 0);
    },

    needHeadingHeight() {
      return (this.fuersorge || this.keinSchutz) && this.numberOfCols >= 2;
    },
  },
};
</script>

<style lang="scss" module>

.gauge {
  font-size: 100px !important;
}

.borderCol + .borderCol {
  border-top: 2px dotted #B4B8C0;
}
</style>
