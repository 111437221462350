import type { Ref } from 'vue';
import { computed } from 'vue';

import type { UnreadHintType } from '@/api/unreadHint/types';
import { useUnreadHintStore } from '@/application/store/unreadHintStore';

export const useUnreadHint = (type: UnreadHintType, id: number): {
  isUnread: Ref<boolean>,
} => {
  const store = useUnreadHintStore();

  const isUnread = computed(() => store[type].has(id));

  return {
    isUnread,
  };
};
