import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 70 70"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g fill=\"none\" fill-rule=\"evenodd\"><path stroke=\"#000\" stroke-width=\"1.5\" d=\"M7.5 58.44h51.3V22.8H7.5z\"></path><path stroke=\"#000\" stroke-width=\"1.5\" d=\"M3.09 25.62 33.83.97l28.74 24.65M18.64 57.8h10.35V36.46H18.64zm19.83-10.89h9.42V36.56h-9.42z\"></path><path stroke=\"#000\" stroke-width=\"1.5\" d=\"M30.46 19.06h6.85v-7.55h-6.85z\"></path><path stroke=\"#C34F4A\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"4\" d=\"M2 2.14 67.75 67.9m.08-65.73L2.08 67.91\"></path></g>", 1)
  ])))
}
export default { render: render }