<script setup lang="ts">
import { LoginView } from "@/authentication/types/LoginView";
import AuthCardShell from '@/application/components/AuthCardShell.vue';
import { ref, watch } from 'vue';
import TheEmailLoginForm from "@/authentication/components/TheEmailLoginForm.vue";
import ThePasswortLoginForm from "@/authentication/components/ThePasswortLoginForm.vue";
import TwoFactorChallengeForm from "@/authentication/components/TwoFactorChallengeForm.vue";
import { useRoute, useRouter } from "vue-router";
import { Finanzmanager } from "@/application/router/types/types";
import { useUserStore } from "@/user/store/userStore";
import { SecretNames, setSecret } from "@/application/plugins/secretStore";
import { DsIcon } from "@demvsystems/design-components";

const router = useRouter();
const route = useRoute();

const view = ref<LoginView>(LoginView.Password);
const requestAccessToken = ref('');
const loading = ref(false);

function continueWithToken(token: string) {
  requestAccessToken.value = token;
  view.value = LoginView.TwoFactor;
}

async function loggingIn(accessToken?: string, clientSecret?: string) {
  loading.value = true;
  if (accessToken && clientSecret) {
    await Promise.all([
      setSecret(SecretNames.AccessToken, accessToken),
      setSecret(SecretNames.ClientSecret, clientSecret),
    ]);
  }

  await useUserStore().onLoggedIn();
  const redirect = route.query?.redirect ?? null;

  return typeof redirect === 'string'
    ? await router.push(decodeURIComponent(redirect))
    : await router.push({ name: Finanzmanager.VERTRAEGE });
}

watch(() => route.query.requestAccessToken,
  (token: unknown) => {
    if (token) {
      continueWithToken(token as string)
    }
  },
  { immediate: true },
);
</script>

<template>
  <AuthCardShell>
    <div v-if="loading" class="relative text-center">
      <DsIcon class="mr-2 text-blue-700" name="spinner-third" variant="duotone" spin size="2x" />
    </div>

    <TheEmailLoginForm
      v-else-if="view === LoginView.Email"
      @switch-to-password="view = LoginView.Password"
    />

    <ThePasswortLoginForm
      v-else-if="view === LoginView.Password"
      @switch-to-mail="view = LoginView.Email"
      @two-factor-challenge="continueWithToken"
    />

    <TwoFactorChallengeForm
      v-else-if="view === LoginView.TwoFactor"
      :request-access-token="requestAccessToken"
      @switch-to-password="view = LoginView.Password"
      @logging-in="loggingIn"
    />
  </AuthCardShell>
</template>
