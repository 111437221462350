<template>
  <div>
    <div class="flex flex-col items-center text-center">
      <h6
        :class="$style.subheading"
        class="mb-5"
      >
        <strong>{{ title }}</strong>
      </h6>
      <img
        alt="Unfallversicherung"
        :src="image"
        class="img-fluid"
      >
    </div>
    <div class="flex justify-center mt-7">
      <div class="mr-5 self-end">
        <List
          :highlight="insured"
          :mute="!insured"
          sub-title="Versichert"
        >
          {{ coveredHours }} Stunden
        </List>
        <List sub-title="Nicht versichert">
          {{ notCoveredHours }} Stunden
        </List>
      </div>
      <div class="flex items-center">
        <gauge
          :percentage="percent"
          :class="$style.gauge"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Gauge from '@/application/components/Gauge.vue';
import List from '@/application/components/List.vue';

export default {
  components: {
    Gauge,
    List,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    coveredHours: {
      type: Number,
      required: true,
    },
  },

  data() {
    const HOURS_OF_DAY = 24;
    return {
      HOURS_OF_DAY,
    };
  },

  computed: {
    notCoveredHours() {
      return this.HOURS_OF_DAY - this.coveredHours;
    },

    percent() {
      return (this.coveredHours / this.HOURS_OF_DAY) * 100;
    },
    insured() {
      return this.coveredHours > 0;
    },
  },
};
</script>

<style lang="scss" module>

  .gauge {
    font-size: 75px !important;
  }

</style>
