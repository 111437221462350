import { useGet } from "@/api/lib/integration";
import { NotificationVariant, useNotification } from "@demvsystems/design-components";
import { isNativePlatform } from "@/application/utils/envInfo";
import type { StatOptions } from "@capacitor/filesystem";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { triggerDownload } from "@/application/utils/triggerDownload";
import { captureException } from "@sentry/vue";
import { addAuthHeaders } from "@/api/lib/request";

export function incrementFilename(filePath: string) {
  // Regular expression to match '(number)' at the end of the file name
  const regExp = /\((\d+)\)(\.[^.]+)?$/;
  const match = filePath.match(regExp);

  // If there's no '(number)', append '(1)' before extension
  if (!match) {
    const dotIndex = filePath.lastIndexOf('.');
    return dotIndex < 1 // If there's no extension
      ? filePath + "(1)"
      : filePath.slice(0, dotIndex) + "(1)" + filePath.slice(dotIndex);
  } else {
    // If there's already '(number)', increment that number by 1
    const number = parseInt(match[1]) + 1;
    return filePath.replace(regExp, '(' + number + ')' + (match[2] ?? ''));
  }
}

async function checkFileExists(opts: StatOptions): Promise<boolean> {
  try {
    await Filesystem.stat(opts);

    return true;
  } catch {
    return false;
  }
}

async function getIncrementedFilename(opts: { path: string, directory: Directory }): Promise<{ path: string, directory: Directory }> {
  const copy = { ...opts };
  while (await checkFileExists(copy)) {
    copy.path = incrementFilename(copy.path);
  }

  return copy;
}

export const useFileDownload = () => {
  const { show } = useNotification();

  function fail(error?: unknown): void {
    if (error) captureException(error);
    show('Download fehlgeschlagen', NotificationVariant.Warning, 0);
  }

  function ok(): void {
    show('Download erfolgreich', NotificationVariant.Success)
  }

  async function nativeDownload(url: string, filename: string, isExternalUrl: boolean = true) {
    let payload = { path: `Download/${filename}`, directory: Directory.ExternalStorage };
    try {
      payload = (await getIncrementedFilename(payload));
    } catch (e) {
      captureException(e);
      fail();
      return;
    }
    try {
      const options = { url, headers: { 'accept': '*/*' }, ...payload };
      if (!isExternalUrl) await addAuthHeaders(options.headers);
      await Filesystem.downloadFile(options);
      ok();
    } catch (error: unknown) { fail(error); }
  }

  async function webDownload(url: string, filename: string) {
    const success = await triggerDownload(
      useGet<void, Blob>(url, { accept: '*/*', isAbsoluteUrl: true }),
      filename,
    );

    if (success) {
      ok();
    } else {
      fail();
    }
  }

  async function downloadApiFile(url: string, filename: string) {
    return isNativePlatform
      ? await nativeDownload(url, filename, false)
      : await webDownload(url, filename);
  }

  async function downloadS3File(url: string, filename: string) {
    const response = await useGet<void, { url: string }>(url, { isAbsoluteUrl: true })();
    if (response instanceof Error) return fail(response);

    return isNativePlatform
      ? await nativeDownload(response.url, filename, true)
      : await webDownload(response.url, filename);
  }

  return { downloadApiFile, downloadS3File };
};
