<template>
  <div>
    <div
      v-if="hasSeparatedTabs"
      :class="{'bg-dark': hasDarkBg || hasDarkBgTabs}"
    >
      <div class="container">
        <div class="row py-7 mb-5">
          <div
            v-if="title"
            class="col-12"
          >
            <h3 class="text-center text-light mb-7">
              {{ title }}
            </h3>
          </div>
          <div
            class="col-12 col-xl-10 offset-xl-1"
            role="tabpanel"
          >
            <div
              class="row"
              role="tablist"
            >
              <div
                v-for="tab in tabs"
                :key="tab.props.name"
                class="col-12 col-md mb-2.5 md:mb-0 items-stretch flex"
                role="presentation"
              >
                <a
                  :class="tabStyles(tab)"
                  href="#"
                  role="tab"
                  class="flex justify-center items-center w-full hover:no-underline whitespace-nowrap"
                  @click.prevent="select(tab)"
                  @focus.prevent="select(tab)"
                  v-text="tab.props.name"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="{'bg-dark pb-7': hasDarkBg}">
      <div
        v-if="hasSeparatedTabs"
        class="container pt-7 md:pb-7"
      >
        <slot />
      </div>
    </div>

    <div
      v-if="!hasSeparatedTabs"
      class="container"
    >
      <div class="row">
        <div
          class="col-12 col-xl-3"
          role="tabpanel"
        >
          <div
            class="row"
            role="tablist"
          >
            <div
              v-for="tab in tabs"
              :key="tab.props.name"
              class="col-md-12"
              role="presentation"
            >
              <a
                :class="tabStyles(tab)"
                href="#"
                role="tab"
                class="flex justify-center items-center w-full hover:no-underline whitespace-nowrap
                mb-2.5"
                @click.prevent="select(tab)"
                @focus.prevent="select(tab)"
                v-text="tab.props.name"
              />
            </div>
          </div>
        </div>

        <div
          class="col-12 col-xl-9 mt-5 xl:mt-0"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onUnmounted, provide, ref, useSlots, watch } from 'vue';

import { useTabs } from '@/application/composables/useTabs';

const props = defineProps({
  hasDarkBg: {
    type: Boolean,
    default: false,
  },
  hasDarkBgTabs: {
    type: Boolean,
    default: false,
  },
  hasSmallButtons: {
    type: Boolean,
    default: false,
  },
  hasSeparatedTabs: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  initialTab: {
    type: String,
    default: null,
  },
});

const slots = useSlots();
const tabs = ref(slots.default());

const selected = ref(props.initialTab);

const { bus, TAB_LOADED, SELECT_TAB } = useTabs();

provide('bus', bus);

bus.on(TAB_LOADED, tabMounted);

onUnmounted(() => {
  bus.off(TAB_LOADED, tabMounted);
});

function tabMounted() {
  select({ props: { name: selected.value } });
}

function select(tab) {
  selected.value = tab.props.name;
  bus.emit(SELECT_TAB, tab.props.name);
}

watch(() => props.initialTab, (name) => select({ props: { name } }));

function tabStyles(tab) {
  return {
    'active': tab.props.name === selected.value && !props.hasDarkBgTabs,
    'btn-light': tab.props.name === selected.value && (props.hasDarkBg || props.hasDarkBgTabs),
    'btn-light-lg': tab.props.name === selected.value && (props.hasDarkBg || props.hasDarkBgTabs)
        && !props.hasSmallButtons,
    'btn-dark': tab.props.name === selected.value && !(props.hasDarkBg || props.hasDarkBgTabs),
    'btn-dark-lg': tab.props.name === selected.value && !(props.hasDarkBg || props.hasDarkBgTabs)
        && !props.hasSmallButtons,
    'btn-outline-light': tab.props.name !== selected.value && (props.hasDarkBg || props.hasDarkBgTabs),
    'btn-outline-light-lg': tab.props.name !== selected.value && (props.hasDarkBg || props.hasDarkBgTabs)
        && !props.hasSmallButtons,
    'btn-outline-dark': tab.props.name !== selected.value && !(props.hasDarkBg || props.hasDarkBgTabs),
    'btn-outline-dark-lg': tab.props.name !== selected.value && !(props.hasDarkBg || props.hasDarkBgTabs)
        && !props.hasSmallButtons,
  };
}
</script>
