<script lang="ts">
import type { PropType, VNode } from 'vue';
import { defineComponent, h, useCssModule } from 'vue';

export type GridTableItem = {
  title: string,
  value: string | number | VNode | VNode[] | null,
};

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<GridTableItem[]>,
      required: true,
    },
    mutedTitle: {
      type: Boolean,
      default: false,
    },
    rightAlignedValue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const $style = useCssModule();
    const visibleItems = props.items.filter(({ value }) => value !== null);

    return () => h(
      'div',
      { class: $style.twoColumnGridTable },
      visibleItems
        .map(({ title, value }, i) => [
          h('div', { key: `${i}-1`, class: props.mutedTitle ? 'text-muted' : '' }, title),
          h('div', { key: `${i}-2`, class: props.rightAlignedValue ? 'text-right' : '' }, value!),
        ]),
    );
  },
});
</script>

<style lang="scss" module>
.twoColumnGridTable {
  display: grid;
  grid-template-columns: repeat(2, 50%);

  & > * {
    word-wrap: break-word;
    border-bottom: 1px solid #e7e7e7;
    padding: 0.4rem 0;

    &:nth-last-child(-n+2) {
      border-bottom: 0;
    }
  }
}
</style>
