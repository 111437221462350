import type { ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';

import { notifyAboutUploadedFiles, uploadToS3 } from '@/api/documents/documentsApi';
import type { S3Info } from '@/api/documents/types';
import type { MetaData } from '@/upload/types/types';
import { compressImage, isCompressableImageFileType } from '@/application/utils/compressImage';
import { prettyPrintSize } from "@/application/utils/prettyPrintSize";

const validFileTypes = [
  'image/*',
  'application/pdf',
  'application/zip',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
];

export const MAX_UPLOAD_SIZE = 20_971_520; // 20 * 1024 * 1024 = 20 MB

export const IMAGE_COMBINATION_LIMIT = 10;

const mergeFileNames = (files: S3Info[], fileNames: string[]): S3Info[] => files.map(
  (file, index) => {
    const extension = file.name.split('.').pop();
    const fileNameFragments = fileNames[index].split('.');
    fileNameFragments.pop();

    file.name = fileNameFragments.length > 0
      ? `${fileNameFragments.join('')}.${extension}`
      : `${fileNames[index]}.${extension}`;

    return file;
  },
);

export const useDocumentUpload = (options: { compressImages?: boolean } = {}): {
  validFileTypes: string[],
  validFiles: Ref<File[]>,
  invalidFiles: Ref<File[]>,
  imageFiles: ComputedRef<File[]>,
  fileNames: Ref<string[]>,
  hasMultipleImageFiles: ComputedRef<boolean>,
  exceedsImageCombinationLimit: ComputedRef<boolean>
  loading: Ref<boolean>,
  setFiles: (files: FileList) => void,
  setFileName: (value: string, index: number) => void,
  validate: (file: File) => boolean,
  remove: (index: number) => void,
  upload: (metaData: MetaData) => Promise<void>,
  errorMessages: Ref<string[]>
} => {
  const invalidFiles = ref<File[]>([]);
  const validFiles = ref<File[]>([]);
  const fileNames = ref<string[]>([]);
  const loading = ref(false);
  const errorMessages = ref<string[]>([])

  const imageFiles = computed(() => validFiles.value.filter((file) => file.type.startsWith('image/')));
  const hasMultipleImageFiles = computed(() => imageFiles.value.length > 1);
  const exceedsImageCombinationLimit = computed(() => imageFiles.value.length > IMAGE_COMBINATION_LIMIT);

  const validate = (file: File) => file.size <= MAX_UPLOAD_SIZE && [
    'image', ...validFileTypes.slice(1), // catch all images without explicit type
  ].some((validType) => file.type.includes(validType));

  const setFileName = (value: string, index: number) => {
    fileNames.value[index] = value;
  };

  const setFiles = (files: FileList) => {
    invalidFiles.value = [];
    errorMessages.value = [];

    if (files.length === 0) return;

    const totalSize = Array.from(files).reduce((acc, curr) => acc + curr.size, 0);
    if (totalSize > MAX_UPLOAD_SIZE) {
      errorMessages.value.push("Die Dateien sind zusammen größer als " + prettyPrintSize(MAX_UPLOAD_SIZE));
      return;
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (!validate(file)) {
        invalidFiles.value.push(file);
      } else {
        validFiles.value.push(file);
        fileNames.value.push(file.name);
      }
    }
  };

  const upload = async (metaData: MetaData) => {
    loading.value = true;
    try {
      const s3info = await Promise.all(
        validFiles.value.map(async (file) => {
          if (options.compressImages && isCompressableImageFileType(file.type)) {
            const compressed = await compressImage(file, 250);
            return uploadToS3(compressed);
          }
          return uploadToS3(file);
        }),
      );
      await notifyAboutUploadedFiles({ ...metaData, files: mergeFileNames(s3info, fileNames.value) });
    } finally {
      loading.value = false;
    }
  };

  const remove = (index: number) => {
    validFiles.value.splice(index, 1);
    fileNames.value.splice(index, 1);
  };

  return {
    validFileTypes,
    validFiles,
    invalidFiles,
    fileNames,
    imageFiles,
    hasMultipleImageFiles,
    exceedsImageCombinationLimit,
    loading,
    setFiles,
    setFileName,
    validate,
    remove,
    upload,
    errorMessages,
  };
};
