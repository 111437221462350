import type { Ref } from 'vue';
import { ref } from 'vue';

import { getGesellschaften } from '@/api/common/commonApi';
import type { Gesellschaft } from '@/api/common/types';
import { call } from "@/api/lib/integration";

export const useGesellschaften = (): {
  gesellschaften: Ref<Gesellschaft[]>,
  fetchGesellschaften: () => Promise<void>
} => {
  const gesellschaften = ref<Gesellschaft[]>([]);

  return {
    gesellschaften,
    fetchGesellschaften: async (): Promise<void> => {
      await call(
        getGesellschaften(),
        (data) => { gesellschaften.value = data },
      );
    },
  };
};
