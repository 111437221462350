import type { Ref } from 'vue';
import { ref } from 'vue';

import { getSparten } from '@/api/common/commonApi';
import type { Sparte } from '@/api/common/types';
import { call } from "@/api/lib/integration";

export const useSparten = (): {
  sparten: Ref<Sparte[]>,
  fetchSparten: () => Promise<void>
} => {
  const sparten = ref<Sparte[]>([]);

  return {
    sparten,
    fetchSparten: async (): Promise<void> => {
      await call(
        getSparten(),
        (data) => { sparten.value = data },
      );
    },
  };
};
