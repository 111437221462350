<script setup lang="ts">

import { DsIcon } from '@demvsystems/design-components';
import { Finanzmanager } from '@/application/router/types/types';
import { useBaseDataStore } from '@/user/store/baseDataStore';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const { handlungCount } = storeToRefs(useBaseDataStore());

const label = computed(() => handlungCount.value && handlungCount.value > 1 ?
  `${handlungCount.value} offene Handlungen` : `${handlungCount.value} offene Handlung`
);

const route = useRoute();

const show = computed(() => {
  return !!handlungCount.value && !route.matched.some(route => route.path === '/handlungen');
})
</script>

<template>
  <div
    v-if="show"
    class="bg-red-100 z-50 shadow-xs py-3 bg-opacity-70"
  >
    <div class="container flex flex-row items-center gap-3 text-sm md:text-base">
      <DsIcon
        name="circle-info"
        variant="light"
        class="text-red-800"
      />
      <span class="text-red-800">
        <RouterLink :to="{ name: Finanzmanager.HANDLUNGEN }">
          <span class="font-semibold text-red-800 underline underline-offset-2">{{ label }}</span>
        </RouterLink>
        in Ihrer Inbox.
      </span>
    </div>
  </div>
</template>
