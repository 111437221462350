<script setup lang="ts">
import { DsAlert, DsButton, DsVirtualList } from '@demvsystems/design-components';
import { useContractStore } from "@/contracts/stores/contractStore";
import { spartenMapper } from "@/application/utils/sparteFormatter";
import { storeToRefs } from "pinia";

interface Props {
  modelValue: number[],
}

defineProps<Props>();
const emit = defineEmits(['showSelectContracts'])

const { contractsMap } = storeToRefs(useContractStore());
</script>

<template>
  <div class="flex flex-col gap-2">
    <div class="flex flex-col md:flex-row items-start justify-between">
      <span class="font-medium text-gray-700 mb-1">Verträge</span>
      <div class="flex flex-col">
        <div class="w-80 space-y-1 max-h-40 md:max-h-48 overflow-y-auto">
          <DsVirtualList
            v-slot="{ item: contractId }"
            :items="modelValue"
            :item-height="32"
          >
            <div class="flex flex-row gap-2 items-start text-start text-xs">
              <span class="text-xs truncate bg-gray-100 rounded-full py-1 px-3 border-gray-200">
                {{ `${spartenMapper(contractsMap.get(contractId)?.product!)} (Nr.: ${contractsMap.get(contractId)?.number ?? '-'})` }}
              </span>
            </div>
          </DsVirtualList>
        </div>


        <div class="flex flex-row w-full md:w-80 ml-auto">
          <DsButton
            v-if="contractsMap.size > 0"
            variant="secondary"
            class="mr-auto"
            @click="emit('showSelectContracts')"
          >
            {{ modelValue && modelValue.length > 0 ? 'Verträge bearbeiten' : 'Auswählen' }}
          </DsButton>
          <span v-else>Noch keine Verträge vorhanden.</span>
        </div>
      </div>
    </div>
    <DsAlert
      type="info"
      label="Vertragszuordnung"
      class="mt-3 text-start"
    >
      Durch Zuordnung eines Vertrags zur Bankverbindung, wird dieses
      Konto für die Abbuchung der Versicherungsbeiträge genutzt.
    </DsAlert>
  </div>
</template>
