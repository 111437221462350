<template>
  <NotificationSettingsFormSkeleton v-if="loading" />

  <div v-if="!loading">
    <div v-for="(setting, id) in notificationSettings" :key="id">
      <span class="text-gray-900 font-semibold">{{ getLabel(id) }}</span>
      <div class="flex items-center justify-between my-2 w-full">
        <label>Per Mail benachrichtigen</label>
        <DsSwitch v-model="setting.mail" size="lg" />
      </div>
      <!--      <div class="my-2 w-full">-->
      <!--        <label>Auf dem Handy benachrichtigen</label>-->
      <!--        <DsSwitch v-model="setting.push" class="float-right"></DsSwitch>-->
      <!--      </div>-->
    </div>

    <DsAlert
      v-if="error"
      class="my-10"
      type="error"
      label="Es ist leider ein Fehler aufgetreten."
    >
      {{ error }}
    </DsAlert>

    <div class="text-right mt-5">
      <DsButton
        size="lg"
        :handler="save"
      >
        Speichern
      </DsButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  DsAlert,
  DsButton,
  DsSwitch,
  NotificationVariant,
  useNotification
} from '@demvsystems/design-components';
import { getNotificationSettings, putNotificationSettings } from '@/api/account/accountApi';
import { onMounted, ref } from 'vue';
import { throwableCall } from '@/api/lib/integration';
import NotificationSettingsFormSkeleton from '@/user/components/NotificationSettingsFormSkeleton.vue';
import { captureException } from '@sentry/vue';
import type { NotificationSettings } from '@/api/account/types';
const { show } = useNotification();

const loading = ref<boolean>(true);
const error = ref<string>('');
const notificationSettings = ref<NotificationSettings | null>(null);

const labels = new Map<string, string>([
  ['handlungCreated', 'Neue Inhalte in der Inbox'],
]);

onMounted(async function () {
  try {
    loading.value = true;
    notificationSettings.value = await throwableCall(getNotificationSettings());
  } catch (err) {
    handleError(err);
  } finally {
    loading.value = false;
  }
});

async function save() {
  if (notificationSettings.value === null) {
    return;
  }

  try {
    await throwableCall(putNotificationSettings(notificationSettings.value));
    show('Die Benachrichtigungseinstellungen wurden erfolgreich gespeichert', NotificationVariant.Success);
    error.value = '';
  } catch (err) {
    handleError(err);
  }
}

function getLabel(id: string): string {
  return labels.get(id) ?? id;
}

function handleError(err) {
  captureException(err);
  error.value = 'Es gab einen Fehler. Bitte laden Sie die Seite neu oder probieren Sie es zu einem späteren'
    + ' Zeitpunkt nochmal.'
}
</script>
