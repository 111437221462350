<template>
  <DsFormattedNumberInput
    :model-value="modelValue === null ? null : roundDecimalPlaces(Number(modelValue))"
    :size="size"
    :default-value="null"
    :place-holder="placeHolder"
    suffix="€"
    with-fractions
    class="text-left"
    @update:model-value="emit('update:modelValue', $event === null ? null : asString ? `${$event}` : $event)"
  />
</template>

<script lang="ts" setup>
import { DsFormattedNumberInput } from '@demvsystems/design-components';

interface Props {
  modelValue: string | number | null,
  size?: 'sm' | 'base' | 'lg',
  asString?: boolean,
  placeHolder?: string,
}

withDefaults(defineProps<Props>(), {
  size: 'base',
  returnString: false,
});

const emit = defineEmits<{
  'update:modelValue': [value: string | number | null]
}>()

const roundDecimalPlaces = (value: number): number => {
  return Number(value.toFixed(2));
};
</script>
