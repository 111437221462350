import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 98.89 98.89"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "49.44",
      cy: "49.44",
      r: "49.44",
      fill: "#4181D2"
    }, null, -1),
    _createElementVNode("g", { fill: "#FFF" }, [
      _createElementVNode("path", { d: "M17.3 42.26c.15-.76.25-1.52.44-2.27a17.6 17.6 0 0 1 8.7-11.44 17.34 17.34 0 0 1 10.13-2.18 17.6 17.6 0 0 1 11.89 5.94c.25.32.44.34.74 0a17.38 17.38 0 0 1 10.16-5.72 17.4 17.4 0 0 1 14.39 3.62c3.63 2.9 5.8 6.7 6.52 11.28.43 2.74.2 5.46-.65 8.11-.18.57-.37 1.15-.53 1.73-.14.48-.42.63-.92.63-4.2-.02-8.4-.01-12.6-.01-.67 0-1.34-.02-2.02.01-.39.01-.61-.14-.79-.48a1132 1132 0 0 0-6.86-12.9c-.09-.17-.2-.34-.34-.56l-1.03 3.07-3.61 11.11-2.21 6.75c-.05.14-.06.35-.23.36-.2.01-.18-.22-.21-.35l-3.92-13.33-2.31-7.88-.24-.69c-.2.12-.19.3-.24.44l-4.67 12.93c-.12.33-.25.66-.33 1-.11.42-.35.54-.77.54-2.05-.02-4.1-.01-6.15-.01h-9.98c-.81 0-.85.01-1.08-.74-.48-1.59-1.02-3.17-1.2-4.83-.01-.1-.05-.19-.08-.29z" }),
      _createElementVNode("path", { d: "m48.31 70.42 1.21-3.68 2.42-7.36 2.56-7.9 1.58-4.78c.04-.12.05-.29.21-.31.2-.03.22.18.28.3 1.42 2.65 2.85 5.29 4.25 7.95.21.41.46.53.9.52l14.61-.01h.4c.4-.01.43.15.23.46-.79 1.21-1.6 2.4-2.5 3.53a75.5 75.5 0 0 1-8.95 9.19c-3.1 2.76-6.4 5.27-9.73 7.74a136 136 0 0 1-6.42 4.44c-.4.26-.7.27-1.09 0a200 200 0 0 1-8.63-6.11 120 120 0 0 1-9.97-8.32 62 62 0 0 1-7.65-8.47c-.46-.62-.86-1.28-1.28-1.93-.08-.12-.22-.25-.15-.41.09-.19.3-.12.45-.12h5.69c3.73 0 7.46-.01 11.19.01.51 0 .74-.18.9-.65.77-2.24 1.58-4.46 2.38-6.69.04-.12.06-.31.22-.31.21 0 .16.22.2.35 1.24 4.18 2.49 8.36 3.72 12.55l1.9 6.58c.35 1.1.7 2.21 1.07 3.43" })
    ], -1)
  ])))
}
export default { render: render }