import type { Emitter, EventType } from 'mitt';
import mitt from 'mitt';

const TAB_LOADED = 'eventTabLoaded';
const SELECT_TAB = 'eventSelectTab';

 
export function useTabs() {
  const bus: Emitter<Record<EventType, unknown>> = mitt();

  return { bus, TAB_LOADED, SELECT_TAB };
}
