<template>
  <DsModal
    :show="show"
    title="Bankverbindung anlegen"
    hide-buttons
    @cancel="emit('close')"
  >
    <ModalWrapper>
      <CreateBankAccount
        v-if="show"
        style="max-height: calc(100dvh - 7rem - env(safe-area-inset-top) - env(safe-area-inset-bottom))"
        @close="emit('close')"
      />
    </ModalWrapper>
  </DsModal>
</template>

<script setup lang="ts">
import { DsModal } from '@demvsystems/design-components';

import ModalWrapper from "@/application/components/ModalWrapper.vue";
import CreateBankAccount from "@/user/components/CreateBankAccount.vue";

interface Props {
  show: boolean;
}

defineProps<Props>();
const emit = defineEmits(['close']);
</script>
