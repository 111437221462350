import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 98.89 98.89"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"49.44\" cy=\"49.44\" r=\"49.44\" fill=\"#fff\"></circle><path fill=\"#343a40\" d=\"m82.67 76.9-65.81-.48.62-60.42 65.8.48z\"></path><path fill=\"#fff\" d=\"m21.88 19.48 57.12.41-.33 33.14.33 5.83-9.15 3.88 6.16 2.14-11.96 8.35-42.72-.31z\"></path><g fill=\"#343a40\"><path d=\"m48.14 19.67 3.64.03-.55 53.44-3.63-.03z\"></path><path d=\"m21.59 47.73.03-3.06 57.13.42-.03 3.05z\"></path></g>", 4)
  ])))
}
export default { render: render }