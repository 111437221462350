<template>
  <ShallowCard
    :highlight="isUnread"
    class="relative"
  >
    <span
      v-if="isUnread"
      :class="$style.newBadge"
      class="ml-1.5 badge badge-pill badge-secondary mr-1.5"
    >Neu</span>

    <CardContractBody :contract="contract" />
  </ShallowCard>
</template>

<script setup lang="ts">
import type { Contract } from '@/api/contracts/types';
import { UnreadHintType } from '@/api/unreadHint/types';
import { useUnreadHint } from '@/application/composables/unreadHint';
import CardContractBody from '@/contracts/components/CardContractBody.vue';
import ShallowCard from '@/contracts/components/ShallowCard.vue';

interface Props {
  contract: Contract,
}

const props = defineProps<Props>();

const { isUnread } = useUnreadHint(UnreadHintType.Contract, props.contract.id);
</script>

<style module>
.newBadge {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(30%, -50%);
}
</style>
