<template>
  <div>
    <slot
      v-if="loading"
      name="loading"
    >
      <LoadSpinner>
        Einträge werden geladen...
      </LoadSpinner>
    </slot>
    <slot
      v-else-if="error"
      v-bind="error"
      name="error"
    >
      <DsAlert
        type="error"
        label="Es ist leider ein Fehler aufgetreten."
      >
        <span v-if="errorCode === 404">
          Resource wurde nicht gefunden.
        </span>
        <span v-else-if="errorCode === 403">
          Keine Berechtigung.
        </span>
        <span v-else-if="errorCode === 401">
          Sie scheinen nicht mehr angemeldet zu sein.
        </span>
        <span v-else-if="errorCode === 500">
          Interner Serverfehler
        </span>
        <!--        <span v-else-if="errorCode === 504">-->
        <!--          Der Server konnte nicht erreicht werden.-->
        <!--        </span>-->
        <span v-else-if="error instanceof NetworkError">
          Kein Internetzugang (Offline?)
        </span>
      </DsAlert>

      <div class="text-center">
        <DsButton
          variant="secondary"
          size="lg"
          class="mt-6"
          @click="emit('refresh')"
        >
          {{ refreshLabel }}
        </DsButton>
      </div>
    </slot>
    <slot
      v-else-if="hasData"
      name="success"
    />
    <slot v-else />
  </div>
</template>

<script setup lang="ts">
import { DsAlert, DsButton } from '@demvsystems/design-components';
import { computed } from 'vue';

import LoadSpinner from '@/application/components/LoadSpinner.vue';
import { FetchError, NetworkError } from "@/api/lib/errors";

interface Props {
  loading: boolean,
  hasData: boolean,
  refreshLabel?: string,
  error: object | Error | FetchError | NetworkError | null,
}

const emit = defineEmits(['refresh']);

const props = withDefaults(defineProps<Props>(), {
  error: null,
  refreshLabel: 'Erneut laden',
});

const errorCode = computed(() => {
  if (props.error instanceof FetchError) {
    return props.error.status ?? -1;
  }
  return -1;
});
</script>
