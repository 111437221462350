<template>
  <div class="row">
    <div class="col-12 text-center mt-14 mb-7">
      <h2>Später vorsorgen wird teuer</h2>
      <p>Entwicklung der Sparrate um die {{ gapName }} zu schließen:</p>
    </div>

    <div class="col-12">
      <div class="row text-center items-center">
        <div
          v-for="({ label, icon }, i) in intervalDecoration"
          :key="label"
          class="col-6 col-sm-3 col-lg-2 mb-5 lg:mb-0"
        >
          <div
            v-show="!loading"
            v-text="displayRateForInterval(i)"
          />
          <div
            v-show="loading"
            class="spinner"
          />
          <SvgVue
            :icon="`bedarfsinfo/altersvorsorge/${icon}`"
            class="icon block mx-auto img-fluid my-5"
          />
          <p v-text="label" />
        </div>

        <div class="col text-left mt-5 lg:mt-0">
          <div
            v-for="([event, value], name) in controls"
            :key="name"
            class="row mb-7"
          >
            <div
              class="col-12 col-sm-4 col-md-4 col-lg-6 col-xl-5
              text-center sm:text-right mb-7 sm:mb-0 pb-2.5 pb-sm-0"
            >
              {{ name }}
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-6 col-xl-7 pb-2.5">
              <div class="px-0">
                <Slider
                  :model-value="value"
                  :max="15"
                  :format="{suffix: '%', decimals: 0}"
                  min-label="0%"
                  max-label="15%"
                  @update:model-value="$emit(`update:${event}`, $event)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';

import Slider from '@/application/components/Slider.vue';
import { useMehrfachSparraten } from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/composables/calculators';
import { format } from '@/application/utils/money';

import type { IntervalDecoration } from './types';
import SvgVue from "@/application/components/SvgVue";

interface Props {
  gapName: string;
  gap: number;
  age: number;
  eintrittsalter: number;
  ansparzinssatz: number;
  entnahmezinssatz: number;
}

const props = withDefaults(defineProps<Props>(), {
  gapName: 'Versorgungslücke',
  gap: 0,
  age: 0,
  eintrittsalter: 0,
  ansparzinssatz: 0,
  entnahmezinssatz: 0,
});

defineEmits(['update:ansparzinssatz', 'update:entnahmezinssatz'])

const interval = [0, 1, 3, 5];

const { ansparzinssatz, entnahmezinssatz, gap, eintrittsalter } = toRefs(props);

const intervalDecoration: IntervalDecoration[] = [
  { label: 'heute', icon: 'sparrate-low' },
  { label: 'In 1 Jahr', icon: 'sparrate-mid' },
  { label: 'In 3 Jahren', icon: 'sparrate-high' },
  { label: 'In 5 Jahren', icon: 'sparrate-highest' },
];

const controls = {
  Ansparzins: ['ansparzinssatz', ansparzinssatz],
  Entnahmezins: ['entnahmezinssatz', entnahmezinssatz],
};

const { rates, loading } = useMehrfachSparraten(
  ansparzinssatz,
  entnahmezinssatz,
  gap,
  eintrittsalter,
  props.age,
  interval,
);

const displayRateForInterval = (i) => {
  if (i >= rates.value.length) return '-';
  return format(rates.value[i].sparrate);
};
</script>

<style lang="scss" scoped>
.icon {
  height: 100px;
}
</style>
