<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <h5
      id="kind-gesetzlich-oder-privat"
      class="font-bold text-center md:mb-3"
    >
      Wird ein Kind gesetzlich oder privat versichert?
    </h5>
    <div class="row justify-around">
      <div class="col-12 col-md-6 text-center">
        <p class="mb-0 mt-5">
          Ehepartner mit <u>{{ higherIncomeLabel }}</u> Einkommen
        </p>
        <div class="square-icon square-icon--scale-on-height square-icon--6 m-2.5 lg:mr-5">
          <SvgVue
            icon="bedarfsinfo/pkv/persona"
          />
        </div>

        <div class="input-group md:mx-5">
          <multiselect
            v-model="mehrverdiener"
            :options="options"
            :searchable="false"
            :show-labels="false"
            track-by="value"
            label="label"
            placeholder="Bitte auswählen.."
          >
            <template
              #singleLabel="props"
            >
              <div v-html="props.option.label" />
            </template>
            <template
              #option="props"
            >
              <div v-html="props.option.label" />
            </template>
          </multiselect>
        </div>
      </div>
      <div class="col-12 col-md-6 text-center mt-2.5 md:mt-0">
        <p class="mb-0 mt-5">
          Ehepartner mit <u>{{ lowerIncomeLabel }}</u> Einkommen
        </p>
        <div class="square-icon square-icon--scale-on-height square-icon--6 m-2.5">
          <SvgVue
            icon="bedarfsinfo/pkv/persona"
          />
        </div>

        <div class="input-group md:mx-5">
          <multiselect
            v-model="wenigverdiener"
            :options="options"
            :searchable="false"
            :show-labels="false"
            track-by="value"
            label="label"
            placeholder="Bitte auswählen.."
          >
            <template
              #singleLabel="props"
            >
              <div v-html="props.option.label" />
            </template>
            <template
              #option="props"
            >
              <div v-html="props.option.label" />
            </template>
          </multiselect>
        </div>
      </div>
    </div>
    <div class="row mt-7">
      <div class="col text-center">
        = Ihr Kind wird in der
        <transition mode="out-in">
          <span :key="status.text"><b>{{ status.text }}</b></span>
        </transition>
        versichert.
        <transition mode="out-in">
          <p
            :key="status.info"
            class="text-secondary"
          >
            {{ status.info }}
          </p>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

import { BEIHILFE_UEBER_JAEG,
  BEIHILFE_UNTER_JAEG,
  determineStatusKind,
  GKV,
  PKV_UEBER_JAEG,
  PKV_UNTER_JAEG } from './StatusKind';
import SvgVue from "@/application/components/SvgVue";

export default {
  components: {
    SvgVue,
    Multiselect,
  },
  data() {
    const options = [
      {
        label: 'PKV versichert mit Einkommen unter <u>JAEG</u>',
        value: PKV_UNTER_JAEG,
      },
      {
        label: 'PKV versichert mit Einkommen über <u>JAEG</u>',
        value: PKV_UEBER_JAEG,
      },
      {
        label: 'Beihilfe mit Einkommen unter <u>JAEG</u>',
        value: BEIHILFE_UNTER_JAEG,
      },
      {
        label: 'Beihilfe mit Einkommen über <u>JAEG</u>',
        value: BEIHILFE_UEBER_JAEG,
      },
      {
        label: 'GKV',
        value: GKV,
      },
    ];
    const incomeLabels = {
      lower: 'niedrigerem',
      higher: 'höherem',
    };

    return {
      options,
      incomeLabels,
      mehrverdiener: null,
      wenigverdiener: null,
      status: determineStatusKind(),
    };
  },

  computed: {
    higherIncomeLabel() {
      return !this.status.switchIncome ? this.incomeLabels.higher : this.incomeLabels.lower;
    },
    lowerIncomeLabel() {
      return !this.status.switchIncome ? this.incomeLabels.lower : this.incomeLabels.higher;
    },
  },

  watch: {
    mehrverdiener: 'updateStatus',
    wenigverdiener: 'updateStatus',
  },

  methods: {
    validateSelect(select) {
      return select !== null && select !== undefined && Object.hasOwn(select, 'value');
    },
    updateStatus() {
      const isValidInput = this.validateSelect(this.mehrverdiener)
        && this.validateSelect(this.wenigverdiener);

      this.status = !isValidInput
        ? determineStatusKind()
        : determineStatusKind(this.mehrverdiener.value, this.wenigverdiener.value);
    },
  },
};
</script>

<style lang="scss">
  @import 'vue-multiselect/dist/vue-multiselect.css';

  .multiselect__option--selected.multiselect__option--highlight,
  .multiselect__option--highlight {
    background: #636873;
  }

  .multiselect__content {
    width: 100%;
  }

  .multiselect__option {
    white-space: normal;
  }
</style>

<style lang="scss" scoped>
  .v-enter-active {
    transition: all 1s ease;
  }

  .v-enter {
    opacity: 0;
  }
</style>
