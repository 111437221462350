import type { ComputedRef, Ref } from 'vue';
import { computed } from 'vue';

export function usePasswordCriteria(password: Ref<string>): {
  hasMixedCase: ComputedRef<boolean>,
  hasNumbers: ComputedRef<boolean>,
  hasSpecialChars: ComputedRef<boolean>,
  hasMinLength: ComputedRef<boolean>,
} {
  return {
    hasMixedCase: computed(() => /[A-Z]+/g.test(password.value) && /[a-z]+/g.test(password.value)),
    hasNumbers: computed(() => /\d+/g.test(password.value)),
    hasSpecialChars: computed(() => /\W+/g.test(password.value)),
    hasMinLength: computed(() => password.value.length >= 8),
  };
}
