const defaultRelations = {
  contract: 'contracts',
  type: 'types',
  status: 'statuses',
  company: 'companies',
  product: 'products',
  group: 'groups',
  document: 'documents',
  coinsured: 'people',
  owner: 'people',
};

function isObject(value) {
  return typeof value === 'object' && value != null;
}

function isArrayOf(value: unknown, callback: (value: unknown) => boolean): value is unknown[] {

  return Array.isArray(value) && value.every(callback);
}

function isArrayOfObjects(value: unknown): value is object[] {
  return isArrayOf(value, isObject);
}

function isArrayOfIntegers(value: unknown): value is number[] {
  return isArrayOf(value, Number.isInteger);
}

function resolveRelations(data: object, additionalRelations = {}): object {
  // we need to look up all objects behind relationKeys to make sure every reference is replaced
  const relations = { ...defaultRelations, ...additionalRelations };
  const relationKeys = new Set([...Object.keys(relations), ...Object.values(relations)].sort());

  function lookUpRelation(relation: string, id: number, root: object): object | null {
    const source = root[relation] || root[relations[relation]];
    if (!source) {
      throw new Error(`can not find source for: ${relation} in ${Object.keys(root)}`);
    }
    const relatedEntity = source.find((entity) => Number.parseInt(entity.id, 10) === id);
    return relatedEntity ? compose(relatedEntity, root) : null;
  }

  function compose(object: object, root: object): object {
    if (!isObject(object)) {
      throw new Error(`only objects: ${JSON.stringify(object)}`);
    }

    const mappedEntries = Object
      .entries(object)
      .map(([key, value]) => {
        if (relationKeys.has(key)) {
          // possible reference, can be number or array of numbers
          if (isArrayOfIntegers(value)) {
            return [key, value.map((id) => lookUpRelation(key, id, root) ?? value)];
          }

          if (Number.isInteger(value)) {
            return [key, lookUpRelation(relations[key], value, root) ?? value];
          }
        }

        // if the key is not relevant, we have to check nested objects or arrays of objects
        if (isArrayOfObjects(value)) {
          return [key, value.map((object_) => compose(object_, root))];
        }

        if (isObject(value)) {
          return [key, compose(value, root)];
        }

        // everything elese we can just return
        return [key, value]; // if value is unmappable we just return it
      });

    return Object.fromEntries(mappedEntries);
  }

  return compose(data, data);
}

export { resolveRelations };
