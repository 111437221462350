<template>
  <div class="container">
    <div class="row pb-7">
      <div class="col">
        <h2>Anhänge</h2>
      </div>
    </div>
    <div class="document-list">
      <button
        v-for="{ name, typ, link } in dokumente"
        :key="name"
        class="document-list__item rounded"
        @click="setMediaRef(link, name)"
      >
        <div class="document-list__item-icon">
          <i class="far fa-lg fa-file-alt" />
        </div>
        <div class="document-list__item-type">
          {{ typ }}
        </div>
      </button>
    </div>

    <div class="document-list">
      <a
        v-for="{ url, titel } in links"
        :key="url"
        :href="url"
        target="_blank"
        class="document-list__item rounded"
      >
        <div class="document-list__item-icon">
          <i class="fas fa-md fa-external-link-alt" />
        </div>
        <div class="document-list__item-type">
          {{ titel }}
        </div>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Dokument, Link } from '@/bedarf/types/dtos';
import { useMediaPreviewStore } from "@/application/store/mediaPreviewStore";

interface Props {
  links?: Link[]
  dokumente?: Dokument[]
}

withDefaults(defineProps<Props>(), {
  links: () => [],
  dokumente: () => [],
});

const { setMediaRef } = useMediaPreviewStore();
</script>
