export enum MeinBedarf {
  ALTERSVORSORGE = 'Altersvorsorge',
  AMBULANTE_KRANKENZUSATZ = 'Ambulante Krankenzusatzversicherung',
  AUSLANDSKV = 'Auslandskrankenversicherung',
  BAUHERRENHAFTPFLICHT = 'Bauherrenhaftpflicht',
  BERUFSHAFTPFLICHT = 'Berufshaftpflicht',
  BERUFSUNFAEHIGKEIT = 'Berufsunfähigkeitsversicherung',
  BETRIEBSHAFTPFLICHT = 'Betriebshaftpflicht',
  CYBER = 'Cyber Versicherung',
  DIENSTHAFTPFLICHT = 'Diensthaftpflicht',
  DIENSTUNFAEHIGKEIT = 'Dienstunfähigkeitsversicherung',
  DO = 'D&O',
  DREAD_DISEASE = 'Dread Disease',
  ERWERBSUNFAEHIGKEIT = 'Erwerbsunfähigkeit',
  FIRMENRECHTSSCHUTZ = 'Firmenrechtsschutz',
  GEBAEUDEVERSICHERUNG = 'Wohngebäude',
  GEBAEUDE_GEWERBLICH = 'Gewerbliche Gebäudeversicherung',
  GESCHAEFTSINHALTSVERSICHERUNG = 'Geschäftsinhaltsversicherung',
  GRUNDFAEHIGKEIT = 'Grundfähigkeit',
  HAUSRAT = 'Hausrat',
  JAGDHAFTPFLICHT = 'Jagdhaftpflicht',
  KFZ = 'Kfz Versicherung',
  KRANKENHAUSZUSATZ = 'Krankenhauszusatzversicherung',
  KRANKENTAGEGELD = 'Krankentagegeld',
  LUFTFAHRTHAFTPFLICHT = 'Luftfahrthaftpflicht',
  MULTI_RISK = 'Multi Risk',
  PFLEGEVORSORGE = 'Pflegevorsorge',
  PHOTOVOLTAIKVERSICHERUNG = 'Photovoltaikversicherung',
  PKV = 'Private Krankenvollversicherung',
  PRIVATHAFTPFLICHT = 'Privathaftpflicht',
  RECHTSSCHUTZ = 'Rechtsschutz',
  RISIKOLEBEN = 'Risikolebensversicherung',
  TIERHALTERHAFTPFLICHT = 'Tierhalterhaftpflicht',
  UEBERSICHT = 'Übersicht',
  UNFALL = 'Unfallversicherung',
  VERLAUF = 'Beratungsverlauf',
  VERMOEGENSSCHADENHAFTPFLICHT = 'Vermögensschadenhaftpflicht',
  ZAHNZUSATZ = 'Zahnzusatzversicherung',
}

export enum Finanzmanager {
  ACCOUNT = 'account',
  HINWEISE = 'hinweise',
  HANDLUNGEN = 'handlungen',
  HANDLUNG = 'handlung',
  DATEN = 'daten',
  BANK_ACCOUNT_DETAILS = 'bank-account',
  HINWEISE_SHOW = 'hinweise-show',
  DATENSCHUTZ = 'datenschutz',
  IMPRESSUM = 'impressum',
  NOT_FOUND = 'not-found',

  CHECKUP = 'check-up',
  BEDARF = 'mein-bedarf',
  VERTRAEGE = 'vertraege',
  VERTRAG = 'vertrag',
  DOKUMENTE = 'dokumente',
  BERATUNG = 'beratung',
  INTERESSENT = 'interessent',
  ANMELDEN = 'anmelden',
  LOGOUT = 'logout',
  LOGIN = 'login',
}

declare module 'vue-router' {
  interface RouteMeta {
    title?: string,
    menuTitle?: string
    stepTitle?: string,
    scrollTop?: boolean,
  }
}
