<template>
  <div>
    <section class="section pb-7">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
            <div class="text-center mb-14">
              <h2>Welche Form der Altersvorsorge passt zu mir?</h2>
              <p>
                Es gibt viele verschiedene Möglichkeiten, um privat vorzusorgen.<br>
                Beantworten Sie die folgenden Fragen und Sie erfahren,
                welche Produkte am besten zu Ihnen passen.
              </p>
            </div>

            <option-group-list
              v-model="altersvorsorge.fragen"
              :options="fragenOptions"
              horizontal-options
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section bg-white py-7">
      <div class="container">
        <div class="px-4">
          <h3 class="text-center mt-5">
            Ist Ihre Lebenssituation geeignet für den Aufbau einer Altersvorsorge?
          </h3>
          <TypeQuizSliders
            v-model:cashreserve="altersvorsorge.cashreserve"
            v-model:maximale-risikotoleranz="altersvorsorge.maximaleRisikotoleranz"
            v-model:monatlicher-ueberschuss="altersvorsorge.monatlicherUeberschuss"
          />
          <transition name="fade">
            <DsAlert
              v-if="missingLiquidity"
              key="missingLiquidity"
              label="Info"
              type="info"
              class="my-5"
            >
              Der Aufbau einer Altersvorsorge wird zurzeit nicht empfohlen. Zunächst sollte eine Cashreserve aufgebaut werden.
            </DsAlert>
          </transition>
        </div>
      </div>
    </section>

    <section class="section pt-7">
      <div class="container">
        <div class="row">
          <option-group-list
            :model-value="{
              anlegerorientierung: altersvorsorge.anlegerorientierung,
              produktwissen: altersvorsorge.produktwissen,
              produktwissenInfo: altersvorsorge.produktwissenInfo,
              produkterfahrung: altersvorsorge.produkterfahrung,
            }"
            :options="multipleChoiceOptions"
            class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2"
            @update:model-value="values => Object.assign(altersvorsorge, values)"
          />
        </div>

        <!-- Results -->
        <h2 class="mt-14 text-center">
          Auf Basis Ihrer Angaben sind folgende Produkte geeignet und angemessen:
        </h2>
        <div class="card-deck mt-7 text-center">
          <div
            v-for="sparte in sparten"
            :key="sparte.id"
            :class="result.sparten.includes(sparte.id)
              ? ['bg-secondary', 'text-white', $style.shadow] : ['text-muted', $style.disabled]"
            class="card"
          >
            <div class="card-body px-1.5 py-2.5">
              <div class="card-text font-bold">
                <font-awesome-icon
                  v-show="result.sparten.includes(sparte.id)"
                  :icon="['far', 'check-circle']"
                />
                <font-awesome-icon
                  v-show="!result.sparten.includes(sparte.id)"
                  :icon="['far', 'times-circle']"
                  class="text-danger"
                />
                <span
                  class="ml-1.5"
                  v-text="sparte.name"
                />
              </div>

              <!-- Show text on Betriebliche Altersvorsorge -->
              <!-- TODO: replace with DEMV Werte constant -->
              <span
                v-show="sparte.id === 4"
                class="text-sm"
              >Nur im Angestelltenverhältnis</span>
            </div>
          </div>
        </div>
        <div class="card-deck mt-7 text-center">
          <div
            v-for="produkt in produkte"
            :key="produkt.id"
            :class="result.produkte.includes(produkt.id)
              ? ['bg-light', 'text-secondary', 'border-secondary', 'border-2', $style.shadow]
              : ['text-muted', $style.disabled]"
            class="card"
          >
            <div class="card-body relative p-5">
              <div
                v-show="result.produkte.includes(produkt.id)"
                :class="$style.inlineCheck"
              >
                <font-awesome-icon
                  :icon="['far', 'check-circle']"
                  size="lg"
                />
              </div>
              <div
                v-show="!result.produkte.includes(produkt.id)"
                :class="$style.inlineCheck"
              >
                <font-awesome-icon
                  :icon="['far', 'times-circle']"
                  size="lg"
                />
              </div>

              <SvgVue
                :icon="produkt.image"
                :class="$style.icon"
                class="inline-block mb-5"
              />

              <h5
                class="card-title font-bold"
                v-text="produkt.name"
              />
              <div
                class="card-text"
                v-text="produkt.description"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <StoreAltersvorsorge />
  </div>
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { debounce } from 'lodash-es';
import type { PropType } from 'vue';
import { computed, defineComponent, ref, watch } from 'vue';

import { getAltersvorsorgeType } from '@/api/quiz/quizApi';
import type { AltersvorsorgeType } from '@/api/quiz/types';
import OptionGroupList from '@/application/components/Option/OptionGroupList.vue';
import { useAltersvorsorgeStore } from '@/bedarf/components/altersvorsorge/altersvorsorge/store';
import StoreAltersvorsorge from '@/bedarf/components/altersvorsorge/altersvorsorge/StoreAltersvorsorge.vue';
import type { Altersvorsorge, AltersvorsorgeFragen } from '@/bedarf/components/altersvorsorge/altersvorsorge/types';
import type { AvTypeQuestion } from '@/bedarf/types/quizes';

import TypeQuizSliders from './components/TypeQuizSliders.vue';
import typeQuizOptions from './typeQuizOptions.json';
import SvgVue from "@/application/components/SvgVue";
import { DsAlert } from '@demvsystems/design-components';
import { call } from "@/api/lib/integration";

const getFragen = (fragen: AvTypeQuestion[]) => fragen.reduce((acc, { name, modelValue }) => {
  acc[name] = modelValue;
  return acc;
}, {} as AltersvorsorgeFragen);

const getFragenOptions = (fragen: AvTypeQuestion[]) => fragen.map((frage) => ({
  ...frage,
  options: [
    {
      text: 'Wichtig',
      icon: 'icomoon/check',
      iconClass: 'check',
      modelValue: true,
    },
    {
      text: 'Weniger wichtig',
      icon: 'icomoon/close',
      iconClass: 'close',
      modelValue: false,
    },
  ],
}));

const getMultipleChoiceOptions = (altersvorsorge: Altersvorsorge) => typeQuizOptions.map((option) => ({
  ...option,
  modelValue: altersvorsorge[option.name],
}));

export default defineComponent({
  components: {
    DsAlert,
    SvgVue,
    FontAwesomeIcon,
    OptionGroupList,
    TypeQuizSliders,
    StoreAltersvorsorge,
  },

  props: {
    produkte: {
      type: Array,
      required: true,
    },

    sparten: {
      type: Array,
      required: true,
    },

    fragen: {
      type: Array as PropType<AvTypeQuestion[]>,
      required: true,
    },

    cashreserve: {
      type: Number,
      default: null,
    },

    maximaleRisikotoleranz: {
      type: Number,
      default: null,
    },

    monatlicherUeberschuss: {
      type: Number,
      default: null,
    },

    anlegerorientierung: {
      type: Number,
      default: null,
    },

    produktwissen: {
      type: Array as PropType<number[]>,
      default: null,
    },

    produktwissenInfo: {
      type: Array as PropType<number[]>,
      default: null,
    },

    produkterfahrung: {
      type: Number,
      default: null,
    },
  },

  setup(props) {
    const { altersvorsorge, patchDefaults, $patch } = useAltersvorsorgeStore();

    patchDefaults({
      cashreserve: props.cashreserve,
      maximaleRisikotoleranz: props.maximaleRisikotoleranz,
      monatlicherUeberschuss: props.monatlicherUeberschuss,
      produktwissen: props.produktwissen,
      produktwissenInfo: props.produktwissenInfo,
      fragen: getFragen(props.fragen),
    });

    $patch({ altersvorsorge: {
      anlegerorientierung: props.anlegerorientierung,
      produkterfahrung: props.produkterfahrung,
    } });

    const fragenOptions = getFragenOptions(props.fragen);

    const multipleChoiceOptions = getMultipleChoiceOptions(altersvorsorge);

    const result = ref<AltersvorsorgeType>({
      produkte: [],
      sparten: [],
    });

    const missingLiquidity = computed(() => {
      const { cashreserve = 0, monatlicherUeberschuss = 0 } = altersvorsorge;
      return cashreserve === 0 && monatlicherUeberschuss < 50;
    });

    const fetchEvaluation = async () => {
      await call(
        getAltersvorsorgeType({
          fragen: altersvorsorge.fragen,
          cashreserve: altersvorsorge.cashreserve,
          monatlicherUeberschuss: altersvorsorge.monatlicherUeberschuss,
          maximaleRisikotoleranz: altersvorsorge.maximaleRisikotoleranz,
        }),
        (data) => { result.value = data},
      );
    };

    watch(
      () => [
        altersvorsorge.fragen,
        altersvorsorge.cashreserve,
        altersvorsorge.monatlicherUeberschuss,
        altersvorsorge.maximaleRisikotoleranz,
      ],
      debounce(() => fetchEvaluation(), 400),
      { deep: true, immediate: true },
    );

    return {
      altersvorsorge,
      fragenOptions,
      multipleChoiceOptions,
      result,
      missingLiquidity,
    };
  },
});
</script>

<style lang="scss" module>
  @import '@/../css/variables';

  .shadow {
    box-shadow: 0 3px 20px -2px rgba($black, 0.45);
  }

  .icon {
    height: 50px;
  }

  .inlineCheck {
    left: 10px;
    position: absolute;
    top: 10px;
  }

  .disabled {
    opacity: 0.5;

    .inlineCheck {
      color: $red;
    }
  }
</style>

<style lang="scss" scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease-in-out;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
