import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "font-size": "12",
  viewBox: "0 0 158.9 191.7"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"none\" stroke=\"#333\" stroke-miterlimit=\"10\" d=\"M27.7 7.2V172m.5-.5h123.4\"></path><path fill=\"#4181D2\" d=\"M37.5 98.7h40.7V171H37.5z\"></path><path fill=\"#333\" d=\"m150.1 176.6 8.8-5.1-8.8-5.1zM22.7 8.6l5-8.6 4.9 8.6zm64.8 17.7h40.6V171H87.5z\"></path><text transform=\"translate(40.5 188)\">1960</text><text transform=\"translate(90.8 188)\">2016</text><text transform=\"translate(8 172.4)\">0</text><text transform=\"translate(8 136)\">5</text><text transform=\"translate(0 99.7)\">10</text><text transform=\"translate(0 63.3)\">15</text><text transform=\"translate(0 27)\">20</text><text fill=\"#fff\" transform=\"translate(47.8 117.2)\">9,9<tspan x=\"-5.4\" y=\"16\">Jahre</tspan></text><text fill=\"#fff\" transform=\"translate(93.6 46.5)\">19,5<tspan x=\"-1.2\" y=\"16\">Jahre</tspan></text>", 12)
  ])))
}
export default { render: render }