<template>
  <div class="w-full space-y-4">
    <DsFormGroup
      label="Vertrag"
      :inline-reverse="!isMobile"
    >
      <div class="w-full flex justify-end">
        <DsSelect
          :data="contracts"
          :model-value="contractId"
          :is-loading="loading"
          placeholder=""
          size="lg"
          virtualized
          :item-height="48"
          class="w-full md:w-80"
          @update:model-value="$emit('update:contractId', $event)"
        >
          <template #entry="{ entry }">
            <div class="flex flex-col" :class="{'text-muted': entry.inactive}">
              <span class="truncate">{{ entry.label }} {{ entry.inactive ? '(inaktiv)' : '' }}</span>
              <span class="truncate text-xs">{{ entry.number ? entry.number : 'Keine Vertragsnummer' }} </span>
            </div>
          </template>
        </DsSelect>
      </div>
    </DsFormGroup>

    <DsFormGroup
      label="Typ"
      :inline-reverse="!isMobile"
    >
      <div class="w-full flex justify-end">
        <DsSelect
          :model-value="documentTypeId"
          :data="docTypes"
          placeholder=""
          size="lg"
          class="w-full md:w-80"
          @update:model-value="$emit('update:documentTypeId', $event)"
        />
      </div>
    </DsFormGroup>

    <DsFormGroup
      label="Gesellschaft"
      :inline-reverse="!isMobile"
    >
      <div class="w-full flex justify-end">
        <DsSelect
          :data="companies"
          :model-value="insuranceCompanyId"
          placeholder=""
          size="lg"
          class="w-full md:w-80"
          @update:model-value="$emit('update:insuranceCompanyId', $event)"
        />
      </div>
    </DsFormGroup>

    <DsFormGroup
      label="Sparte"
      :inline-reverse="!isMobile"
    >
      <div class="w-full flex justify-end">
        <DsSelect
          :data="sparten"
          :model-value="productComboId"
          value-key="id"
          label-key="display_name"
          placeholder=""
          size="lg"
          class="w-full md:w-80"
          @update:model-value="$emit('update:productComboId', $event)"
        >
          <template #entry="{ entry }">
            {{ entry.display_name }}
            <span
              v-if="!!entry.type"
              class="badge text-secondary"
            >
              {{ entry.type }}
            </span>
          </template>
        </DsSelect>
      </div>
    </DsFormGroup>


    <DsFormGroup
      label="Notiz für den Makler"
      :inline-reverse="!isMobile"
    >
      <div class="w-full md:flex justify-end">
        <DsTextarea
          :model-value="note ?? undefined"
          placeholder="Wenn Sie möchten, können Sie eine Notiz zur Vertragsanlage hinterlassen..."
          :rows="3"
          class="w-full md:w-80"
          @input="$emit('update:note', $event.target.value)"
        />
      </div>
    </DsFormGroup>
  </div>
</template>

<script setup lang="ts">
import { DsFormGroup, DsSelect, DsTextarea } from '@demvsystems/design-components';

import type { ContractType, Sparte } from '@/api/common/types';
import type { SelectOption } from '@/application/components/propTypes';
import { useDetectMobile } from "@/application/composables/detectMobile";

interface Props {
  contracts: ContractType[];
  docTypes: SelectOption[];
  companies: SelectOption[];
  sparten: Sparte[];
  contractId?: number | null;
  documentTypeId?: number | null;
  insuranceCompanyId?: number | null;
  productComboId?: number | null;
  note?: string | null;
  loading?: boolean;
}

withDefaults(defineProps<Props>(), {
  contractId: null,
  documentTypeId: null,
  insuranceCompanyId: null,
  productComboId: null,
  note: null,
  loading: false,
});

defineEmits([
  'update:contractId',
  'update:documentTypeId',
  'update:insuranceCompanyId',
  'update:productComboId',
  'update:note',
]);

const { isMobile } = useDetectMobile();
</script>
