<template>
  <div class="text-sm text-muted">
    Es können nicht mehr als {{ IMAGE_COMBINATION_LIMIT }} Bilder zu einer PDF kombiniert werden.
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { IMAGE_COMBINATION_LIMIT } from '@/documents/composables/documentUpload';

export default defineComponent({
  setup() {
    return {
      IMAGE_COMBINATION_LIMIT,
    };
  },
});
</script>
