const currencyOptions: Intl.NumberFormatOptions = {
  style: 'currency',
  currency: 'EUR',
  signDisplay: 'never',
};

const currencyFormatter = new Intl.NumberFormat('de-DE', {
  ...currencyOptions,
  minimumFractionDigits: 2,
});

const roundedCurrencyFormatter = new Intl.NumberFormat('de-DE', {
  ...currencyOptions,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const decimalFormatter = new Intl.NumberFormat('de-DE', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const callFormatter = (formatter: Intl.NumberFormat, number: number): string => (
  formatter
    .format(number)
    .replace(/\s/g, ' ') // replace non-breaking space
);

/**
 * Formats the given number as a string.
 * To prevent errors any floating point value will be rounded.
 *
 * e.g. 1000 as "1.000€"
 *      3.14 as "3€"
 */
export const format = (number: number): string => callFormatter(roundedCurrencyFormatter, number);

/**
 * Formats the given number as a string, including decimals up to two places.
 */
export const formatDecimal = (number: number): string => callFormatter(currencyFormatter, number);

export const formatDecimalIgnoreSuffix = (number: number): string => callFormatter(decimalFormatter, number);

export const formatDecimalVariable = (number: number): string => {
  const decimalCount = (number % 1 !== 0) ? 2 : 0;

  return new Intl.NumberFormat('de-DE', {
    style: 'decimal',
    minimumFractionDigits: decimalCount,
    maximumFractionDigits: decimalCount,
  }).format(number);
};
