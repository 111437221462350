import type { ComputedRef } from "vue";
import { type Ref, computed } from "vue";
import type { Filter } from "@/application/filter/type/Filter";

export function useFilters<T> (
    list: Ref<T[]>,
    orFilter: { filter: Filter<T>, active: Ref<boolean> }[],
    andFilter: { filter: Filter<T>, active: Ref<boolean> }[] = [],
): { filtered: Ref<T[]> } {

    const filtered: ComputedRef<T[]> = computed(() => {
        return list.value
            .filter( item =>
                orFilter.length === 0 || orFilter.filter(filter => filter.active.value).some(filter => {
                    return filter.filter(item)
                })
            )
            .filter( item =>
                andFilter.length === 0 || andFilter.filter(filter => filter.active.value).every(filter => {
                    return filter.filter(item)
                })
            )
    });

    return {
        filtered,
    }
}
