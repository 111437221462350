export const containsOnlyLetters = (input: string): boolean => (/^[a-zßäöü]+$/gi).test(input);
export const containsOnlyDigits = (input: string): boolean => (/^\d+$/gi).test(input);

export const toUpperCase = (input: string | null): string | null => input?.toUpperCase() ?? null;

export const validate = (
  input: string | null,
  validator: (input: string) => boolean,
  removeLastChar = true,
): string | null => {
  if (!input) return null;
  if (!validator(input)) {
    return removeLastChar
      ? input.slice(0, -1)
      : null;
  }
  return input;
};

/**
 * Checks if the given input contains only letters (including ßäöü, case insensitive)
 *
 * @param input The input to check
 * @returns The input w/o the last char if the check fails, otherwise the valid input
 */
export const checkForLetters = (input: string | null): string | null => validate(input, containsOnlyLetters);

/**
 * Checks if the given input contains only digits
 *
 * @param input The input to check
 * @returns null if the check fails, otherwise the valid input
 */
export const checkForDigits = (input: string | null): string | null => validate(input, containsOnlyDigits, false);

/**
 * Return the extension of the path, from the last '.' to end of string in the last
 * portion of the path. If there is no '.' in the last portion of the path or the first
 * character of it is '.', then it returns an empty string.
 *
 * @param path
 * @returns {string} The extension of the given path
 */
export const getPathExtension = (path: string): string => {
  const extension = path.slice(path.lastIndexOf('.'));
  return extension.includes('.') && extension.length > 1 ? extension : '';
};
