<template>
  <section
    class="sticky b-0 flex flex-row w-full justify-center items-center bg-gradient-to-t from-gray-50 via-gray-50 via-50% pt-5
    pb-3 pointer-events-none"
  >
    <div class="flex flex-row justify-center gap-8 mb-3 md:mb-5 w-full md:w-1/2">
      <router-link
        v-if="actions.previous"
        v-slot="{ navigate }"
        :to="{ name: actions.previous }"
        custom
      >
        <DsButton
          :disabled="isRouting"
          variant="secondary"
          class="w-32 pointer-events-auto"
          size="lg"
          :handler="navigate"
        >
          Zurück
        </DsButton>
      </router-link>

      <router-link
        v-if="actions.next"
        v-slot="{ navigate }"
        :to="{ name: actions.next }"
        custom
      >
        <DsButton
          :disabled="isRouting"
          variant="primary"
          class="w-32 pointer-events-auto"
          size="lg"
          :handler="navigate"
        >
          Weiter
        </DsButton>
      </router-link>

      <router-link
        v-if="actions.finish"
        v-slot="{ navigate }"
        :to="{ name: actions.finish }"
        custom
      >
        <DsButton
          :disabled="isRouting"
          variant="primary"
          class="w-32 pointer-events-auto"
          size="lg"
          :handler="navigate"
        >
          Abschicken
        </DsButton>
      </router-link>
    </div>
  </section>
</template>

<script setup lang="ts">
import { DsButton } from '@demvsystems/design-components';
import { computed } from 'vue';
import { currentRoute, isRouting } from '@/checkup/state';
import type { StepActions } from '@/checkup/types/types';
import { getAdjacentSteps, Step } from '@/checkup/utils/steps';

const actions = computed((): StepActions => {
  const name = currentRoute.value?.name;

  if (!name) {
    return { previous: null, next: null };
  }

  const adjacent = getAdjacentSteps(Step[name.toString().toUpperCase() as keyof typeof Step]);

  if (name === Step.ABSCHLUSS) {
    adjacent.finish = 'Bestaetigung';
  }

  return adjacent;
});
</script>
