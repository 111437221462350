<template>
  <section>
    <div
      v-for="(list, group) in entriesByGroup"
      :key="group"
    >
      <div class="flex items-center my-5">
        <h6 class="mb-0 mt-2.5 text-muted">{{ group }}</h6>
      </div>

      <CardGrid>
        <RouterLink
          v-for="contract in list"
          :key="contract.id"
          v-slot="{ navigate }"
          custom
          :to="{ name: Finanzmanager.VERTRAG, params: { id: contract.id } }"
        >
          <DepotCard
            v-if="isDepot(contract)"
            class="cursor-pointer"
            :depot="depotsMap[contract.id]"
            @click="navigate"
          />
          <ContractCard
            v-if="!isDepot(contract)"
            class="cursor-pointer"
            :contract="contract"
            @click="navigate"
          />
        </RouterLink>
      </CardGrid>
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

import type { Contract, Depot } from '@/api/contracts/types';
import CardGrid from '@/contracts/components/CardGrid.vue';
import ContractCard from '@/contracts/components/ContractCard.vue';
import DepotCard from '@/contracts/components/DepotCard.vue';
import { Finanzmanager } from '@/application/router/types/types';
import { useContractStore } from "@/contracts/stores/contractStore";

interface Props {
  numContracts?: number,
  contracts: Contract[],
  titleBar?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  numContracts: 0,
  depots: () => [],
});
const { depots } = useContractStore()
const depotsMap = ref(depots.reduce((acc, item) => {
  acc[item.id] = item
  return acc
}, {} as Record<number, Depot>))

function isDepot(entry: Depot | Contract): entry is Depot {
  return depotsMap.value[entry.id] !== undefined;
}

const entriesByGroup = computed(() => {
  const groups: Record<string, Contract[]> = {}
  for (const contract of props.contracts) {
    if (!contract.group) {
      continue
    }
    if (!groups[contract.group.name]) {
      groups[contract.group.name] = []
    }
    groups[contract.group.name].push(contract)
  }
  return groups
});
</script>
