<script setup lang="ts">
import MenuHint from "@/application/menu/components/MenuHint.vue";
import { useMenuItems } from "@/application/menu/composables/menuItems";
const { items } = useMenuItems();
</script>

<template>
  <div class="flex flex-row items-center gap-6 lg:gap-10">
    <RouterLink
      v-for="{ title, to, unreadHints } in items"
      v-slot="{ href, navigate, isActive }"
      :key="title"
      :to="to()"
      custom
    >
      <a
        :href="href"
        :class="{'active-nav-button': isActive}"
        class="nav-button relative"
        @click="navigate"
      >
        {{ title }}
        <MenuHint :number="unreadHints" />
      </a>
    </RouterLink>
  </div>
</template>

<style scoped>
.nav-button {
  @apply lg:text-lg text-gray-800;
  @apply hover:text-secondary hover:decoration-secondary;
  @apply hover:underline hover:decoration-[3px] hover:underline-offset-[22px];
}

.active-nav-button {
  @apply text-secondary underline decoration-secondary decoration-[3px] underline-offset-[22px];
}
</style>
