<script setup lang="ts">

import { DsBadge, DsButton, DsIcon, NotificationVariant, useNotification } from '@demvsystems/design-components';
import { onMounted, ref, computed } from 'vue';
import { useActionStore } from '@/actions/store/actionStore';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router'
import { useGoBack } from '@/application/router/utils/goBack';
import type { Action }from '@/api/actions/types';
import HeadlineBlock from '@/actions/components/HeadlineBlock.vue';
import TextBlock from '@/actions/components/TextBlock.vue';
import ExternalLinkBlock from '@/actions/components/ExternalLinkBlock.vue';
import ChoiceBlock from '@/actions/components/ChoiceBlock.vue';
import SubmitBlock from '@/actions/components/SubmitBlock.vue';
import ActionDetailSkeleton from '@/actions/components/ActionDetailSkeleton.vue';
import ShallowCard from '@/contracts/components/ShallowCard.vue';
import { useBaseDataStore } from '@/user/store/baseDataStore';
import { call } from '@/api/lib/integration';
import { logAction, submitAction } from '@/api/actions/actionsApi';
import { captureException } from '@sentry/vue';
import { Finanzmanager } from '@/application/router/types/types';

const route = useRoute();
const router = useRouter();
const { show } = useNotification();
const { goBack } = useGoBack();
const { loading, fetchError } = storeToRefs(useActionStore());
const { fetchActionById, getActionById } = useActionStore();
const { updateActionCount } = useBaseDataStore();

const actionId = Number(route.params.id)

const action = ref<Action | null | undefined>(null)
const modelValues = ref<Record<string, string>>({});

const componentMap = {
  headline: HeadlineBlock,
  text: TextBlock,
  externalLink: ExternalLinkBlock,
  choice: ChoiceBlock,
  submit: SubmitBlock,
} as const;

onMounted(async () => {
  if (!getActionById(actionId)) {
    await fetchActionById(actionId)
  }
  action.value = getActionById(actionId)
  if (fetchError.value?.status === 404) {
    await router.push({ name: Finanzmanager.HANDLUNGEN })
  }
})

const bausteine = computed(() => {
  return Object.entries(modelValues.value).map(([id, selected_id]) => {
    return { id, selected_id };
  });
});

async function submit(id) {
  await logAction({
    actionId: actionId,
    context: 'submit',
    data: modelValues.value,
  });
  await call(submitAction({
      actionId: actionId,
      submit_id: id,
      bausteine: bausteine.value,
    }),
    (data) => {
      action.value = data
    },
    (error) => {
      show(error.message, NotificationVariant.Error);
      captureException(error);
    },
  )
  await updateActionCount();
}
</script>

<template>
  <div class="flex flex-col grow bg-light">
    <div class="container py-3 md:py-4">
      <DsButton
        variant="clear"
        icon="chevron-left"
        :handler="goBack"
      >
        Zurück
      </DsButton>
    </div>
    <div class="flex flex-col sm:container mb-10">
      <ShallowCard
        class="md:w-2/3 lg:w-1/2"
        no-hover
      >
        <ActionDetailSkeleton v-if="loading" />
        <div
          v-else-if="action && action.interaction_status === 'open'"
          class="flex flex-col grow gap-1"
        >
          <div class="flex flex-col items-start mb-2">
            <h3 class="text-gray-900 font-semibold mb-0">
              {{ action.title }}
            </h3>
            <span
              v-if="action.caption"
              class="text-gray-700"
            >
              {{ action.caption }}
            </span>
            <DsBadge
              v-if="action.occasion"
              class="mt-2"
            >
              {{ action.occasion }}
            </DsBadge>
          </div>
          <component
            :is="componentMap[baustein.type]"
            v-for="baustein in action.bausteine"
            :key="baustein.id"
            :action-id="action.id"
            :baustein="baustein"
            :model-value="modelValues[baustein.id]"
            @update:model-value="value => modelValues[baustein.id] = value"
            @submit="(value) => submit(value)"
          />
        </div>
        <div
          v-else-if="action && action.interaction_status === 'done'"
          class="flex flex-row gap-4 items-center py-6 sm:py-6 md:px-2"
        >
          <div
            class="bg-green-100 text-green-700 mx-auto shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-12 sm:w-12"
          >
            <DsIcon
              name="check"
              size="lg"
              variant="regular"
              fixed-width
            />
          </div>
          <p class="my-2">
            Die Handlung <span class="font-semibold text-gray-900">{{ action.title }}</span> wurde abgeschlossen und ist
            nicht mehr verfügbar.
          </p>
        </div>
      </ShallowCard>
    </div>
  </div>
</template>

<style scoped>
::v-deep(h3) {
  margin-bottom: 0;
}
</style>
