import { defineStore } from 'pinia';
import { shallowRef } from "vue";
import type { MediaRef } from "@/application/components/MediaPreview/types";

export const useMediaPreviewStore = defineStore('mediaPreview', () => {
  const mediaRef = shallowRef<MediaRef|null>(null);

  async function setMediaRef(url: string, filename: string) {
    const ext = filename.split('.').pop()?.toLowerCase() ?? '';
    mediaRef.value = { url, filename, ext };
  }

  function $reset() {
    mediaRef.value = null;
  }

  return { mediaRef, setMediaRef, $reset };
});
