<template>
  <section
    v-if="hasVersorgungen || hasLinksOrDocuments"
    class="section bg-light"
  >
    <VersorgungenList
      v-if="hasVersorgungen"
      :class="{ 'mb-14': hasLinksOrDocuments }"
      :versorgungen="versorgungen"
      :headline="headline"
      :description="description"
    />
    <Dateien
      v-if="hasLinksOrDocuments"
      :links="links"
      :dokumente="dokumente"
    />
  </section>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import Dateien from '@/bedarf/components/Dateien.vue';
import VersorgungenList from '@/bedarf/components/Versorgungen.vue';
import type { Dokument, Link, Versorgung } from '@/bedarf/types/dtos';

interface Props {
  headline?: string,
  description?: string
  links?: Link[],
  dokumente?: Dokument[],
  versorgungen?: Versorgung[]
}

const props = withDefaults(defineProps<Props>(), {
  headline: undefined,
  description: undefined,
  links: () => [],
  dokumente: () => [],
  versorgungen: () => [],
});

const hasLinksOrDocuments = computed(() => props.dokumente.length > 0 || props.links.length > 0);
const hasVersorgungen = computed(() => props.versorgungen.length > 0);
</script>
