<template>
  <div class="flex flex-col -mx-4 md:-mx-6 overflow-y-auto px-4 md:px-6">
    <DsCarousel
      ref="carouselRef"
      :items="items"
    >
      <template #default="{ item }">
        <SvgVue
          class="mb-8 md:mb-10"
          :icon="`onboarding/${item.icon}`"
        />
        <h3 class="text-xl md:text-2xl text-center font-semibold leading-6 text-gray-900 m-0">
          {{ item.title }}
        </h3>
        <p class="text-sm md:text-base text-center leading-6 text-gray-600 mt-3 mb-5 md:my-5">
          {{ item.description }}
        </p>
      </template>
      <template #navigation="{ slideIndex, slideTo }">
        <div class="flex flex-row w-full justify-center gap-2 mb-4">
          <button
            v-for="(item, index) in items"
            :key="item.title"
            class="w-3 h-3 md:w-2 md:h-2 rounded-full"
            :class="[slideIndex === index ? 'bg-blue-500' : 'bg-gray-300 bg-opacity-50']"
            @click="slideTo(index)"
          />
        </div>
        <div class="md:hidden flex flex-row">
          <DsButton
            v-if="carouselRef?.slideIndex + 1 === items.length"
            variant="primary"
            size="lg"
            class="w-full"
            @click="$emit('close')"
          >
            Schließen
          </DsButton>
        </div>
      </template>
    </DsCarousel>
    <div
      v-if="carouselRef"
      class="hidden md:flex flex-row w-full items-center justify-end gap-4"
    >
      <DsButton
        v-if="carouselRef.slideIndex > 0"
        variant="secondary"
        size="default"
        @click="carouselRef.previous"
      >
        Zurück
      </DsButton>
      <DsButton
        v-if="carouselRef.slideIndex + 1 < items.length"
        variant="primary"
        size="default"
        :disabled="carouselRef.slideIndex + 1 === items.length"
        @click="carouselRef.next"
      >
        Weiter
      </DsButton>
      <DsButton
        v-if="carouselRef.slideIndex + 1 === items.length"
        variant="primary"
        size="default"
        @click="$emit('close')"
      >
        Schließen
      </DsButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DsButton, DsCarousel } from '@demvsystems/design-components';
import { ref } from 'vue';

import SvgVue from "@/application/components/SvgVue";

defineEmits(['close', 'open']);

// @ts-ignore
const carouselRef = ref<InstanceType<typeof DsCarousel> |null>(null);

interface Item {
  title: string;
  description: string;
  icon: string;
}

const items: Item[] = [
  {
    icon: 'onboarding_01',
    title: 'Willkommen im Finanzmanager',
    description: 'Mit dem Finanzmanager können Sie Ihrem Makler Informationen bereitstellen, '
      + 'womit Sie bestmöglich und bedarfsgerecht beraten werden können. '
      + 'Der Finanzmanager besteht aus den Bereichen Checkup, Bedarf, Dokumente, Verträge und meine Daten. '
      + 'Möchten Sie diese Informationen noch einmal aufrufen, gehen Sie im Menü auf "Hinweise" und anschließend '
      + 'oben rechts auf die Einführung.',
  },
  {
    icon: 'onboarding_02',
    title: 'Checkup',
    description: 'Im Checkup können Sie Ihrem Makler Informationen zu Ihrer Lebenssituation bereitstellen '
      + 'bzw. diese aktualisieren. Aufgrund dieser Informationen kann Sie ihr Makler umfassend beraten. '
      + 'Das Ausfüllen dauert ungefähr 3 Minuten.',
  },
  {
    icon: 'onboarding_03',
    title: 'Bedarf',
    description: 'In diesem Bereich kann Ihnen Ihr Makler Hintergrundinformationen zu verschiedenen Produktsparten '
      + 'freischalten. Dort erhalten Sie Informationen zu Ihren Versorgungslücken und Möglichkeiten diese '
      + 'zu schließen. Die dargestellten Informationen helfen bei der gemeinsamen Entscheidungsfindung. ',
  },
  {
    icon: 'onboarding_04',
    title: 'Verträge',
    description: 'Auf der Vertragsseite können Sie alle Ihre Verträge einsehen sowie neue Verträge anlegen.  ',
  },
  {
    icon: 'onboarding_05',
    title: 'Dokumente',
    description: 'In diesem Bereich können Sie für Ihren Makler Dokumente hochladen. '
      + 'Diese können Sie auch Verträgen zuordnen. Beim Upload von mehreren Bildern bzw. '
      + 'Fotos haben Sie die Möglichkeit diese als PDF gebündelt bereitzustellen.',
  },
  {
    icon: 'onboarding_06',
    title: 'Mein Account',
    description: 'Hier können Sie Ihren Zugang mit einer 2-Faktor-Authentifizierung schützen sowie Ihr Passwort ändern. ',
  },
  {
    icon: 'onboarding_07',
    title: 'Meine Daten',
    description: 'Unter Meine Daten können Sie Ihre Kontaktdaten wie E-Mail, Telefonnummer und Adresse ändern.',
  },
];
</script>
