<template>
  <section class="section bg-light">
    <div class="container text-center">
      <h2>Warum zahlt die gesetzliche Rentenversicherung immer weniger aus?</h2>
      <p>
        Der medizinische Fortschritt und ein deutlicher Rückgang der Geburten sorgen dafür,
        dass die Bevölkerung in Deutschland immer älter wird.<br>
        Die Zahlung einer auskömmlichen Rente wird für den Staat künftig immer schwieriger
        und private Vorsorge somit immer wichtiger.
      </p>
      <div class="row mt-7">
        <div class="col-sm-5 col-xl-3 flex flex-col justify-between items-center">
          <p><strong>Ø Anzahl der Geburten je Frau</strong></p>
          <SvgVue icon="bedarfsinfo/altersvorsorge/geburten-je-frau" />
        </div>
        <div class="col-sm-2 col-xl-1 justify-center self-center pt-xl-5">
          <i class="fas fa-plus fa-3x my-5 xl:my-0" />
        </div>
        <div class="col-sm-5 col-xl-3 flex flex-col justify-between items-center">
          <p><strong>Rentenbezugsdauer</strong></p>
          <SvgVue icon="bedarfsinfo/altersvorsorge/rentenbezugsdauer" />
        </div>
        <div class="col-xl-1 justify-center self-center pt-xl-5">
          <div class="hidden d-xl-block">
            <i class="fas fa-arrow-right fa-3x" />
          </div>
          <div class="block xl:hidden mb-5">
            <i class="fas fa-arrow-down fa-3x" />
          </div>
        </div>
        <div class="col-xl-4">
          <div class="row items-center">
            <div class="col-12 col-md-6 col-xl-12 md:text-left xl:text-center">
              <strong>Rentenlast in 2030</strong>
              <p>Zwei Beschäfigte müssen die monatliche Rente für einen Rentner finanzieren.</p>
            </div>
            <div
              ref="finance"
              class="col-12 col-md-6 col-xl-12"
            >
              <div class="px-2.5 sm:px-0">
                <div
                  v-for="({year, people}, i) in rentenFinanzierung"
                  :key="year"
                  class="row bg-white sm:mb-0"
                  :class="{ 'mb-2.5': isLastIndex(i, rentenFinanzierung) }"
                >
                  <div class="col-sm-2 bg-secondary text-white p-2.5">
                    {{ year }}
                  </div>
                  <div
                    :class="{ 'border-sm-bottom-0': isLastIndex(i, rentenFinanzierung) }"
                    class="col-6 col-sm-5 p-2.5 border-left border-sm-left-0
                border-xs-bottom border-sm-top text-right"
                  >
                    <i
                      v-for="j in people"
                      :key="j + people"
                      class="fas fa-user"
                    >asd</i>
                  </div>
                  <div
                    :class="{'border-sm-bottom-0': isLastIndex(i, rentenFinanzierung)}"
                    class="col-4 col-sm-3 p-2.5 border-xs-bottom"
                  >
                    <span class="badge badge-pill badge-secondary">{{ people }}</span>
                    <span class="mx-1">:</span>
                    <span class="badge badge-pill badge-secondary">1</span>
                  </div>
                  <div
                    :class="{'border-sm-bottom-0': isLastIndex(i, rentenFinanzierung)}"
                    class="col-2 p-2.5 border-xs-bottom border-sm-top text-left border-right"
                  >
                    <i class="fas fa-user" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { dom } from '@fortawesome/fontawesome-svg-core';
import { onMounted, ref } from 'vue';
import SvgVue from "@/application/components/SvgVue";

const finance = ref(null);

const rentenFinanzierung = [
  { year: 2030, people: 2 },
  { year: 2010, people: 3 },
  { year: 1975, people: 4 },
  { year: 1955, people: 5 },
];

function isLastIndex<T>(index: number, iterable: Iterable<T>): boolean {
  return index === [...iterable].length - 1;
}

onMounted(() => {
  if (finance.value !== null) dom.i2svg({ node: finance.value });
});
</script>
