import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 45 45"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"22.4\" cy=\"22.7\" r=\"21.6\" style=\"fill:#4181d2;\"></circle><circle cx=\"22.2\" cy=\"15.2\" r=\"5.4\" style=\"fill:#fff;\"></circle><path d=\"m37.6 22.7-6-6c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l3.6 3.6h-3.7c-.5-.6-1.3-1-2.2-1h-9.5c-.7 0-1.4.2-1.9.7h-4.4l3.5-4.8c.6-.8.4-1.8-.4-2.4s-1.8-.4-2.4.4l-5 6.8v2.4c0 .4.3.8.8.8h6.7v7.5a3 3 0 0 0 3 3h9.5a3 3 0 0 0 3-3v-7.2h6.8c.5 0 .9-.4.9-.9z\" style=\"fill:#fff;\"></path><path d=\"M19.1 15.6h6.2v2.9h-6.2z\" style=\"fill:#4181d2;\"></path><path d=\"M16.4 15.4h3.2v.3h-3.2z\" style=\"fill:#4181d2;\" transform=\"rotate(9.21 17.98 15.57)\"></path><path d=\"M25.2 15.4h3.2v.3h-3.2z\" style=\"fill:#4181d2;\" transform=\"rotate(-9.99 26.78 15.52)\"></path><path d=\"M24.7 18.4h3.2v.3h-3.2z\" style=\"fill:#4181d2;\" transform=\"matrix(.9953 .09719 -.09719 .9953 1.92 -2.47)\"></path><path d=\"M16.1 18.5h3.2v.3h-3.2z\" style=\"fill:#4181d2;\" transform=\"rotate(-8.76 17.66 18.63)\"></path>", 8)
  ])))
}
export default { render: render }