<template>
  <div class="row">
    <div class="col">
      <h3 class="text-center pb-2.5 md:pb-7">
        Gesetzliche Krankenversicherung
      </h3>
      <FlowList
        :items="gesetzlich"
        svg-icon="ambulant_1"
      />
    </div>
    <div class="col-1" />
    <div class="col">
      <h3 class="text-center mt-5 md:mt-0 pb-14 md:pb-7">
        Private Krankenversicherung
      </h3>
      <FlowList
        :items="privat"
        svg-icon="ambulant_2"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import FlowList from '@/bedarf/components/FlowList.vue';

const minusIcon = 'fas fa-minus';
const plusIcon = 'fas fa-plus';

const gesetzlich = [
  {
    icon: minusIcon,
    text: 'Es besteht für alle Patienten ein gemeinsames, festes Budget für Behandlungen und Medikamente.',
  },
  {
    icon: minusIcon,
    text: 'Ist das Budget aufgebraucht, behandelt der Arzt umsonst und er kann keine Medikamente mehr verschreiben.',
  },
  {
    icon: minusIcon,
    text: 'Daher werden Behandlungen ins nächste Quartal verschoben und es entstehen lange Wartezeiten für Patienten.',
  },
];

const privat = [
  {
    icon: plusIcon,
    text: 'Es besteht keine Budgetierung für Behandlungen und Medikamente.',
  },
  {
    icon: plusIcon,
    text: 'Der Arzt wird somit für jede Behandlung vergütet und kann unbegrenzt Medikamente verschreiben.',
  },
  {
    icon: plusIcon,
    text: 'Es besteht keine Einschränkung in der Versorgung und Arzttermine können kurzfristig vereinbart werden.',
  },
];
</script>
