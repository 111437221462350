/**
 * Maps each value to the given value, if it hasn't been filled yet
 * Input is an array with objects, output is an associative object
 *
 * @param array array array to transform
 * @return mixed defaultVal default value defaults to null
 */

interface Item {
  name: string;
  value: any;
  modelValue?: any;
}

export const arrayToObjectWithDefaults = (array: Item[], defaultValue: any = null): Record<string, any> => (
  array.reduce((accumulator: Record<string, any>, { name, value, modelValue = null }): Record<string, any> => {
    const valueProp = modelValue ?? value;
    accumulator[name] = valueProp !== null
    && valueProp !== undefined
    && valueProp !== defaultValue ? valueProp : defaultValue;
    return accumulator;
  }, {})
);
