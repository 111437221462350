import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 100 100"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M50 .4C22.7.4.6 22.5.6 49.8S22.7 99.3 50 99.3s49.4-22.1 49.4-49.4S77.3.4 50 .4m26.2 48.9-3.1-2v26.2H23.8V47.3l-3.1 2-1.8-6.6 29.5-18.9L78 42.7z",
      style: {"fill":"#fff"}
    }, null, -1),
    _createElementVNode("path", {
      d: "M39.6 52.8a3.17 3.17 0 0 0-4.5.1c-1.2 1.3-1.2 3.3.1 4.5l9.5 9.1 18.9-17.2c1.3-1.2 1.4-3.2.2-4.5s-3.2-1.4-4.5-.2L44.8 57.8z",
      style: {"fill":"#fff"}
    }, null, -1)
  ])))
}
export default { render: render }