<template>
  <Transition appear>
    <div class="border-t border-gray-100 pb-safe bg-white md:hidden">
      <div
        class="px-2 pt-2 pb-1 flex items-center w-full z-[990]"
      >
        <MenuItem
          v-for="item in items"
          :key="item.id"
          :title="item.title"
          :icon="icons[item.id].icon"
          :active-icon="icons[item.id].activeIcon"
          :to="item.to"
          :unread-hints="item.unreadHints"
          :width="100/items.length"
        />

        <MenuItem
          title="Mehr"
          :icon="['far', 'fa-ellipsis']"
          :active-icon="['far', 'fa-ellipsis']"
          :action="() => showMore = !showMore"
          :unread-hints="unreadHintStore[UnreadHintType.News].size"
          :width="100/items.length"
        />

        <MenuModal
          :show="showMore"
          data-testid="menu-modal"
          @close="showMore = false"
        />
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import { UnreadHintType } from '@/api/unreadHint/types';
import MenuModal from '@/application/menu/components/MenuModal.vue';
import MenuItem from '@/application/menu/components/MenuItem.vue';
import { Finanzmanager } from '@/application/router/types/types';
import { useUnreadHintStore } from '@/application/store/unreadHintStore';
import { useMenuItems } from "@/application/menu/composables/menuItems";

const unreadHintStore = useUnreadHintStore();
const { items } = useMenuItems();
const showMore = ref(false);

const icons = {
  [Finanzmanager.CHECKUP]: {
    icon: ['fat', 'user'],
    activeIcon: ['fas', 'user'],
  },
  [Finanzmanager.BEDARF]: {
    icon: ['fat', 'list-check'],
    activeIcon: ['fas', 'list-check'],
  },
  [Finanzmanager.VERTRAEGE]: {
    icon: ['fat', 'handshake'],
    activeIcon: ['fas', 'handshake'],
  },
  [Finanzmanager.DOKUMENTE]: {
    icon: ['fat', 'file-lines'],
    activeIcon: ['fas', 'file-lines'],
  },
}
</script>

<style scoped>
a {
  @apply text-gray-700 hover:text-blue-700
}
</style>
