import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  "fill-rule": "evenodd",
  "stroke-linejoin": "round",
  "stroke-miterlimit": "2",
  "clip-rule": "evenodd",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "nonzero",
      d: "M18.51 22.36v-1.44s4.45-4.42 4.72-8.5c0-.4 0-.82-.1-1.22-.19-.92-.4-1.83-.74-2.7-1.02-2.62-3.71-3.92-6.4-3.92-2.67 0-5.37 1.3-6.38 3.92a19 19 0 0 0-.75 2.7c-.08.4-.08.81-.09 1.22.26 4.08 4.72 8.5 4.72 8.5v1.44m5.1 1.71H13.4a.46.46 0 0 1-.45-.45c0-.25.2-.46.45-.46h5.18c.26 0 .46.2.46.46 0 .25-.2.45-.45.45"
    }, null, -1),
    _createElementVNode("path", { d: "M18.75 24.73a.47.47 0 0 0-.46-.46H13.7a.46.46 0 1 0 0 .92h4.59c.25 0 .46-.2.46-.46m-.28 1.12c0-.26-.2-.46-.46-.46h-4.03a.46.46 0 1 0 0 .92H18c.25 0 .46-.2.46-.46Zm-.24 1.11a.46.46 0 0 0-.46-.45h-3.54a.46.46 0 1 0 0 .91h3.54c.25 0 .46-.2.46-.46" }, null, -1),
    _createElementVNode("path", { d: "M16 0a16 16 0 1 1-.01 32.01A16 16 0 0 1 16 0m0 .96a15.05 15.05 0 1 1-.01 30.1A15.05 15.05 0 0 1 16 .95Z" }, null, -1)
  ])))
}
export default { render: render }