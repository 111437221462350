<template>
  <section>
    <h3 class="text-lg md:text-xl my-5">
      Bei Problemen oder Fragen kontaktieren Sie {{ maklerIsAgency ? 'uns' : 'mich' }} gerne!
    </h3>

    <div class="text-center">
      <span
        v-if="!maklerIsAgency"
        class="text-lg"
      >
        {{ maklername }}
      </span>
      <span class="text-gray-500 px-2">—</span>
      <span class="font-semibold text-lg">
        {{ firmenname }}
      </span>
    </div>

    <div class="flex flex-col md:flex-row items-center justify-center">
      <div
        v-if="maklertelefon"
        class="flex flex-row items-center space-x-2 mr-5"
      >
        <DsIcon
          name="phone"
          variant="regular"
          class="text-gray-500"
        />
        <a :href="`tel:${maklertelefon}`">
          {{ maklertelefon }}
        </a>
      </div>
      <div
        v-if="makleremail"
        class="flex flex-row items-center space-x-2 flex-shrink-1 min-w-0 max-w-full"
      >
        <DsIcon
          name="envelope"
          variant="regular"
          class="text-gray-500"
        />
        <a
          :href="`mailto:${makleremail}`"
          class="truncate"
        >
          {{ makleremail }}
        </a>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { DsIcon } from '@demvsystems/design-components';

import { storeToRefs } from 'pinia';
import { useBaseDataStore } from '@/user/store/baseDataStore';

const {
  firmenname,
  makleremail,
  maklertelefon,
  maklername,
  maklerIsAgency,
} = storeToRefs(useBaseDataStore());
</script>
