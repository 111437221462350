<template>
  <SpartenWrapper
    :text="subtitle"
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    :versorgungen="data.bedarfsinfo.versorgungen"
    image="images/bedarfsinfo/risikolebensversicherung/mann-im-auto"
    versorgungen-headline="Wie bin ich oder meine Hinterbliebenen aktuell bei einem plötzlichen Todesfall geschützt?"
    versorgungen-description="Risikolebensversicherung"
  >
    <section class="section pb-7">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
            <div class="text-center pb-7">
              <h2>Warum benötige ich eine Risikolebens&shy;versicherung?</h2>
            </div>

            <div>
              <div class="text-center pb-2.5">
                <p>Das Einkommen bildet die Basis, um den finanziellen Verpflichtungen nachzukommen.</p>
              </div>
              <div class="simple-flowchart text-sm mb-7">
                <div class="simple-flowchart__start col-md-2 offset-md-1">
                  <div class="square-icon square-icon--6 m-2.5 lg:mr-5">
                    <SvgVue icon="bedarfsinfo/risikolebensversicherung/boy-head.svg" />
                  </div>
                  <div class="square-icon square-icon--6 square-icon--scale-on-height m-2.5">
                    <SvgVue icon="bedarfsinfo/risikolebensversicherung/girl-head.svg" />
                  </div>
                </div>
                <div class="simple-flowchart__to col-md-4">
                  <div>Einkommen</div>
                  <div class="m-2.5">
                    <div class="visual-arrow hidden md:block" />
                    <div class="visual-arrow visual-arrow--vertical block md:hidden" />
                  </div>
                  <div>Einkommen</div>
                </div>
                <div class="simple-flowchart__end col-md-5">
                  <div class="row">
                    <div class="col-4 col-md-12 flex">
                      <div class="row flex-grow content-start items-center">
                        <div class="col-12 col-md-4">
                          <div class="square-icon square-icon--6 m-1.5">
                            <SvgVue icon="bedarfsinfo/risikolebensversicherung/house.svg" />
                          </div>
                        </div>
                        <div class="col-12 col-md-8">
                          Immobilien&shy;darlehen
                        </div>
                      </div>
                    </div>
                    <div class="col-4 col-md-12 flex">
                      <div class="row flex-grow content-start items-center">
                        <div class="col-12 col-md-4">
                          <div class="square-icon square-icon--6 m-1.5">
                            <SvgVue icon="bedarfsinfo/risikolebensversicherung/kids.svg" />
                          </div>
                        </div>
                        <div class="col-12 col-md-8">
                          Kinder
                        </div>
                      </div>
                    </div>
                    <div class="col-4 col-md-12 flex">
                      <div class="row flex-grow items-center">
                        <div class="col-sm-12 col-md-4">
                          <div class="square-icon square-icon--6 m-1.5">
                            <SvgVue icon="bedarfsinfo/risikolebensversicherung/life.svg" />
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-8">
                          Lebens&shy;standard
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class="text-center pb-2.5">
                <p>Verstirbt ein Partner, reicht das Einkommen häufig nicht aus.</p>
              </div>
              <div class="simple-flowchart text-sm mb-7">
                <div class="simple-flowchart__start col-md-2 offset-md-1">
                  <div class="square-icon square-icon--6 m-2.5 lg:mr-5">
                    <SvgVue icon="bedarfsinfo/risikolebensversicherung/boy-sad.svg" />
                  </div>
                  <div class="square-icon square-icon--6 square-icon--scale-on-height m-2.5">
                    <SvgVue icon="bedarfsinfo/risikolebensversicherung/girl-sad.svg" />
                  </div>
                </div>
                <div class="simple-flowchart__to col-md-4">
                  <div>Einkommen</div>
                  <div class="m-2.5">
                    <div class="visual-arrow hidden md:block" />
                    <div class="visual-arrow visual-arrow--vertical block md:hidden" />
                  </div>
                  <div class="line-through decoration-[3px] decoration-[#C34F4AFF]">
                    Einkommen
                  </div>
                </div>
                <div class="simple-flowchart__end col-md-5">
                  <div class="row">
                    <div class="col-4 col-md-12 flex">
                      <div class="row flex-grow content-start items-center">
                        <div class="col-12 col-md-4">
                          <div class="square-icon square-icon--6 m-1.5">
                            <SvgVue icon="bedarfsinfo/risikolebensversicherung/house-x.svg" />
                          </div>
                        </div>
                        <div class="col-12 col-md-8">
                          Verkauf oder Zwangs&shy;versteigerung
                        </div>
                      </div>
                    </div>
                    <div class="col-4 col-md-12 flex">
                      <div class="row flex-grow content-start items-center">
                        <div class="col-12 col-md-4">
                          <div class="square-icon square-icon--6 m-1.5">
                            <SvgVue icon="bedarfsinfo/risikolebensversicherung/kids-sad.svg" />
                          </div>
                        </div>
                        <div class="col-12 col-md-8">
                          Weniger Zeit für Kinder, da das Einkommen nur mit Vollzeitstelle reicht
                        </div>
                      </div>
                    </div>
                    <div class="col-4 col-md-12 flex">
                      <div class="row flex-grow content-start items-center">
                        <div class="col-sm-12 col-md-4">
                          <div class="square-icon square-icon--6 square-icon--scale-on-height m-1.5">
                            <SvgVue icon="bedarfsinfo/risikolebensversicherung/life-small.svg" />
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-8">
                          Lebens&shy;standard sinkt
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class="text-center pb-2.5">
                <p>Eine Risikolebens&shy;versicherung zahlt im Todesfall eine Einmalsumme</p>
              </div>
              <div class="simple-flowchart text-sm mb-7">
                <div class="simple-flowchart__start col-md-2 offset-md-1">
                  <div class="square-icon square-icon--6 m-2.5 mr-7 md:mr-2.5">
                    <SvgVue icon="bedarfsinfo/risikolebensversicherung/boy-head.svg" />
                  </div>
                </div>
                <div class="simple-flowchart__to col-md-4">
                  <div class="pl-2.5 md:pl-0">
                    Einkommen
                  </div>
                  <div class="m-2.5">
                    <div class="visual-arrow hidden md:block" />
                    <div class="visual-arrow visual-arrow--vertical block md:hidden" />
                  </div>
                  <div>Einmalsumme</div>
                </div>
                <div class="simple-flowchart__end col-md-5">
                  <div class="row">
                    <div class="col-4 col-md-12 flex">
                      <div class="row flex-grow content-start items-center">
                        <div class="col-12 col-md-4">
                          <div class="square-icon square-icon--6 m-1.5">
                            <SvgVue icon="bedarfsinfo/risikolebensversicherung/house.svg" />
                          </div>
                        </div>
                        <div class="col-12 col-md-8">
                          Darlehen kann abbezahlt werden
                        </div>
                      </div>
                    </div>
                    <div class="col-4 col-md-12 flex">
                      <div class="row flex-grow content-start items-center">
                        <div class="col-12 col-md-4">
                          <div class="square-icon square-icon--6 m-1.5">
                            <SvgVue icon="bedarfsinfo/risikolebensversicherung/kids.svg" />
                          </div>
                        </div>
                        <div class="col-12 col-md-8">
                          Arbeit kann reduziert werden, um Kinder in der Trauerphase aktiv zu
                          betreuen
                        </div>
                      </div>
                    </div>
                    <div class="col-4 col-md-12 flex">
                      <div class="row flex-grow content-start items-center">
                        <div class="col-sm-12 col-md-4">
                          <div class="square-icon square-icon--6 m-1.5">
                            <SvgVue icon="bedarfsinfo/risikolebensversicherung/life.svg" />
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-8">
                          Lebens&shy;standard bleibt erhalten
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section bg-light p-risikoleben-stoerer-section">
      <div class="container">
        <div class="row mb-7 justify-center">
          <div class="p-risikoleben-stoerer-overlay col-10 col-md-6 text-center py-2.5 shadow rounded">
            <h4 class="mb-0">
              Diese Faktoren beeinflussen den Beitrag
            </h4>
          </div>
        </div>
        <div class="row justify-center">
          <div class="col p-risikoleben-stoerer-overlay shadow rounded">
            <div class="flex flex-col lg:flex-row p-2.5">
              <div class="w-full lg:w-1/2 p-2.5 lg:px-7 pb-14 lg:pb-2 border rounded">
                <div class="pb-2.5">
                  <BulletPointList
                    :items="[
                      {text: 'Alter, berufliche Tätigkeit und BMI'},
                      {text: 'Höhe und Verlauf der Versicherungssumme'},
                    ]"
                  />
                </div>
                <div
                  style="max-width: 450px;"
                  class="mx-auto"
                >
                  <div class="svg-abs svg-abs--todesfallsumme">
                    <SvgVue icon="bedarfsinfo/berufsunfaehigkeit/todesfallsumme.svg" />
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-lg-6 p-2.5 lg:px-7 pt-14 pt-lg-2">
                <BulletPointList
                  :items="[
                    {text: 'Gefährliche Hobbies wie beispielsweise Motorradfahren'},
                    {text: 'Der Beitrag für Raucher erhöht sich um das Zwei- bis Dreifache. ' +
                      'Als Konsument von E-Zigaretten gelten Sie im Regelfall ebenfalls als Raucher.'},
                  ]"
                />
                <div class="pt-2.5">
                  <NiceToKnow
                    classes="mb-0"
                    :bullets="[
                      {
                        title: 'Tipp:',
                        text: 'Sind Sie mehr als ein Jahr rauchfrei, gelten Sie bei vielen ' +
                          'Gesellschaften als Nichtraucher.'
                      },
                      {
                        title: 'Aber:',
                        text: 'Fangen Sie nach Vertragsabschluss mit dem Rauchen (wieder) an, ' +
                          'besteht eine nachvertragliche Anzeigepflicht.'
                      }
                    ]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <h3 class="text-center mb-7">
          Muss ich die Todesfallsumme versteuern?
        </h3>
        <div class="row">
          <div class="col-12 col-lg-6 pr-lg-4">
            <BulletPointList
              :items="[
                {text: 'Die Todesfallsumme kann steuerpflichtig sein.'},
                {text: 'Freibetrag zwischen Ehepartnern liegt bei 500.000 €, bei Kindern bei 400.000 €.'},
                {text: 'Freibetrag zwischen Unverheirateten liegt nur bei 20.000 €.'},
              ]"
            />
            <div class="pt-2.5">
              <NiceToKnow
                classes="mb-0"
                :bullets="[
                  {
                    title: 'Tipp:',
                    text: `Beiträge zur Risikolebens&shy;versicherung sind wie Kranken&shy;versicherungs&shy;beiträge
                          als Vorsorge&shy;aufwendungen steuerlich absetzbar (Höchstgrenze 1.900 € für Angestellte bzw.
                          2.800 € für Selbständige, Stand 2018).`
                  }
                ]"
              />
            </div>
          </div>
          <div class="col-12 col-lg-6 pl-lg-4 pt-7 pt-lg-0">
            <p>
              <b>Mit der richtigen Vertragsgestaltung, dem sogenannten Überkreuz-Modell, erfolgt die
                Auszahlung der Todesfallsumme steuerfrei.</b>
            </p>
            <div
              style="max-width: 484px;"
              class="mx-auto"
            >
              <div class="svg-abs svg-abs--kreuzmodell">
                <SvgVue icon="bedarfsinfo/berufsunfaehigkeit/kreuzmodell.svg" />
              </div>
            </div>
          </div>
        </div>
        <h3 class="text-center mt-5 mb-7">
          Lohnt sich eine verbundene Risikolebens&shy;versicherung um Geld zu
          sparen?
        </h3>
        <div class="row">
          <div class="col col-lg-8 offset-lg-2">
            <p>
              Bei einer verbundenen Risikoleben schließen zwei (oder mehr Personen) eine Risikolebens&shy;versicherung
              ab, weshalb der Beitrag auch nur für einen Vertrag anfällt.
            </p>
            <p>
              Diese Vertragsform eignet sich <b>nicht</b>...
            </p>
            <BulletPointList
              :items="[
                {text: '... wenn Sie unverheiratet sind, da der Freibetrag für eine steuerfreie' +
                  ' Auszahlung der Todesfallsumme bei 20.000 € liegt.'},
                {text: '... für Ehepaare, die Kinder versichern wollen, da die Todesfallsumme' +
                  ' bei versterben beider Ehepartner nur einmalig ausgezahlt wird und womöglich zu gering ist.'},
                {text: '... wenn Laufzeit und Leistung für jeden Partner individuell bestimmt' +
                  ' werden sollen.'},
              ]"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-8 offset-lg-2 pt-2.5">
            <NiceToKnow
              classes="mb-0"
              :bullets="[
                {
                  title: 'Fazit:',
                  text: `Eine verbundene Risikolebensversicherung hat im Leistungsfall erhebliche Nachteile.`
                }
              ]"
            />
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';
import SvgVue from "@/application/components/SvgVue";

interface Props {
  data: Bedarfsinfo,
}

defineProps<Props>();

const subtitle = `
  Kommt es innerhalb einer Familie oder Partnerschaft
  zu einem Todesfall, sind die finanziellen Folgen häufig
  existenzbedrohend. Ob Immobiliendarlehen oder Versorgung der Kinder –
  mit einer Risikolebensversicherung schützen Sie Ihre
  Angehörigen.`;
</script>
