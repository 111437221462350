import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 33 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M28.22 1.55A179 179 0 0 1 17.2 19.38a137 137 0 0 1-6.11 8c-.1.06-6.13-8.3-6.6-9.08-1.24-2.24-4.89-.68-3.64 1.56 1.97 3.6 4.44 7.68 7.72 10.33 2.78 2.19 4.63.9 6.53-1.52A154.8 154.8 0 0 0 31.97 3.05C33.13.8 29.46-.7 28.22 1.55" }, null, -1)
  ])))
}
export default { render: render }