<template>
  <div class="relative text-center overflow-hidden">
    <picture class="block mx-auto w-full max-w-[2000px]">
      <source
        :srcset="findImage(`${image}.jpg`)"
        media="(min-width: 576px)"
      >
      <img
        class="object-cover object-center w-full max-h-[540px]"
        :src="findImage(`${image}-575.jpg`)"
        :alt="alt"
      >
    </picture>
    <slot />
  </div>
</template>

<script setup lang="ts">
const jumboImages = Object.entries(import.meta.glob(
  '../../../../assets/images/bedarfsinfo/**/*.jpg',
  { eager: true, query: '?url', import: 'default' }
));

interface Props {
  image: string,
  alt?: string,
}

withDefaults(defineProps<Props>(), {
  alt: '',
});

function findImage(image: string) {
  return jumboImages.find(([relativePath]) => relativePath.includes(image))?.[1];
}
</script>
