import { BiometricAuth } from "@aparajita/capacitor-biometric-auth";
import { isWebPlatform } from "@/application/utils/envInfo";
import type { CheckBiometryResult } from "@aparajita/capacitor-biometric-auth";

export { BiometryType } from "@aparajita/capacitor-biometric-auth/dist/esm/definitions";
export type { CheckBiometryResult } from "@aparajita/capacitor-biometric-auth";

export const checkBiometry = async (): Promise<CheckBiometryResult> => {
  return BiometricAuth.checkBiometry();
}

export const biometryAuth = async (): Promise<void> => {
  if (isWebPlatform) return;

  await BiometricAuth.authenticate({
    cancelTitle: 'Abbrechen',
    allowDeviceCredential: true,
    androidConfirmationRequired: false,
  });
};
