import type { Pension, Rate } from './types';
import { useGet } from "@/api/lib/integration";
import type { GetPensionsRequestData } from "./types";
import type { GesetzlicheRenteRequestData } from "./types";
import type { MehrfachsparteRente } from "./types";

export const getPensions = useGet<GetPensionsRequestData, Pension[]>('/api/rechner/pension')
export const getGesetzlicheRente = useGet<GesetzlicheRenteRequestData, number>('/api/rechner/gesetzlicherente')
export const getMehrfachSparrate = useGet<MehrfachsparteRente, Rate[]>('/api/rechner/mehrfachsparrate')
export const getKrankentageGeld = useGet<{ nettojahreseinkommen: number }, { result: number }>('/api/rechner/krankentagegeld')
export const getErwerbsminderungsrente = useGet<{ gehalt: number, type: 'halb' | 'voll', gehaltIstNetto: 'true' | 'false' }, { result: number }>('/api/rechner/erwerbsminderungs-rente/{type}')
