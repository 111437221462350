import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import { ref } from "vue";
import { getAppInfos, isAndroid, isWebPlatform } from "@/application/utils/envInfo";
import { captureException } from '@sentry/vue';
import type { AppUpdateResult } from "@capawesome/capacitor-app-update/dist/esm/definitions";

export const newAppVersion = ref('');

const forceTwaUpdate = async () => {
  if (!document.referrer.includes('android-app://de.demv.finanzmanager.app')) return;

  newAppVersion.value = (await getAppInfos()).version;
}

export const performAppUpdate = async (): Promise<void|AppUpdateResult> => {
  if (isWebPlatform) return await forceTwaUpdate();

  // force native app update later
  return;

  try {
    const result = await AppUpdate.getAppUpdateInfo();
    const currentVersion = isAndroid ? result.currentVersionCode : result.currentVersionName;
    const availableVersion = (isAndroid ? result.availableVersionCode : result.availableVersionName) ?? null;

    if (!availableVersion) return;

    // @ts-ignore
    if (availableVersion && compareVersions(availableVersion, currentVersion) === 1) newAppVersion.value = availableVersion;

    if (result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE) {
      if (isAndroid && result.immediateUpdateAllowed) return await AppUpdate.performImmediateUpdate();

      return await AppUpdate.openAppStore();
    }
  } catch(error: unknown) {
    captureException(error);
  }
}

export const openAppStore = (): Promise<void> => {
  return AppUpdate.openAppStore();
}

function compareVersions(a: string, b: string) {
  const parseVersion = (version) => version.split('.').map(Number);
  const v1 = parseVersion(a);
  const v2 = parseVersion(b);

  for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
    const num1 = v1[i] || 0;  // Default to 0 if the version part is missing
    const num2 = v2[i] || 0;

    if (num1 > num2) return 1;
    if (num1 < num2) return -1;
  }

  return 0;
}
