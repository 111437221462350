<template>
  <div>
    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
            <div class="text-center mb-14">
              <h2>Welche Form der Einkommenssicherung passt zu mir?</h2>
              <p>
                Um zu prüfen, welche Produkte alternativ zur Berufsunfähigkeitsversicherung
                grundsätzlich geeignet sind, um den finanziellen Einkommensverlust zu
                versichern, bitten wir Sie, die nachstehenden Fragen zu beantworten:
              </p>
            </div>

            <OptionGroupList
              v-model="selectionYesNo"
              :options="yesNo"
              horizontal-options
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <h2 class="mt-14 text-center">
              Auf Basis Ihrer Angaben sind folgende Produkte geeignet und angemessen:
            </h2>

            <div class="card-deck mt-7 text-center">
              <div
                v-for="({ id, image, description, name }) in sparten"
                :key="id"
                :class="inArray(id, result.sparten)
                  ? ['bg-light', 'text-secondary', 'border-secondary', 'border-2', $style.shadow]
                  : ['text-muted', $style.disabled]"
                class="card"
              >
                <div class="card-body items-center relative p-5">
                  <div
                    v-show="inArray(id, result.sparten)"
                    :class="$style.inlineCheck"
                  >
                    <font-awesome-icon
                      :icon="['far', 'check-circle']"
                      size="lg"
                    />
                  </div>
                  <div
                    v-show="!inArray(id, result.sparten)"
                    :class="$style.inlineCheck"
                  >
                    <font-awesome-icon
                      :icon="['far', 'times-circle']"
                      size="lg"
                    />
                  </div>

                  <SvgVue
                    :icon="image"
                    :class="$style.icon"
                    class="inline-block mb-5"
                  />
                  <!-- eslint-disable vue/no-v-html -->
                  <h5
                    class="card-title font-bold"
                    v-html="mapBreakableName(name)"
                  />
                  <!-- eslint-enable vue/no-v-html -->
                  <div
                    class="card-text"
                    v-text="description"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div
      v-if="canStoreValues"
      class="col-12 text-center py-7 bg-dark text-light print:hidden"
    >
      <p class="lead">
        Wenn Sie fertig sind, können Sie Ihre
      </p>
      <button
        type="button"
        class="btn-light-lg"
        @click="storeValues"
      >
        Eingaben speichern
      </button>

      <div
        v-if="valuesSaved !== null"
        class="mt-5"
      >
        <div v-if="valuesSaved === 'pending'">
          <span class="spinner spinner--white" />
          Wird gespeichert...
        </div>
        <div v-else-if="valuesSaved === true">
          <font-awesome-icon
            :icon="['far', 'check-circle']"
            size="lg"
          />
          Gespeichert!
        </div>
        <div v-else-if="valuesSaved === false">
          <font-awesome-icon
            :icon="['far', 'times-circle']"
            size="lg"
          />
          Fehler beim Speichern
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { getEvaluation, storeQuiz } from '@/api/einkommenssicherung/einkommenssicherungApi';
import OptionGroupList from '@/application/components/Option/OptionGroupList.vue';
import { arrayToObjectWithDefaults } from '@/application/utils/transformers';
import SvgVue from "@/application/components/SvgVue";
import type { EinkommenssicherungType } from "@/api/einkommenssicherung/types";
import { call } from "@/api/lib/integration";

interface Props {
  sparten: [];
  yesNo: [];
}

const props = defineProps<Props>();

const canStoreValues = ref(true); // TODO: correct handling isMakler
const valuesSaved = ref<string | boolean | null>(null);
const selectionYesNo = ref(arrayToObjectWithDefaults(props.yesNo));
const result = ref<EinkommenssicherungType>({
  sparten: [],
});

const fetchEvaluation = async () => {
  await call(
    getEvaluation({ fragen: selectionYesNo.value }),
    (data) => { result.value = data },
  );
};

const inArray = (item, array) => {
  return array.includes(item);
};

const mapBreakableName = (name) => {
  switch (name) {
    case 'Erwerbsunfähigkeit':
      return 'Erwerbs&shy;unfähigkeit';
    case 'Grundfähigkeit':
      return 'Grund&shy;fähigkeit';
    default:
      return name;
  }
};

const storeValues = async () => {
  if (!canStoreValues.value) {
    return;
  }

  valuesSaved.value = 'pending';

  await call(
    storeQuiz({ fragen: selectionYesNo.value }),
    () => { valuesSaved.value = true },
    () => { valuesSaved.value = false },
  );
};

watch(selectionYesNo, () => {
  fetchEvaluation();
}, { deep: true });

onMounted(() => {
  fetchEvaluation();
});

</script>

<style lang="scss" module>
.shadow {
  box-shadow: 0 3px 20px -2px rgba(#000, 0.45);
}

.icon {
  height: 50px;
}

.inlineCheck {
  left: 10px;
  position: absolute;
  top: 10px;
}

.disabled {
  opacity: 0.5;

  .inlineCheck {
    color: #c34f4a;
  }
}
</style>
