import type { VNode } from 'vue';
import { defineComponent } from 'vue';

// Despite being mentioned in the docs import.meta.glob does not support aliases, so we
// use a relative path here.
const svgPathComponentMap = import.meta.glob<{ render(): VNode }>('../../../../assets/**/*.svg', {
  import: 'default',
  query: 'component',
  eager: true,
});
const svgPaths = Object.keys(svgPathComponentMap);

if (svgPaths.length === 0) {
  throw new Error('No svg-files found');
}

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const svgPath = svgPaths.find((path) => path.includes(props.icon));

    if (svgPath === undefined) {
      throw new Error(`${props.icon} was not found`);
    }

    return svgPathComponentMap[svgPath].render;
  },
});
