import type { Company, DocumentType, Product } from '@/api/common/types';

export type Payment = {
  interval: string | null,
  brutto: number | null,
  netto: number | null,
  zahlbeitrag: number | null,
  monatlich: number | null,
};

type Term = {
  start: string | null,
  end: string | null,
};

export type Owner = {
  firstName: string,
  lastName: string
}

export type ContractMeta = {
  kfz?: {
    licensePlate: string
  },
};

export type RisikoOrt = {
  street: string | null,
  streetNumber: string | null,
  postcode: string | null,
  city: string | null,
  displayText: string,
};

export type NormalizedContractDocument = {
  id: number,
  name: string,
  extension: string,
  type: number,
  createdAt: string // datestring YYYY-mm-dd
  urls: {
    download: string
  },
};

export type ProductGroup = {
  id: number,
  name: string,
  order: number,
};

export type Status = {
  id: number,
  name: string,
};

export type ContractDocument = {
  id: number,
  name: string,
  extension: string,
  type: DocumentType,
  createdAt: string // datestring YYYY-mm-dd
  urls: {
    download: string
  },
};

export type Coinsured = {
  id: number,
  firstName: string | null,
  lastName: string,
  relation: string,
  birthday: string | null
};

export type BaseContract = {
  id: number,
  status: Status,
  product: Product,
  group: ProductGroup | null,
  company: Company,
  typeId: ContractTypeId,
}

export enum ContractTypeId {
  EIGENVERTRAG = 1,
  FREMDVERTRAG = 2,
  KORRESPONDENZMAKLER = 3,
  GDV_VERTRAG = 4,
  ANTRAG = 5,
  VORANFRAGE = 6,
}

export type Contract = {
  number: number | string | null,
  note: string | null,
  payment: Payment | null | undefined,
  term: Term | null,
  owner: Owner | null,
  risikoort: RisikoOrt | null,
  coinsured: Coinsured[],
  documents: ContractDocument[],
  meta: ContractMeta | null,
  userContractId: number | null,
  canBeDeleted: boolean,
  spartenName?: string,
} & BaseContract;

export type Depot = {
  depot_evaluation: number,
  monthly_payment_rate?: number,
  depotFonds: DepotFond[] | undefined,
} & BaseContract;

export type NormalizedContract = {
  id: number,
  status: number,
  product: number,
  company: number,
  number: number | string | null,
  note: string | null,
  payment: Payment | null | undefined,
  term: Term | null,
  owner: string | null,
  coinsured: unknown[], // TODO use better type when we a have use case
  documents: number[],
  meta: ContractMeta | unknown[], // PW returns an empty array when there is no metaData
  group: number | null,
  userContractId: number | null,
  canBeDeleted: boolean,
};

export type ContractsResponse = {
  companies: Company[],
  contracts: NormalizedContract[],
  groups: ProductGroup[],
  products: Product[],
  statuses: Status[],
  documentTypes?: DocumentType[],
  documents?: NormalizedContractDocument[],
};

export type Fond = {
  isin: string,
  name: string,
  url: string,
};

export type DepotFond = {
  fond: Fond
  proportions: number,
  evaluation: number,
  deadline: string | null // date string: 2022-06-03
  pricePerProportion: number,
  paymentType: number,
  paymentRate: number
};

export type NormalizedDepotFond = {
  fond: Fond
  proportions: number,
  evaluation: number,
  deadline: string // date string: 2022-06-03
  pricePerProportion: number,
  paymentType: number,
};

export type NormalizedDepot = {
  id: number,
  company: number,
  product: number,
  depot_evaluation: number,
  monthly_payment_rate?: number,
  group: number,
  status: number,
  depotFonds: NormalizedDepotFond[] | undefined,
};

export type DepotsResponse = {
  fondFinanzDepots: Pick<NormalizedDepot, "depot_evaluation" | "depotFonds" | "id" | "monthly_payment_rate">[]
  depots?: NormalizedDepot[],
  statuses?: Status[],
  products?: Product[],
  companies?: Company[],
  groups?: ProductGroup[],
};
