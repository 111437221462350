import type { PropType } from 'vue';
import { defineComponent, h } from 'vue';

import type { Contract, ContractMeta } from '@/api/contracts/types';
import type { GridTableItem } from '@/application/components/GridTable.vue';
import GridTable from '@/application/components/GridTable.vue';
import ContractPayment from '@/contracts/components/ContractPayment.vue';
import ContractStatus from '@/contracts/components/ContractStatus.vue';
import LicensePlate from '@/contracts/components/LicensePlate.vue';
import { formatDate } from '@/application/utils/date';

const isValidMeta = (meta: Contract['meta']): meta is ContractMeta => !Array.isArray(meta);

export default defineComponent({
  name: 'ContractDetailsTable',
  props: {
    contract: {
      type: Object as PropType<Contract>,
      required: true,
    },
  },
  setup(props) {
    const term: GridTableItem[] = props.contract.term !== null
      ? [
        {
          title: 'Beginn',
          value: props.contract.term.start !== null
            ? formatDate(props.contract.term.start)
            : null,
        },
        {
          title: 'Ende',
          value: props.contract.term.end !== null
            ? formatDate(props.contract.term.end)
            : null,
        },
      ]
      : [];

    const items: GridTableItem[] = [
      {
        title: 'Vertragsnummer',
        value: props.contract.number,
      },
      { title: 'Status',
        value: h(ContractStatus, { status: props.contract.status }) },
      {
        title: 'Kennzeichen',
        value: isValidMeta(props.contract.meta) && props.contract.meta?.kfz
          ? h(LicensePlate, { licensePlate: props.contract.meta?.kfz.licensePlate })
          : null,
      },
      {
        title: 'Kosten',
        value: props.contract.payment
          ? h(
            ContractPayment,
            {
              amount: props.contract.payment.zahlbeitrag,
              interval: props.contract.payment.interval,
              inline: true,
              bold: true,
            },
          )
          : null,
      },
      ...term,
      {
        title: 'Mitversichert bei',
        value: props.contract.owner !== null
          ? h('strong', `${props.contract.owner.firstName || ''} ${props.contract.owner.lastName}`)
          : null,
      },
      {
        title: 'Risikoort',
        value: props.contract.risikoort
          ? props.contract.risikoort.displayText
            .split(',')
            .map((part) => h('div', part.trim().replace(/\s/g, String.fromCodePoint(160))))
          : null,
      },
      {
        title: 'Mitversicherte Personen',
        value: props.contract.owner === null && props.contract.coinsured.length > 0
          ? props.contract.coinsured.map(({ firstName, lastName, relation }, i) => {
            const fullName = firstName === null ? `${lastName} ` : `${firstName} ${lastName} `;
            return h(
              'div',
              { key: i },
              [`${fullName}`, h('small', { class: 'text-muted' }, `(${relation})`)],
            );
          })
          : null,
      },
    ];

    return () => h(GridTable, { items, mutedTitle: true, rightAlignedValue: true });
  },
});
