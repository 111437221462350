<script setup lang="ts">
</script>

<template>
  <slot name="text">
    <div>
      <div class="flex-wrap max-sm:flex gap-2 font-bold italic text-blue-700 herofontxs sm:herofontsm md:herofontmd xl:herofontxl">
        <div class="text-blue-300">Meine</div>
        <div class="text-blue-500">digitalen</div>
        <div>Finanzen</div>
      </div>

      <div class="font-light md:text-lg text-balance text-gray-800 mt-4 md:mt-10">
        Ihr Zugang zu allen <span class="font-medium">Verträgen und Dokumenten</span>.
        Mit dem Check-up passgenau Ihre <span class="font-medium">Versicherungsbedürfnisse</span> ermitteln und erfahren
        welche Versicherungen Sie <span class="font-medium">optimal absichern</span>.
      </div>
    </div>
  </slot>
  <div class="max-w-[420px] w-full mx-auto">
    <slot />
  </div>
</template>

<style scoped lang="scss">
@tailwind utilities;
@layer utilities {
  .herofontxl {
    line-height: 0.85;
    font-size: 6rem;
  }

  .herofontmd {
    line-height: 0.85;
    font-size: 5rem;
  }

  .herofontsm {
    line-height: 0.85;
    font-size: 4.5rem;
  }

  .herofontxs {
    line-height: 0.85;
    font-size: 2rem;
  }
}
</style>
