import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 122.3 87.1"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"#4181D2\" d=\"M37.4 11.1h84.9v71H37.4z\"></path><path d=\"M50.3 50.3v3\" class=\"cls-2\"></path><circle cx=\"9.6\" cy=\"36.9\" r=\"7.1\" class=\"cls-2\"></circle><path d=\"M32.2 41a2.6 2.6 0 0 0-.4-.3c-1.8-1.3-16.3-10.4-14 .7 2.9 13.1 1.5 9.6 4 15.9.6 1.3.9 2.9-3.7 4l-6.6 2.3a2.4 2.4 0 0 0-1.5 1.7L6.5 80a2.4 2.4 0 0 0 0 1.1l-1.3 1.6-5.2 1 .6 2 3 1.5 3.2-.3 4.4-2.3c.8-.5.3-2-.2-3a2.4 2.4 0 0 0 .2-.3L14.6 70a2.4 2.4 0 0 1 1.7-1.6l9.2-2.5a2.4 2.4 0 0 1 2.6.9l.7 1a2.4 2.4 0 0 0 4.2-.3l1-1.9a2.4 2.4 0 0 1 .7-.7l1.7-1.3a2.5 2.5 0 0 0 1-2c0-3.8-.3-15.4-5.2-20.5z\" class=\"cls-2\"></path><path fill=\"none\" stroke=\"#000\" stroke-miterlimit=\"10\" stroke-width=\"2\" d=\"M37.6 3.6h42.2\"></path><path d=\"M78.8 7.2 85 3.6 78.8 0z\"></path>", 6)
  ])))
}
export default { render: render }