import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 70 70"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g fill=\"none\" fill-rule=\"evenodd\"><path stroke=\"#424242\" stroke-width=\"1.5\" d=\"M56.95 19.67c-1.34-9.1-3.48-11.05-12.28-12.36-4.7-.7-9.26-2.84-13.94-3.06-12.66-.6-22.8 9.35-22.9 21.98-.1 12.02-.03 24.04-.03 36.3 5.44.5 11.22 1.09 15.88 1.52\"></path><path stroke=\"#424242\" stroke-width=\"1.5\" d=\"M51.22 32.78c-2.74-2.74-4.66-6.3-6.13-7.77L13.54 39.93c.1 6.6 2.3 12.52 8.04 17.6 5.28 4.67 10.61 4.68 16.52 3.06 7.03-1.93 11.25-6.69 12.23-13.9.63-4.58.62-9.27.89-13.9\"></path><path stroke=\"#424242\" stroke-width=\"1.33\" d=\"M56.95 19.34v43.19c-5.44.5-10.93 1.09-15.58 1.53\"></path><path stroke=\"#333\" d=\"M21 40.5h5.83m12.17 0h5.83M29.17 53.33h8.33\"></path></g>", 1)
  ])))
}
export default { render: render }