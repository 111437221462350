<template>
  <div class="w-full">
    <table class="w-full -ml-1">
      <tr
        v-for="(file, index) in files"
        :key="index.toString() + file.name"
        class="text-muted"
      >
        <td
          style="max-width: 200px;"
          class="align-top truncate py-1 px-1"
        >
          <DsInput
            :disabled="disableImageInputs && file.type.startsWith('image/')"
            size="base"
            :prefix="`${index + 1}`"
            :model-value="fileNames[index]"
            @update:model-value="value => emit('setFileName', value, index)"
          />
        </td>
        <td
          class="content-center"
          style="width: 5%"
        >
          <a
            class="px-2.5 py-1.5 rounded hover:bg-gray-100"
            href="#"
            @click.prevent.stop="emit('remove', index)"
          >
            <DsIcon
              name="trash"
              variant="light"
              size="lg"
              title="Datei entfernen"
              class="text-red-500"
            />
          </a>
        </td>
      </tr>
    </table>
  </div>
</template>

<script setup lang="ts">
import { DsIcon, DsInput } from '@demvsystems/design-components';

interface Props {
  files: File[],
  fileNames: string[],
  disableImageInputs: boolean,
}

defineProps<Props>();

const emit = defineEmits<{
  remove: [index: number]
  setFileName: [value: string, index: number]
}>();
</script>
