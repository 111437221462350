import { useRouter } from "vue-router";

export function useGoBack() {

  const router = useRouter();

  async function goBack() {
    const back = router.options.history.state.back

    if (back === null || typeof back === 'string' && back?.includes('login')) {
      await router.push('/');
      return
    }

    router.go(-1);
  }

  return {
    goBack
  };
}
