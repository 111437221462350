import { defineStore } from 'pinia';
import { getUserContactData as getUserContactDataEndpoint } from '@/api/user/userApi';
import { deleteBankAccount, getBankAccounts as getBankAccountsEndpoint } from '@/api/bankAccount/bankAccountsApi';
import type { UserContactData } from '@/api/user/types';
import type { BankAccount, BankAccountContract } from '@/api/bankAccount/types';
import { call } from '@/api/lib/integration';
import { FetchError } from '@/api/lib/errors';
import { captureException } from '@sentry/vue';
import { ref, computed } from 'vue';
import type { Ref } from 'vue';
import { useContractStore } from "@/contracts/stores/contractStore";
import type { ContractOption } from "@/contracts/stores/types";

export const useUserDataStore = defineStore('userContactsDataStore', () => {
  const userData: Ref<UserContactData | null> = ref(null);
  const bankAccounts: Ref<BankAccount[] | null> = ref(null);
  const userDataLoading = ref(false);
  const bankAccountsLoading = ref(false);
  const userDataError: Ref<FetchError | null> = ref(null);
  const bankAccountsError: Ref<FetchError | null> = ref(null);

  const contractToBankAccountMap = computed<Map<number, BankAccount>>(() => {
    const map = new Map();
    bankAccounts.value?.forEach((account) => account.contractIds.forEach((id) => map.set(id, account)));

    return map;
  })

  function getBankAccountContractByIds(ids: number[]): BankAccountContract[] {
    const idSet = new Set(ids);
    return useContractStore().contractOptions
      .filter((option: ContractOption) => idSet.has(option.value))
      .map((option: ContractOption) => {
        const bankAccount = contractToBankAccountMap.value.get(option.value) ?? null;
        return {
          id: option.value,
          label: option.label,
          number: option.number ? `${option.number}` : undefined,
          iban: bankAccount?.iban ?? null,
          bank: bankAccount?.bankName ?? null,
        };
      });
  }

  async function fetchUserContacts() {
    userDataLoading.value = true;
    await call(getUserContactDataEndpoint(),
      (data) => {
        userData.value = data;
      },
      (error) => {
        if (error instanceof FetchError) {
          userDataError.value = error;
        }
        captureException(error);
      }
    );
    userDataLoading.value = false;
  }

  async function fetchBankAccounts() {
    bankAccountsLoading.value = true;
    await call(getBankAccountsEndpoint(),
      (data) => {
        bankAccounts.value = data.sort((a, b) => b.main ? 1 : -1)
      },
      (error) => {
        if (error instanceof FetchError) {
          bankAccountsError.value = error;
        }
        captureException(error);
      }
    );
    bankAccountsLoading.value = false;
  }

  async function getBankAccount(id: number) {
    if (!bankAccounts.value) {
      await fetchBankAccounts();
      useContractStore().fetchContractsAndDepots();
    }
    return bankAccounts.value?.find(bankAccount => bankAccount.id === id) ?? null;
  }

  async function getBankAccounts() {
    if (!bankAccounts.value) {
      await fetchBankAccounts();
    }
  }

  async function deleteBankAccountById(id: number) {
    const assigned = bankAccounts.value?.find((account) => account.id === id)?.contractIds ?? [];
    const res = await deleteBankAccount({
      id,
      additional: {
        contracts: useUserDataStore().getBankAccountContractByIds(assigned),
      }
    });

    if (res instanceof Error) return false;

    if (!bankAccounts.value) return true;

    bankAccounts.value = bankAccounts.value.filter(bankAccount => bankAccount.id !== id);

    return true;
  }

  function $reset() {
    userData.value = null;
    bankAccounts.value = null;
    userDataLoading.value = false;
    bankAccountsLoading.value = false;
    userDataError.value = null;
    bankAccountsError.value = null;
  }

  return {
    userData,
    userDataLoading,
    userDataError,
    bankAccounts,
    bankAccountsLoading,
    bankAccountsError,

    contractToBankAccountMap,

    $reset,
    getBankAccountContractByIds,
    fetchUserContacts,
    fetchBankAccounts,
    getBankAccounts,
    getBankAccount,
    deleteBankAccountById,
  };
});
