<template>
  <div>
    <div>
      Bitte geben Sie den 6-stelligen Code aus der Authenticator-App ein
    </div>
    <div class="flex flex-row justify-center gap-2 mt-1.5">
      <DsInput
        v-model="form.code"
        size="lg"
        mask="######"
        style="width: 95px"
      />
      <DsButton
        size="lg"
        :handler="submit"
      >
        Bestätigen
      </DsButton>
    </div>

    <DsFieldError
      v-for="error in errors?.code"
      :key="error"
    >
      {{ error }}
    </DsFieldError>
  </div>
</template>

<script setup lang="ts">
import { DsButton, DsFieldError, DsInput } from '@demvsystems/design-components';

import { confirmTwoFactorAuthentication } from "@/api/auth/authApi";
import { useForm } from "@/api/lib/integration";

const emit = defineEmits(['confirmed']);

const { submit, form, errors } = useForm(
  confirmTwoFactorAuthentication,
  { code: '' },
  () => emit('confirmed'),
);
</script>
