import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 93.33 93.33"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "46.67",
      cy: "46.67",
      r: "46.67",
      fill: "#4181D2"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#FFF",
      d: "M28.24 51.61S16.65 18.53 70.62 15.42c.01 0-29.5 14.17-42.38 36.19"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#FFF",
      d: "M30.39 53.35c9.09 8.58 39.37 2.12 40.19-37.94 0 0-32.17 18.89-40.19 37.94"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#FFF",
      d: "m13.94 68.52 8.81 7.59.02.01c4.72 3.53 12.5 4.58 18.37 4.12l21.83-1.69a8.13 8.13 0 0 0 5.31-2.56l18.89-20.34a.2.2 0 0 0 .06-.18c-.07-.7-.78-4.49-8.17-.87a.2.2 0 0 0-.06.04l-8.88 7.98c-.05.05-.62.68-.62.75.02.75.72 4.49-5.88 7.83-.51.26-12.56.16-13.13.11l.06-.42s5.43.25 11.62-1.15c0 0 5.78-.34 4.82-6.04a1.29 1.29 0 0 0-.68-.92c-1.33-.66-5.33-1.76-15.64.58a.14.14 0 0 1-.09 0c-.77-.18-12.02-2.7-19.36.71a.25.25 0 0 1-.17.01L9.12 56.15c-.57-.21 4.29 11.64 4.78 12.33z"
    }, null, -1)
  ])))
}
export default { render: render }