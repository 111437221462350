<template>
  <Tab :name="name">
    <h3>Multi-Risk-Versicherung</h3>
    <div class="pb-7">
      Diese Versicherung ist ein Kombinationsprodukt aus verschiedenen Bausteinen, wie die Dread-Disease-,
      Unfall-, Pflege- und Grundfähigkeitsversicherung.
      Aufgrund der jeweiligen Einschränkungen im Versicherungsschutz bei den einzelnen Bausteinen,
      wird durch die Kombination ein breiteres Spektrum an Ursachen abgedeckt, die zum Verlust der
      Arbeitskraft führen können.
    </div>
    <h3>Worauf muss ich bei dieser Versicherung achten?</h3>
    <div class="pb-2.5">
      Diese Versicherung wird sowohl von Sachversicherern, als auch von Lebensversicherern angeboten.
      Die Tarife von Sachversicherern haben meist deutliche Nachteile:
    </div>
    <div class="row pb-7">
      <div class="col-12 col-lg-8">
        <BulletPointList
          small
          :items="bullets"
        />
      </div>
    </div>

    <NiceToKnow
      classes="mb-0"
      :bullets="niceToKnow"
    />
  </Tab>
</template>

<script setup lang="ts">
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import Tab from '@/application/components/Tab.vue';

interface Props {
  name: string
}

defineProps<Props>();

const bullets = [
  { text: 'Keine Beitragsgarantie' },
  { text: 'Kündigungsmöglichkeit des Versicherers' },
  { text: `Leistungen aus der Unfallversicherung häufig erst wenn, infolge
            eines Unfalls der Invaliditätsgrad 50 Prozent oder mehr beträgt` },
];

const niceToKnow = [
  {
    title: 'Empfehlung:',
    text: 'Wir empfehlen, die Multi-Risk-Versicherung im Rahmen einer Lebensversicherung abzuschließen.',
  },
  {
    title: 'Hinweis:',
    text: 'Bei einzelnen Sachversicherern kann es zu Abweichungen in den Vertragsbedingungen kommen.',
  },
];
</script>
