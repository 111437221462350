<template>
  <div>
    <p
      v-if="isShortText"
      :class="$style.lineClamp"
      class="mb-0"
      v-text="text"
    />

    <p
      v-else
      class="mb-0"
      v-text="text"
    />

    <DsButton
      variant="clear"
      :icon="isShortText ? 'chevron-down' : 'chevron-up'"
      size="sm"
      @click="isShortText = !isShortText"
    >
      {{ isShortText ? 'mehr anzeigen' : 'weniger anzeigen' }}
    </DsButton>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { DsButton } from "@demvsystems/design-components";

interface Props {
  text: string,
}
defineProps<Props>();

const isShortText = ref(true);
</script>

<style lang="scss" module>
  .lineClamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
</style>
