<template>
  <SpartenWrapper
    image="images/bedarfsinfo/haftpflicht/fahrrad-unfall"
    text="Fügen Sie einem Dritten einen Schaden zu, so sind Sie
          verpflichtet, diesen zu ersetzen – unabhängig von der
          Schadenhöhe. Eine Privathaftpflicht nimmt Ihnen das
          finanzielle Risiko ab."
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    :versorgungen="data.bedarfsinfo.versorgungen"
    versorgungen-headline="Wie bin ich aktuell bei einem Haftpflichtschaden geschützt?"
    versorgungen-description="Privathaftpflicht"
  >
    <section class="section">
      <div class="container">
        <DamageExamplesSection
          :data="damageExamples"
          headline="Wann leistet die Privathaftpflicht?"
          subline="Die Privathaftpflicht trägt die finanziellen Kosten
                durch Schäden, die Sie einem Dritten unbeabsichtigt zugefügt haben."
          second-headline="Schadenbeispiele - welche Kosten werden erstattet?"
        />

        <div class="row justify-center">
          <div class="col-12 col-md-8 col-lg-6 col-xl-5">
            <NiceToKnow
              classes="mt-7 mb-0"
              :bullets="[{
                title: 'Info:',
                text: 'Zusätzlich weist die Versicherung unberechtigte Forderungen ab und setzt dieses ' +
                  'gerichtlich durch (sogenannter passiver Rechtsschutz).'
              }]"
            />
          </div>
        </div>
      </div>
    </section>

    <JumboImage image="images/bedarfsinfo/haftpflicht/drohne" />

    <section class="section">
      <div class="container">
        <div class="row text-center">
          <div class="col-12">
            <h3 class="mb-5">
              Welche zusätzlichen Leistungen bieten Top-Tarife?
            </h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <List title="Schlüsselverlust">
              Versichert werden beispielsweise der Schlüsselverlust von privat gemieteten Objekten oder
              der Verlust eines beruflich genutzten Schlüssels. Dies gilt im Regelfall auch für
              Schließanlagen, Generalschlüssel oder Code-Karten.
            </List>
            <List title="Gefälligkeitsschäden">
              Aus juristischer Sicht können Sie nicht haftbar gemacht werden, sofern der Schaden entsteht,
              weil Sie einem Freund einen Gefallen getan haben. Lassen Sie die Umzugskiste fallen, sind
              Sie aber in der moralischen Verpflichtung den Schaden zu ersetzen.
            </List>
            <List title="Mietsachschäden">
              Mieter von Wohnungen haften für selbst verursachte Beschädigungen gegenüber dem Vermieter.
              Ob Kratzer in der Einbauküche oder eine gesprungene Fliese im Badezimmer - mit der richtigen
              Haftpflicht erstattet die Versicherung den Schaden.
            </List>
          </div>
          <div class="col-12 col-md-6">
            <List title="Schäden durch Kinder unter acht Jahren">
              Verursacht Ihr Kind bei Nachbarn einen Schaden, kann es erst ab dem achten Lebensjahr haftbar
              gemacht werden. Gute Haftpflichtversicherungen erstatten den Schaden unabhängig vom Alter und
              wahren den Nachbarschaftsfrieden.
            </List>
            <List title="Schäden im Ehrenamt">
              Kommt es infolge der Ausübung eines Ehrenamtes zu einem Sach- oder Personenschaden sind
              Sie schadenersatzpflichtig. Sofern der Verein keine Vorsorge getroffen hat, leistet die
              Privathaftpflicht.
            </List>
            <List title="Gebrauch von Drohnen">
              Als Besitzer einer Drohne oder eines anderen Luftfahrzeugs sind Sie verpflichtet, eine
              Versicherung abzuschließen. Sofern gewisse Grenzen hinsichtlich des Gewichts und der
              Antriebsart nicht überschritten werden, besteht Versicherungsschutz über die Haftpflicht.
            </List>
          </div>
        </div>
        <div class="row text-center mt-14">
          <div class="col-12">
            <h3 class="mb-7">
              Wann habe ich keinen Versicherungsschutz?
            </h3>
          </div>
        </div>
        <div class="row mt-2.5">
          <div class="col-12 col-md-6">
            <List>Bei Eigenschäden, die Sie sich selbst oder einer mitversicherten Person zufügen.</List>
            <List>Bei Schäden eines Familienmitglieds, dass im gleichen Haushalt wie Sie wohnt.</List>
            <List>Bei vorsätzlich herbeigeführten Schäden.</List>
          </div>
          <div class="col-12 col-md-6">
            <List>
              Bei Teilnahme an Rennveranstaltungen (z.B. Autorennen), Wettkämpfen (z.B. Boxen) und
              bestimmten Hobbies (z.B. Jagd).
            </List>
            <List>Bei Schäden an gemieteten oder geliehenen Gegenständen (außer gemietete Wohnung).</List>
          </div>
        </div>
        <div class="row mt-7">
          <div class="col-12 col-md-6">
            <NiceToKnow
              :bullets="[{
                title: 'Hinweis:',
                text: 'Diese Aufzählung beinhaltet wesentliche Leistungsausschlüsse und ist nicht ' +
                  'abschließend. Sofern einzelne Leistungspunkte für Sie wichtig sind, prüfen wir gerne ' +
                  'Sondertarife.'
              }]"
            />
          </div>
          <div class="col-12 col-md-6">
            <NiceToKnow
              :bullets="[{
                title: 'Hinweis:',
                text: 'Sind Sie Halter eines Hundes oder Pferdes, benötigen Sie eine Tierhalterhaftpflicht.' +
                  ' Über die Privathaftpflicht besteht kein Versicherungsschutz.'
              }]"
            />
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import DamageExamplesSection from '@/bedarf/components/DamageExamplesSection.vue';
import JumboImage from '@/bedarf/components/JumboImage.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';
import List from '@/application/components/List.vue';

interface Props {
  data: Bedarfsinfo,
}

defineProps<Props>();

const damageExamples = [
  {
    icon: 'bedarfsinfo/haftpflicht/hp1.svg',
    title: 'Sachschaden',
    description: 'Unbeabsichtigt setzen Sie sich auf das Smartphone eines Freundes. Die Haftpflicht übernimmt die '
      + 'Reparaturkosten.',
  },
  {
    icon: 'bedarfsinfo/haftpflicht/hp2.svg',
    title: 'Personenschaden',
    description: 'Beim Fahrradfahren nehmen Sie einem Fußgänger unbeabsichtigt die Vorfahrt und stoßen mit diesem '
      + 'zusammen. Die Privathaftpflicht übernimmt die Behandlungs- und Folgekosten und…',
  },
  {
    icon: 'bedarfsinfo/haftpflicht/hp3.svg',
    title: 'Vermögensschaden',
    description: '… darüber hinaus das Schmerzensgeld sowie die Kosten für den Verdienstausfall der verletzten Person.',
  },
];

</script>
