<template>
  <SpartenWrapper
    image="images/bedarfsinfo/jagdhaftpflicht/jagdhaftpflicht-titelbild"
    text="Ohne Jagdhaftpflichtversicherung, erhalten Sie keinen
          Jagdschein. Sie ist in Deutschland für Jäger - auch
          Berufsjäger und Jagdaufseher - gesetzlich
          vorgeschrieben. Auch Förster, Forstbeamte
          sowie Falkner sollten auf diese Absicherung
          nicht verzichten."
    alt="Nachdenkliche Frau im Stuhl"
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    :versorgungen="data.bedarfsinfo.versorgungen"
    versorgungen-headline="Wie sehr bin ich bei der Jagd versichert?"
    versorgungen-description="Jagdhaftpflicht"
  >
    <section class="section">
      <div class="container">
        <DamageExamplesSection
          class="pb-10"
          :data="damageExamples"
          headline="Wann leistet die Jagdhaftpflichtversicherung?"
          second-headline="Schadenbeispiele - welche Kosten werden erstattet?"
        />

        <div class="row justify-center">
          <div class="col-12 col-md-6">
            <NiceToKnow
              classes="mt-7 mb-0 w-full"
              :bullets="[{
                title: 'Hinweis!',
                text: 'Zusätzlich weist die Versicherung unberechtigte Forderungen ' +
                  'ab und setzt dieses gerichtlich durch (sogenannter passiver Rechtsschutz).',
              }]"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="mb-7">
              <h4 class="mb-5">
                Welche zusätzlichen Leistungen bieten Top-Tarife?
              </h4>
              <List title="Forderungsausfalldeckung">
                Durch Verschulden eines anderen Jägers, erleiden Sie einen Personen- oder Sachschaden,
                den der Verursacher wegen Zahlungsunfähigkeit oder fehlender Versicherung nicht begleichen kann.
                Mit der Forderungsausfalldeckung übernimmt Ihre eigene Versicherung den Schaden.
              </List>
              <List title="Querschlägerrisiko">
                Der Versicherer verzichtet auf den Einwand des fehlenden Verschuldens bei Schusswaffengebrauch.
              </List>
              <List title="Umweltschäden">
                Wenn Sie beim Bau eines Hochsitzes versehentlich Motorsägebenzin verschütten und dadurch ein
                Froschbiotop beschädigt bzw. zerstört wird, leistet der Versicherer.
              </List>
              <List title="Jagdveranstaltungen">
                Auch für Jagdveranstaltungen einschließlich der üblichen Bewirtung besteht Versicherungsschutz.
              </List>
            </div>
            <h4 class="mb-5">
              Wann habe ich keinen Versicherungsschutz?
            </h4>
            <List>
              Bei Vorsatz
            </List>
            <List>
              Bei Eigenschäden oder wenn Ansprüche von Ihren Angehörigen gegen mitversicherte
              Personen geltend gemacht werden.
            </List>
            <List>
              Bei bewusstem Abweichen von gesetzlichen oder behördlichen Vorschriften.
            </List>
          </div>
          <div class="col-12 col-md-6 flex items-center justify-end">
            <img
              src="../../../../../../assets/images/bedarfsinfo/jagdhaftpflicht/schadensfall-jagdhaftpflicht.jpg"
              class="img-fluid mt-7 "
              alt="Ein Ausblick von einem Hochstand. Ein Gewehr liegt schräg an der Außenwand."
            >
          </div>
        </div>
        <div class="row text-center mt-14">
          <div class="col-12">
            <h3 class="mb-7">
              Worauf muss ich im Schadenfall achten?
            </h3>
          </div>
        </div>
        <div class="row mt-2.5">
          <div class="col-12 col-md-6">
            <List>
              Melden Sie den Schaden unverzüglich der Versicherung.
            </List>
            <List>
              Schalten Sie bei Personenschäden bitte immer sofort die Polizei ein.
            </List>
            <List>
              Geben Sie nie ein Schuldanerkenntnis ab, auch wenn die Schuldfrage klar erscheint.
            </List>
          </div>
          <div class="col-12 col-md-6">
            <List>
              Leiten Sie Schriftstücke mit Schadenersatzforderungen umgehend an den Versicherer weiter.
            </List>
            <List>
              Fertigen Sie Fotos der beschädigten Sachen an und bewahren Sie
              diese auf bis der Versicherer den Schaden reguliert hat.
            </List>
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import DamageExamplesSection from '@/bedarf/components/DamageExamplesSection.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';
import List from '@/application/components/List.vue';

interface Props {
  data: Bedarfsinfo,
}

defineProps<Props>();

const damageExamples = [
  {
    icon: 'bedarfsinfo/jagdhaftpflicht/sachschaden.svg',
    title: 'Sachschaden',
    description: 'Unbeabsichtigt stolpern Sie über die Flinte eines Jagdfreundes und beschädigen den Lauf.',
  },
  {
    icon: 'bedarfsinfo/jagdhaftpflicht/personenschaden.svg',
    title: 'Personenschaden',
    description: 'Auf der Jagd verletzen Sie mit der Schusswaffe einen vorbei gehenden Fußgänger. Die Jagdhaftpflicht '
      + 'übernimmt die Behandlungs- und Folgekosten und …',
  },
  {
    icon: 'bedarfsinfo/jagdhaftpflicht/vermoegendsschaden.svg',
    title: 'Vermögensschaden',
    description: '… darüber hinaus das Schmerzensgeld sowie die Kosten für den Verdienstausfall der verletzten Person.',
  },
];

</script>
