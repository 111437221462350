import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 57.4 86.4"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#4181D2",
      d: "M32.5 8.6h24.9v9.5H32.5z"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "17.6",
      cy: "7.1",
      r: "7.1",
      class: "cls-2"
    }, null, -1),
    _createElementVNode("path", {
      d: "m38 17.9-1.4 1.4-3.6 7.6c-5.7-9.3-9-10.3-9.2-10.3l-7-.2s-6.4-1-8 0l-8.3 12L8 37.2c-1.3 17-3.9 40-4.1 42.4-.2.1-3.8 1.7-4 3.4a7.5 7.5 0 0 0 .5 3.2l5.4-1L8.7 83v-3.3l6-31h2.6l7.1 31.2v3.3l2.8 2.3 5.4 1a7.5 7.5 0 0 0 .4-3.2c-.1-1.8-4-3.3-4-3.3a151.7 151.7 0 0 1-4.7-55.7l8 6.5 2.4-.2 4-10c1.8.2 4.5-2.2 4.5-2.2l.2-.4c-.3.4-5.4 0-5.4 0zM8.4 33.2 4.7 28 9 24c0 2.4-.3 5.6-.6 9.2",
      class: "cls-2"
    }, null, -1)
  ])))
}
export default { render: render }