import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 1416 1176"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "701.6",
      cy: "584.7",
      r: "570.1",
      fill: "#4181D2"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "698.7",
      cy: "415.8",
      r: "148.9",
      style: {"fill":"#fff"}
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      "fill-opacity": ".85",
      d: "m435.3 241.4 11.8-13.7 30.1 15.5-15.1 21.9-4.6 5.8 90 45.7 21.6-27.1-66.7-33.9 18.3-26.6-10-4.8v-.1l-41.8-21.6 12.8-14.8-39.4-4.3-39.5-4.2 16.3 31.1zm453.5 15.8 21 24.5 7.3-8.5v.1l31.6-34.9 11 16.1 14.2-37 14.2-37-34.2 7.7-34.2 7.8 10.2 14.9-22.7 25.1-17.3-20.2-4.4-5.9-67.2 75.3 20.7 27.9z"
    }, null, -1),
    _createElementVNode("path", {
      d: "M1123.7 621.2 959.1 456.7a46.72 46.72 0 0 0-66 0 46.72 46.72 0 0 0 0 66l98.6 98.6H891a81.9 81.9 0 0 0-61-27.3H567.4c-19.4 0-37.3 6.8-51.4 18.2H395.1l96.9-133a46.58 46.58 0 0 0-10.3-65.2 46.58 46.58 0 0 0-65.2 10.3l-136.9 188h-.1v67.3c0 11.5 9.3 20.7 20.7 20.7h185.1v208c0 45.1 36.9 82 82 82H830c45.1 0 82-36.9 82-82V709.4h187.3c13.4 0 24.3-10.9 24.3-24.3z",
      style: {"fill":"#fff"}
    }, null, -1)
  ])))
}
export default { render: render }