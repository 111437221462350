<template>
  <div class="row mb-7">
    <div class="col-12">
      <div class="flex">
        <Segment
          :height="incomeToTotal"
          horizontal
          class="text-sm text-light items-center"
        >
          {{ labels.income }}
          <slot name="incomeBar" />
        </Segment>

        <Segment
          :height="1 - incomeToTotal"
          horizontal
          empty
          class="no-padding border border-left-0"
        />
      </div>
    </div>

    <div class="col-12 my-2.5 flex justify-center items-center">
      <font-awesome-icon
        :icon="['fas', 'arrow-down']"
        size="2x"
      />
      <div class="ml-2.5">
        Inflation<br>
        <strong>{{ inflation }}%</strong>
      </div>
    </div>

    <div class="col-12">
      <div class="flex flex-row">
        <slot name="segmentsBar" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import type { PropType } from 'vue';
import { defineComponent, toRef } from 'vue';

import { useRatios } from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/composables/ratios';
import { format } from '@/application/utils/money';

import Segment from './Segment.vue';
import type { Labels } from './types';

export default defineComponent({
  components: {
    FontAwesomeIcon,
    Segment,
  },

  props: {
    labels: {
      type: Object as PropType<Omit<Labels, 'popoverText' | 'monetaryNeed'>>,
      required: true,
    },

    income: {
      type: Number,
      required: true,
    },

    inflation: {
      type: Number,
      required: true,
    },

    monetaryNeed: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { incomeToTotal } = useRatios(
      toRef(props, 'income'),
      toRef(props, 'monetaryNeed'),
    );

    return {
      // computed
      incomeToTotal,

      // methods
      format,
    };
  },
});
</script>

<style lang="scss" scoped>
// because spacing utilities like p-<n> take always precedence over p-0 we need to
// circumentvent this by using the no-padding class. This behaviour is resolved in
// boostrap 5 (see: https://github.com/twbs/bootstrap/issues/27825)

.no-padding {
  padding: 0 !important;
}
</style>
