<template>
  <img :src="base64String">
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { getBase64Image } from "@/api/lib/integration";
interface Props {
  src: string,
}

defineOptions({ inheritAttrs: true });
const props = defineProps<Props>();
const emit = defineEmits(['load', 'error']);
const base64String = ref('');

watch(() => props.src, async (val) => {
  const src = await getBase64Image(val);

  if (!src) {
    emit('error');
    return;
  }

  base64String.value = src;
  emit('load');

}, { immediate: true });
</script>
