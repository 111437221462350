<template>
  <div>
    <div
      v-if="leftLabel || rightLabel"
      class="row justify-between"
    >
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="leftLabel"
        class="col"
      >
        <span
          class="label"
          v-html="leftLabel"
        />
      </div>
      <div
        v-if="rightLabel"
        class="col text-right"
      >
        <span
          class="label"
          v-html="rightLabel"
        />
      </div>
      <!-- eslint-enable vue/no-v-html -->
    </div>

    <div class="row mb-1.5">
      <div class="col">
        <div
          class="column"
        >
          <div
            v-if="$slots.default"
            class="full flex flex-row justify-center"
          >
            <span v-show="!isUpdating">
              <slot />
            </span>
            <span
              v-show="isUpdating"
              class="spinner spinner--white"
            />
          </div>
          <div
            v-if="$slots.stack"
            :style="{ width: `${ratio}%` }"
            :class="{'left-border': !$slots.default}"

            class="half flex flex-row justify-center"
          >
            <span v-show="!isUpdating">
              <slot name="stack" />
            </span>
            <span
              v-show="isUpdating"
              class="spinner"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    leftLabel: {
      type: String,
      default: '',
    },
    rightLabel: {
      type: String,
      default: '',
    },
    ratio: {
      type: Number,
      default: 0,
    },
    isUpdating: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>

  $blue-light: #D8E7F7;
  $blue: #4181D2;
  $white: #FFF;

  .column {
    display: flex;
    width: 100%;

    .half,
    .full {
      font-size: 0.875rem;
      margin: 10px 0;
      min-height: 10px;
      text-align: center;
      transition: width 125ms ease-in-out;
      vertical-align: middle;
      white-space: nowrap;
    }

    .half {
      background-color: $blue-light;
      border: 2px dashed $blue;
      border-left-width: 0;
      padding: 5px;
      width: 60px;
    }

    .left-border {
      border-left-width: 2px !important;
    }

    .full {
      background-color: $blue;
      color: $white;
      flex: 1 0 auto;
      padding: 7px 5px;
    }
  }

  .label {
    font-size: 0.875rem;
  }

</style>
