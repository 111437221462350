import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 1416 1176"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "719.9",
      cy: "585.3",
      r: "572.7",
      fill: "#4181D2"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "673.6",
      cy: "231.7",
      r: "92.4",
      style: {"fill":"#fff"}
    }, null, -1),
    _createElementVNode("path", {
      d: "m1056.9 915.1-79.7-258.2c-1.1-7.1-3.9-13.6-7.9-19.2a44.98 44.98 0 0 0-47.2-18.7H716.8V394.4c0-30.3-24.8-55.1-55.1-55.1s-55.1 24.8-55.1 55.1V652c0 7.7 1.6 15.1 4.5 21.8 4.6 20 22.5 35 43.8 35h244.3l71.9 232.8c7.3 23.6 32.6 37 56.2 29.7 23.6-7.4 36.9-32.6 29.6-56.2",
      style: {"fill":"#fff"}
    }, null, -1),
    _createElementVNode("path", {
      d: "M721.5 555c.3 11.6 10.1 20.9 21.7 20.6l139.1-3.8c11.6-.3 20.9-10.1 20.6-21.7a21.26 21.26 0 0 0-21.7-20.6L742 533.3c-11.6.3-20.9 10.1-20.5 21.7m155.1 161.4c-25.1-.1-32.2-.1-70.6.6-.4 0-.7.3-.7.7 0 .7 0 1.1.1 2 .3 3.8.6 7.6.7 11.5 2.6 102.7-83.3 186.6-185.9 181.5-93.7-4.6-168.3-82-168.3-176.9 0-86.9 62.5-159.1 145.1-174.2.9-.2.1 0 1-.2.5-.1.6-.5.6-.6s-.4-47.1-.4-71.4h-.2c-134 16.8-234.9 140.3-214.6 282.8 14.3 100.5 109.4 195.3 209.9 209.3 153.1 21.4 284.1-96.9 284.1-245.8 0-5.8-.2-11.6-.6-17.3 0-.5-.1-1.3-.2-2",
      style: {"fill":"#fff"}
    }, null, -1)
  ])))
}
export default { render: render }