import type { RouteLocationNormalized, RouteLocationRaw } from "vue-router";
import type { Override } from "@/checkup/types/types";
import { getStepIndex, Step, StepOrder } from "@/checkup/utils/steps";
import {
  checkupStartDate,
  checkupState,
  currentStep,
  flattenedCheckupDataBefore,
  handleValidationError,
  isRouting,
  resetErrors,
  resetStepErrors,
} from "@/checkup/state";
import { trackEvent } from "@/application/utils/matomo";
import { TrackEventCategory } from "@/application/utils/matomo/events";
import { captureException } from '@sentry/vue';
import { abschluss, logCheckup, postSessionData } from "@/api/checkup/checkupApi";
import { call } from "@/api/lib/integration";
import { differenceInSeconds } from 'date-fns';
import { flattenCheckupState } from '@/checkup/utils/flatten';
import { scrollAppMainTop } from "@/application/utils/scrolling";
import { NotificationVariant, useNotification } from "@demvsystems/design-components";

export async function checkupStepGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
): Promise<boolean | RouteLocationRaw> {
  const { name: fromName } = from as Override<RouteLocationNormalized, { name: string }>;
  const { name: toName } = to as Override<RouteLocationNormalized, { name: string }>;
  const fromStep = fromName != null ? Step[fromName.toUpperCase() as keyof typeof Step] : null;
  const toStep = toName !== 'Bestaetigung' ? Step[toName.toUpperCase() as keyof typeof Step] : null;

  isRouting.value = true;

  function trackCheckup(action: string, name: string, value?: number) {
    trackEvent({
      category: TrackEventCategory.CHECKUP,
      action,
      name,
      value,
    });
  }

  // tracks how often checkup is started and sets timestamp for start.
  // Ignores, if Grunddaten is visited again after checkup already started
  if (toName === Step.GRUNDDATEN && !fromStep) {
    checkupStartDate.value = new Date();
    trackCheckup('Start', toName)
  }

  if (fromStep === Step.ABSCHLUSS && toName === 'Bestaetigung') {

    const flattenedCheckupDataAfter = flattenCheckupState(checkupState)

    if (checkupState[Step.ABSCHLUSS].value.hatAgbZugestimmt) {
      await call(logCheckup({
          data_before: flattenedCheckupDataBefore,
          data_after: flattenedCheckupDataAfter,
          version: 1 // hardcoded checkup version. Increment when changing checkup data structure
        }),
        () => {
        },
        (error) => {
          captureException(error)
        }
      );
    }

    return await call(
      abschluss({
        hatAgbZugestimmt: checkupState[Step.ABSCHLUSS].value.hatAgbZugestimmt,
        anmerkungen: checkupState[Step.ABSCHLUSS].value.anmerkungen
      }),
      () => {
        const endDate = new Date();
        const checkupTimeInSeconds = checkupStartDate.value ? differenceInSeconds(endDate, checkupStartDate.value) : undefined;
        trackCheckup('Abschicken', `click-${toName}`, checkupTimeInSeconds)
      },
      (error) => {
        if (!handleValidationError(Step.ABSCHLUSS, error)) {
          captureException(error, {
            extra: checkupState,
          });
        }
        useNotification().show('Ein unerwarteter Fehler ist aufgetreten.', NotificationVariant.Error);
        trackCheckup('Abschicken', `fehler-in-${fromName}`);
        isRouting.value = false;
      }
    )
  }

  if (!toStep) {
    resetErrors();
    return { name: StepOrder[0] };
  }

  if (fromStep == null) {
    return true;
  }

  if (getStepIndex(toStep) > getStepIndex(fromStep)) {
    // forwards

    return await call(
      postSessionData({ [fromName]: checkupState[fromName].value }),
      (data) => {
        resetStepErrors(fromName);
        currentStep.value = data.currentStep;
        scrollAppMainTop();

        // trackCheckup only if toStep is directly the next step to prevent tracking later steps
        // more often than previous steps (can occur if the user does not navigate back and forth through the checkup
        // via the "weiter" button, but via the menu bar)
        if (getStepIndex(toStep) === getStepIndex(fromStep) + 1) {
          trackCheckup('Weiter', `click-${toName}`);
        }
      },
      (error) => {
        if (!handleValidationError(fromName, error)) {
          captureException(error, {
            extra: checkupState,
          });
        }
        trackCheckup('Weiter', `fehler-in-${fromName}`);
        isRouting.value = false;
      });
  } else {
    // backwards
    trackCheckup('Zurück', toName);
    return true;
  }
}
