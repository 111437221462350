<template>
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
          <div class="text-center pb-7">
            <h2>Maßnahmen, um Beitragssteigerungen zu verringern</h2>
          </div>
        </div>
      </div>
    </div>

    <Tabs
      has-small-buttons
      initial-tab="Steigende Gesundheitskosten"
    >
      <Tab name="Steigende Gesundheitskosten">
        <p><b>Beitragsentwicklung</b></p>
        <p>
          Beitragssteigerungen in der Krankenversicherung sind aufgrund der
          steigenden Kosten im Gesundheitswesen vorprogrammiert. Ursache sind teurere
          Medikamente, immer aufwendigere Therapien und eine älter werdende Gesellschaft
          mit entsprechend hohem ärztlichen Versorgungsaufwand.
        </p>
        <p class="mt-2.5">
          <b>Inflationsrate im Vergleich zu durchschnittlichen Kostensteigerungen im Gesundheitswesen</b>
        </p>
        <div style="max-width: 570px;">
          <div class="svg-abs svg-abs--medizinische-inflation mb-5">
            <SvgVue icon="bedarfsinfo/medizinische-inflation.svg" />
          </div>
        </div>
        <p class="mb-0">
          Laut deutscher Aktuarvereinigung lag die durchschnittliche
          Beitragssteigerung zwischen 2000 und 2016 in der PKV bei 3,3 % pro Jahr und
          in der GKV bei 3,1 % pro Jahr.
        </p>
      </Tab>

      <Tab name="Tarifwechsel">
        <p><b>Tarifwechsel</b></p>
        <p>
          Jeder Versicherte kann bei seinem Krankenversicherer einen Wechsel in einen
          günstigeren, gleichartigen Tarif verlangen (§ 204 VVG). Gebildete
          Altersrückstellungen werden in vollem Umfang mitgenommen.
        </p>
        <div class="row">
          <div class="col-12 col-md-6 md:pr-3 flex justify-center md:justify-start">
            <div
              class="mb-5 self-center w-full hidden md:block"
              style="max-width: 340px;"
            >
              <div class="svg-abs svg-abs--tarifwechsel">
                <SvgVue icon="bedarfsinfo/tarifwechsel.svg" />
              </div>
            </div>
            <div
              class="mb-5 self-center w-full md:hidden"
              style="max-width: 220px;"
            >
              <div class="svg-abs svg-abs--tarifwechsel-mobile">
                <SvgVue icon="bedarfsinfo/tarifwechsel-mobile.svg" />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <template
              v-for="({title, items}, i) in tariffChange"
              :key="title"
            >
              <p :class="{'pt-5': i > 0}">
                {{ title }}
              </p>
              <BulletPointList :items="items" />
            </template>
          </div>
        </div>
      </Tab>

      <Tab name="Demografierücklage">
        <div class="row">
          <div class="col-12 col-md-6">
            <p><b>Demografierücklage</b></p>
            <p>
              Seit dem Jahr 2000 bilden Krankenversicherer eine Demografierücklage, die
              Altersrückstellungen. Rechnerisch könnten damit alle Leistungen nach
              heutigem Umfang über 8,9 Jahre finanziert werden.
            </p>
            <p>
              Die Rücklage in der GKV beträgt zum selben Zeitraum hingegen nur 25 MRD
              EUR und somit ca. 1,5 Monatsausgaben.
            </p>
          </div>
          <div class="col-12 col-md-6">
            <p><b>Finanzierbarkeit der Leistungen auf heutigen Niveau aus Rücklagen.</b></p>
            <div
              style="max-width: 400px"
              class="mx-auto"
            >
              <div class="svg-abs svg-abs--demografieruecklage">
                <SvgVue icon="bedarfsinfo/demografieruecklage.svg" />
              </div>
            </div>
          </div>
        </div>
      </Tab>

      <Tab name="Beitragsentwicklung GKV">
        <p><b>Beitragsentwicklung der gesetzlichen Krankenkasse</b></p>
        <p>Veränderung des Höchstbetrages der GKV seit 1970</p>
        <div
          style="max-width: 485px"
          class="mx-auto md:mx-0"
        >
          <div class="svg-abs svg-abs--beitragsentwicklung-gkv">
            <SvgVue icon="bedarfsinfo/beitragsentwicklung.svg" />
          </div>
        </div>
      </Tab>

      <Tab name="Beitragsentlastungstarif">
        <p><b>Beitragsentlastungstarif</b></p>
        <p>
          Eine weitere Möglichkeit, um im Rentenalter einen möglichst geringen
          Krankenversicherungsbeitrag tragen zu müssen, sind Beitragsentlastungstarife.
          Dabei zahlen Sie bis zum Rentenalter einen erhöhten Beitrag an Ihre
          Krankenversicherung:
        </p>
        <div class="row mb-5">
          <div class="col-12 col-xl-7">
            <div
              style="max-width: 485px"
              class="mx-auto"
            >
              <div class="svg-abs svg-abs--beitragsentlastung">
                <SvgVue icon="bedarfsinfo/beitragsentlastung.svg" />
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-5">
            <BulletPointList :items="beitragsentlastungstarif" />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-xl-6">
            <NiceToKnow :bullets="niceToKnowChange" />
          </div>
          <div class="col-12 col-xl-6">
            <NiceToKnow :bullets="niceToKnowSavings" />
          </div>
        </div>
      </Tab>

      <Tab name="Sonstiges">
        <template
          v-for="{ title, text } in additionalInfo"
          :key="title"
        >
          <p><b>{{ title }}</b></p>
          <BulletPoint :item="{ text }" />
        </template>
      </Tab>
    </Tabs>
  </section>
</template>

<script setup lang="ts">
import BulletPoint from '@/bedarf/components/BulletPoint.vue';
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import Tab from '@/application/components/Tab.vue';
import Tabs from '@/application/components/Tabs.vue';
import SvgVue from "@/application/components/SvgVue";

const oldTariff = [
  {
    icon: 'minus',
    text: 'steigendes Alter der Versichertengemeinschaft',
  },
  {
    icon: 'minus',
    text: 'häufigere Erkrankungen der Versicherten',
  },
  {
    icon: 'minus',
    text: 'höhere Kosten für Behandlungen',
  },
  {
    icon: 'minus',
    text: 'höhere Beiträge',
  },
];

const newTariff = [
  {
    icon: 'plus',
    text: 'niedriges Durchschnittsalter',
  },
  {
    icon: 'plus',
    text: 'weniger Erkrankungen',
  },
  {
    icon: 'plus',
    text: 'niedrigere Kosten für Behandlungen',
  },
  {
    icon: 'plus',
    text: 'Niedrigere Beiträge',
  },
];

const tariffChange = [
  { title: 'Alttarif', items: oldTariff },
  { title: 'Neutarif', items: newTariff },
];

const beitragsentlastungstarif = [
  {
    title: 'Vorteile',
    icon: 'plus',
    text: `Sofern Arbeitnehmer den Höchstzuschuss des Arbeitgebers noch nicht ausgeschöpft haben, zahlt dieser 50
            Prozent der Beiträge`,
  },
  {
    icon: 'plus',
    text: 'Die Anlage wird in der Ansparphase verzinslich angelegt und es entfällt die Abgeltungssteuer.',
  },
  {
    icon: 'plus',
    text: `Da es sich um eine Beitragsentlastung zu Rentenbeginn handelt und nicht um eine Auszahlung, ist diese
            steuerfrei.`,
  },
];

const niceToKnowChange = [
  {
    title: 'Hinweis:',
    text: `Bei einem Wechsel zu einer andern Krankenversicherung, kann die angesparte Summe aus dem
            Beitragsentlastungstarif nicht mitgenommen werden. Wer ﬂexibel bleiben möchte, kann alternativ eine private
            Altersvorsorge aufbauen.`,
  },
];

const niceToKnowSavings = [
  {
    title: 'Tipp:',
    text: `Um die Kosten zwischen gesetzlichem und privatem Krankenversicherungssystem zu vergleichen, müssen die Phase
            der Erwerbstätigkeit und die Rentenphase betrachtet werden. Die Ersparnis aus einem Wechsel sollte für die
            Altersvorsorge gespart werden.`,
  },
];

const additionalInfo = [
  {
    title: 'Gesetzlicher Zuschlag',
    text: `Zwischen dem 22. und 60. Lebensjahr erheben die privaten Assekuranzen einen gesetzlichen Zuschlag in Höhe von
            10 Prozent auf den Krankheitskostentarif. Dieser Betrag wird als zusätzliche Altersrückstellung gebildet, um
            Kostensteigerungen ab dem 65. Lebensjahr zu mildern.`,
  },
  {
    title: 'Basistarif',
    text: `Es kann ohne Gesundheitsprüfung in den Basistarif gewechselt werden. Dieser ist in Art, Umfang und Höhe
            vergleichbar mit der GKV (§ 193 Abs. 5 VVG). Der Höchstbeitrag im Basistarif ist dabei auf den Höchstbeitrag
            in der GKV begrenzt, zuzüglich des durchschnittlichen Zusatzbeitrages.`,
  },
  {
    title: 'Hilfsbedürftigkeit',
    text: `Ist der Versicherte im Sinne des  § 9 SGB II hilfsbedürftig, reduziert sich der Beitrag im Basistarif um die
            Hälfte. Zusätzlich kann sich der Träger der Grundsicherung am verminderten Beitrag beteiligen.`,
  },
];
</script>
