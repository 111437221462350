import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 33 36"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "none",
      stroke: "#000",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M7 5.7.5 8.9l2.55 4.47 4.26-1.97 6.6 11.9 9.81-6.76-7.11-9.4 3.32-2.03L16.3.5 11 3.62s-.3 3.11-4 2.08m14.55 14.14 10.28.32-.41 14.86-5.93-.21-.62-4.99-2.18 4.26-5.2-1.87z"
    }, null, -1)
  ])))
}
export default { render: render }