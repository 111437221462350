<template>
  <DsCard>
    <div
      class="-mx-4 -mt-4 h-[5px] rounded-t-lg bg-blue-300 shrink-0"
    />
    <h5 class="font-semibold mt-4 mb-2.5">
      {{ name }}
    </h5>
    <ul
      v-for="(entry, idx) in entries"
      :key="idx"
      class="pl-0 my-0 list-none"
    >
      <li :class="{'border-b border-gray-100': idx !== entries.length - 1}">
        <AccordionItem
          :id="entry.name"
          :img="entry.img"
          :text="entry.text"
          :name="entry.name"
          :img-alt="entry.imgAlt"
          :headline="entry.headline"
          :table-entries="entry.tableEntries"
          :open="isVisible(entry.name)"
        />
      </li>
    </ul>
  </DsCard>
</template>

<script setup lang="ts">
import { DsCard } from '@demvsystems/design-components';
import AccordionItem from './AccordionItem.vue';
import type { AccordionEntry } from "@/bedarf/components/altersvorsorge/altersvorsorge/finder/types";

interface Props {
  name: string,
  entries: AccordionEntry[],
  visibleAccordions: string[],
}

const props = defineProps<Props>();

function isVisible(name: string) {
  return props.visibleAccordions.includes(name);
}
</script>
