export async function cleanUpServiceWorker () {
  if (navigator.serviceWorker) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    registrations.forEach((registration) => {
      try {
          registration.unregister();
      } catch (error) {
         
        console.error(error);
      }
    });
  }
}
