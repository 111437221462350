import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  "fill-rule": "evenodd",
  "stroke-linejoin": "round",
  "stroke-miterlimit": "2",
  "clip-rule": "evenodd",
  viewBox: "0 0 100 100"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"50\" cy=\"50\" r=\"50\" fill=\"#fff\"></circle><path fill=\"#343a40\" d=\"M72.72 29.43a15 15 0 0 0-15-15H41.69a15 15 0 0 0-15 15v12.7h46.03z\"></path><path fill=\"#fff\" d=\"M66.93 32.62c0-6.2-5.02-11.24-11.23-11.24h-12c-6.2 0-11.23 5.03-11.23 11.24v9.5h34.46z\"></path><path fill=\"#343a40\" d=\"M79.3 48c0-3.8-3.09-6.88-6.89-6.88H27.6a6.89 6.89 0 0 0-6.89 6.89v30.68a6.9 6.9 0 0 0 6.89 6.89h44.8a6.9 6.9 0 0 0 6.89-6.9V48.02Z\"></path><path fill=\"#fff\" d=\"m48.42 47.3-5.35 18.21 7.69-3.91-1.55 11.55-1.47-1.25 1.24 7.5 4.29-6.22-2.1.66 4.67-17.16-8.54 4.54 6.23-13.8z\"></path>", 5)
  ])))
}
export default { render: render }