import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "33",
  height: "32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#00897B",
      "fill-rule": "nonzero",
      d: "M28.221 1.548a179 179 0 0 1-11.017 17.828 137 137 0 0 1-6.118 7.996c-.089.069-6.124-8.296-6.59-9.075-1.25-2.24-4.892-.681-3.642 1.559 1.964 3.612 4.433 7.687 7.719 10.337 2.781 2.187 4.626.889 6.527-1.526 6.513-7.948 12.067-16.575 16.868-25.616C33.129.786 29.46-.708 28.22 1.548z"
    }, null, -1)
  ])))
}
export default { render: render }