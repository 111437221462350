<template>
  <div class="rounded bg-gray-100 px-3 py-2 my-4">
    <span class="text-sm font-semibold">Passwortvoraussetzungen:</span>
    <ul class="mt-2">
      <li
        v-for="{label, fulfilled} in passwordConditions"
        :key="label"
      >
        <div class="flex flex-row items-center gap-3">
          <div class="shrink-0 flex items-center justify-center h-6 rounded-full">
            <DsIcon
              variant="regular"
              :name="fulfilled ? 'check' : 'times'"
              :class="fulfilled ? 'text-green-700' : 'text-red-700'"
            />
          </div>
          {{ label }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">

import { DsIcon } from '@demvsystems/design-components';
import { computed, toRefs } from 'vue';

import { usePasswordCriteria } from '@/authentication/composables/passwordCriteria';

interface Props {
  password: string;
}

const props = defineProps<Props>();

const { password } = toRefs(props);

const {
  hasMixedCase,
  hasNumbers,
  hasSpecialChars,
  hasMinLength,
} = usePasswordCriteria(password);

const passwordConditions = computed(() => [
  { label: 'Mindestens ein Groß- und Kleinbuchstabe', fulfilled: hasMixedCase.value },
  { label: 'Mindestens eine Zahl', fulfilled: hasNumbers.value },
  { label: 'Mindestens ein Sonderzeichen', fulfilled: hasSpecialChars.value },
  { label: 'Mindestens 8 Zeichen', fulfilled: hasMinLength.value },
]);
</script>
