<template>
  <div>
    <mobile
      v-bind="childProps"
      class="block lg:hidden"
    >
      <MoneyInput
        v-model="income"
        size="lg"
      />
    </mobile>

    <desktop
      v-bind="childProps"
      class="hidden lg:block"
    >
      <MoneyInput
        v-model="income"
        class="text-gray-800"
      />
    </desktop>
  </div>
</template>

<script>
import { debounce } from 'lodash-es';
import { useRequest } from "@/api/lib/integration";
import { getErwerbsminderungsrente, getKrankentageGeld } from '@/api/rechner/rechnerApi';
import MoneyInput from '@/application/components/form-elements/MoneyInput/MoneyInput.vue';
import Desktop from './desktop.vue';
import Mobile from './mobile.vue';

export default {
  components: {
    MoneyInput,
    Mobile,
    Desktop,
  },

  props: {
    moneyInput: {
      type: Number,
      default: null,
    },
    isFreierBeruf: {
      type: Boolean,
      default: false,
    },
    isSelbststaendig: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      income: null,
      moneyMonthly: 0,
      krankentagegeld: 0,
      erwerbsminderungsrenteVoll: 0,
      erwerbsminderungsrenteHalb: 0,
      isUpdating: false,
    };
  },

  computed: {
    showLohnfortzahlung() {
      return !this.isSelbststaendig;
    },

    showErwerbsminderungsrente() {
      return !this.isFreierBeruf;
    },

    childProps() {
      const money = this.moneyMonthly;
      return {
        money,
        krankentagegeld: this.krankentagegeld,
        krankentagegeldLuecke: Math.max(0, money - this.krankentagegeld),
        erwerbsminderungsrenteVoll: this.erwerbsminderungsrenteVoll,
        erwerbsminderungsrenteVollLuecke: Math.max(0, money - this.erwerbsminderungsrenteVoll),
        erwerbsminderungsrenteHalb: this.erwerbsminderungsrenteHalb,
        erwerbsminderungsrenteHalbLuecke: Math.max(0, money - this.erwerbsminderungsrenteHalb),
        isUpdating: this.isUpdating,
        showLohnfortzahlung: this.showLohnfortzahlung,
        showErwerbsminderungsrente: this.showErwerbsminderungsrente,
      };
    },
  },

  watch: {
    income: debounce(function update() {
      this.updateCalculation();
    }, 400),
  },

  created() {
    this.income = this.moneyInput ?? 0;
  },

  methods: {
    async updateCalculation() {

      this.isUpdating = true;

      const apiKrankentagegeld = useRequest(getKrankentageGeld, (data) => {
        this.krankentagegeld = data.result / 12;
      })

      const apiErwerbsminderungsrenteVoll = useRequest(getErwerbsminderungsrente, (data) => {
        this.erwerbsminderungsrenteVoll = data.result / 12;
      })

      const apiErwerbsminderungsrenteHalb = useRequest(getErwerbsminderungsrente, (data) => {
        this.erwerbsminderungsrenteHalb = data.result / 12;
      })

      await Promise.all([
        apiKrankentagegeld.call({ nettojahreseinkommen: this.income * 12 }),
        apiErwerbsminderungsrenteVoll.call({ gehalt: this.income * 12, type: 'voll', gehaltIstNetto: 'true' }),
        apiErwerbsminderungsrenteHalb.call({ gehalt: this.income * 12, type: 'halb', gehaltIstNetto: 'true' }),
      ]);

      this.moneyMonthly = this.income;
      this.isUpdating = false;
    },
  },
};
</script>
