import type { MappingProductIds, Product } from "@/api/common/types";

export function getShortSparteName(name = ''): string {
  const segments = name.split('->');
  if (!segments || segments.length === 0) return '';
  return segments[segments.length - 1].split(' - ')[0];
}

const sparteName: Record<MappingProductIds, string> = {
  284: 'Private Krankenzusatzversicherung - Ambulant',
  285: 'Private Krankenzusatzversicherung - Stationär',
  286: 'Private Krankenzusatzversicherung - Zahn',
  139: 'Riester-Rente - klassisch',
  140: 'Privatvorsorge - klassisch',
  141: 'Rürup-Rente - klassisch',
  142: 'Riester-Rente - Hybrid',
  143: 'Rürup-Rente - Hybrid',
  144: 'Riester-Rente - fondsgebunden',
  145: 'Rürup-Rente - fondsgebunden',
  146: 'Privatvorsorge - Hybrid',
  147: 'Privatvorsorge - fondsgebunden',
  153: 'Berufsunfähigkeitsversicherung - Selbständig',
  154: 'Berufsunfähigkeitsversicherung - Zusatzversicherung',
  213: 'Betriebliche Altersvorsorge',
  219: 'Betriebliche Altersvorsorge - klassisch',
  220: 'Betriebliche Altersvorsorge - Hybrid',
  222: 'Pensionskasse - klassisch',
  223: 'Pensionskasse - Hybrid',
  224: 'Pensionskasse - fondsgebunden',
  564: 'Risikolebensversicherung - Todesfallleistung fallend',
  565: 'Risikolebensversicherung - Todesfallleistung steigend',
  566: 'Risikolebensversicherung - verbundene Leben',
  567: 'Risikolebensversicherung - ohne Gesundheitsfragen',
  569: 'Risikolebensversicherung - Todesfallleistung konstant',
  281: 'KFZ-Versicherung - Schutzbrief',
  406: 'KFZ-Versicherung - LKW',
  409: 'KFZ-Versicherung - KFZ-Anhänger',
  410: 'KFZ-Versicherung - Oldtimer',
  411: 'KFZ-Versicherung - Wohnmobil',
  412: 'KFZ-Versicherung - Motorrad',
  413: 'KFZ-Versicherung - Automobilclub',
  418: 'KFZ-Versicherung - PKW',
  423: 'KFZ-Versicherung - Schlepper / Zugmaschine',
  433: 'KFZ-Versicherung - Quad',
  436: 'KFZ-Versicherung - Trike',
  440: 'KFZ-Versicherung - Wohnwagen',
  495: 'KFZ-Versicherung - GAP-Deckung',
  506: 'KFZ-Versicherung - Kfz-Handel- und Handwerk',
  531: 'KFZ-Versicherung - Lieferwagen',
  557: 'KFZ-Versicherung - Rückrufkosten',
  559: 'KFZ-Versicherung - Anhänger',
  577: 'KFZ-Versicherung - Elektrokleinstfahrzeug',
  584: 'KFZ-Versicherung - Selbstfahrende Arbeitsmaschine',
  593: 'KFZ-Versicherung - Landwirtschaftliche Zugmaschine',
};

export function spartenMapper(product: Product): string {

  //Todo: remove when updating Product type
  if (!product) {
    return 'keine Produktsparte'
  }

  if (sparteName[product.id]) {
    return sparteName[product.id];
  } else {
    return getShortSparteName(product.name);
  }
}
