<template>
  <SpartenHeader
    :image="image"
    :text="text"
    :alt="alt"
  />
  <slot />
  <SpartenPagination />
  <Beratungsinhalte
    :dokumente="dokumente"
    :versorgungen="versorgungen"
    :links="links"
    :headline="versorgungenHeadline"
    :description="versorgungenDescription"
  />
</template>

<script setup lang="ts">
import { dom } from '@fortawesome/fontawesome-svg-core';
import { onMounted, onUpdated } from 'vue';

import Beratungsinhalte from '@/bedarf/components/Beratungsinhalte.vue';
import SpartenHeader from '@/bedarf/components/SpartenHeader.vue';
import SpartenPagination from '@/bedarf/components/SpartenPagination.vue';
import type { Dokument, Link, Versorgung } from '@/bedarf/types/dtos';

interface Props {
  text: string,
  image: string,
  alt?: string,
  versorgungenHeadline?: string,
  versorgungenDescription?: string,
  versorgungen?: Versorgung[]
  dokumente?: Dokument[],
  links?: Link[],
}

withDefaults(defineProps<Props>(), {
  alt: '',
  versorgungenHeadline: undefined,
  versorgungenDescription: undefined,
  links: () => [],
  dokumente: () => [],
  versorgungen: () => [],
});

onMounted(() => dom.i2svg());
onUpdated(() => dom.i2svg());
</script>
