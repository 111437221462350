<template>
  <SwitchGroup>
    <div class="inline-flex items-center">
      <SwitchLabel class="text-gray-900 mr-2 grow">
        <slot />
      </SwitchLabel>

      <Switch
        :model-value="modelValue"
        :class="{
          'bg-blue-500': modelValue && !disabled,
          'bg-gray-300': !modelValue && !disabled,
          'bg-gray-200': modelValue && disabled,
          'bg-gray-100': !modelValue && disabled,
          'pointer-events-none': disabled,
          'ring-offset-2 ring-2 ring-blue-500 outline-none': focus,
          'h-[28px] w-[56px]': true,
        }"
        :tabindex="disabled ? -1 : 0"
        class="
          flex-none border-2 border-transparent rounded-full cursor-pointer
          transition-colors ease-in-out duration-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue/75
          relative inline-flex shrink-0
        "
        :disabled="disabled"
        @update:model-value="emit('update:modelValue', $event)"
        @keydown.enter="emit('update:modelValue', !modelValue)"
        @focus="focus = true"
        @blur="focus = false"
      >
        <div
          aria-hidden="true"
          :class="{
            'bg-blue-100': modelValue && !disabled,
            'bg-gray-100': modelValue && disabled,
            'bg-gray-700': !modelValue && !disabled,
            'bg-gray-300': !modelValue && disabled,
            'translate-x-7': modelValue,
            'translate-x-0': !modelValue,
            'h-[24px] w-[24px]': true,
          }"
          class="rounded-full ring-0 transition ease-in-out duration-100 pointer-events-none inline-block transform bg-white shadow-lg"
        />
      </Switch>
    </div>
  </SwitchGroup>
</template>

<script lang="ts" setup>
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue';
import { withDefaults, ref } from 'vue';

interface Props {
  modelValue: boolean,
  disabled?: boolean,
}

withDefaults(defineProps<Props>(), { disabled: false });
const emit = defineEmits(['update:modelValue']);

const focus = ref(false);
</script>
