/*
originale wahrheitstablelle, sollte einsehbar bleiben

const truth;
truth[PKV_UNTER_JAEG     ][PKV_UNTER_JAEG     ] = status_PKV;
truth[PKV_UNTER_JAEG     ][PKV_UEBER_JAEG     ] = status_PKV; // gibts nicht
truth[PKV_UNTER_JAEG     ][BEIHILFE_UNTER_JAEG] = status_PKV;
truth[PKV_UNTER_JAEG     ][BEIHILFE_UEBER_JAEG] = status_PKV;
truth[PKV_UNTER_JAEG     ][GKV                ] = status_GKV;

truth[PKV_UEBER_JAEG     ][PKV_UNTER_JAEG     ] = status_PKV;
truth[PKV_UEBER_JAEG     ][PKV_UEBER_JAEG     ] = status_PKV;
truth[PKV_UEBER_JAEG     ][BEIHILFE_UNTER_JAEG] = status_PKV;
truth[PKV_UEBER_JAEG     ][BEIHILFE_UEBER_JAEG] = status_PKV;
truth[PKV_UEBER_JAEG     ][GKV                ] = status_PKV;

truth[BEIHILFE_UNTER_JAEG][PKV_UNTER_JAEG     ] = status_PKV;
truth[BEIHILFE_UNTER_JAEG][PKV_UEBER_JAEG     ] = status_PKV; // gibts nicht
truth[BEIHILFE_UNTER_JAEG][BEIHILFE_UNTER_JAEG] = status_PKV;
truth[BEIHILFE_UNTER_JAEG][BEIHILFE_UEBER_JAEG] = status_PKV;
truth[BEIHILFE_UNTER_JAEG][GKV                ] = status_gkvBeihilfe;

truth[BEIHILFE_UEBER_JAEG][PKV_UNTER_JAEG     ] = status_PKV
truth[BEIHILFE_UEBER_JAEG][PKV_UEBER_JAEG     ] = status_PKV;
truth[BEIHILFE_UEBER_JAEG][BEIHILFE_UNTER_JAEG] = status_PKV;
truth[BEIHILFE_UEBER_JAEG][BEIHILFE_UEBER_JAEG] = status_PKV;

truth[BEIHILFE_UEBER_JAEG][GKV                ] = status_PKV;

truth[GKV                ][PKV_UNTER_JAEG     ] = status_GKV;
truth[GKV                ][PKV_UEBER_JAEG     ] = status_GKV;
truth[GKV                ][BEIHILFE_UNTER_JAEG] = status_GKV;
truth[GKV                ][BEIHILFE_UEBER_JAEG] = status_GKV;
truth[GKV                ][GKV                ] = status_GKV;
*/

export const PKV_UEBER_JAEG = 0;
export const PKV_UNTER_JAEG = 1;
export const BEIHILFE_UNTER_JAEG = 2;
export const BEIHILFE_UEBER_JAEG = 3;
export const GKV = 4;

export const STATES = {
  GKV: {
    text: 'GKV',
    info: '',
    switchIncome: false,
  },
  PKV: {
    text: 'PKV',
    info: '',
    switchIncome: false,
  },
  PKV_SWITCHED: {
    text: 'PKV',
    info: '',
    switchIncome: true,
  },
  GKV_BEIHILFE: {
    text: 'GKV',
    info: 'Es besteht ein Beihilfeanspruch in Höhe von 80%, sofern das Kind in der PKV versichert werden soll.',
    switchIncome: false,
  },
  INVALID: {
    text: '—',
    info: 'Wählen Sie eine gültige Kombination aus.',
    switchIncome: false,
  },
};

const displayNames = {
  [PKV_UEBER_JAEG]: 'PKV über JAEG',
  [PKV_UNTER_JAEG]: 'PKV unter JAEG',
  [BEIHILFE_UNTER_JAEG]: 'Beihilfe unter JAEG',
  [BEIHILFE_UEBER_JAEG]: 'Beihilfe über JAEG',
  [GKV]: 'GKV',
};

export function humanReadableConst(constant) {
  return displayNames[constant];
}

export function determineStatusKind(mehrverdiener, wenigverdiener) {
  if (mehrverdiener === null
    || mehrverdiener === undefined
    || wenigverdiener === null
    || wenigverdiener === undefined) {
    return STATES.INVALID;
  }

  let status = STATES.INVALID;
  switch (mehrverdiener) {
    case PKV_UNTER_JAEG:
      if (wenigverdiener === GKV) {
        status = STATES.GKV;
      } else if (wenigverdiener === PKV_UEBER_JAEG || wenigverdiener === BEIHILFE_UEBER_JAEG) {
        status = STATES.PKV_SWITCHED;
      } else {
        status = STATES.PKV;
      }
      break;

    case PKV_UEBER_JAEG:
      status = STATES.PKV;
      break;

    case BEIHILFE_UNTER_JAEG:
      if (wenigverdiener === GKV) {
        status = STATES.GKV_BEIHILFE;
      } else if (wenigverdiener === PKV_UEBER_JAEG || wenigverdiener === BEIHILFE_UEBER_JAEG) {
        status = STATES.PKV_SWITCHED;
      } else {
        status = STATES.PKV;
      }
      break;

    case BEIHILFE_UEBER_JAEG:
      status = STATES.PKV;
      break;

    case GKV:
      status = STATES.GKV;
      break;

    default:
      status = STATES.INVALID;
  }

  return status;
}
