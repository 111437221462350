import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 75 75"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"37.5\" cy=\"37.5\" r=\"37.5\" fill=\"#fff\"></circle><g clip-path=\"url(#a)\"><path fill=\"#343A40\" fill-rule=\"evenodd\" d=\"M41.56 37.57a4.07 4.07 0 1 0-8.13-.15 4.07 4.07 0 0 0 8.13.15m18.5-4.06-5.58-1a13.1 13.1 0 0 0-1.49-3.44l3.34-4.84a1.11 1.11 0 0 0-.13-1.4l-4.03-3.99a1.2 1.2 0 0 0-1.5-.14l-4.8 3.34a16 16 0 0 0-3.45-1.49l-.94-5.62a1.37 1.37 0 0 0-1.2-.93h-5.7a1.22 1.22 0 0 0-1.14.93l-1.07 5.76c-1.15.3-2.25.76-3.27 1.35l-4.77-3.2a1.05 1.05 0 0 0-1.5.15l-3.99 3.98a1.1 1.1 0 0 0-.14 1.43l3.2 4.7a19.8 19.8 0 0 0-1.35 3.44l-5.62.97a1.11 1.11 0 0 0-.93 1.16v5.76a1.02 1.02 0 0 0 .93 1l5.62 1.15c.31 1.17.81 2.28 1.5 3.29l-3.21 4.67a1.03 1.03 0 0 0 .15 1.49l3.98 4.05a1.18 1.18 0 0 0 1.5.15l4.63-3.2c1.02.57 2.13 1 3.27 1.28l1.07 5.76a1.15 1.15 0 0 0 1.14.93h5.7a1.27 1.27 0 0 0 1.2-.93l1-5.59c1.15-.42 2.35-.78 3.45-1.28l4.7 3.13a1.18 1.18 0 0 0 1.4-.13L56 52.15a1.05 1.05 0 0 0 .2-1.48l-3.25-4.8c.69-1 1.2-2.13 1.52-3.31l5.62-1.15a1.02 1.02 0 0 0 .93-1v-5.74a1.11 1.11 0 0 0-.93-1.14zM37.58 47.29a9.83 9.83 0 1 1 6.88-2.85 9.84 9.84 0 0 1-6.88 2.88z\" clip-rule=\"evenodd\"></path></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M14 14h47v47H14z\"></path></clipPath></defs>", 3)
  ])))
}
export default { render: render }