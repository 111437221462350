import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 100 100"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M50.1.5C22.8.5.7 22.6.7 49.9s22.1 49.4 49.4 49.4 49.4-22.1 49.4-49.4S77.4.5 50.1.5m-15 8.2 5.8.2-.3 6-.5 7.1-6.4.7L37.2 34 26.3 19l9-1.7zm42.6 45.7-3.1-2v26.2H25.3V52.4l-3.1 2-1.8-6.6 29.5-18.9 29.5 18.8z"
    }, null, -1)
  ])))
}
export default { render: render }