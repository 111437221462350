import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 98.89 98.4"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"#fff\" d=\"M57.66.21a48.4 48.4 0 0 1 9.27 28.51A48.65 48.65 0 0 1 8.27 76.33 49.44 49.44 0 1 0 57.66.21M2.75 32.7l16.65.24-2.89-20.84A49.44 49.44 0 0 0 2.75 32.7\"></path><path fill=\"#fff\" d=\"M42.58 0a49.26 49.26 0 0 0-22.44 9.15l3.54 25.54a2 2 0 0 1-2 2.28l-20.13-.29a49.4 49.4 0 0 0 .38 26 37.34 37.34 0 0 0 16.34 3.74A37.62 37.62 0 0 0 42.58 0\"></path><path fill=\"#343a40\" stroke=\"#fff\" stroke-miterlimit=\"10\" d=\"M70.46 27.31S51.63 32 54 47l1.53 5.54 20.62-10.31-10.29 17.46s9.45 1.3 12.3.89 13.91-8.67 10.7-22.41c-2.67-11.41-18.4-10.86-18.4-10.86z\"></path><path fill=\"#343a40\" d=\"m88.77 42.61 3.2-30s-3.8-8.07-6-8.57-6.09 33.89-6.09 33.89z\"></path><path fill=\"#343a40\" d=\"m70 37.72 28.16-6.38a59.5 59.5 0 0 0-1.63-7.7c-.65-2.11-26 4.31-26 4.31z\"></path><circle cx=\"84.72\" cy=\"29.22\" r=\"3.13\" fill=\"#fff\"></circle><g fill=\"none\" stroke=\"#343a40\" stroke-miterlimit=\"10\" stroke-width=\"4\"><rect width=\"7.86\" height=\"13.11\" x=\"43.08\" y=\"47.67\" rx=\"2.58\" transform=\"rotate(-60 47 54.23)\"></rect><rect width=\"7.86\" height=\"13.11\" x=\"53.15\" y=\"55.36\" rx=\"3.25\" transform=\"rotate(-27.78 57.08 61.9)\"></rect><rect width=\"7.86\" height=\"13.11\" x=\"60.21\" y=\"67.86\" rx=\"3.25\" transform=\"rotate(-27.78 64.12 74.4)\"></rect><rect width=\"7.86\" height=\"13.11\" x=\"59.81\" y=\"78.75\" rx=\"3.25\" transform=\"rotate(30 63.74 85.3)\"></rect><rect width=\"7.86\" height=\"13.11\" x=\"31.28\" y=\"51.53\" rx=\"3.25\" transform=\"rotate(30 35.2 58.09)\"></rect><rect width=\"7.86\" height=\"13.11\" x=\"47.91\" y=\"82.18\" rx=\"3.25\" transform=\"rotate(94.74 51.84 88.73)\"></rect><rect width=\"7.86\" height=\"13.11\" x=\"31.77\" y=\"67.61\" rx=\"3.25\" transform=\"rotate(94.74 35.7 74.16)\"></rect><rect width=\"7.86\" height=\"13.11\" x=\"37.93\" y=\"77.84\" rx=\"3.25\" transform=\"rotate(143.72 41.86 84.4)\"></rect></g>", 7)
  ])))
}
export default { render: render }