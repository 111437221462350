import AppCore from "@/application/AppCore.vue";
import { createRouter, createWebHistory } from "vue-router";
import { createPinia } from "pinia";
import { routes } from "@/application/router/routes"
import { authenticateGuard } from "@/application/router/guards/authenticateGuard";
import '../css/app.scss';
import passwordHealth from "@/application/plugins/passwordHealth";
import '@/application/resources/Icons';
import { SvgVue } from "@/application/plugins/svgVue";
import { vaporAssetPlugin } from "@/application/plugins/vapor";
import { setRouter } from "@/api/lib/routerRef";
import { checkupBeforeGuard } from "@/application/router/guards/checkupBeforeGuard";
import { checkupAfterGuard } from "@/application/router/guards/checkupAfterGuard";
import { scrollBehavior } from '@/application/router/utils/scrollBehavior';
import { cleanUpServiceWorker } from "@/application/utils/worker/cleanUpServiceWorker";
import { registerServiceWorker } from "@/application/utils/worker/registerServiceWorker";
import { createApp } from 'vue';
import { initSentry } from "@/application/plugins/sentry";
import VueMatomo from 'vue-matomo';
import type { URLOpenListenerEvent } from '@capacitor/app';
import { App } from '@capacitor/app';
import { routeTrackingGuard } from '@/application/router/guards/routeTrackingGuard';
import {
  isLocalEnv,
  isNativePlatform,
  isProductionEnv,
  isWebPlatform,
  platform,
} from "@/application/utils/envInfo";
import { configureLiveUpdate, liveUpdateSyncAndReload } from "@/application/plugins/liveUpdate";
import { updateDocumentHead } from "@/application/router/guards/updateDocumentHead";
import { configureSecureStorage } from "@/application/plugins/secretStore";
import { performAppUpdate } from "@/application/plugins/appUpdate";
import { useUserStore } from "@/user/store/userStore";
import { addPushNotificationListeners } from "@/application/plugins/pushNotification";
import { SplashScreen } from "@capacitor/splash-screen";
import { Finanzmanager } from "@/application/router/types/types";

async function initApp() {
  await Promise.all([
    performAppUpdate(),
    configureLiveUpdate(),
    configureSecureStorage(),
  ]);

  const pinia = createPinia();
  const router = createRouter({ scrollBehavior, history: createWebHistory(), routes });
  router.beforeEach(authenticateGuard);
  router.beforeEach(checkupBeforeGuard);
  router.beforeEach(routeTrackingGuard)
  router.afterEach(checkupAfterGuard);
  router.afterEach(updateDocumentHead);
  setRouter(router);

  const app = createApp(AppCore);
  initSentry(app, router);

  if (isProductionEnv) {
    app.use(VueMatomo, { host: 'https://analytics.demv-systems.de', siteId: '6' });
    window._paq.push(['enableLinkTracking']);
    window._paq.push(['setCustomDimension', 1, platform]);
    if (isNativePlatform) {
      window._paq.push(['disableCookies']);
    }
  }

  app.use(SvgVue)
  app.use(passwordHealth);
  app.use(router);
  app.use(pinia);
  app.use(vaporAssetPlugin);

  const { checkLockscreenSettings, lockApp, updateBiometry } = useUserStore();

  const promises = Promise.all([
    checkLockscreenSettings(),
    updateBiometry(),
  ]);

  App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
    const parsedUrl = new URL(event.url);
    const query = Object.fromEntries(parsedUrl.searchParams.entries());

    if (parsedUrl.pathname) router
      .push({ path: parsedUrl.pathname, query })
      .then((e) => {
        if (e instanceof Error) router.push({ path: parsedUrl.pathname, query })
      });
  }).then();

  App.addListener('resume', async () => {
    // when closing the app on the logout page, route to login on next app usage
    if (router.currentRoute.value.name === Finanzmanager.LOGOUT) {
      router.push({ name: Finanzmanager.LOGIN }).then()
    }

    await Promise.all([
      updateBiometry(),
      liveUpdateSyncAndReload(),
      performAppUpdate(),
    ]);
  }).then();

  App.addListener('pause', async () => {
    await lockApp();
  }).then();

  app.mount('#app');

  liveUpdateSyncAndReload().then();
  addPushNotificationListeners().then();
  await promises;
  await lockApp();
  SplashScreen.hide().then();

  cleanUpServiceWorker().then(() => {
    if (isWebPlatform && !isLocalEnv) registerServiceWorker();
  });
}

initApp().then();
