import { ref } from 'vue';
import { detectMobile } from '@/application/utils/detectMobile';

export function useDetectMobile(){
  const isMobile = ref(false);
  detectMobile((_isMobile) => {
    isMobile.value = _isMobile;
  });

  return { isMobile };
}
