import { useGet, usePost } from '@/api/lib/integration';
import type { Action } from '@/api/actions/types';

export const getActions = useGet<void, Action[]>('/api/handlungen');

export const getActionById = useGet<{ id: number }, Action>('/api/handlungen/{id}');

export const getActionCount = useGet<void, { count: number }>('/api/handlungen/count');

export const logAction = usePost<{
  actionId: number,
  context: string,
  data: unknown,
}, void>('/api/handlungen/{actionId}/log');

export const submitAction = usePost<{
  actionId: number,
  submit_id: number,
  bausteine: { id: string, selected_id: string }[],
}, Action>('/api/handlungen/{actionId}/submit');

