<template>
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
          <div class="text-center pb-7">
            <h2>Leistungen in der Krankenversicherung</h2>
          </div>
        </div>
      </div>
    </div>

    <Tabs
      initial-tab="Ambulant"
      has-separated-tabs
    >
      <Tab name="Ambulant">
        <Ambulant />
      </Tab>

      <Tab name="Stationär">
        <Behandlung />
        <div class="row">
          <div class="col-xl-10 offset-xl-1">
            <Table />
          </div>
        </div>
      </Tab>

      <Tab name="Zahn">
        <Zahn :value="erstattungssatz" />
      </Tab>

      <Tab name="Krankengeld">
        <div class="text-center mb-7">
          <h2>Was passiert, wenn ich (länger) erkranke?</h2>
        </div>
        <div class="row mb-5 md:mb-14">
          <div class="col-12 col-lg-8 offset-lg-2 pb-14 text-center">
            Im Krankheitsfall ist Ihr Arbeitgeber für die ersten sechs Wochen verpflichtet,
            ihnen den vollen Lohn auszuzahlen. Bei längerer Erkrankung oder im Falle der
            Erwerbsunfähigkeit müssen Sie hingegen deutliche Abschläge hinnehmen.
          </div>
          <div class="col-12 col-lg-10 offset-lg-1">
            <BuChart :money-input="Math.max(einkommen, 0)" />
          </div>
        </div>

        <Krankentagegeld />
      </Tab>
    </Tabs>
  </section>

  <JumboImage image="images/bedarfsinfo/pkv/bandage" />

  <Beitraege />

  <Infos>
    <template #arbeitslosigkeit>
      <div>
        <h4 class="mb-5">
          Arbeitslosigkeit in der PKV
        </h4>
        <div class="row">
          <div class="col-12 col-md-6">
            <BulletPoint
              v-for="text in unemployment"
              :key="text"
              :item="{ text }"
            />
          </div>
          <div class="col-12 col-md-6 mt-2.5 md:mt-0">
            <NiceToKnow
              classes="mb-0"
              :bullets="niceToKnowUnemployment"
            />
          </div>
        </div>
      </div>
    </template>
    <template #wechsel>
      <div>
        <h4 class="mb-5">
          Wechsel zurück in die GKV
        </h4>

        <div class="row">
          <div class="col-12 col-md-6">
            <p>
              Ein Wechsel von der PKV in die GKV ist mit dem Erreichen des 56. Lebensjahres nur noch sehr
              eingeschränkt möglich.
              Für alle Jüngeren bestehen folgende Möglichkeiten:
            </p>

            <BulletPoint
              v-for="text in changingBack"
              :key="text"
              :item="{ text }"
            />
          </div>
          <div class="col-12 col-md-6 mt-2.5 md:mt-0">
            <NiceToKnow
              classes="mb-0"
              :bullets="niceToKnowChange"
            />
          </div>
        </div>
      </div>
    </template>
    <template #beitrag>
      <div>
        <h4 class="mb-5">
          Beitrag PKV zu Rentenbeginn
        </h4>
        <div class="row">
          <div class="col-12 col-lg-8">
            <BulletPoint
              v-for="text in retirement"
              :key="text"
              :item="{ text }"
            />
          </div>
        </div>
      </div>
    </template>
  </Infos>

  <Massnahmen />
</template>

<script setup lang="ts">
import Behandlung from '@/bedarf/components/Behandlung.vue';
import BulletPoint from '@/bedarf/components/BulletPoint.vue';
import JumboImage from '@/bedarf/components/JumboImage.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import BuChart from '@/bedarf/components/einkommenssicherung/berufsunfaehigkeit/chart/index.vue';
import Krankentagegeld from '@/bedarf/components/einkommenssicherung/berufsunfaehigkeit/Krankentagegeld.vue';
import Ambulant from '@/bedarf/components/gesundheitsvorsorge/ambulant/Ambulant.vue';
import Table from '@/bedarf/components/gesundheitsvorsorge/krankenhauszusatz/Table.vue';
import Beitraege from '@/bedarf/components/gesundheitsvorsorge/pkv/Beitraege.vue';
import Infos from '@/bedarf/components/gesundheitsvorsorge/pkv/Infos.vue';
import Massnahmen from '@/bedarf/components/gesundheitsvorsorge/pkv/Massnahmen.vue';
import Zahn from '@/bedarf/components/gesundheitsvorsorge/pkv/Zahn.vue';
import Tab from '@/application/components/Tab.vue';
import Tabs from '@/application/components/Tabs.vue';

interface Props {
  einkommen: number,
  erstattungssatz: number
}

defineProps<Props>();

const JAEG = '69.300';

const unemployment = [
  `Mit Bezug von Arbeitslosengeld (ALG I) beginnt automatisch Versicherungspflicht in der GKV
    (vergleiche § 5 Abs. 1 Nr. 2 SGB V).`,
  `Besteht seit mindestens fünf Jahren eine PKV, kann ein Antrag auf Befreiung von der Versicherungspflicht gestellt
    werden. Es entsteht ein Anspruch auf Zuschuss zur PKV in Höhe der Beiträge für die GKV.`,
  `Ab dem 56. Lebensjahr verbleiben Versicherte in der PKV und ein Wechsel in die GKV ist nur sehr eingeschränkt
    möglich.`,
];

const niceToKnowUnemployment = [
  {
    title: 'Hinweis:',
    text: `Wenn Sie wieder einer Berufstätigkeit nachgehen, bleiben Sie Mitglied der GKV – völlig unabhängig von der
            Dauer der Arbeitslosigkeit. Und wenn Sie freiwillig versichert sind, können Sie mit einer Anwartschaft
            später ganz ohne Gesundheitsfragen zurück in die PKV wechseln.`,
  },
];

const changingBack = [
  `Angestellte mit einem Verdienst unterhalb der Jahresarbeitsentgeldgrenze (JAEG, 2024: ${JAEG}€) werden automatisch in
    der GKV pflichtversichert.`,
  `Selbständige, die im Hauptjob ins  Angestelltenverhältnis wechseln und unterhalb der JAEG verdienen, werden
    automatisch in der GKV pflichtversichert.`,
  'Bei Arbeitslosigkeit werden Sie automatisch in der GKV pflichtversichert.',
];

const niceToKnowChange = [
  {
    title: 'Tipp für Angestellte:',
    text: 'Mit einer betrieblichen Altersvorsorge oder Teilzeit lässt sich das Gehalt unter die JAEG senken.',
  },
  {
    title: 'Tipp für Selbständige:',
    text: 'Selbständige, die keinen Arbeitgeber finden, können das Gewerbe aufgeben und sich arbeitslos melden.',
  },
];

const retirement = [
  `Haben Sie Anspruch auf die gesetzliche Rente, können Sie einen steuerfreien Zuschuss (§3 Nr. 14 EStG) in Höhe von
    rund 50 % des nach der Rente zu bemessenden gesetzlichen Krankenversicherungsbeitrages vom Rentenversicherungsträger
    beantragen (§ 249a SGB V). Dies kompensiert teilweise den Wegfall des Arbeitgeberzuschusses. Die Pflegeversicherung
    ist nicht zuschussfähig.`,
  `Mit dem 61. Lebensjahr reduziert sich der Beitrag zum Krankheitskostentarif um 10 %, da der gesetzliche Zuschlag
  entfällt.`,
  'Zu Rentenbeginn wird kein Krankentagegeld mehr benötigt und die Beiträge entfallen.',
];
</script>
