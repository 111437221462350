import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 93.33 93.33"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "46.67",
      cy: "46.67",
      r: "46.67",
      fill: "#4181D2"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#FFF",
      d: "M83.52 37.65C81.02 26 66.71 23.84 66.71 23.84c-12.46.59-18.07 10.21-19.24 12.49-1.16-2.29-6.78-11.91-19.24-12.49 0 0-14.31 2.16-16.81 13.81 0 0-7.61 24.37 36.16 38.69 43.78-14.32 35.94-38.69 35.94-38.69"
    }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      stroke: "#4181D2",
      "stroke-linecap": "round",
      "stroke-miterlimit": "10",
      "stroke-width": "3",
      d: "m30.62 55.46 3.48-17.5 5.63 13.25 1.34-7.75 5.71 19 4.2-14.25 3.18 4.12 4.05-8 4.55 7.13 5.36-2.63"
    }, null, -1)
  ])))
}
export default { render: render }