<script setup lang="ts">

import { DsAlert, DsButton, DsIcon } from '@demvsystems/design-components';
import { Finanzmanager } from '@/application/router/types/types';
import { useRouter } from 'vue-router';
import AuthCardShell from '@/application/components/AuthCardShell.vue';

const router = useRouter();

function getReason(): string {
 return router.options.history.state?.reason as string ?? 'Sie wurden erfolgreich abgemeldet.';
}
</script>

<template>
  <AuthCardShell>
    <div class="text-center pb-5 text-blue-700">
      <DsIcon
        name="hand-wave"
        variant="light"
        size="3x"
        class="animate-handshake"
      />
    </div>
    <DsAlert
      type="success"
      class="w-full mb-7"
      label="Abgemeldet"
    >
      <div class="flex flex-col gap-2">
        <span
          class="text-base"
        >
          {{ getReason() }}
        </span>
      </div>
    </DsAlert>
    <RouterLink
      v-slot="{navigate}"
      :to="{ name: Finanzmanager.LOGIN }"
      custom
    >
      <DsButton
        size="lg"
        class="w-full"
        variant="outline"
        :handler="navigate"
      >
        zur Startseite
      </DsButton>
    </RouterLink>
  </AuthCardShell>
</template>
