import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 55 55"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "27.5",
      cy: "27.5",
      r: "27.5",
      fill: "#4181D2"
    }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      stroke: "#fff",
      "stroke-miterlimit": "10",
      "stroke-width": "2",
      d: "m10.1 36.1 1.1-6.1 4.1 2.8 3-4.1 3.5 2.2 3.7-7.6 4.3 6.1 4-10.9 2.2 6.4 2.5-6.5 3.7 2.2"
    }, null, -1),
    _createElementVNode("path", {
      fill: "none",
      stroke: "#fff",
      "stroke-miterlimit": "10",
      "stroke-width": "3",
      d: "M42.5 12.7V37H9.1"
    }, null, -1)
  ])))
}
export default { render: render }