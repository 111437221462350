import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M16.36 0A15.96 15.96 0 1 0 32.3 15.96C32.3 7.16 25.16 0 16.36 0m0 31.02A15.07 15.07 0 1 1 16.37.9a15.07 15.07 0 0 1-.01 30.12" }, null, -1),
    _createElementVNode("path", { d: "M12.93 9.38a.98.98 0 1 1-1.95 0 .98.98 0 0 1 1.95 0m8.8 0a.98.98 0 1 1-1.95 0 .98.98 0 0 1 1.95 0m2.71 9.91H8.27c-.27 0-.45.18-.45.44s.18.45.45.45h16.17c.27 0 .45-.18.45-.45s-.22-.44-.45-.44" }, null, -1)
  ])))
}
export default { render: render }