import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "font-size": "12",
  viewBox: "0 0 159.9 160"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"none\" stroke=\"#333\" stroke-miterlimit=\"10\" d=\"M16.7 7.2v133.1m.5-.5h135.4\"></path><path fill=\"#4181D2\" d=\"M26.6 49.8h40.6v89.6H26.6z\"></path><path fill=\"#333\" d=\"m11.7 8.6 5-8.6 4.9 8.6zm65.1 74.7h40.6v56H76.8zm74.3 61.6 8.8-5.1-8.8-5z\"></path><text transform=\"translate(0 140.7)\">0</text><text transform=\"translate(30.2 156.4)\">1960</text><text transform=\"translate(79.2 156.4)\">2015</text><text transform=\"translate(0 104.4)\">1</text><text transform=\"translate(0 68)\">2</text><text transform=\"translate(0 31.7)\">3</text><text fill=\"#fff\" transform=\"translate(36.9 69.6)\">2,4</text><text fill=\"#fff\" transform=\"translate(87.1 102)\">1,5</text>", 11)
  ])))
}
export default { render: render }