<template>
  <div class="col-12 text-center py-7 mb-14 bg-dark text-light print:hidden">
    <p class="lead">
      Wenn Sie fertig sind, können Sie Ihre
    </p>
    <button
      type="button"
      class="btn-light-lg"
      @click="store"
    >
      Eingaben speichern
    </button>
    <div
      v-if="storageStatus !== StorageStatus.NONE"
      class="mt-5"
    >
      <div v-if="storageStatus === StorageStatus.PENDING">
        <span class="spinner spinner--white" />
        Wird gespeichert...
      </div>
      <div v-else-if="storageStatus === StorageStatus.SUCCESS">
        <font-awesome-icon
          :icon="['far', 'check-circle']"
          size="lg"
        />
        Gespeichert!
      </div>
      <div v-else-if="storageStatus === StorageStatus.FAILURE">
        <font-awesome-icon
          :icon="['far', 'times-circle']"
          size="lg"
        />
        Fehler beim Speichern
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { captureException } from '@sentry/vue';
import { storeToRefs } from 'pinia';
import { StorageStatus, useAltersvorsorgeStore } from '@/bedarf/components/altersvorsorge/altersvorsorge/store';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const avStore = useAltersvorsorgeStore();
const { storageStatus } = storeToRefs(avStore);

const store = async () => {
  try {
    await avStore.store();
  } catch (error) {
    captureException(error);
  }
};
</script>
