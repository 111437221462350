<template>
  <div :style="{ width: `${maxWidth}px` }">
    <component
      :is="isNativePlatform ? ImageWithAuth : 'img'"
      :class="{ 'hidden': failed }"
      :src="`${appUrl ?? ''}/api/gesellschaft/${id}/logo?maxHeight=${maxHeight}&maxWidth=${maxWidth}`"
      alt=""
      class="rounded self-center"
      @load="emit('load'); failed = false"
      @error="emit('error'); failed = true"
    />
    <slot v-if="failed">
      <div class="rounded-lg h-9 w-20 border border-dashed" />
    </slot>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { appUrl, isNativePlatform } from "@/application/utils/envInfo";
import ImageWithAuth from "@/application/components/ImageWithAuth.vue";

interface Props {
  id: number,
  maxHeight?: number,
  maxWidth?: number,
}

withDefaults(defineProps<Props>(), {
  maxHeight: 48,
  maxWidth: 90,
});
const emit = defineEmits(['load', 'error']);
const failed = ref(true);
</script>
