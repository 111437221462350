import { usePost, useDelete, usePut, useGet } from '../lib/integration';
import type {
  BankAccount,
  IbanDetails,
  MetaCreateBankAccount,
  MetaDeleteBankAccount,
  MetaUpdateBankAccount
} from '@/api/bankAccount/types';

export const getBankAccounts = useGet<void, BankAccount[]>('/api/user/bank-accounts')
export const getIbanDetails = useGet<{ iban: string }, IbanDetails>('/api/iban-details')
export const createBankAccount = usePost<MetaCreateBankAccount, BankAccount>('/api/user/bank-accounts');
export const updateBankAccount = usePut<MetaUpdateBankAccount, BankAccount>('/api/user/bank-accounts/{data.id}');
export const deleteBankAccount = useDelete<MetaDeleteBankAccount, void>('/api/user/bank-accounts/{id}');
