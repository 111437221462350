<template>
  <div
    :class="{'OptionIconGroup': hasIcons,
             'OptionTileGroup': hasTiles,
             'OptionImageGroup': hasImages,
    }"
  >
    <div
      :class="{ 'mb-7': hasIcons }"
      class="flex flex-row gap-5 justify-center"
    >
      <div
        v-for="option in options"
        :key="`${option.value}`"
        :class="optionWidthClasses"
      >
        <FormRadioButton
          :value="option.value"
          :label="option.label"
          :image="option.image"
          :image-src="option.imageSrc"
          :label-classes="option.labelClasses"
          :small-image="option.smallImage"
          :tile="hasTiles"
          :name="name"
          :checked="value === option.value"
          :small-tile="smallTiles"
          :small-margin="smallMargin"
          @change="$emit('change', $event)"
        >
          <SvgVue
            v-if="option.icon"
            :icon="option.icon"
            :class="`Icon--${option.iconClass}`"
            class="icon Icon OptionIcon-animation"
          />
        </FormRadioButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { nanoid } from 'nanoid';

import SvgVue from '@/application/components/SvgVue';
import FormRadioButton from '@/checkup/components/FormRadioButton.vue';

type Option = {
  value: string | number | boolean | null,
  label: string,
  labelClasses?: string,
  image?: boolean,
  imageSrc?: string,
  smallImage?: boolean,
  icon?: string,
  iconClass?: string,
};

interface Props {
  options: Option[],
  value: string | number | boolean | null,
  hasTiles?: boolean,
  hasIcons?: boolean,
  hasImages?: boolean,
  smallTiles?: boolean,
  smallMargin?: boolean,
  optionWidthClasses?: string;
}

withDefaults(defineProps<Props>(), {
  options: () => [],
  value: null,
  optionWidthClasses: 'w-1/3 md:w-1/6',
});

defineEmits(['change']);

const name = nanoid();
</script>
