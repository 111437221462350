import type { EventHint } from '@sentry/vue';
import { browserTracingIntegration, init } from '@sentry/vue';
import type { App } from 'vue';
import type { Router } from 'vue-router';
import { AbortError, UnauthorizedError, ValidationError } from "@/api/lib/errors";
import * as Sentry from "@sentry/browser";
import {
  appEnv,
  isLocalEnv,
  platform,
  getVersionString,
  getAppInfos,
  commit
} from "@/application/utils/envInfo";

const isErrorToIgnore = (error: EventHint['originalException']): boolean => [
  error instanceof UnauthorizedError,
  error instanceof AbortError,
  error instanceof ValidationError,
].some(Boolean);

export const initSentry = (app: App, router: Router | undefined): void => {
  if (isLocalEnv) return;

  init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: appEnv,
    integrations: [
      browserTracingIntegration({ router }),
      Sentry.rewriteFramesIntegration(),
    ],
    tracesSampleRate: 1.0,
    denyUrls: [
      'https://cloud.ccm19.de',
      /webkit-masked-url:\/\/hidden/i,
      /googleapis\.com/i,
    ],
    beforeSend: (event, { originalException }) => isErrorToIgnore(originalException) ? null : event,
  });

  Sentry.setTag('commit', commit);
  Sentry.setTag('platform', platform);
  getAppInfos().then((info) => {
    Sentry.setTag('build', info.build);
    Sentry.setTag('version', info.version);
  });
  getVersionString().then((versionString) => {
    Sentry.setTag('versionString', versionString);
  });
};
