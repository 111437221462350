import type { Api } from "@/api/lib/types";

export async function triggerDownload<Data, Res extends Blob>(blobFn: Api<Data, Res>, filename: string,) {
  const resp = await blobFn();
  if (resp instanceof Error) return false;

  const link = document.createElement('a');
  Object.assign(link, { href: URL.createObjectURL(resp), download: filename });
  link.click();
  URL.revokeObjectURL(link.href);
  link.remove();

  return true;
}
