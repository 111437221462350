import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 34 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M31.34 1.92c-.23.17-.42.38-.63.58-.52.54-1.01 1.1-1.56 1.6a14.1 14.1 0 0 1-4.49 2.85c-3.17 1.24-6.59 1.52-9.9 2.18-1.33.27-2.64.63-3.86 1.25-.22.12-.35.2-.06.38 2 .48 4.02.86 6.09.92.47.01.8.21.91.73a20 20 0 0 1 .36 3.78c.01.83-.1 2.81-.1 2.81.04 0 .1 3.03.1 3.03s-.08 1.2.52 3.08c.6 1.9 1.6 3.62 1.6 3.62.2.16 4.82-2.12 4.82-2.12l2.18-1.71-.18-1.67s-.12-.95-.05-.87c-.1-.62-.27-8.21-.23-9.32.03-.7.02-.91.28-1.57a4.3 4.3 0 0 1 1.22-1.56l1.82-1.61A17.4 17.4 0 0 0 33.07 5c.37-.56.8-1.2.86-1.87.04-.48-.23-1.3-.67-1.56-.66-.41-1.36-.08-1.92.34zM9.91 9.07a3.13 3.13 0 1 0 0-6.25 3.13 3.13 0 0 0 0 6.25m10.48-2.11c1.87 0 3.38-1.51 3.38-3.38S21.99 0 20.12 0s-3.33 1.67-3.11 3.58c.2 1.86 1.51 3.38 3.38 3.38M13.6 23.94l-.35-1.05s-1.98-7.08-1.43-7.42c.56-.34 3.74.08 5.16-.87.5-.34.64-1.36.37-1.82-.28-.51-.84-.53-1.36-.57-.82-.07-1.63-.2-2.44-.35-2.48-.46-4.9-1.13-6.95-2.66-1.65-1.23-2.57-2.95-3.33-4.79-.44-1.05-.85-2.13-1.41-3.13C1.66.9 1.32.76.9.8c-.42.05-.63.32-.77.69-.13.4-.13.8-.12 1.2a16.8 16.8 0 0 0 1.88 6.8c.53 1.05 1.32 1.97 1.68 3.1C5.55 18.73 4.86 25.7 4.64 27.6c-.08.79 4.64 2.86 5.6 3.3.27.13 2.05.77 2.05.86 1.09-.67 3.84-3.73 3.84-3.73l-.8-1-1.14-1.43-.58-1.66z" }, null, -1)
  ])))
}
export default { render: render }