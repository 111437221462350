<script setup lang="ts">
import { onUnmounted, ref, watch } from "vue";
import { captureException } from "@sentry/vue";
import type { MediaRef, MediaType, } from "@/application/components/MediaPreview/types";
import { useGet } from "@/api/lib/integration";
import { useMediaPreviewStore } from "@/application/store/mediaPreviewStore";
import { DsIcon } from "@demvsystems/design-components";
import MediaPreviewToolbar from "@/application/components/MediaPreview/MediaPreviewToolbar.vue";
import { useFileDownload } from "@/application/composables/fileDownload";

interface Props {
  mediaRef: MediaRef,
}

const props = defineProps<Props>();

const mediaType = ref<null|MediaType>(null);
const loadingMsg = ref('');
const errorMsg = ref('');
const zoomInFn = ref<null | (() => void)>(null);
const zoomOutFn = ref<null | (() => void)>(null);
const destroyInstanceFn = ref(() => {});

const pdfTarget = ref<HTMLDivElement | null>(null);
const imageUrl = ref<string>('');

const { downloadS3File } = useFileDownload();

watch(() => props.mediaRef, makePreview, { immediate: true })
onUnmounted(() => reset());

function reset() {
  mediaType.value = null;
  loadingMsg.value = '';
  errorMsg.value = '';
  destroyInstanceFn.value();
  zoomInFn.value = null;
  imageUrl.value = '';
  zoomOutFn.value = null;
}

function close() {
  useMediaPreviewStore().$reset();
}

function setError(msg: string = '') {
  errorMsg.value = msg;
  loadingMsg.value = '';
}

function makePreview(media: MediaRef | null) {
  reset();
  if (!media) return;

  switch (media.ext) {
    case 'pdf': return pdfCase(media);
    case 'jpg':
    case 'jpeg':
    case 'png': return imageCase(media);
    default: return noPreviewCase();
  }
}

async function imageCase(media: MediaRef) {
  mediaType.value = 'image';
  loadingMsg.value = 'Bild wird geladen ...';
  const asd = await useGet<void, {  url: string }>(media.url, { isAbsoluteUrl: true })();
  if (asd instanceof Error) return setError('Bild konnte nicht geladen werden');
  imageUrl.value = asd.url;
}

function noPreviewCase() {
  mediaType.value = 'none';
}

async function pdfCase(media: MediaRef) {
  mediaType.value = 'pdf';
  loadingMsg.value = 'PDF-Datei wird geladen ...';
  const { renderPdf, getPdfErrorMsg } = await import('./Pdf');
  try {
    const response = await useGet<void, { url: string }>(media.url, { isAbsoluteUrl: true })();
    if (response instanceof Error) throw response;
    const { zoomIn, zoomOut, destroyInstance } = await renderPdf(response.url, pdfTarget.value!);
    destroyInstanceFn.value = destroyInstance;
    zoomInFn.value = zoomIn;
    zoomOutFn.value = zoomOut;
  } catch (error: unknown) {
    captureException(error);
    setError(getPdfErrorMsg( error instanceof Error ? error.name : ''));
  } finally {
    loadingMsg.value = '';
  }
}
</script>

<template>
  <div
    class="overlay pt-safe pointer-events-auto fixed left-0 w-full h-full z-[2000] overflow-hidden bg-gray-100 backdrop-blur bg-opacity-50"
    @click.prevent
  >
    <MediaPreviewToolbar
      :download="() => downloadS3File(mediaRef.url, mediaRef.filename)"
      :filename="mediaRef.filename"
      :zoom-in="zoomInFn"
      :zoom-out="zoomOutFn"
      @close="close"
    />

    <div v-if="errorMsg || loadingMsg || mediaType === 'none'" class=" top-1/2 left-1/2 absolute -translate-y-1/2 -translate-x-1/2">
      <div class="flex items-center flex-row">
        <template v-if="loadingMsg">
          <DsIcon class="mr-2 text-blue-700" name="spinner-third" variant="duotone" spin size="lg" />
          {{ loadingMsg }}
        </template>
        <template v-else-if="mediaType === 'none'">
          <DsIcon class="mr-2" name="cancel" size="lg" />
          Keine Vorschau verfügbar
        </template>
        <template v-else-if="errorMsg">
          <DsIcon class="mr-2" name="times" size="lg" />
          {{ errorMsg }}
        </template>
      </div>
    </div>

    <div ref="pdfTarget" class="absolute top-[48px] pt-safe left-0 bottom-0 w-full overflow-auto">
      <div /> <!-- mandatory pdfjs div -->

      <div v-if="imageUrl" class="text-center w-full">
        <img
          :src="imageUrl"
          alt=""
          class="inline-block img-fluid px-4 py-6"
          @load="loadingMsg = ''"
          @error="setError('Bild konnte nicht geladen werden')"
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
 /* needed to show pdfjs pages properly */
:deep(.page) {
  box-sizing: content-box;
}
</style>
