import { nextTick } from "vue";
import type { NavigationHookAfter } from "vue-router";

function updateAppleMeta() {
  const appleMeta = document.head.querySelector('meta[name="apple-itunes-app"]');
  if (appleMeta) {
    // @ts-ignore
    appleMeta.content = appleMeta.content.replace(/(app-argument=)([^"]+)/, '$1'+document.location.href);
  }
}

export const updateDocumentHead: NavigationHookAfter = (to) => {
  const site = 'meine-finanzen.digital';
  nextTick(() => {
    document.title = to.meta?.title ? `${to.meta.title} | ${site}` : site;
    updateAppleMeta();
  });
}
