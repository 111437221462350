<template>
  <SpartenWrapper
    image="images/bedarfsinfo/wohngebaeude/gebaeudeversicherung-titelbild"
    text="Entstehen Schäden an Ihrer Immobilie, die beispielsweise durch Feuer,
          Blitzschlag, Sturm oder das Platzen eines Leitungswasserrohres
          ausgelöst werden, kommen erhebliche Kosten auf Sie zu. Mit einer
          Gebäudeversicherung übertragen Sie das finanzielle Risiko auf einen
          Versicherer."
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    :versorgungen="data.bedarfsinfo.versorgungen"
    versorgungen-headline="Wie gut ist mein Gebäude versichert?"
    versorgungen-description="Gebäudeversicherung"
  >
    <section class="section">
      <div class="container">
        <div class="row text-center mb-7">
          <div class="col-12 col-lg-10 offset-lg-1">
            <h2 class="pb-2.5">
              Was zählt alles zu meinem Gebäude?
            </h2>
            <p>
              Das Gebäude selbst und alle fest mit dem Gebäude verbundenen Gegenstände werden hinzugezählt.
            </p>
          </div>
        </div>

        <div class="row mb-7 items-center">
          <div class="col-12 col-md-6 col-lg-7 mb-7 md:mb-0 text-center">
            <SvgVue
              icon="bedarfsinfo/wohngebaeude/gebaeude.svg"
              class="w-full px-14 sm:px-0"
              style="max-width: 450px;"
            />
          </div>

          <div class="col-12 col-md-6 col-lg-5">
            <h3 class="mb-7">
              Folgende Elemente können über die Gebäudeversicherung versichert werden:
            </h3>
            <BulletPointList
              inline-title
              :items="[
                { title: 'Das Gebäude und Nebengebäude', text: 'Garagen, Carports, Hundehütten und Schuppen'},
                { title: 'Gebäudezubehör', text: 'Terrassenzubehör, Müllcontainer, Klingel- und Briefkastenanlagen'},
                {
                  title: 'Einbauten',
                  text: 'Einbauküchen, Einbauschränke, Sanitäranlagen und fest verlegte Fußbodenbeläge',
                },
                {
                  title: 'Zubehör',
                  text: 'Soweit es der Instandhaltung oder zu Wohnzwecken ' +
                    'dient z. B. Klima- und Zentralheizungsanlagen oder elektrische Rollläden',
                },
              ]"
            />
          </div>
        </div>

        <div class="row mb-14">
          <div class="col-12 col-md-6">
            <NiceToKnow
              :bullets="[{
                title: 'Vom Versicherungsschutz ausgeschlossen',
                text: 'Nicht versichert sind beispielsweise Einrichtungsgegenstände (Möbel, lose Teppiche, Vorhänge) ' +
                  'oder Gebrauchsgegenstände (Haushaltsgeräte, Computer, Kleidung). Diese müssen Sie über die ' +
                  'Hausratversicherung absichern.',
              }]"
            />
          </div>
          <div class="col-12 col-md-6">
            <NiceToKnow
              :bullets="[{
                title: 'Versicherungsschutz nur in Top-Tarifen',
                text: 'Platzen Ableitungsrohre, Wasser- oder Heizungsrohre außerhalb des Grundstücks, besteht ' +
                  'Versicherungsschutz nur in Top-Tarifen. Aufgrund hoher Kosten die infolge eines Schadens ' +
                  'entstehen, empfehlen wir das Risiko mitzuversichern.',
              }]"
            />
          </div>
        </div>

        <div class="row text-center mb-7">
          <div class="col-12 col-lg-10 offset-lg-1">
            <h2 class="pb-2.5">
              Wann habe ich Anspruch auf Leistungen aus der Gebäudeversicherung?
            </h2>
            <p>
              Kommt es zu Schäden am Gebäude, werden die Kosten erstattet, sofern eines dieser Ereignisse die
              Ursache war:
            </p>
          </div>
        </div>

        <div class="row items-center">
          <div class="col-12 col-md-6 col-lg-5 mb-7 md:mb-0 ">
            <h3 class="mb-7">
              Die Gebäudeversicherung erstattet Schäden infolge von...
            </h3>
            <BulletPointList
              inline-title
              :items="[
                { title: 'Feuer und Blitzschlag', text: 'z. B. wenn der Tannenbaum in Brand geraten ist'},
                { title: 'Explosion und Implosion', text: 'z. B. durch eine defekte Gasleitung'},
                { title: 'Leitungswasser', text: 'z. B. nach einem Wasserrohrbruch'},
                { title: 'Sturm und Hagel', text: 'z. B. nach einem Unwetter'},
              ]"
            />
          </div>

          <div class="col-12 col-md-6 col-lg-7 text-center">
            <SvgVue
              icon="bedarfsinfo/wohngebaeude/gebaeude-kaputt.svg"
              class="w-full px-14 sm:px-0"
              style="max-width: 450px;"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="section bg-dark text-light text-center">
      <div class="container">
        <div class="row class mb-5 md:mb-7 lg:mb-14">
          <div class="col-lg-10 offset-lg-1">
            <h3 class="mb-0">
              Um welche Leistungen kann ich den Versicherungsschutz individuell erweitern?
            </h3>
          </div>
        </div>
        <div class="flex flex-col md:flex-row md:flex-wrap md:justify-center gap-6">
          <div
            v-for="{icon, heading, subline} in individualServices"
            :key="icon"
          >
            <div class="flex flex-col items-center text-center w-full">
              <SvgVue
                :icon="`assets/svg/bedarfsinfo/wohngebaeude/${icon}`"
                class="mb-2.5"
                style="height: 75px;"
              />
              <div class="flex flex-col mt-2.5 md:mt-5 md:w-48">
                <h5 class="break-normal">
                  {{ heading }}
                </h5>
                <span class="text-sm"> {{ subline }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row text-center mb-7">
          <div class="col-12 col-lg-10 offset-lg-1">
            <h2 class="pb-2.5">
              Wann habe ich keinen Versicherungsschutz?
            </h2>
          </div>
        </div>

        <div class="row mb-7 items-center">
          <div class="col-12 col-md-6 col-lg-7 mb-7 md:mb-0 text-center">
            <SvgVue
              icon="bedarfsinfo/wohngebaeude/gebaeude-innen.svg"
              class="w-full px-14 sm:px-0"
              style="max-width: 450px;"
            />
          </div>

          <div class="col-12 col-md-6 col-lg-5">
            <h3 class="mb-7">
              Bedeutende Leistungsausschlüsse:
            </h3>
            <BulletPointList
              inline-title
              :items="[
                { title: 'Schäden durch Unwetter', text: 'viele Versicherer regulieren erst ab Windstärke 8'},
                { title: 'Vorsatz', text: 'durch einen selbst verursachten Schaden'},
                { title: 'Grobe Fahrlässigkeit', text: 'hat im Regelfall eine Leistungskürzung zur Folge'},
              ]"
            />
          </div>
        </div>

        <div class="row mb-14">
          <div class="col-12 col-md-6">
            <NiceToKnow
              :bullets="[{
                title: 'Hinweis bei Neubauten',
                text: 'Die Gebäudeversicherung leistet erst ab Bezugsfertigkeit der Immobilie. Zuvor wird im ' +
                  'Regelfall eine prämienfreie Feuer-Rohbauversicherung über den späteren Gebäudeversicherer ' +
                  'abgeschlossen. Diese leistet für Schäden durch Brand, Blitzschlag oder Explosion. Nicht ' +
                  'mitversichert sind Schäden durch Leitungswasser, Sturm oder Hagel.' ,
              }]"
            />
          </div>
          <div class="col-12 col-md-6">
            <NiceToKnow
              :bullets="[{
                title: 'Hinweis',
                text: 'Diese Aufzählung beinhaltet wesentliche Leistungsausschlüsse und ist nicht abschließend.'
              }]"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h2>Welche Zahlungen erhalte ich im Schadenfall?</h2>
            </div>
          </div>
        </div>
        <div class="row mb-14">
          <div class="col col-md-10 offset-md-1 col-lg-8 offset-lg-2 flex flex-col justify-center">
            <BulletPointList
              :items="[
                {
                  text: 'Neuwert bei Wiederaufbau: Kommt es zu einem Totalschaden an der Immobilie, ' +
                    'leistet die Versicherung die Kosten, um die Immobilie in neuem Zustand aufzubauen.'
                },
                {
                  text: 'Zeitwert bei Auszahlung: Soll nach einem Totalschaden die Immobilie nicht ' +
                    'wieder aufgebaut werden, wird das Alter und die Abnutzung der Immobilie bei der ' +
                    'Ermittlung der Schadensumme berücksichtigt (einige Top-Tarife entschädigen auch ' +
                    'den Neuwert).'
                },
                {text: 'Bei beschädigten Sachen werden die notwendigen Reparaturen erstattet.'},
              ]"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="text-center pb-7">
              <h2>Schadenbeispiele</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <p>
              Infolge eines Rohrbruchs tritt Leitungswasser aus und beschädigt Fußboden und Wände.
            </p>
            <BulletPointList
              :items="[
                { text: 'Die Versicherung erstattet die Reparaturkosten, um das Leitungsrohr auszutauschen.' },
                {
                  text: 'Es werden die Kosten zum Trocknen und neu Streichen der Wände sowie der Austausch ' +
                    'des Fußbodens übernommen.'
                },
              ]"
            />
          </div>
          <div class="col-12 col-md-6">
            <p>
              Ein Sturm entwurzelt einen Baum und dieser beschädigt die Eingangstür
              des Gebäudes. Die Immobilie wird kurzfristig unbewohnbar.
            </p>
            <BulletPointList
              :items="[
                { text: 'Es werden die Aufräumkosten für den Baum erstattet.' },
                {
                  text: 'Es werden die Hotelkosten sowie die Kosten zur Bewachung der Eingangstür übernommen, ' +
                    'bis diese repariert ist.'
                },
                { text: 'Es werden die Reparaturkosten für die Eingangstür und Terrasse erstattet.' },
              ]"
            />
          </div>
        </div>
      </div>
    </section>
  </SpartenWrapper>
</template>

<script setup lang="ts">
import SvgVue from '@/application/components/SvgVue';
import BulletPointList from '@/bedarf/components/BulletPointList.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import type { Bedarfsinfo } from '@/bedarf/types/bedarfsinfo';

interface Props {
  data: Bedarfsinfo,
}

defineProps<Props>();

const individualServices = [
  { icon: 'elementarschaeden.svg',
    heading: 'Elementarschäden',
    subline: '(z.B. Überschwemmung, Erdbeben oder Schneedruck)' },
  { icon: 'glasbruch.svg', heading: 'Glasbruch', subline: '(unabhängig von der Schadensursache)' },
  { icon: 'regen-schmelwasser.svg', heading: 'Regen- / Schmelzwasser', subline: '' },
  { icon: 'ueberspannung.svg', heading: 'Überspannungsschäden', subline: '' },
  { icon: 'unbenannte-gefahren.svg', heading: 'Unbenannte Gefahren', subline: '(All-Risk-Deckung)' },
];

</script>
