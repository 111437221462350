<template>
  <section class="section container">
    <h3 class="mb-7">
      Warum sollte ich mit der Einkommenssicherung so früh wie möglich beginnen?
    </h3>
    <div class="row">
      <div
        v-for="{ icon, iconVariant, image, reasons } in ageProgression"
        :key="image"
        class="col"
      >
        <div class="row mb-2.5">
          <img
            :src="image"
            class="mx-auto"
            alt="Warum Jetzt"
            style="height: 200px"
          >
        </div>
        <div class="row mb-5 md:mb-0">
          <div
            v-for="(reason, i) in reasons"
            :key="image + reason"
            class="col-12"
            :class="{'border-t border-dashed pt-2.5': i > 0}"
          >
            <BulletPointList :items="[{text: reason, icon, iconVariant}]" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import BulletPointList from './BulletPointList.vue';
import PersonMiddle from '../../../../assets/images/bedarfsinfo/unfallversicherung/person-middle.jpg';
import PersonYoung from '../../../../assets/images/bedarfsinfo/unfallversicherung/person-young.jpg';
import PersonOld from '../../../../assets/images/bedarfsinfo/unfallversicherung/person-old.jpg';

const ageProgression = [
  {
    icon: 'plus',
    image: PersonYoung,
    reasons: [
      'Niedriger Beitrag',
      'Leistungsausschlüsse sind selten',
      'Hohe Annahmewahrscheinlichkeit',
    ],
  },
  {
    icon: 'circle',
    iconVariant: 'regular',
    image: PersonMiddle,
    reasons: [
      'Mittelhoher Beitrag',
      'Leistungsausschlüsse kommen vor',
      'Mittelhohe Annahmewahrscheinlichkeit',
    ],
  },
  {
    icon: 'minus',
    image: PersonOld,
    reasons: [
      'Hoher Beitrag',
      'Leistungsausschlüsse wahrscheinlich',
      'Niedrige Annahmewahrscheinlichkeit',
    ],
  },
];
</script>
