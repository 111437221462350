import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 98.89 98.89"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"49.44\" cy=\"49.44\" r=\"49.44\" fill=\"#fff\"></circle><path fill=\"#343a40\" d=\"M69.51 23.36a2.36 2.36 0 1 1-4.71.31c-.09-1.31 1.37-4.36 2-5.11s2.63 3.49 2.71 4.8m8.09 11.25a2.37 2.37 0 0 1-4.72.31c-.08-1.31 1.37-4.36 2-5.11s2.63 3.49 2.72 4.8m15.62 7.02a3.59 3.59 0 0 1-7.17.48c-.13-2 2.09-6.63 3.09-7.77s3.95 5.31 4.08 7.29M79.68 23.36a2.36 2.36 0 1 1-4.71.31c-.09-1.31 1.37-4.36 2-5.11s2.62 3.49 2.71 4.8m-20.53-9a1.62 1.62 0 0 1-1.5 1.71 1.6 1.6 0 0 1-1.71-1.5 7.7 7.7 0 0 1 1.38-3.47c.43-.49 1.77 2.37 1.83 3.26M73.31 13a1.6 1.6 0 0 1-3.2.22 7.7 7.7 0 0 1 1.38-3.48c.43-.47 1.76 2.4 1.82 3.26m6.49 32.14L50.29 26.3 20.75 45.12l1.77 6.63 3.09-2s0 18.14-.05 21.3a21.2 21.2 0 0 1 7 1.09c.08 0 9.5 4.39 13.52 4.08C49 76 52 76.35 56.6 74.5c8.19-3.28 12.68-3.5 18.4-2.88V49.8l3.08 2z\"></path><g fill=\"none\" stroke=\"#343a40\" stroke-miterlimit=\"10\"><path d=\"M13.61 74.5s11.7-5.33 22.69.67 25.31-1.34 25.31-1.34S74.17 70 84 75.5\"></path><path stroke-width=\"2\" d=\"M13.45 79.33S25.14 74 36.14 80s25.3-1.33 25.3-1.33S74 74.83 83.78 80.33\"></path><path stroke-width=\"3\" d=\"M18.76 84.33S29 79 38.54 85s22.06-1.33 22.06-1.33 10.95-3.84 19.47 1.66\"></path><path stroke-width=\"4\" d=\"M28.45 91.33s7-4 13.59.49 15.15-1 15.15-1 7.53-2.82 13.38 1.25\"></path></g>", 3)
  ])))
}
export default { render: render }