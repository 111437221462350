import { defineStore } from 'pinia';

import { getMaklerInfo, getBaseData } from "@/api/common/commonApi";
import type { MaklerOrAgencyIdentifier } from "@/api/zugang/types";
import { call } from "@/api/lib/integration";
import { computed, ref } from "vue";
import SHA256 from 'crypto-js/sha256';
import { getActionCount } from '@/api/actions/actionsApi';

export type BaseDataStoreState = {
  isCompany: boolean | null,
  isAuthenticated: boolean | null,
  firmenname: string | null,
  maklerOrAgencyUid: string | null,
  makleremail: string | null,
  maklertelefon: string | null,
  maklername: string | null,
  maklerIsAgency: boolean | null,
  isBroker: boolean | null,
  kundeVorname?: string | null,
  kundeNachname?: string | null,
  kundeEmail: string | null,
  twoFactorEnabled: boolean,
  hasPasswortSet: boolean,
  isFirstLogin: boolean,
  user: { id: number, client_id: number } | null,
  handlungCount: number | null,
};

export const useBaseDataStore = defineStore('baseDataStore', () => {
  const isCompany =  ref<null | boolean>(null);
  const isAuthenticated = ref<null | boolean>(null);
  const firmenname = ref<null | string>(null);
  const maklerOrAgencyUid = ref<null | string>(null);
  const makleremail = ref<null | string>(null);
  const maklertelefon = ref<null | string>(null);
  const maklername = ref<null | string>(null);
  const maklerIsAgency = ref<null | boolean>(null);
  const isBroker = ref<null | boolean>(null);
  const kundeVorname = ref<null | string>(null);
  const kundeNachname = ref<null | string>(null);
  const kundeEmail = ref<null | string>(null);
  const twoFactorEnabled = ref<boolean>(false);
  const hasPasswortSet = ref<boolean>(false);
  const isFirstLogin = ref<boolean>(false);
  const user = ref<null | { id: number, client_id: number } >(null);
  const handlungCount = ref<number | null>(null);

  const hashedUserId = computed(() => user.value ? SHA256(`${user.value.id}`).toString() : null);

  function $reset(): void {
    isCompany.value = null;
    isAuthenticated.value = null;
    firmenname.value = null;
    maklerOrAgencyUid.value = null;
    makleremail.value = null;
    maklertelefon.value = null;
    maklername.value = null;
    maklerIsAgency.value = null;
    isBroker.value = null;
    kundeVorname.value = null;
    kundeNachname.value = null;
    kundeEmail.value = null;
    twoFactorEnabled.value = false;
    hasPasswortSet.value = false;
    isFirstLogin.value = false;
    user.value = null;
    handlungCount.value = null;
  }

  async function fetchBaseData() {
    await call(
      getBaseData(),
      (data) => {
          isCompany.value = data.isCompany;
          isAuthenticated.value = data.isAuthenticated;
          firmenname.value = data.firmenname;
          makleremail.value = data.makleremail;
          maklertelefon.value = data.maklertelefon;
          maklername.value = data.maklername;
          maklerIsAgency.value = data.maklerIsAgency;
          isBroker.value = data.isBroker;
          kundeVorname.value = data.kundeVorname ?? null;
          kundeNachname.value = data.kundeNachname ?? null;
          kundeEmail.value = data.kundeEmail;
          twoFactorEnabled.value = data.twoFactorEnabled;
          hasPasswortSet.value = data.hasPasswortSet;
          isFirstLogin.value = data.isFirstLogin;
          user.value = data.user;
        handlungCount.value = data.handlungCount;
      },
    );
  }

  async function fetchMaklerInfos (maklerOrAgency: MaklerOrAgencyIdentifier, uid: string) {
    maklerOrAgencyUid.value = uid;
    maklerIsAgency.value = maklerOrAgency === 'f';

    await call(
      getMaklerInfo({ maklerOrAgency, uid }),
      (data) => {
          maklername.value = data.maklername;
          firmenname.value = data.firmenname;
          makleremail.value = data.makleremail;
          maklertelefon.value = data.maklertelefon;
          maklerIsAgency.value = data.maklerIsAgency;
      },
    );
  }

  async function updateActionCount() {
    await call(
      getActionCount(),
      ({ count }) => {
        handlungCount.value = count
      },
    );
  }

  return {
    isCompany,
    isAuthenticated,
    firmenname,
    maklerOrAgencyUid,
    makleremail,
    maklertelefon,
    maklername,
    maklerIsAgency,
    isBroker,
    kundeVorname,
    kundeNachname,
    kundeEmail,
    twoFactorEnabled,
    hasPasswortSet,
    isFirstLogin,
    user,
    hashedUserId,
    handlungCount,
    $reset,
    fetchBaseData,
    fetchMaklerInfos,
    updateActionCount,
  };
});
