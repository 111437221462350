<template>
  <form @submit.prevent="submit">
    <DsFormGroup
      label="Ja, ich möchte meinen Account unwiderruflich löschen."
      label-for="delete-account-check"
      inline
    >
      <DsCheckbox
        id="delete-account-check"
        v-model="confirmation"
      />
    </DsFormGroup>
    <div class="text-right mt-3">
      <DsButton
        :disabled="!confirmation"
        variant="danger"
        size="lg"
        type="submit"
      >
        Account löschen
      </DsButton>
    </div>
  </form>
</template>

<script setup lang="ts">
import { DsButton, DsCheckbox, DsFormGroup } from '@demvsystems/design-components';
import { ref } from 'vue';

import { deleteUser } from '@/api/user/userApi';
import { useRequest } from "@/api/lib/integration";

const confirmation = ref(false);
const { call } = useRequest(deleteUser, () => {
  window.location.replace('/');
})

const submit = async () => {
  await call(confirmation.value);
};
</script>
