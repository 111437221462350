import type { App } from "vue";
import { ZXCVBNService } from "@/application/utils/security/passwordHealth";
import { passwordHealthServiceKey } from "@/application/serviceContainer/keys";

export default {
  install(app: App) {
    const zxcvbnService = new ZXCVBNService();
    app.provide(passwordHealthServiceKey, zxcvbnService);
  },
};
