import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 67 131"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#212529",
      d: "M29.98 130.22c-10.85 0-19.49-2.36-25.92-7.06a4.52 4.52 0 0 1-2.16-4.18c.1-1.63.77-2.92 2.01-3.88 1.35-.96 2.98-.92 4.9.14a32.2 32.2 0 0 0 9.94 4.32 42.8 42.8 0 0 0 11.37 1.58c5.76 0 10.42-1.39 13.97-4.17 3.55-2.69 5.33-6.63 5.33-11.81q0-5.475-2.88-9.36c-1.83-2.69-5.19-5.18-10.08-7.49l-15.84-7.34c-7.68-3.65-13.01-7.4-15.99-11.23C1.75 65.9.31 61.29.31 55.9c0-3.45.77-6.81 2.3-10.08a27 27 0 0 1 6.34-8.5 19.8 19.8 0 0 1-2.16-4.75 25.7 25.7 0 0 1-.72-6.48c0-5.37 1.3-9.98 3.9-13.82A25.5 25.5 0 0 1 20.9 3.5 36.9 36.9 0 0 1 36.46.33q15.255 0 25.2 7.05c1.63 1.06 2.35 2.45 2.16 4.18a5.04 5.04 0 0 1-2.16 4.03c-1.35.96-2.93.87-4.75-.29a32.8 32.8 0 0 0-9.8-4.46 39.2 39.2 0 0 0-10.94-1.58c-5.38 0-9.84 1.39-13.4 4.17-3.45 2.79-5.18 6.77-5.18 11.95 0 3.65.92 6.77 2.74 9.36 1.92 2.6 5.33 5.04 10.22 7.35l15.84 7.49c7.78 3.64 13.1 7.39 15.99 11.23q4.32 5.76 4.32 13.68c0 3.55-.77 6.96-2.3 10.22a26.3 26.3 0 0 1-6.34 8.5 14.5 14.5 0 0 1 2.01 4.75q.87 2.73.87 6.48c0 5.38-1.35 9.98-4.03 13.82a25.36 25.36 0 0 1-11.1 8.79c-4.6 2.11-9.88 3.17-15.83 3.17m-19.01-75.9a15.1 15.1 0 0 0 3.17 9.37c2.2 2.78 6.24 5.52 12.1 8.2l13.53 6.34a55 55 0 0 1 7.34 4.32 30.8 30.8 0 0 1 5.19 4.32 16.6 16.6 0 0 0 3.74-10.8c0-3.45-1.1-6.53-3.31-9.21-2.11-2.79-6.1-5.52-11.95-8.21l-13.54-6.48a53.4 53.4 0 0 1-7.49-4.18 50 50 0 0 1-5.04-4.32 16.4 16.4 0 0 0-3.74 10.66Z"
    }, null, -1)
  ])))
}
export default { render: render }