import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 38 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M27.46 0c-3.63 0-6.88 1.95-8.66 4.82A10.15 10.15 0 0 0 0 10.12c0 .28 0 .55.05.77 0 0 1.41 11.48 18.75 20.74C36.18 22.32 37.54 10.9 37.54 10.9c0-.27.05-.55.05-.76A10.11 10.11 0 0 0 27.46 0" }, null, -1)
  ])))
}
export default { render: render }