import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 39 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M6.3 8.87c1.07-.64 2.09-1.4 3.16-2.09.48.8.96 1.6 1.5 2.36.42.64 3.14-1.4 2.61-2.09l-1.6-2.08a330 330 0 0 1 4.64-3.48c1.18-.85.65-1.6-.53-.7-1.55 1.18-3.1 2.3-4.65 3.48-.16-.16-.26-.37-.42-.53C10.2 2.78 9.46 1.6 8.34 1 8 .8 7 1.71 7.05 2.03c.22 1.28 1.07 2.4 1.71 3.52.11.16.22.38.32.54a60 60 0 0 0-2.83 2.35c-.48.32-.59.85.06.43zm17.63.59c0 .21.43-.06.49-.06 1.5-.9 2.61-2.4 3.79-3.68.75.7 1.5 1.44 2.3 2.13.69.6 2.88-1.55 2.4-1.92-.96-.75-1.92-1.5-2.89-2.19.7-.75 1.4-1.55 2.09-2.3.32-.37.64-.96-.05-1.01-.65-.05-1.6.85-1.98 1.28L28.96 3c-.06 0-.06-.05-.11-.05-1.23-.86-2.56-2.19-4.06-2.3-.21 0-.64.27-.64.48.1 1.34 1.76 2.46 2.67 3.37l.43.43c-.32.42-.7.8-1.02 1.23-.59.85-2.24 2.13-2.3 3.3M38.57 27.3c-3.68-2.88-7.2-6.14-12.12-6.36-1.98-.1-3.9.32-5.67 1.18-2.13 1.01-3.42 2.94-5.34 4.11-2.78 1.71-3.63-.85-5.56-2.19-1.33-.96-3.04-.96-4.59-.48-1.39.48-5.72 3.15-4.92 4.97.06.1.38-.1.38-.1 1.33-1.08 3.47-3.32 5.02-1.93.96.85 1.23 2.5 2.2 3.42 1.97 1.87 4.59.96 6.62-.27a23 23 0 0 0 3.57-2.83c1.71-1.5 3.32-1.76 5.56-1.39 3.8.7 7 3.37 9.67 5.98 1.34 1.5 6.2-3.25 5.18-4.11" }, null, -1)
  ])))
}
export default { render: render }