import type { ComputedRef, Ref } from 'vue';
import { computed } from 'vue';

import type { SelectOption } from '@/application/components/propTypes';

type Mapper<T> = (input: T) => SelectOption;

export const toOptions = <T>(
  inputReference: Ref<T[]>,
  labelKey: keyof T | null,
  valueKey: keyof T | null,
  customMapper?: Mapper<T>,
): ComputedRef<SelectOption[]> => {
  let mapper: Mapper<T>;

  if (labelKey !== null && valueKey !== null) {
    mapper = (input) => ({ label: input[labelKey.toString()], value: input[valueKey.toString()] });
  } else if (customMapper !== undefined && typeof customMapper === 'function') {
    mapper = customMapper;
  } else {
    throw new Error('Either label- and valueKey need to be set or a custom mapper must be used');
  }

  return computed(() => inputReference.value.map((value) => mapper(value)));
};
