import type { PropType } from 'vue';

import type { Altersvorsorge } from '@/bedarf/components/altersvorsorge/altersvorsorge/types';

export type CommonProps = {
  age: number,
  income: number,
  calculateGrv: boolean,
};

export const chartProps = {
  data: {
    type: Object as PropType<Altersvorsorge>,
    required: true,
  },
  commonProps: {
    type: Object as PropType<CommonProps>,
    required: true,
  },
} as const;
