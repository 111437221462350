<template>
  <DsModal
    v-if="show"
    :show="show"
    hide-buttons
    @cancel="$emit('close')"
  >
    <ModalWrapper>
      <div class="flex">
        <Heading
          :level="isMobile ? '5' : '4'"
          class="text-lg text-gray-900 font-semibold mb-5 grow text-left"
          text="Dokument einem Vertrag zuordnen"
        />
        <DsIcon
          name="times"
          variant="regular"
          size="lg"
          class="flex-none rounded hover:bg-gray-100 px-3 py-1 text-blue-700"
          @click="$emit('close')"
        />
      </div>
      <DsAlert type="info">
        Wählen Sie einen Vertrag aus, der diesem Dokument zugeordnet sein soll, oder ändern Sie die bisherige Zuordnung.
      </DsAlert>
      <p class="text-start mt-5">
        Dokument: <span class="font-semibold"> {{ fileName }} </span>
      </p>
      <DsFormGroup
        label="Vertrag"
        class="mb-5"
      >
        <DsSelect
          v-model="id"
          value-key="value"
          :data="contractOptions"
          :is-loading="loading"
          :loading="loading"
          size="lg"
          placeholder="Vertrag zuordnen"
        >
          <template #entry="{ entry }">
            <div
              class="text-sm"
              :class="{'text-muted': entry.inactive}"
            >
              {{ entry.label }} {{ entry.inactive ? '(inaktiv)' : '' }}
            </div>
          </template>
        </DsSelect>
      </DsFormGroup>
    </ModalWrapper>
  </DsModal>
</template>

<script setup lang="ts">
import { DsAlert, DsFormGroup, DsIcon, DsModal, DsSelect } from '@demvsystems/design-components';
import { captureException } from '@sentry/vue';
import { ref, watch } from 'vue';

import { updateDocument } from '@/api/documents/documentsApi';
import type { Document } from '@/api/documents/types';
import Heading from '@/application/components/Heading.vue';
import { useDetectMobile } from '@/application/composables/detectMobile';
import { useDocumentStore } from '@/documents/store/documentStore';
import { resolveRelations } from '@/application/utils/api/resolveRelations';
import type { NestedDocuments } from "@/contracts/types/types";
import { call } from "@/api/lib/integration";
import ModalWrapper from "@/application/components/ModalWrapper.vue";
import { useContractStore } from "@/contracts/stores/contractStore";
import { storeToRefs } from "pinia";

interface Props {
  document: Document,
  show: boolean;
  fileName: string;
}

const props = defineProps<Props>();

const emit = defineEmits(['close', 'open']);

const loading = ref(false);
const editing = ref(false);
const id = ref<number | null>(props.document.contract?.id ?? null);

const { updateUserDocument } = useDocumentStore();
const { contractOptions } = storeToRefs(useContractStore());
const { isMobile } = useDetectMobile();

const linkDocumentToContract = async (document: Document, contractId: number) => {
  loading.value = true;
  await call(
    updateDocument({
      contract_id: contractId,
      document_id: document.id,
    }),
    (data) => {
      if (document.userDocumentId === null) return;

      const { documents: [updatedDocument] } = resolveRelations(data) as NestedDocuments;
      updateUserDocument(document.userDocumentId, updatedDocument);
    },
    (error) => {
      captureException(error)
    },
  );
  loading.value = false;
  editing.value = false;
};

watch(id, (contractId) => {
  if (contractId === null) return;
  linkDocumentToContract(props.document, contractId);
  emit('close');
});
</script>
