<template>
  <div class="graph mt-7">
    <strong>Stationär</strong>
    <Bar
      :ratio="ratio.stationaer"
      left-label="Gesetzliche Pflegeversicherung"
      right-label="Ver&shy;sorgungs&shy;lücke"
    >
      {{ format(stationaer.spv) }}<sup>2</sup>

      <template #stack>
        <div>
          {{ format(stationaer.luecke) }}<sup>1</sup>
        </div>
      </template>
    </Bar>

    <strong>Ambulant</strong>
    <Bar
      :ratio="ratio.ambulant"
      left-label="Gesetzliche Pflegeversicherung"
      right-label="Ver&shy;sorgungs&shy;lücke"
    >
      {{ format(ambulant.spv) }}<sup>3</sup>

      <template #stack>
        <div>
          {{ format(ambulant.luecke) }}<sup>1</sup>
        </div>
      </template>
    </Bar>
  </div>
</template>

<script>
import Bar from '@/application/utils/chart/ChartBarHorizontal.vue';
import { format } from '@/application/utils/money';

export default {
  components: {
    Bar,
  },

  props: {
    stationaer: {
      type: Object,
      required: true,
    },
    ambulant: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ratio() {
      return {
        stationaer: (this.stationaer.luecke / this.stationaer.total) * 100,
        ambulant: (this.ambulant.luecke / this.ambulant.total) * 100,
      };
    },
  },

  methods: {
    format,
  },
};
</script>
