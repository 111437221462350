<template>
  <popover
    v-click-outside="hide"
    :default-show="false"
    :show="show"
    :width="320"
    :icon="false"
    :options="options"
    class="inline-block"
    @hide="hide"
  >
    <template #reference>
      <font-awesome-icon
        :icon="['far', 'question-circle']"
        class="ml-1.5 cursor-pointer"
        @click.stop="show = !show"
      />
    </template>

    <div class="text-left">
      <slot>
        <div>
          {{ content }}
        </div>
      </slot>
    </div>
  </popover>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import vClickOutside from 'v-click-outside';

import Popover from './Popover.vue';

export default {
  components: {
    FontAwesomeIcon,
    Popover,
  },

  directives: {
    ClickOutside: vClickOutside.directive,
  },

  props: {
    content: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      show: false,
    };
  },

  methods: {
    hide() {
      this.show = false;
    },
  },
};
</script>
