import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 63 42"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "none",
      "fill-rule": "evenodd",
      stroke: "#000",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, [
      _createElementVNode("path", { d: "M36.21 38.35c5.11 1.17 6.63.48 8.79-4.55-1.64-1.02-3.35-2.18-5.15-3.2-2.99-1.71-5.7-3.5-6.88-6.93-.6-1.79-2.03-3.37-3.28-5.33l-14.88-6.22c-3.53 2.2-7.01 5.62-12.78 2.93C1.34 17.74.61 19.6.5 21.52c-.04.72 1.23 1.78 2.13 2.36 1.45.94 3.1 1.6 4.66 2.38m21.52 8.96L14.68 29.3" }),
      _createElementVNode("path", { d: "M14.68 29.3a3.92 3.92 0 1 1-7.24-3.02 3.92 3.92 0 0 1 7.24 3.03zm21.35 8.98a3.92 3.92 0 1 1-7.23-3.03 3.92 3.92 0 0 1 7.23 3.03m-28.9-22.3s6.6 2.75 8.81.14l.61-1.46-2.78-1.86m16.52 6.43-3.09-.02s.4 4.57 4.86 7.2l3.31 1.03m1.5-21.74-6.5 3.22 2.55 4.46 4.26-1.97 6.6 11.9 9.81-6.76-7.12-9.4 3.33-2.02L46.16.5l-5.3 3.12s-.3 3.11-4 2.08zm14.55 14.14 10.28.32-.41 14.86-5.93-.21-.62-4.99-2.18 4.26-5.2-1.87z" })
    ], -1)
  ])))
}
export default { render: render }