import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 33.7 89.7"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "16.5",
      cy: "7.1",
      r: "7.1",
      class: "cls-1"
    }, null, -1),
    _createElementVNode("path", {
      d: "M29 83.1a154.7 154.7 0 0 1-5.2-43.6l10-10.2S24 17 23.4 17c-.8-.4-7 0-7 0s-6.4-1-7.9 0L.2 29l8.1 9.4C7 56.3 4 83 4 83s-3.8 1.6-4 3.4a7.5 7.5 0 0 0 .5 3.2l5.4-1 2.8-2.3V83l6-31.3h2.7L24.3 83l-.1 3.3 2.8 2.3 5.4 1a7.5 7.5 0 0 0 .4-3.2C32.7 84.7 29 83 29 83zm-4.3-58 4.6 3.9-5.4 4.8a86 86 0 0 1 .8-8.7M8.7 33l-4.3-4.4L9 24.4c0 1.8 0 4.8-.3 8.5z",
      class: "cls-1"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#4181D2",
      d: "M4.4 20.5h24.9v17.7H4.4z"
    }, null, -1)
  ])))
}
export default { render: render }