/**
 * Pretty prints a given size in MiB to MB and rounds to zero decimal places. Sizes less
 * than 1 MB will be displayed as '<1 MB'.
 *
 * @param size Size in MiB
 * @returns The pretty printed size in MB
 */
export function prettyPrintSize(size: number): string {
  const mbSize = size / 1000 ** 2;
  return `${mbSize < 1 ? '<1' : Math.round(mbSize)} MB`;
}
