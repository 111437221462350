import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 51.2 61.05"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "#fff",
      "data-name": "Layer 2"
    }, [
      _createElementVNode("path", { d: "m8.38 9.96 39.7 5.08L45 39.09 5.3 34.01z" }),
      _createElementVNode("path", {
        stroke: "#e5e5e5",
        "stroke-miterlimit": "10",
        d: "M.84 28.91c-1.27 7.33.94 21.15 6.87 29.28 0 0 5.56 6.3 8.11-1.68C19.11 46.23 16.9 42.43 21 40.88c0 0 3.87-2.65 7.4 16.4 0 0 4.37 13 20.12-24.85.05-.05-43.36-6.11-47.68-3.52zm48.52.45c8-32.73-22.6-23.58-22.09-23.77s-7.07-4-14-5c0 0-11-2.05-11.42 15.18 0 0-.88 5.51-1.06 9.82 9.05 1.25 12.28-1.79 48.18 4.9.03-.06.36-.98.39-1.13z"
      })
    ], -1)
  ])))
}
export default { render: render }