import type { NavigationHookAfter } from "vue-router";
import { currentRoute, isRouting } from "@/checkup/state";

export const checkupStatusGuard: NavigationHookAfter = (to, from, failure) => {
  if (failure) {
    return false;
  }

  isRouting.value = false;
  currentRoute.value = to;

  return true;
};
