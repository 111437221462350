<script setup lang="ts">
import {
  DsAlert,
  DsButton,
  DsForm,
  DsFormGroup,
  DsInput,
  DsSwitch,
  NotificationVariant,
  useNotification
} from '@demvsystems/design-components';
import { updateBankAccount } from '@/api/bankAccount/bankAccountsApi';
import { useDetectMobile } from '@/application/composables/detectMobile';
import type { BankAccount, BankAccountContract } from '@/api/bankAccount/types';
import { useUserDataStore } from '@/user/store/userDataStore';
import { ref } from 'vue';
import SelectContracts from '@/user/components/SelectContracts.vue';
import ContractsField from '@/user/components/ContractsField.vue';
import { trackEvent } from '@/application/utils/matomo';
import { TrackEventCategory } from '@/application/utils/matomo/events';
import { useForm } from "@/api/lib/integration";

interface Props {
  bankAccount: BankAccount
}

const props = defineProps<Props>();
const emit = defineEmits(['close']);
const showSelectContracts = ref(false);

const { isMobile } = useDetectMobile();
const { fetchBankAccounts } = useUserDataStore();
const { show: showNotification } = useNotification();

const { form, submit, reset, errors, errorMsg } = useForm(
    updateBankAccount,
    {
      data: {
        id: props.bankAccount.id,
        depositor: props.bankAccount.depositor,
        main: props.bankAccount.main,
        contractIds: props.bankAccount.contractIds,
      },
      previous: {
        id: props.bankAccount.id,
        depositor: props.bankAccount.depositor,
        main: props.bankAccount.main,
        contractIds: props.bankAccount.contractIds,
      },
      additional: {
        contracts: [] as BankAccountContract[],
      },
    },
    async () => {
      showNotification('Ihre Daten wurden erfolgreich gespeichert.', NotificationVariant.Success);
      trackEvent({ category: TrackEventCategory.BANK_ACCOUNT, action: 'Bearbeiten', name: 'click' });
      await fetchBankAccounts();
      close();
    },
  );

function close() {
  reset();
  showSelectContracts.value = false;
  emit('close');
}

async function handleEditAccount() {
  form.value.additional.contracts = useUserDataStore()
    .getBankAccountContractByIds([...form.value.data.contractIds, ...props.bankAccount.contractIds]);
  await submit();
}
</script>

<template>
  <Transition name="fade" mode="out-in">
    <DsForm
      v-if="!showSelectContracts"
      :validation-errors="errors"
      @submit.prevent
    >
      <div class="flex flex-col gap-4 pt-6 pb-2">
        <DsFormGroup
          label="Kontoinhaber"
          :inline-reverse="!isMobile"
          validation-name="depositor"
          class="whitespace-nowrap text-start md:text-end"
        >
          <div class="w-full flex justify-end">
            <DsInput
              v-model="form.data.depositor"
              placeholder=""
              class="w-full md:w-80"
              size="lg"
            />
          </div>
        </DsFormGroup>

        <DsFormGroup
          label="Standard"
          :inline-reverse="!isMobile"
          class="whitespace-nowrap"
        >
          <div class="w-full flex md:justify-end py-1">
            <DsSwitch v-model="form.data.main" class="md:mr-[272px]" size="lg" />
          </div>
        </DsFormGroup>

        <ContractsField
          :model-value="form.data.contractIds"
          @show-select-contracts="showSelectContracts = !showSelectContracts"
        />
      </div>
    </DsForm>
    <SelectContracts
      v-else
      v-model="form.data.contractIds"
      :bank-account-id="bankAccount.id"
      @close="showSelectContracts = false"
    />
  </Transition>

  <DsAlert
    v-if="errorMsg"
    :label="errorMsg"
    type="error"
    class="my-5"
  />

  <div class="flex flex-col-reverse sm:flex-row justify-end gap-3 mt-3">
    <DsButton
      variant="secondary"
      size="lg"
      @click="close"
    >
      Abbrechen
    </DsButton>
    <DsButton
      variant="primary"
      size="lg"
      :handler="handleEditAccount"
    >
      Speichern
    </DsButton>
  </div>
</template>
