<template>
  <SpartenWrapper
    image="images/bedarfsinfo/altersvorsorge/frau-im-stuhl"
    :text="subtitle"
    alt="Nachdenkliche Frau im Stuhl"
    :dokumente="data.bedarfsinfo.dokumente"
    :links="data.bedarfsinfo.links"
    :versorgungen="data.bedarfsinfo.versorgungen"
    versorgungen-headline="Wie bin ich aktuell auf meine Rente vorbereitet?"
    versorgungen-description="Altersvorsorge"
  >
    <section class="section">
      <div class="container">
        <div class="text-center mb-7">
          <h2>
            Wie viel Geld steht mir mit
            {{ data.bedarfsinfo.isBeamter ? 'Pensionseintritt' : 'Rentenbeginn' }}
            zur Verfügung?
          </h2>
        </div>
        <div class="row pb-7 text-center">
          <div class="col-12 col-md-10 offset-md-1">
            Mit dem Anspruch auf {{ data.bedarfsinfo.isBeamter ? 'die Pension' : 'die gesetzliche Rente' }}
            baut sich ein Teil Ihrer Altersvorsorge bereits auf. Wollen Sie im Ruhestand
            jedoch Ihren Lebensstandard beibehalten, können Sie Ihre Versorgungslücke mit
            einer privaten Altersvorsorge schließen.
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-10 offset-md-1">
            <AltersvorsorgeChart
              v-bind="data.vlRechner"
              :is-beamter="data.bedarfsinfo.isBeamter"
              :is-freier-beruf="data.bedarfsinfo.isFreierBeruf"
            />
          </div>
        </div>
      </div>
    </section>

    <template v-if="!data.bedarfsinfo.isBeamter">
      <div class="tilt-divider bg-light" />

      <Angestellter />
    </template>

    <JumboImage image="images/bedarfsinfo/altersvorsorge/nachdenklicher-mann" />

    <AltersvorsorgeTypeQuiz v-bind="data.typeQuiz" />

    <div
      v-if="data.bedarfsinfo.isFreierBeruf"
      class="container"
    >
      <div class="row justify-center">
        <div class="col-md-8">
          <NiceToKnow
            title="Tipp:"
            :bullets="[tipp]"
          />
        </div>
      </div>
    </div>

    <AltersvorsorgeFinder v-bind="data.tarifQuiz" />
  </SpartenWrapper>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import Angestellter from '@/bedarf/components/altersvorsorge/altersvorsorge/Angestellter.vue';
import AltersvorsorgeChart from '@/bedarf/components/altersvorsorge/altersvorsorge/chart/index.vue';
import AltersvorsorgeFinder from '@/bedarf/components/altersvorsorge/altersvorsorge/finder/index.vue';
import AltersvorsorgeTypeQuiz from '@/bedarf/components/altersvorsorge/altersvorsorge/quiz/TypeQuiz.vue';
import JumboImage from '@/bedarf/components/JumboImage.vue';
import NiceToKnow from '@/bedarf/components/NiceToKnow.vue';
import SpartenWrapper from '@/bedarf/components/SpartenWrapper.vue';
import type { AltersvorsorgeInfo } from '@/bedarf/types/bedarfsinfo';

interface Props {
  data: AltersvorsorgeInfo,
}

const props = defineProps<Props>();

const retirementName = computed(() => (props.data.bedarfsinfo.isBeamter ? 'Pensions' : 'Renten'));
const subtitle = computed(() => (
  `Es besteht Konsens in der Gesellschaft darüber, dass die private
   Altersvorsorge wichtig ist. Gestritten wird vor allem über die richtigen
   Durchführungswege und Produkte, um im ${retirementName.value}alter optimal versorgt
   zu sein. Hier finden Sie Fakten, um eine Entscheidungsgrundlage zu bekommen.`
));

const tipp = {
  title: 'Tipp:',
  text: `
    Sofern Ihr Ehepartner die Voraussetzungen zur Riester-Rente erfüllt und diese
    Förderung nicht oder nur teilweise in Anspruch nimmt, so sind Sie mittelbar förderfähig.
    Bei Abschluss einer Riester-Rente können Sie die Förderung bis zur Förderhöchstgrenze in Anspruch nehmen.
    `,
};

</script>
